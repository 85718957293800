export function agency(
  state = { requestStatus: 0, agencyData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'AGENCY_SUCCESS':
      return {
        ...state,
        requestStatus: 'AGENCY_SUCCESS',
        requestAgencyStatus: 'AGENCY_SUCCESS',
        agencyData: action.data.data,
        data: action.data,
      };
    case 'AGENCY_FAILURE':
      return {
        ...state,
        requestStatus: 'AGENCY_FAILURE',
        requestAgencyStatus: 'AGENCY_FAILURE',
        agencyData: {},
        data: action.data,
      };
    case 'AGENCY_REQUEST':
      return {
        ...state,
        requestStatus: 'AGENCY_REQUEST',
        requestAgencyStatus: 'AGENCY_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
