import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { categoryActions, userActions } from '../_actions';
import { LoadingImg } from '../_components';
const CandidateRegister = props => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [user, setUser] = useState({
    name: '',
    last_name: '',
    password: '',
    confirmpassword: '',
    email: '',
    is_temp_candidate: 1,
    is_referal: true,
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesrows, setcategoriesrows] = useState();
  // handler for state user values from inputs
  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  // call get categories  for select options
  const getAllcategories = () => {
    const { dispatch } = props;
    dispatch(categoryActions.categoryListNoAuth({ status: 1 })).then(res => {
      let categoryData = res.data.rows;
      let categorylist = [];
      for (let i = 0; i < categoryData.length; i++) {
        categorylist.push(
          <option value={categoryData[i]._id}>{categoryData[i].name}</option>,
        );
      }
      setcategoriesrows(categorylist);
    });
  };
  // effect for get all categories
  useEffect(() => {
    getAllcategories(1);
    setUser({
      ...user,
      referal_id: id,
    });
  }, []);
  // validate email  ---
  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  // submit candidate data
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    const { name, last_name, email, confirmpassword, password } = user;
    const { dispatch } = props;
    if (name && last_name && email && password) {
      if (password != confirmpassword) {
        setIsLoading(false);
        setError({
          common: 'password does not match',
        });
        setSubmitted(false);
        return;
      }
      if (!validateEmail(email)) {
        setIsLoading(false);
        setError({
          common: 'enter a valid email',
        });
        setSubmitted(false);
        return;
      }
      setIsLoading(true);
      if (!user.referal_id) {
        alert('no referal _id ');
        return;
      }
      dispatch(userActions.registerCandidate(user)).then(
        function () {
          setIsLoading(false);
          navigate('/referalsuccess');
        },
        function (err) {
          setIsLoading(false);

          setError({
            common: err,
          });
        },
      );
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/flexi_logo_bg.jpg" />
          </div>
        </div>
        <div className=" row justify-content-center">
          <div className="col-md-8 col-lg-8  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6">
                <h2 className="bold">Welcome to Flexirecs !</h2>
                <span className="color-red">{error.common}</span>
                <form name="form" onSubmit={handleSubmit}>
                  {/* //--------------first name --------------- */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.username ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="username">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={user.name}
                      onChange={handleChange}
                    />
                    {submitted && !user.name && (
                      <div className="help-block">name is required</div>
                    )}
                  </div>
                  {/* ///----------last name --------------- */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.last_name ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="username">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      value={user.last_name}
                      onChange={handleChange}
                    />
                    {submitted && !user.last_name && (
                      <div className="help-block">last name is required</div>
                    )}
                  </div>
                  {/* //-------email------------ */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.email ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="username">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                    {submitted && !user.email && (
                      <div className="help-block">Email is required</div>
                    )}
                  </div>
                  {/* //--------------category ---------- */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.category ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="username">Jobrole</label>
                    <select
                      className="select col-md-12 selectDesign"
                      name="category"
                      value={user.category}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      {categoriesrows}
                    </select>
                    {submitted && !user.category && (
                      <div className="help-block">Jobrole is required</div>
                    )}
                  </div>
                  {/* //--------------job type  ---------- */}
                  <div
                    className={
                      'form-group' + (submitted && !user.about ? ' ' : '')
                    }
                  >
                    <label htmlFor="username">Job Type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="about"
                      value={user.about}
                      onChange={handleChange}
                    />
                  </div>
                  {/* password---------------- */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.password ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                    {submitted && !user.password && (
                      <div className="help-block">Password is required</div>
                    )}
                  </div>
                  {/* //------------confirm password---------- */}
                  <div
                    className={
                      'form-group' +
                      (submitted && !user.confirmpassword ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="password">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="confirmpassword"
                      value={user.confirmpassword}
                      onChange={handleChange}
                    />
                    {submitted && !user.confirmpassword && (
                      <div className="help-block">
                        confirmpassword is required
                      </div>
                    )}
                  </div>
                  <div className="form-group mt-5">
                    <button className="btn btn-primary-login width-100">
                      REGISTER
                    </button>
                    {isLoading && <LoadingImg />}
                  </div>
                </form>
              </div>
              {/* //----second div */}
              <div className="col-md-6 d-flex align-items-center">
                <div className="col-md-4 col-lg-4 col-sm-push-3  d-flex align-items-center">
                  <img
                    className="image-width-loginscreen"
                    src="/assets/img/reg_candi.png"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedCandidateRegister = connect(mapStateToProps)(CandidateRegister);
export { connectedCandidateRegister as CandidateRegister };
