export function candidateemployerallocation(
  state = {
    requestStatus: 0,
    allocatecandidateData: {},
    blockbookingData: {},
    data: {},
  },
  action,
) {
  switch (action.type) {
    case 'ALLOCATECANDIDATE_SUCCESS':
      return {
        ...state,
        requestStatus: 'ALLOCATECANDIDATE_SUCCESS',
        requestAllocatecandidateStatus: 'ALLOCATECANDIDATE_SUCCESS',
        allocatecandidateData: action.data.data,
        data: action.data,
      };
    case 'ALLOCATECANDIDATE_FAILURE':
      return {
        ...state,
        requestStatus: 'ALLOCATECANDIDATE_FAILURE',
        requestAllocatecandidateStatus: 'ALLOCATECANDIDATE_FAILURE',
        allocatecandidateData: {},
        data: action.data,
      };
    case 'ALLOCATECANDIDATE_REQUEST':
      return {
        ...state,
        requestStatus: 'ALLOCATECANDIDATE_REQUEST',
        requestAllocatecandidateStatus: 'ALLOCATECANDIDATE_REQUEST',
        data: action.data,
      };

    case 'BLOCKBOOKINGLIST_SUCCESS':
      return {
        ...state,
        requestStatus: 'BLOCKBOOKINGLIST_SUCCESS',
        requestblockbookingStatus: 'BLOCKBOOKINGLIST_SUCCESS',
        blockbookingData: action.data.data,
        data: action.data,
      };
    case 'BLOCKBOOKINGLIST_FAILURE':
      return {
        ...state,
        requestStatus: 'BLOCKBOOKINGLIST_FAILURE',
        requestblockbookingStatus: 'BLOCKBOOKINGLIST_FAILURE',
        blockbookingData: {},
        data: action.data,
      };
    default:
      return state;
  }
}
