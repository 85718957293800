/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     INVOICE_SUCCESS:'INVOICE_SUCCESS',
     INVOICE_REQUEST:'INVOICE_REQUEST',
     INVOICE_FAILURE:'INVOICE_FAILURE', 
    */
export function invoice(
  state = { requestStatus: 0, invoiceData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'INVOICE_SUCCESS':
      return {
        ...state,
        requestStatus: 'INVOICE_SUCCESS',
        requestInvoiceStatus: 'INVOICE_SUCCESS',
        invoiceData: action.data.data,
        data: action.data,
      };
    case 'INVOICE_FAILURE':
      return {
        ...state,
        requestStatus: 'INVOICE_FAILURE',
        requestInvoiceStatus: 'INVOICE_FAILURE',
        invoiceData: {},
        data: action.data,
      };
    case 'INVOICE_REQUEST':
      return {
        ...state,
        requestStatus: 'INVOICE_REQUEST',
        requestInvoiceStatus: 'INVOICE_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
