/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faCircleCheck,
//   faCalendar,
//   faBars,
// } from '@fortawesome/free-solid-svg-icons';

const BlockListcalendar = props => {
  //--------TO CHECK WHAT DAY IS IT

  const tablesample = () => {
    return (
      <div className="table-scroll-div">
        <table className="tablescroll">
          <thead className="th-stciky">
            <tr className="th-stciky">
              <th className="fixedcol th-first">1</th>

              <th className=" fixedcol th-first">2</th>
              <th className="tdscroll ">3</th>
              <th className="tdscroll ">4</th>
              <th className="tdscroll">abcd</th>
              <th className="tdscroll">abcd</th>
              <th className="tdscroll">abcd</th>
              <th className="tdscroll">abcd</th>
              <th className="tdscroll">abcd</th>
              <th className="tdscroll">abcd</th>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="fixedcol th-first">11</th>
              <td className="fixedcol th-first">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
            </tr>
            <tr>
              <td className="fixedcol th-first">efgh</td>
              <td className="fixedcol th-first">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
              <td className="tdscroll">efgh</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    return (
      <div>
        hello table
        <div>{tablesample()}</div>
      </div>
    );
  });
};

function mapStateToProps() {
  return {};
}

const connectedBlockListcalendar = connect(mapStateToProps)(BlockListcalendar);
export { connectedBlockListcalendar as BlockListcalendar };
