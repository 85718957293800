import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, reducerConstants } from '../_constants';

const UserAdd = props => {
  let navigate = useNavigate();
  let { id } = useParams();

  const [user, setUser] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    /**
     * Edit data using url query
     */
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllUser(1, query);
    }

    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllUser(1, query);
    }
  }, []);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  /**
   * Get all users
   * */
  const getAllUser = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(userActions.userList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setUser(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    // setSubmitted(true);
    const { dispatch } = props;

    setIsLoading(true);
    dispatch(userActions.userAdd(user)).then(
      () => {
        navigate('/userslist');
        setIsLoading(false);
      },
      err => {
        setError({
          common:
            err && err.message ? err.message : alertConstants.SERVER_ERROR,
        });
        hideError();
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="UserAdd">
      <div className="col-md-8 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className="card-header page-header ">
            <div className="card-header page-header">
              <div className="page-title-content">
                <h4 className="card-title">Add user </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="email"
                      value={user.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">phone</label>
                    <input
                      type="phone"
                      className="form-control"
                      name="phone"
                      placeholder="phone"
                      value={user.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Profile_pic</label>
                    <input
                      type="Profile_pic"
                      className="form-control"
                      name="Profile_pic"
                      placeholder="Profile_pic"
                      value={user.Profile_pic}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Postcode</label>
                    <input
                      type="Postcode"
                      className="form-control"
                      name="Postcode"
                      placeholder="Postcode"
                      value={user.Postcode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button className="btn btn-primary btn-wd">
                      {' '}
                      Save
                      {props.requestStatus ===
                        reducerConstants.USER_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {
    // requestStatus,
    // userData
  };
}
const connectedUserAdd = connect(mapStateToProps)(UserAdd);
export { connectedUserAdd as UserAdd };
