import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { staffActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const StaffAdd = props => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [staff, setStaff] = useState({ role: 6 });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    /**
     * Edit data using url query
     */
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllStaff(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllStaff(1, query);
    }
  }, []);
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  /**
   * Get all staffs
   * */
  const getAllStaff = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(staffActions.staffList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setStaff(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // event handler for set state staff
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'phone' && value.length > 11) {
      return;
    }
    setStaff(prevState => ({ ...prevState, [name]: value }));
  };
  // validate phone number
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // submit aciton to add staff
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (staff.name && staff.email && staff.phone && staff.role) {
      if (!query._id && !staff.confirmpassword) {
        setError({ common: 'confirm password required' });
        return;
      }
      if (!query._id && !staff.password) {
        setError({ common: ' password required' });
        return;
      }
      if (query._id && staff.password) {
        if (staff.password !== staff.confirmpassword) {
          setError({ common: ' password does not match' });
          return;
        }
      }
      if (staff.password !== staff.confirmpassword && !query._id) {
        setError({ common: 'password does not match' });
        return;
      } else {
        let contact_number = valid_contact_number(staff.phone);
        if (contact_number == false) {
          setError({ common: 'Phone number not valid' });
          return;
        }
        setIsLoading(true);
        dispatch(staffActions.staffAdd(staff)).then(
          () => {
            navigate('/stafflist');
            setIsLoading(false);
          },
          err => {
            setError({ common: err ? err : '' });
            hideError();
            setIsLoading(false);
          },
        );
      }
    }
  };
  return (
    <div className="StaffAdd">
      <div className="col-md-12 col-sm-6">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className="card-header page-header ">
            <div className="card-header page-header">
              <div className="page-title-content">
                <h4 className="card-title">Add staff </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      name : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.name ? ' has-error' : '')
                      }
                    >
                      <input
                        type="name"
                        className="form-control"
                        name="name"
                        placeholder="name"
                        value={staff.name}
                        onChange={handleChange}
                      />
                      {submitted && !staff.name && (
                        <div className="help-block">Name is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Last name : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.last_name ? ' has-error' : '')
                      }
                    >
                      <input
                        type="last name"
                        className="form-control"
                        name="last_name"
                        placeholder="last name"
                        value={staff.last_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Email : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.email ? ' has-error' : '')
                      }
                    >
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        value={staff.email}
                        onChange={handleChange}
                      />
                      {submitted && !staff.email && (
                        <div className="help-block">Email is required</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Phone : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.phone ? ' has-error' : '')
                      }
                    >
                      <input
                        type="phone"
                        className="form-control"
                        name="phone"
                        placeholder="Phone number"
                        value={staff.phone}
                        onChange={handleChange}
                      />
                      {submitted && !staff.phone && (
                        <div className="help-block">
                          Phone number is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Password : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.password ? ' has-error' : '')
                      }
                    >
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={staff.password}
                        onChange={handleChange}
                      />
                      {submitted && !staff.password && !query._id && (
                        <div className="help-block">Password is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">
                      Confirm Password : <span>*</span>
                    </label>
                    <div
                      className={
                        'form-group ' +
                        (submitted && !staff.confirmpassword
                          ? ' has-error'
                          : '')
                      }
                    >
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        placeholder="confirmpassword"
                        value={staff.confirmpassword}
                        onChange={handleChange}
                      />
                      {submitted && !staff.confirmpassword && !query._id && (
                        <div className="help-block">
                          confirmpassword is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group float-right">
                    <button
                      className="btn btn-primary btn-wd"
                      data-cy="addstaff"
                    >
                      Save
                      {props.requestStatus ===
                        reducerConstants.STAFF_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />{' '}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, staffData } = state.staff;
  return { requestStatus, staffData };
}
const connectedStaffAdd = connect(mapStateToProps)(StaffAdd);
export { connectedStaffAdd as StaffAdd };
