import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const documentsService = {
  documentsAdd,
  documentsList,
  documentsDelete,
};

function documentsAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/adddocuments', requestOptions).then(
    miscService.handleResponse,
  );
}
function documentsList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getdocumentsList', requestOptions).then(
    miscService.handleResponse,
  );
}
function documentsDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletedocuments', requestOptions).then(
    miscService.handleResponse,
  );
}
