import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { qualificationService } from '../_services';
export const qualificationActions = {
  qualificationAdd,
  qualificationList,
  qualificationDelete,
};

function qualificationAdd(qualificationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.QUALIFICATION_REQUEST,
        qualificationObj,
      });

      qualificationService.qualificationAdd(qualificationObj).then(
        data => {
          dispatch({
            type: reducerConstants.QUALIFICATION_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.QUALIFICATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function qualificationList(qualificationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.QUALIFICATION_REQUEST,
        qualificationObj,
      });

      qualificationService.qualificationList(qualificationObj).then(
        data => {
          dispatch({
            type: reducerConstants.QUALIFICATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.QUALIFICATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function qualificationDelete(qualificationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.QUALIFICATION_REQUEST,
        qualificationObj,
      });

      qualificationService.qualificationDelete(qualificationObj).then(
        data => {
          dispatch({
            type: reducerConstants.QUALIFICATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.QUALIFICATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
