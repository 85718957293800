import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { vacancyshiftActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Vacancyshift = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [vacancyshift, setVacancyshift] = useState({ name: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  // effect to load get vacancies
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllVacancyshift(1, query);
    }
  }, []);
  // call for get vacancies
  const getAllVacancyshift = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(vacancyshiftActions.vacancyshiftList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setVacancyshift(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Vacancyshift">
      {(props.requestStatus === reducerConstants.VACANCYSHIFT_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Vacancyshift view</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">From</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.from}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Shift</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.shift}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                {' '}
                <label className="float-right">Number of Vaccancies</label>{' '}
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.no_of_openings}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Jobrole</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.category}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Business Unit</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.business_unit}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Break paid</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.break_time}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Note</label>
              </div>
              <div className="col-md-6">
                <b>{vacancyshift.note}</b>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <Link className="link" to={'/vacancyshiftadd/' + vacancyshift._id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, vacancyshiftData } = state.vacancyshift;
  return {
    requestStatus,
    vacancyshiftData,
  };
}

const connectedVacancyshift = connect(mapStateToProps)(Vacancyshift);
export { connectedVacancyshift as Vacancyshift };
