import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { LoadingImg, Pagination } from '../../_components';
import { apiConstants } from '../../_constants';
import { authHeader } from '../../_helpers';
import { PriceService } from '../../_services';

const ShiftPriceTable = () => {
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [priceRows, setPriceRows] = useState([]);
  const [priceData, setPriceData] = useState({ rows: [] });
  const [price, setPrice] = useState([]);

  useEffect(() => {
    getAllEmployerPriceList(1);
  }, [query.business_unit_id]);

  const handleBusinessUnitChange = selectedOption => {
    if (selectedOption.value === 'all') {
      delete query.business_unit_id;
      getAllEmployerPriceList();
    } else {
      setQuery({ ...query, business_unit_id: selectedOption.value });
    }
  };

  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'searchKey') {
      query.searchKey = value;
      setQuery(query);
      getAllEmployerPriceList();
    }
  };

  const getBusinessUnitList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        employer_id: employerId,
        searchKey: inputvalue,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        let options = data.rows.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        if (options.length === 0) {
          return [];
        }
        options.unshift({ value: 'all', label: 'All' });
        return options;
      });
  };

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      fontSize: '14px',
      lineHeight: '25px',
      innerWidth: '25px',
    }),
  };

  const getAllEmployerPriceList = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setError(null);
    setQuery(query);
    setIsLoading(true);
    PriceService.PriceList(queryNew).then(
      res => {
        setPrice(res?.data?.rows);
        setListAndPagination(res?.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);

        setError('Error fetching price list. Please try again.');
      },
    );
  };

  const setListAndPagination = priceData => {
    let pricelist = [];
    for (
      let j = 0;
      priceData && priceData.rows && j < priceData.rows.length;
      j = j + 1
    ) {
      pricelist.push(
        <tr key={j}>
          <td data-title="User Name" className="td-flx-user-settings-table">
            {priceData.rows[j]['Category.name']}
          </td>

          <td data-title="Unit Name">
            <div className="td-flx-user-settings-table bus-unit-name-settings-usr">
              {priceData.rows[j].morning_price}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].evening_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].night_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].day_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].weekend_day_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].weekend_night_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>

          <td data-title="Location">
            <div className="user-settings-postcode td-flx-user-settings-table">
              {priceData.rows[j].holiday_price}
            </div>
            <div className="user-settings-address">
              {priceData.rows[j].address}
            </div>
          </td>
        </tr>,
      );
    }
    setPriceRows(pricelist);
    setPriceData(priceData);
  };

  return (
    <div>
      <div
        className="flx-ag-sett-other-benefits-header-container d-flex mt-4"
        style={{ justifyContent: 'normal !important' }}
      >
        <div className="input-group flx-search-box-ag-shift">
          <span
            className="input-group-text search-icon-settings-area"
            style={{ height: '40px' }}
          >
            <i className="fa fa-search flx-settings-search-icon"></i>
          </span>
          <input
            type="text"
            className="form-control flx-search-box flx-ag-shift-search-box"
            placeholder="Search"
            name="searchKey"
            onChange={handleChange}
          />
        </div>
        <div
          className="flx-ag-sett-bus-unit-section"
          style={{ marginLeft: '26px !important' }}
        >
          <AsyncSelect
            styles={customSelectStyles}
            name="businessUnit"
            placeholder={'Select business unit'}
            cacheOptions
            defaultOptions
            className="selectemployer"
            noOptionsMessage={() => 'No results found'}
            loadOptions={getBusinessUnitList}
            onChange={handleBusinessUnitChange}
          />
        </div>
      </div>

      {price.length > 0 && (
        <div className="flx-table-sett-user-responsive">
          <table
            className="table flx-user-settings-table"
            style={{ borderRadius: '10px' }}
          >
            <thead>
              <tr>
                <th>Job Role</th>
                <th>
                  Morning
                  <div>08.00 - 11.00</div>
                </th>
                <th>
                  Evening
                  <div>16.00 - 19.00</div>
                </th>
                <th>
                  Night
                  <div>20.00 - 23.00</div>
                </th>
                <th>Day</th>
                <th>Weekend Day</th>
                <th>Weekend Night</th>
                <th>Holiday</th>
              </tr>
            </thead>
            <tbody>{priceRows}</tbody>
          </table>
        </div>
      )}
      {priceData &&
        priceData.rows &&
        priceData.rows.length === 0 &&
        !isLoading && (
          <p className="margin-top-20 text-center"> No result found </p>
        )}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {price.length != 0 && (
        <div className="card-footer">
          <Pagination
            pageOnClick={data => {
              getAllEmployerPriceList(data);
            }}
            paginationData={priceData}
          />
        </div>
      )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <LoadingImg />
          </div>
        </div>
      )}
      {price.length > 0 && (
        <div className="flx-price-table-foot-note-container">
          <p className="flxflx-price-table-foot-note-text">
            Please note : All the prices mentioned above are per hour rates
          </p>
        </div>
      )}
    </div>
  );
};

export default ShiftPriceTable;
