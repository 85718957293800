export const InviteEmployerPopup = props => {
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content flx-save-employer-popup-text">
        <div className="modal-header justify-content-end mt-3">
          <button
            type="button"
            className="close flx-closebutton-popup"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.setShowModal(false)}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body ">
          <h5 className="justify-content-center" id="modalLabel">
            Send Invitation
          </h5>
          <p className="">
            This will send an invitation your potential client to enrol on the
            platform
          </p>

          <form>
            <div className="mt-3">
              <label
                htmlFor="Companyname"
                className="form-label company-info-labels flx-recs-required"
              >
                Company Name
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="ProfileName"
                placeholder="Enter invitee company name"
              />
            </div>

            <div className="form-row mt-3">
              <div className="form-group col-md-6">
                <label
                  htmlFor="email"
                  className="form-label company-info-labels flx-recs-required"
                >
                  Email
                </label>{' '}
                <input
                  type="text"
                  className="form-control flx-comp-settings-form-control"
                  id="email"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group col-md-6">
                <div>
                  <label
                    htmlFor="phone"
                    className="form-label company-info-labels flx-recs-required"
                  >
                    Phone
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="flx-basic-addon1">
                        +44
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control flx-comp-settings-form-control"
                      placeholder="07888 435 355"
                      aria-label="Phone number"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <i className="flx-add-button-invite-employer fa fa-plus-circle">
            <span className="flx-add-employer-invite-text">Add</span>
          </i>
        </div>

        <div className="modal-footer flx-popup-bottom flx-modal-invite-employer-popup-bottom">
          <button type="button" className="btn btn-sm flx-popup-button">
            Send Invitation
          </button>
          <a
            className="flx-popup-email-change-cancel"
            onClick={() => props.setShowModal(false)}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};
