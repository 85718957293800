import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { userActions } from '../_actions';
import { messageConstant } from '../_constants';
import { miscService } from '../_services';

const EmailVerify = props => {
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();
  const [user, setUser] = useState({
    email: '',
    phone: '',
    otp: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(120); // Timer in seconds
  const [isTimerActive, setIsTimerActive] = useState(false);
  // effect to set email to user state from url
  useEffect(() => {
    // Retrieve timer state from local storage if available
    const storedTimer = localStorage.getItem('timer');
    if (storedTimer) {
      setTimer(parseInt(storedTimer));
      setIsTimerActive(true);
    }
    const { phone } = miscService.getUrlVars();
    const email = searchParams.get('email');
    if (email) {
      setUser({ ...user, email });
      setIsTimerActive(true);
    }
    if (phone) {
      setUser({ ...user, phone });
    }
  }, []);

  // Update local storage with timer value whenever it changes
  useEffect(() => {
    localStorage.setItem('timer', timer);
  }, [timer]);
  // to hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // handler for state otp
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'otp') {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (name == 'otp' && numericValue.length > 4) {
        return;
      }
    }
    setUser({
      ...user,
      [name]: value,
    });
  };

  // Masked the Email Id
  const maskEmail = email => {
    const [emailPrefix, emailDomain] = email.split('@');
    const emailMask = emailPrefix
      .split('')
      .map((c, i, arr) => (i < 2 || i > arr.length - 3 ? c : '*'))
      .join('');
    return `${emailMask}@${emailDomain}`;
  };

  const resendOTP = e => {
    e.preventDefault();
    setIsTimerActive(true);
    setError({
      ...error,
      otp_error: '',
      phone: '',
      email: '',
      isError: false,
    });
    const { dispatch } = props;
    if (user.email) {
      dispatch(userActions.sendOtp({ email: user.email, isweb: true })).then(
        () => {
          navigate('/emailverify?email=' + user.email);
        },
        () => {
          setTimeout(() => {
            setError({ common: ' ' });
          }, 3000);
        },
      );
    }
  };

  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerActive(false);
      setTimer(120); // Reset timer
    }
  }, [timer]);

  // call for verify otp
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    const { otp, email } = user;
    const { dispatch } = props;
    if (otp && email) {
      setIsLoading(true);
      dispatch(userActions.verifyOtp({ otp, email })).then(
        res => {
          if (res.data.token) {
            // store user details and jwt token in local storage
            // to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(res.data));
          }
          setIsLoading(false);
          navigate('/updatepassword');
        },
        function () {
          setIsLoading(false);
          setError({
            common: messageConstant.OTP_EXP_OR_INVALID,
          });
          hideError();
        },
      );
    } else {
      setError({
        common: messageConstant.ERROR,
      });
    }
  };
  return (
    <div className="row justify-content-center m-1 ">
      <div
        className="logo-box-auth"
        style={{ padding: '10px', marginBottom: '30px' }}
      >
        <div className="logo-box">
          <img src="/assets/img/recsy-temp-logo.svg" />
        </div>
      </div>
      <div className="col-lg-4 col-xl-4 col-md-8 col-sm-12">
        <div className="login-box">
          <div className="d-flex justify-content-center">
            <div className="fp-image">
              <div className="fp-iamge-in">
                <img
                  className="fp-image-key"
                  src="/assets/img/email_green.png"
                />
              </div>
            </div>
          </div>
          <h2 className="fpText mt-2 text-center">OTP Verification</h2>
          <p className="fpdesc text-center">We have send you an OTP to</p>
          <p className="fpotpemailaddr text-center">{maskEmail(user.email)}</p>
          <form name="form" onSubmit={handleSubmit}>
            <div
              className={
                'form-group mt-2' + (submitted && !user.otp ? ' has-error' : '')
              }
            >
              {/* Kindly update your code to this field for capturing the otp 
              <div className="d-flex justify-content-center my-4">
                <input type="text" maxLength={1} className="fp-otp-input" />
                <input type="text" maxLength={1} className="fp-otp-input" />
                <input type="text" maxLength={1} className="fp-otp-input" />
                <input type="text" maxLength={1} className="fp-otp-input" />
              </div>*/}

              {/* <label htmlFor="username">OTP</label> */}
              <input
                type="number"
                step="any"
                className="form-control"
                placeholder="  Enter OTP"
                name="otp"
                value={user.otp}
                onChange={handleChange}
              />
              {submitted && !user.otp && (
                <div className="help-block">OTP is required</div>
              )}
            </div>
            <p className="fp-resend-link-text text-center">
              Didn&apos;t Received OTP &nbsp;&nbsp;?
              <>
                {isTimerActive ? (
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;{` Resend OTP in ${timer} seconds`}
                  </span>
                ) : (
                  <button
                    className="resend-link text-center ml-2 back-login-text"
                    onClick={resendOTP}
                    style={{ textDecoration: 'underline' }}
                  >
                    Resend OTP
                  </button>
                )}
              </>
            </p>
            <div className="">
              <button className="send-btn btn-sm">Verify</button>
              {isLoading && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </div>
            {/* <p className="fp-sent-to-phone text-center mt-20">
              Send OTP to Phone Number ?
            </p> */}
            <Link to="/login">
              <p className="back-login-text">Back to log in.</p>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {
    requestStatus,
  };
}
const connectedEmailVerify = connect(mapStateToProps)(EmailVerify);

export { connectedEmailVerify as EmailVerify };
