export const messageConstant = {
  VALID_PHONE: "Please enter complete phone number, leading with '0'",
  VALID_PHONE_WITHOUT_ZERO: 'Please enter complete phone number',
  PHONE_REQUIRED: 'Phone is required ',
  EMAIL_REQUIRED: 'Email is required',
  INVALID_EMAIL: ' Invalid email format. Please enter valid email',
  COMPANY_REQUIRED: 'Company name is required',
  PASSWORD_REQUIRED: 'Password is required',
  VERIFY_EMAIL: 'Please verify the email',
  PHONE_ALREADY_USED:
    'Phone number already registered for another user type. Please use an unused contact number',
  EMAIL_ALREADY_USED:
    'Email already registered as another user type. Use an unused email',
  OTP_EXP_OR_INVALID: 'Incorrect OTP! Please re-enter the OTP',
  USER_NOT_FOUND: 'User not found',
  NOT_AUTHRIZED_USER: 'You are not authorised',
  EMAIL_ALREADY_USED_FORGET_PASSWORD:
    'Email already registered as an Employer. Use forget password',
  PHONE_NOT_UPDATED: 'Phone number not updated',
  PASSWORD_LENGTH: 'Password length should be min 8 characters',
  MINIMUM_BREAK: 'Minimum break should be 20 mins',
  START_TIMER_REQUIRED: 'Start time is required',
  END_TIMER_REQUIRED: 'End time is required',
  SHIFT_NAME_REQUIRED: 'Shift name is required',
  BREAK_TIME_REQUIRED: 'Break time is required',
  SHIFT_ALERT:
    'You cannot publish for more than 10 vacancies. If you need more people to do the same shift, please use copy shift functionality',
  SHIFT_EXISTS:
    'A shift with the same start time, end time, and break time already exists. Please select a different shift.',
  DUPLICATE_SHIFT: 'Duplicate shift time detected!',
  BUSINESS_UNIT_ERROR: 'Please select a business unit',
  DATE_ERROR: 'Please select date',
  JOB_TITLE_ERROR: 'Please select a job title',
  SHIFT_TIME_EXP:
    'Please select a valid shif time, the time is already expired',
};
