import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { experienceActions } from '../_actions';
import { alertConstants } from '../_constants';

const ExperienceAdd = props => {
  // eslint-disable-next-line no-unused-vars
  const [isSubmit, setisSubmit] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [experience, setexperience] = useState({
    currently_enrolled: 0,
  });
  const { dispatch } = props;
  const [experience_id, setexperience_id] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    // if (props?.experience) {
    //   setexperience(props.experience);
    //   experience.from_date = moment(experience.from_date).format('DD-MM-YYYY');
    // }
  }, [experience]);

  useEffect(() => {
    if (props.experience_id) {
      setexperience_id(props.experience_id);
      getexperience();
    }

    // if (props.candidate_id) {
    // }
  }, []);

  const handleExperience = event => {
    const { name, value } = event.target;
    if (name == 'currently_enrolled') {
      if (event.target.checked) {
        setexperience({
          ...experience,
          [name]: 1,
        });
      } else {
        setexperience({
          ...experience,
          [name]: 0,
        });
      }
    } else if (
      name == 'Full Time Employee' ||
      name == 'Part Time Employee' ||
      name == 'Student' ||
      name == 'Volunteering'
    ) {
      if (event.target.checked) {
        setexperience({
          ...experience,
          during_this_time: name,
        });
      } else {
        setexperience({
          ...experience,
          during_this_time: 0,
        });
      }
    } else {
      setexperience({
        ...experience,
        [name]: value,
      });
    }
    setdisabled(false);
  };

  const getexperience = () => {
    let experience = {};

    setisLoading(true);
    const { dispatch } = props;

    if (props.experience_id) {
      experience._id = props.experience_id;
    }
    dispatch(experienceActions.experienceList(experience)).then(
      res => {
        setisLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setexperience(res?.data?.rows?.[0]);
        }
      },
      () => {
        setisLoading(false);
      },
    );
  };

  const handleSubmit = () => {
    setisSubmit(true);
    setdisabled(true);
    setisLoading(true);
    if (
      experience.from_date &&
      experience.job_title &&
      experience.company_name &&
      experience.location &&
      experience.during_this_time
      // &&
      // experience.reference_description
    ) {
      if (experience.from_date && experience.to_date) {
        experience.currently_enrolled = 0;
      }
      if (experience.from_date) {
        let from_date_string = moment(experience.from_date).format(
          'YYYY-MM-DD',
        );
        experience.from_date_string = from_date_string;
      }
      if (experience.currently_enrolled == 0 && !experience.to_date) {
        setdisabled(false);
        setisLoading(false);
        setError({ common: 'end date is required' });
        return;
      }
      if (experience.to_date) {
        const currentDate = new Date().toISOString().split('T')[0];

        if (experience.to_date > currentDate) {
          setError({ common: 'choose valid  end date' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        if (experience.from_date > currentDate) {
          setError({ common: 'choose valid start date' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        if (experience.to_date < experience.from_date) {
          setError({ common: 'choose valid dates' });
          setdisabled(false);
          setisLoading(false);
          return;
        }
        let to_date_string = moment(experience.to_date).format('YYYY-MM-DD');
        experience.to_date_string = to_date_string;
      }
      if (experience_id) {
        experience._id = experience_id;
      }
      if (props.candidate_id) {
        experience.candidate_id = props.candidate_id;
      }
      experience.isverified = 1;
      dispatch(experienceActions.experienceAdd(experience)).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          // window.location.href = '/profile/experiencelist/' + candidate_id;
          setisSubmit(false);
          props?.hideaddexperiencemodal();
          // props?.hideeditexperiencemodal();
        },
        err => {
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          // hideError();
          // setisSubmit(false);
          setdisabled(false);
          setisLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });

      // setisSubmit(false);
      setdisabled(false);
      setisLoading(false);
    }
  };

  return (
    <div className="card  mt-2">
      <div className="card-body">
        <form name="form">
          <h2 style={{ fontWeight: '700' }}>
            {experience._id ? 'Edit ' : 'Add '} Experience
          </h2>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group currentLocationCardText datefield">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.from_date ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Start Date: <span>*</span>
                  </label>
                  <input
                    type={'date'}
                    name="from_date"
                    onChange={handleExperience}
                    value={moment(experience.from_date).format('YYYY-MM-DD')}
                    // value={
                    //   experience.from_date
                    //     ? moment(experience.from_date).format('YYYY-MM-DD')
                    //     : ''
                    // }
                    className="form-control"
                  />
                  {isSubmit && !experience.from_date && (
                    <div className="help-block"> Start Date is required</div>
                  )}
                </div>
              </div>
            </div>
            {experience.currently_enrolled !== 1 && (
              <div className="col-md-6">
                <div className={'form-group currentLocationCardText datefield'}>
                  <div
                    className={
                      'form-group ' +
                      (isSubmit &&
                      experience.currently_enrolled !== 1 &&
                      !experience.to_date
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label className="currentLocationCardText">
                      {' '}
                      End Date:*
                    </label>
                    <input
                      type={'date'}
                      name="to_date"
                      onChange={handleExperience}
                      value={moment(experience.to_date).format('YYYY-MM-DD')}
                      // value={
                      //   experience.to_date
                      //     ? moment(experience.to_date).format('YYYY-MM-DD')
                      //     : ''
                      // }
                      className="form-control"
                    />
                    {isSubmit &&
                      !experience.to_date &&
                      experience.currently_enrolled !== 1 && (
                        <div className="help-block"> End Date is required</div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className={'form-group'}>
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="m-0"
                    name="currently_enrolled"
                    checked={experience.currently_enrolled == 1 ? true : false}
                    onChange={handleExperience}
                  />
                  <label className="ml-2 ">This is my current Employment</label>
                </div>

                {/* <span className="checkmark"></span> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={'form-group'}>
                <label>During This Time I Was :*</label>

                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="Full Time Employee"
                        checked={
                          experience.during_this_time == 'Full Time Employee'
                            ? true
                            : false
                        }
                        onChange={handleExperience}
                      />
                      <label className="ml-2 m-0">Full Time Employee</label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="Part Time Employee"
                        checked={
                          experience.during_this_time == 'Part Time Employee'
                            ? true
                            : false
                        }
                        onChange={handleExperience}
                      />
                      <label className="ml-2 m-0">Part Time Employee</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="Student"
                        checked={
                          experience.during_this_time == 'Student'
                            ? true
                            : false
                        }
                        onChange={handleExperience}
                      />
                      <label className="ml-2 m-0">Student</label>
                    </div>
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="Volunteering"
                        checked={
                          experience.during_this_time == 'Volunteering'
                            ? true
                            : false
                        }
                        onChange={handleExperience}
                      />
                      <label className="ml-2 m-0">Volunteering</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group currentLocationCardText">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.job_title ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Job title: *
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="job_title"
                    onChange={handleExperience}
                    value={experience.job_title ? experience.job_title : ''}
                    className="form-control"
                    id="usr"
                  />
                  {isSubmit && !experience.job_title && (
                    <div className="help-block">Job title is required</div>
                  )}
                </div>
              </div>
            </div>
            {/* {experience.level_of_experience == 'OTHER_KEY' && */}
            <div className="col-md-12">
              <div className="form-group currentLocationCardText">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.company_name ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Name of Employer :<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="company_name"
                    onChange={handleExperience}
                    value={
                      experience.company_name ? experience.company_name : ''
                    }
                    className="form-control"
                    id="usr"
                  />
                  {isSubmit && !experience.company_name && (
                    <div className="help-block">Company name is required</div>
                  )}
                </div>
              </div>
            </div>
            {/* } */}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group currentLocationCardText">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.location ? ' has-error' : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Location :<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    value={experience.location}
                    name="location"
                    onChange={handleExperience}
                    className="form-control"
                    id="usr"
                  />
                  {isSubmit && !experience.location && (
                    <div className="help-block">Location is required</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className={'form-group'}>
                <label className="currentLocationCardText datefield">
                  Reference :<span>*</span>
                </label>
                <textarea
                  className="form-control"
                  name="reference_description"
                  id=""
                  onChange={handleExperience}
                  cols="30"
                  rows="5"
                >
                  {experience.reference_description
                    ? experience.reference_description
                    : ''}
                </textarea>
                {/* {isSubmit && !experience.reference_description && (
                  <div className="help-block">Reference is required</div>
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={'form-group'}>
                <label className="currentLocationCardText datefield">
                  Roles and Responsibilities
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  id=""
                  onChange={handleExperience}
                  cols="30"
                  rows="5"
                >
                  {experience.description ? experience.description : ''}
                </textarea>
              </div>
            </div>
          </div>
          <div className="col-md-12 pr-1">
            <span className="color-red">{error.common}</span>
          </div>
          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group currentLocationCardText">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.reference_first_name
                      ? ' has-error'
                      : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    First Name *
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    value={experience.reference_first_name}
                    name="reference_first_name"
                    onChange={handleExperience}
                    className="form-control"
                    id="usr"
                  />
                  {isSubmit && !experience.reference_first_name && (
                    <div className="help-block">First Name is required</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group currentLocationCardText">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !experience.reference_last_name
                      ? ' has-error'
                      : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    value={experience.reference_last_name}
                    name="reference_last_name"
                    onChange={handleExperience}
                    className="form-control"
                    id="usr"
                  />
                  {isSubmit && !experience.reference_last_name && (
                    <div className="help-block">Last Name is required</div>
                  )}
                </div>
              </div>
            </div>

            <div className="error">{error.common && error.common}</div>
          </div> */}
          {!disabled ? (
            <div
              data-cy="add_experience"
              className=" btn regbutton float-right"
              onClick={handleSubmit}
            >
              Save
            </div>
          ) : (
            <div className=" btn regbutton-disabled  float-right">
              Saving..
              {isLoading && (
                <div className="lds-ring ml-2">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}
const connectedExperienceAdd = connect(mapStateToProps)(ExperienceAdd);

export { connectedExperienceAdd as ExperienceAdd };
