import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

// eslint-disable-next-line no-unused-vars
let user_role = '';
// eslint-disable-next-line no-unused-vars
let employer_id = '';
const AgencyReport = props => {
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [viewshiftsAgency_id, SetviewshiftsAgency_id] = useState([]);
  const [selecteddate, setselecteddate] = useState({
    from_date: '',
    to_date: '',
  });
  const [filter, setfilter] = useState({ employer_id: '', candidate_id: '' });
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isEmployer, setisEmployer] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  useEffect(() => {
    if (id) {
      if (id == 'unverified') {
        query.is_accepted = 0;
      }
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
      }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        setisEmployer(true);
      }
    }
    getAllShifts(1);
  }, [
    selecteddate,
    query,
    query.shift_type,
    query.status,
    query.is_verified,
    isAdmin,
    query.employer_id,
    query.agency_id,
  ]);

  const selectedagency = selectedOption => {
    setfilter({ ...filter, agency_id: selectedOption.value });
    let newquery = { ...query, agency_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  const setEmployer = selectedOption => {
    setfilter({ ...filter, employer_id: selectedOption.value });
    let newquery = { ...query, employer_id: selectedOption.value };
    getAllShifts(1, newquery);
  };

  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (id == 'unverified') {
      queryNew.is_accepted = 0;
    }
    if (selecteddate.from_date) {
      queryNew.from_date = selecteddate.from_date;
    }
    if (selecteddate.to_date) {
      queryNew.to_date = selecteddate.to_date;
    }
    if (!queryNew.is_verified) {
      delete queryNew.is_verified;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.Agencyshiftreport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  let shiftsList = [];
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j} className="row-body2">
          <td className="username">
            {' '}
            {shiftsData.rows[j].profileimage ? (
              <img
                className=" round-emp-image"
                src={apiConstants.imgUrl + shiftsData.rows[j].profileimage}
              />
            ) : (
              <img
                className=" round-emp-image"
                src="/assets/img/recsy-fav-icon.png"
              />
            )}
            {shiftsData.rows[j].company_name && shiftsData.rows[j].company_name}
          </td>
          <td>
            {shiftsData.rows[j].attended ? shiftsData.rows[j].attended : 0}
          </td>
          <td>
            {shiftsData.rows[j].not_attended
              ? shiftsData.rows[j].not_attended
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].canceled_shifts
              ? shiftsData.rows[j].canceled_shifts
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].deleted_shifts
              ? shiftsData.rows[j].deleted_shifts
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].invoice_generated
              ? shiftsData.rows[j].invoice_generated
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].payout_generated
              ? shiftsData.rows[j].payout_generated
              : 0}
          </td>
          <td>
            {shiftsData.rows[j].paid_amount
              ? shiftsData.rows[j].paid_amount
              : 0}
          </td>
          <td>{shiftsData.rows[j].to_pay ? shiftsData.rows[j].to_pay : 0}</td>
          <td>
            {shiftsData.rows[j].total_hours
              ? shiftsData.rows[j].total_hours
              : 0}
          </td>
          <td>
            Night &nbsp; - &nbsp;{' '}
            {shiftsData.rows[j].total_night && shiftsData.rows[j].total_night
              ? shiftsData.rows[j].total_night
              : 0}
            &nbsp; hrs
            <p>
              {' '}
              Morning&nbsp; - &nbsp;
              {shiftsData.rows[j].total_morning &&
              shiftsData.rows[j].total_morning
                ? shiftsData.rows[j].total_morning
                : 0}
              &nbsp; hrs
            </p>
            Custome&nbsp; - &nbsp;
            {shiftsData.rows[j].total_custome &&
            shiftsData.rows[j].total_custome
              ? shiftsData.rows[j].total_custome
              : 0}
            &nbsp; hrs
          </td>
          <td>
            <div
              data-cy="allshift-viewdt"
              className="allshift-viewdt"
              onClick={() => {
                SetviewshiftsAgency_id(shiftsData.rows[j]._id),
                  setviewdetailsmodal(true);
              }}
            >
              {' '}
              View{' '}
            </div>
          </td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setselecteddate({
      ...selecteddate,
      [name]: value,
    });
  };

  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };

  return (
    <div className="ShiftList">
      <div className="col-md-12">
        <div className="card regular-table-with-color m-0">
          <div
            className="card-body  table-responsive main-card-body p-0"
            style={{ paddingTop: 0 }}
          >
            <div className="">
              <div className="filter-row">
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-10">
                      {' '}
                      <label className="" htmlFor="type">
                        From
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="form-control "
                        name="from_date"
                        placeholder=""
                        value={selecteddate.from_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="filter-item">
                  <div className="" style={{}}>
                    <div className="mb-10">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        To
                      </label>
                    </div>
                    <div>
                      <input
                        type="date"
                        className="form-control "
                        name="to_date"
                        placeholder=""
                        value={selecteddate.to_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {!isEmployer && (
                  <div className="filter-item mb-30">
                    <div className="" style={{}}>
                      <div className="mb-10">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Employer
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="employer_id"
                          onChange={setEmployer}
                          placeholder={'select employer'}
                          cacheOptions
                          defaultOptions
                          className="employerselect"
                          loadOptions={getEmployerlist}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!isEmployer && (
                  <div className="filter-item">
                    <div className="" style={{}}>
                      <div className="mb-10">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Agency
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="agency_id"
                          onChange={selectedagency}
                          placeholder={'select agency'}
                          cacheOptions
                          defaultOptions
                          className="agencyselect"
                          // isMulti
                          loadOptions={getagencylist}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!isLoading && (
              <table className="table table-hover flx-custom-table">
                <thead>
                  <tr>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead border-top1"
                    >
                      Name
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Attended</th>
                    <th>Not Attended</th>
                    <th>Canceled</th>
                    <th>Deleted</th>
                    <th>Invoice generated</th>
                    <th>Payout generated</th>
                    <th>Paid</th>
                    <th>to Pay</th>
                    <th>Total hours</th>
                    <th>Worked shifts</th>
                    <th className="border-top2"></th>
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {shiftsRows &&
              shiftsRows &&
              shiftsRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <AllShiftsList
                  is_report={true}
                  agency_id={viewshiftsAgency_id}
                  employer_id={filter.employer_id}
                  from_date={selecteddate.from_date}
                  to_date={selecteddate.to_date}
                  candidate_id={filter.candidate_id}
                  is_verified="1"
                  status="1"
                ></AllShiftsList>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getagencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        select: ['_id', 'company_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}
const connectedAgencyReport = connect(mapStateToProps)(AgencyReport);
export { connectedAgencyReport as AgencyReport };
