import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { sampleService } from '../_services';
export const sampleActions = {
  sampleAdd,
  sampleList,
  sampleDelete,
};

function sampleAdd(sampleObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SAMPLE_REQUEST, sampleObj });

      sampleService.sampleAdd(sampleObj).then(
        data => {
          dispatch({
            type: reducerConstants.SAMPLE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.SAMPLE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function sampleList(sampleObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SAMPLE_REQUEST,
        sampleObj,
      });

      sampleService.sampleList(sampleObj).then(
        data => {
          dispatch({
            type: reducerConstants.SAMPLE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SAMPLE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function sampleDelete(sampleObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SAMPLE_REQUEST,
        sampleObj,
      });

      sampleService.sampleDelete(sampleObj).then(
        data => {
          dispatch({
            type: reducerConstants.SAMPLE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SAMPLE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
