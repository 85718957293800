/* eslint-disable no-useless-escape */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { educationActions } from '../_actions';
import { EducationAdd } from './EducationAdd';

const Education = props => {
  const [educationDataRows, setEducationDataRows] = useState([]);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });

  const [educationAddModal, seteducationAddModal] = useState(false);
  const [selectedEducationId, setselectedEducationId] = useState(false);

  useEffect(() => {
    if (props && props.candidate_id) {
      query.candidate_id = props.candidate_id;

      setQuery(query);
      getEducation(1, query);
    }
  }, []);

  const getEducation = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    // setIsLoading(true);
    const { dispatch } = props;

    dispatch(educationActions.educationList(queryNew)).then(res => {
      // setIsLoading(false);
      if (
        res &&
        res.data &&
        res.data.rows &&
        res.data.rows &&
        res.data.rows.length > 0
      ) {
        setEducationDataRows([]);
        setEducationlist(res.data.rows);
      }
    });
  };
  const hideaddeducationmodal = () => {
    getEducation();
    seteducationAddModal(false);
  };

  const setEducationlist = educationdata => {
    let educationlist = [];
    for (let j = 0; j < educationdata.length; j = j + 1) {
      educationlist.push(
        <div className="educard_border mt-3 " data-cy="educard" key={j}>
          {/* <img className="pointer" src="/assets/img/edit.png"></img> */}
          <div className="card-body">
            <div className=" d-flex justify-content-between">
              <div>
                <p className="collegename">
                  {educationdata[j].college_university}
                </p>
                <p className="stream">
                  {educationdata[j].level_of_education},{' '}
                  {educationdata[j].field_of_study}
                </p>
                <p style={{}}>
                  {moment(educationdata[j].from_date).format('YYYY')}-
                  {educationdata[j].to_date &&
                    moment(educationdata[j].to_date).format('YYYY')}
                </p>
                <p style={{ textTransform: 'capitalize' }}>
                  {educationdata[j].location}
                </p>
              </div>
              <div>
                <div
                  data-cy="editedu"
                  className="AddBtn"
                  onClick={() => {
                    setselectedEducationId(educationdata[j]._id),
                      seteducationAddModal(true);
                  }}
                >
                  edit
                </div>
              </div>
            </div>
          </div>
        </div>,
      );
    }

    setEducationDataRows([educationlist]);
  };

  return (
    <div className="CandidateAdd " data-cy="edcucationview">
      {/* <div className="row">
        <div className="col-md-12">
          <div className="educat">Add Education</div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          {/* {isLoading && <LoadingModal />}
          <LoadingModal /> */}

          {/* <div className="qualificationList"> */}
          <div className="">
            {educationDataRows.length > 0 ? (
              educationDataRows
            ) : (
              <p
                className="collegename"
                style={{ textAlign: 'center', fontSize: 12 }}
              >
                No result found
              </p>
            )}
          </div>
          {props?.hideedit ? (
            <div className="addBtnFooter">
              <div
                data-cy="addedu"
                className="AddBtn"
                onClick={() => {
                  setselectedEducationId(null), seteducationAddModal(true);
                }}
              >
                Add
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        {/* //--------------qulaifiacation add modal section --------------- */}
        {educationAddModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        seteducationAddModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <EducationAdd
                    education_id={selectedEducationId}
                    candidate_id={props.candidate_id}
                    hideaddeducationmodal={hideaddeducationmodal}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedEducation = connect(mapStateToProps)(Education);
export { connectedEducation as Education };
