import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { PriceActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Price = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [Price, setPrice] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  // effect to load price data
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllPrice(1, query);
    }
  }, []);
  /**
   * Get all Prices
   * */
  const getAllPrice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(PriceActions.PriceList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setPrice(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Price">
      {((props &&
        props.requestStatus &&
        props.requestStatus === reducerConstants.PRICE_REQUEST) ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Price view</h4>
            </div>
          </div>
          <div className=" col text_align">
            <Link className="link" to={'/Priceadd/' + Price._id}>
              <i className="fas fa-pen pointer f18"></i>
            </Link>
          </div>
          <div className="card-body ">
            <div className="row d-flex align-items-center">
              <div className="col-md-2">
                <p className="float-right">name :</p>
              </div>
              <div className="col-md-6">
                <p>{Price.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedPrice = connect(mapStateToProps)(Price);
export { connectedPrice as Price };
