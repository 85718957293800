export function staff(
  state = { requestStatus: 0, staffData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'STAFF_SUCCESS':
      return {
        ...state,
        requestStatus: 'STAFF_SUCCESS',
        requestStaffStatus: 'STAFF_SUCCESS',
        staffData: action.data.data,
        data: action.data,
      };
    case 'STAFF_FAILURE':
      return {
        ...state,
        requestStatus: 'STAFF_FAILURE',
        requestStaffStatus: 'STAFF_FAILURE',
        staffData: {},
        data: action.data,
      };
    case 'STAFF_REQUEST':
      return {
        ...state,
        requestStatus: 'STAFF_REQUEST',
        requestStaffStatus: 'STAFF_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
