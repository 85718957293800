import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { agencycandidateService } from '../_services';
export const agencycandidateActions = {
  agencycandidateAdd,
  agencycandidateList,
  agencycandidateDelete,
};

function agencycandidateAdd(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCYCANDIDATE_REQUEST,
        candidateObj,
      });

      agencycandidateService.agencycandidateAdd(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function agencycandidateList(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCYCANDIDATE_REQUEST,
        candidateObj,
      });

      agencycandidateService.agencycandidateList(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function agencycandidateDelete(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCYCANDIDATE_REQUEST,
        candidateObj,
      });

      agencycandidateService.agencycandidateDelete(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCYCANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
