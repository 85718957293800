import { useState } from 'react';
// import CompanyInfo from "./ContentComponents/CompanyInfo";
import EmailChangeOTP from './ContentComponents/EmailChangeOTP';
import ExistingEmailOTP from './ContentComponents/ExistingEmailOTP';
import LoginSecurity from './ContentComponents/LoginSecurity';
// import MyProfileInfo from './ContentComponents/MyProfileInfo';
import NewEmailChange from './ContentComponents/NewEmailChange';

export const EmployerProfileContent = props => {
  const [newEmail, setNewEmail] = useState('');
  const [token, setToken] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);

  const newEmailToToken = item => {
    setToken(item);
  };

  const newEmailToOTP = item => {
    setNewEmail(item);
  };

  const handleSuccess = () => {
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
      window.location.reload();
    }, 5000);
  };

  return (
    <>
      {/* <div className="profileDocument">
        {props.currentPage == 1 && <MyProfileInfo />}
      </div> */}
      <div className="loginSecurity">
        {props.currentPage == 1 && (
          <LoginSecurity
            id={props?.id}
            profile={props?.profile}
            setCurrentPage={props?.setCurrentPage}
          />
        )}
      </div>
      <div className="ExistingEmailOtp">
        {props.currentPage == 5 && (
          <ExistingEmailOTP
            id={props?.id}
            newEmailToToken={newEmailToToken}
            profile={props?.profile}
            setCurrentPage={props?.setCurrentPage}
          />
        )}
      </div>
      <div className="newEmailChange">
        {props.currentPage == 6 && (
          <NewEmailChange
            id={props?.id}
            newEmailToOtp={newEmailToOTP}
            token={token}
            profile={props?.profile}
            setCurrentPage={props?.setCurrentPage}
          />
        )}
      </div>
      <div className="emailChangeOTP">
        {props.currentPage == 7 && (
          <EmailChangeOTP
            id={props?.id}
            newEmail={newEmail}
            profile={props?.profile}
            setCurrentPage={props?.setCurrentPage}
            onSuccess={handleSuccess}
          />
        )}
      </div>
      {successMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>Successfully Email Changed</p>
        </div>
      )}
    </>
  );
};
