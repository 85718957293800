import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const descriptions = {
  'Agency Shift':
    'Are you looking for Short-term, flexible candidates for specific needs or projects.',
  'Permanent Recruitment':
    'Looking for Establish lasting candidates with dedicated professionals for stable, long-term contributions.',
  'International Recruitment':
    'Connect with a diverse global candidates pool for cross-border workforce solutions.',
};
const JobSelection = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [categories, setCategories] = useState([
    'Agency Shift',
    'Permanent Recruitment',
    'International Recruitment',
  ]);

  const handleCategoryChange = category => {
    setSelectedCategory(category);
    setIsDefault(false);
  };

  const handleMakeDefault = () => {
    setIsDefault(true);
    setCategories(prevCategories => [
      selectedCategory,
      ...prevCategories.filter(c => c !== selectedCategory),
    ]);
  };

  return (
    <>
      <div className="container flx-job-post-main-container">
        <h2 className="text-center">Create a Job Post</h2>
        <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
          <p className="text-center flx-recs-required">
            Select the job type you&apos;d like to post
          </p>

          {categories.map(category => (
            <div
              key={category}
              className={`container flx-job-post-cat-select-section ${
                selectedCategory === category
                  ? 'flx-job-post-cat-select-section-active'
                  : ''
              }`}
            >
              <div className="flx-job-post-cat-radio-btn-section">
                <input
                  type="radio"
                  id={`flx-${category}-radio`}
                  name="job-post-cat"
                  value={category}
                  onChange={() => handleCategoryChange(category)}
                />
              </div>

              <div className="flx-job-post-cat-select-inner-text">
                <label htmlFor={category}>{category}</label>
                <br />
                <span>{descriptions[category]}</span>
              </div>

              {selectedCategory === category && (
                <div className="flx-job-post-make-default-btn-section">
                  <button
                    type="button"
                    className={`btn flx-recs-primary-btn flx-job-post-make-default-btn btn-sm ${
                      isDefault ? 'hidden' : ''
                    }`}
                    onClick={handleMakeDefault}
                  >
                    Make it as default
                  </button>
                  <button
                    type="button"
                    className={`btn flx-recs-default-btn flx-job-post-make-default-active-btn btn-sm ${
                      isDefault ? '' : 'hidden'
                    }`}
                  >
                    Default
                  </button>
                </div>
              )}
            </div>
          ))}

          <div className="flx-job-post-nxt-back-btn-section">
            <button className="btn btn-sm flx-job-post-back-btn">Back</button>
            <Link
              to="/postagency"
              className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
            >
              Save &amp; Next -&gt;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedJobSelection = connect(mapStateToProps)(JobSelection);
export { connectedJobSelection as JobSelection };
