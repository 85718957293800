import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';

export const experienceService = {
  experienceAdd,
  experienceRemove,
  experienceList,
  experienceDelete,
  verifyexperience,
};

function experienceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addexperience', requestOptions).then(
    miscService.handleResponse,
  );
}
function verifyexperience(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/verifyexperience', requestOptions).then(
    miscService.handleResponse,
  );
}
function experienceRemove(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/removeexperience', requestOptions).then(
    miscService.handleResponse,
  );
}
function experienceList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getexperienceList', requestOptions).then(
    miscService.handleResponse,
  );
}

function experienceDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteexperience', requestOptions).then(
    miscService.handleResponse,
  );
}
