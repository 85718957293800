import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { employerActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';

import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { InviteEmployerPopup } from './InviteEmployerPopup';
const EmployerList = props => {
  const [showModal, setShowModal] = useState('');
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [IsParentEmployer, setIsParentEmployer] = useState(false);
  const [IsSubscriptionModal, setIsSubscriptionModal] = useState({
    modal: false,
    data: {},
  });
  const [error, setError] = useState('');
  const [isSubscription, setIsSubscription] = useState(false);
  const [employerData, setEmployerData] = useState({ rows: [] });
  const [employerRows, setEmployerRows] = useState([]);
  const [allocated_agency_user_ids, setallocated_agency_user_ids] = useState(
    [],
  );
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  const { dispatch } = props;

  //--- debouncing feature start ---
  const isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query.searchKey, 500);
  const debouncedSearchSector = useDebounce(query.service_type, 500);
  const debouncedSearchBranch = useDebounce(query.branch, 500);
  useEffect(() => {
    if (debouncedSearch || debouncedSearchSector || debouncedSearchBranch) {
      getAllEmployer(1);
    }
  }, [debouncedSearch, debouncedSearchSector, debouncedSearchBranch]);
  //--- debouncing feature end ---
  // effect when checking the user role and state is parent employer
  // effect when load employer
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4 && user.is_parent == 1) {
        setQuery({ ...query, parent_id: user.employer_id });
        setIsParentEmployer(true);
      }
    }
    getAllEmployer(1);
  }, []);
  // filter handler for searchKey, service_type, branch
  const handleChange = event => {
    const { name, value } = event.target;
    //-----------search query--------
    if (name == 'searchKey') {
      isfirsttime.current = false;
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
    } else {
      setQuery(prevstate => ({
        ...prevstate,
        [name]: value,
      }));
    }
  };
  /**
   * Get all employers
   **/
  const getAllEmployer = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    dispatch(employerActions.employerListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // filter for sort employer list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = employerData => {
    let employerList = [];
    for (
      let j = 0;
      employerData &&
      employerData.rows &&
      employerData.rows.length &&
      j < employerData.rows.length;
      j = j + 1
    ) {
      employerList.push(
        <tr key={j}>
          {/* --------id starts from 2000 --------- */}
          <td> {2000 + employerData.rows[j]._id} </td>
          <td>
            <div className="row d-flex align-items-center ml-2">
              <div className=" border-rad-55 ml-2 ">
                {employerData &&
                employerData.rows[j] &&
                employerData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={
                      apiConstants.imgUrl + employerData.rows[j].profileimage
                    }
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/un-avatar.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {employerData.rows[j].company_name}
                </p>
                {employerData.rows[j].city && (
                  <div className="row d-flex align-items-center">
                    <div className="col pr-3">
                      <img className=" pin-image" src="/assets/img/pin.png" />
                      <span>{employerData.rows[j].city}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </td>
          <td>
            {employerData &&
              employerData.rows &&
              employerData.rows[j].service_type}
          </td>
          <td>
            {employerData &&
            employerData.rows &&
            employerData.rows[j].is_parent == 1
              ? 'Parent'
              : 'Organisation'}
            {employerData?.rows[j]?.Parent?.company_name &&
              '(' + employerData?.rows[j]?.Parent?.company_name + ')'}
          </td>
          <td>
            {employerData &&
              employerData.rows &&
              employerData.rows[j].createdAt &&
              moment(employerData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          <td>
            {employerData.rows[j].shift_completed
              ? employerData.rows[j].shift_completed
              : 0}
          </td>
          <td>
            {employerData.rows[j].available_shift
              ? employerData.rows[j].available_shift
              : 0}
          </td>
          <td>
            {employerData.rows[j].upcoming_shifts
              ? employerData.rows[j].upcoming_shifts
              : 0}{' '}
          </td>
          <td>
            {' '}
            {employerData.rows[j].total_amount
              ? '£' + ' ' + employerData.rows[j].total_amount.toFixed(2)
              : '£' + ' ' + 0}{' '}
          </td>
          <td className="eventCell  eventView">
            <a
              onClick={() => {
                handleSubscriptionModal(true, employerData.rows[j]);
              }}
            >
              subscription
            </a>
            <Link
              data-cy="viewdetails"
              to={'/employer/' + employerData.rows[j]._id}
            >
              view
            </Link>
          </td>
        </tr>,
      );
    }
    setEmployerRows(employerList);
    setEmployerData(employerData);
  };
  // to choose agency
  const handleSelectAgency = selectedOption => {
    setallocated_agency_user_ids(selectedOption);
  };
  // to hide error
  const hideError = () => {
    setTimeout(() => {
      setError('');
      setIsSubscription(false);
    }, 3000);
  };
  // to add subscription
  const subscriptionAdd = () => {
    let employer = IsSubscriptionModal.data;
    employer.allocated_agency_user_ids = allocated_agency_user_ids.map(
      item => item.value,
    );

    setIsSubscription(true);
    dispatch(employerActions.employerAdd(employer)).then(
      () => {
        setallocated_agency_user_ids([]);
        setIsSubscription(false);
        setIsSubscriptionModal({ modal: false, data: {} });
        window.location.reload();
      },
      err => {
        setError(err);
        hideError();
      },
    );
  };
  //to open or close subscription modal
  const handleSubscriptionModal = (value, data) => {
    setIsSubscriptionModal({
      modal: value,
      data: data ? data : {},
    });
    if (data?.agencyData?.length > 0)
      setallocated_agency_user_ids(
        data?.agencyData.map(item => {
          return { value: item.user_id, label: item.company_name };
        }),
      );
  };
  // style for select box
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '100%',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="EmployerList">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color ">
            {!props.isHomepage && (
              <div className="card-header page-header ">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title">Employer</div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-4 ">
                    <p>
                      <div className="input-outer-div">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-2">
                    <select
                      className="select col-md-12 selectDesign"
                      name="service_type"
                      value={query.service_type}
                      onChange={handleChange}
                      placeholder={'Select service sector'}
                    >
                      <option value="77" hidden>
                        {' '}
                        Select service sector
                      </option>
                      <option value=""> ALL</option>
                      <option value="Hospitality"> Hospitality</option>
                      <option value="Health Care"> Health Care</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <select
                      className="select col-md-12 selectdesing2 mt-1"
                      name="branch"
                      value={query.branch}
                      onChange={handleChange}
                    >
                      <option value="77" hidden={true}>
                        {' '}
                        Select Branch
                      </option>
                      <option value=""> All</option>
                      <option value={'Basildon'}>Basildon</option>
                      <option value={'Wales'}>Wales</option>
                    </select>
                  </div>
                  {/* FROM DATE PICKER======== */}
                  <div className="col-md-4">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col">
                        <div
                          className=" btn-primary-blue-nowidth float-right flx-invite-employer-button"
                          onClick={() => setShowModal('inviteemployer')}
                        >
                          Invite Employer
                        </div>

                        <div
                          data-cy="addemployer"
                          className=" btn-primary-blue-nowidth float-right"
                          // href="/employeradd/0"
                          onClick={() => {
                            navigate('/employeradd/0');
                          }}
                        >
                          {!IsParentEmployer
                            ? ' Add Employer'
                            : 'Add Organization'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                !props.isHomepage
                  ? 'card-body  table-responsive main-card-body '
                  : '  table-responsive main-card-body '
              }
            >
              {!isLoading && (
                <table className="table table-hover flx-custom-table">
                  <thead>
                    <tr>
                      <th className="border-top1">Id</th>
                      <th
                        onClick={() => handleSort('company_name')}
                        style={{ width: '200px' }}
                        className="sortHead"
                      >
                        Employer name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'company_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'company_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Service Sector</th>
                      <th>Organisation</th>
                      <th
                        onClick={() => handleSort('createdAt')}
                        className="sortHead"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      {/* <th className="">Employer name</th> */}
                      <th>Shift completed</th>
                      <th>Total Shift </th>
                      <th>Upcoming Shift </th>
                      <th>Payment</th>
                      <th className="eventCell  eventView border-top2" />
                    </tr>
                  </thead>
                  <tbody>{employerRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {employerRows && employerRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            {!props.isHomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllEmployer(data);
                  }}
                  paginationData={employerData}
                />
              </div>
            )}
          </div>
        </div>
        {IsSubscriptionModal.modal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setIsSubscriptionModal({ modal: false, data: {} });
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8 mb-4">
                        <div className="mt-1" style={{ marginRight: '20px' }}>
                          <AsyncSelect
                            styles={customSelectStyles}
                            value={allocated_agency_user_ids}
                            name="allocate_agency_user_ids"
                            onChange={handleSelectAgency}
                            placeholder="select Agency"
                            cacheOptions
                            defaultOptions
                            loadOptions={getAgencylist}
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                    {isSubscription && error.length > 0 && (
                      <div className="row d-flex justify-content-center">
                        <div className="error">{error}</div>
                      </div>
                    )}
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setIsSubscriptionModal({
                                modal: false,
                                data: {},
                              });
                            }}
                          >
                            Close
                          </button>
                          <button
                            data-cy="deletebutton"
                            className="button_delete_close width_100"
                            onClick={() => {
                              subscriptionAdd();
                            }}
                          >
                            {!isSubscription ? 'Submit' : 'Submitting...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* popup on click invite employer */}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${showModal == 'inviteemployer' ? 'show' : ''}`}
        style={{ display: showModal == 'inviteemployer' ? 'block' : 'none' }}
      >
        <InviteEmployerPopup setShowModal={setShowModal} />
        <a onClick={() => setShowModal('')}>x</a>
      </div>
    </div>
  );
};

// loader for agency list in async select
const getAgencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        select: ['user_id', 'company_name'],
        searchKey: inputvalue,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add an all select option
          // let AllElement = { _id: 0, company_name: 'ALL' };
          // let newArray = [AllElement].concat(data.rows);
          resolve(
            data.rows.map(({ user_id, company_name }) => ({
              value: user_id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedEmployerList = connect(mapStateToProps)(EmployerList);
export { connectedEmployerList as EmployerList };
