import React from 'react';
import { LoadingImg } from '../../_components';

const RequestModal = () => {
    return (
        <div className="request-modal-overlay">
            <div className="request-modal-content">
                <LoadingImg />
            </div>
        </div>
    );
};

export default RequestModal;
