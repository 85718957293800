import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

let agency_id = '';

const AgencyUpcomingShift = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      agency_id = user._id;
    }
    getAllShifts(1);
  }, []);

  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.shifttype = 'agencyupcomingshift';
    queryNew.allocated_user_id = agency_id;
    (queryNew.check_in_date = new Date().toISOString().slice(0, 10)),
      dispatch(shiftsActions.upcomingandcompleteshiftsList(queryNew)).then(
        res => {
          setIsLoading(false);
          setListAndPagination(res.data);
        },
        () => {
          setIsLoading(false);
        },
      );
  };

  let shiftsList = [];

  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      let color = 0;
      if (shiftsData.rows[j].section_type == 'Night') color = 1;
      else if (shiftsData.rows[j].section_type == 'Morning') color = 2;
      else color = 0;
      shiftsList.push(
        <tr key={j}>
          <td style={{ color: '#3D4A54' }}>
            {shiftsData.rows[j].check_in_date_string}
          </td>
          <td
            style={{ color: '#3D4A54' }}
            className={
              'font-11' &&
              (color == 1 ? 'night' : color == 2 ? 'morning' : 'custome')
            }
          >
            <p style={{ fontWeight: 'bold' }}>
              {shiftsData.rows[j].section_type}{' '}
            </p>
            <p style={{ color: '#183245' }}>
              {' '}
              {shiftsData.rows[j].company_name}{' '}
            </p>
          </td>
          <td>{shiftsData.rows[j].postcode}</td>
          <td style={{ color: '#3D4A54' }}>
            {shiftsData.rows[j].check_in_time_string +
              '-' +
              shiftsData.rows[j].check_out_time_string}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].actual_hours_after_break_time}&nbsp; hrs
          </td>
          <td style={{ color: '#02365C' }}>{shiftsData.rows[j].price}</td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };

  return (
    <div className="ShiftsList">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color ml-0 mr-0">
            <div className="card-body  table-responsive main-card-body pl-0 pr-0">
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Shift</th>
                      <th>Postcode</th>
                      <th>Time</th>
                      <th>Total hrs</th>
                      <th>Pay</th>
                    </tr>
                  </thead>
                  <tbody>{shiftsRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {shiftsData &&
                shiftsData.rows &&
                shiftsData.rows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                shiftsData={shiftsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedAgencyUpcomingShift =
  connect(mapStateToProps)(AgencyUpcomingShift);
export { connectedAgencyUpcomingShift as AgencyUpcomingShift };
