/* eslint-disable no-undef */

const stripe_key =
  'pk_test_51Oo5jlHy1SQIgtgmPjQ44bhv4MxlfbxJ0IxXxewy4tRY4CU7TrbM8KSVCmPAK9ayJ72S7FUVE7XaQoMqTLN9jYKy00CZmrkNhL';

const imgUrl = 'https://flexirecs.s3.us-east-2.amazonaws.com/';
const postal_key = 'a1s6ILYqIEqFkF82GY01SA36009';
export const apiConstants = {
  apiUrl: process.env.REACT_APP_API_URL,
  shiftpdfUrl: process.env.REACT_APP_API_SHIFT_PDF_URL,
  permanentUrl: process.env.REACT_APP_API_PERMANENT_URL,
  imgUrl,
  postal_key,
  sharableurl: process.env.REACT_APP_SHAREABLE_URL,
  stripe_key,
};
