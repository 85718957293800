import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { sampleActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Sample = props => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [sample, setSample] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllSample(1, query);
    }
  }, []);

  /**
   * Get all samples
   * */
  const getAllSample = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(sampleActions.sampleList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setSample(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="Sample">
      {(props.requestStatus === reducerConstants.SAMPLE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Sample view</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Email</label>
              </div>
              <div className="col-md-6">
                <b>{sample.email}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Swaxirprion</label>
              </div>
              <div className="col-md-6">
                <b>{sample.description}</b>
              </div>
            </div>{' '}
          </div>
          <div className="card-footer">
            <Link className="link" to={'/sampleadd/' + sample._id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  // const { results } = state.test

  const { requestStatus, sampleData } = state.sample;
  return {
    requestStatus,
    sampleData,
  };
}

const connectedSample = connect(mapStateToProps)(Sample);
export { connectedSample as Sample };
