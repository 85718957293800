import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const invoiceService = {
  invoiceAdd,
  agencyinvoiceAdd,
  invoiceUpdate,
  invoiceList,
  invoiceListAgency,
  invoiceDelete,
  generateInvoice,
  invoiceBulkGenerate,
};

function invoiceBulkGenerate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/invoicebulkgenerate',
    requestOptions,
  ).then(miscService.handleResponse);
}

function invoiceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addinvoice', requestOptions).then(
    miscService.handleResponse,
  );
}
function agencyinvoiceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/agencyaddinvoice', requestOptions).then(
    miscService.handleResponse,
  );
}
function invoiceUpdate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/updateInvoice', requestOptions).then(
    miscService.handleResponse,
  );
}
function generateInvoice(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/generateinvoice ', requestOptions).then(
    miscService.handleResponse,
  );
}
function invoiceList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getinvoicelist', requestOptions).then(
    miscService.handleResponse,
  );
}
function invoiceListAgency(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getinvoicelistagency',
    requestOptions,
  ).then(miscService.handleResponse);
}
function invoiceDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteinvoice', requestOptions).then(
    miscService.handleResponse,
  );
}
