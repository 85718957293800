export const ShiftSignOffCancelPopup = () => {
  return (
    <>
      <div className="modal-dialog flx-modal-popup" role="document">
        <div className="modal-content">
          <div className="modal-header flx-invoice-close-icon">
            <button
              type="button"
              className="close flx-closebutton-popup"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="flx-closeicon">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p className="flx-popup-body flx-invoice-decline-text text-center">
              Cancel shift
            </p>

            <div className="mt-2 text-center">
              <label htmlFor="reason" className="form-label">
                Are you sure you want cancel the shift? this shift wont be
                considered anymore and this action cannot be undone
              </label>
            </div>
            <div className="mt-4 d-block">
              <label
                htmlFor="cancellationReason"
                className="form-label flx-recs-required d-block"
              >
                Select reason for cancellation
              </label>
              <select id="cancellationReason" className="form-select">
                <option selected>Select reason</option>
                <option value="1">Option 1</option>
                <option value="2">Option 2</option>
                <option value="3">Option 3</option>

                {/* More options here */}
              </select>
            </div>
          </div>
          <div className="flx-popup-bottom">
            <div className="flx-popup-buttons-invoice-confirm">
              <div className="modal-footer flx-popup-bottom flx-add-shift-popup mb-10">
                <span className="flx-add-shift-cancel">Cancel</span>
                <button
                  type="button"
                  className="btn btn-sm flx-popup-button flx-send-reminder-button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
