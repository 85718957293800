import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { staffActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Staff = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [staff, setStaff] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllStaff(1, query);
    }
  }, []);
  /**
   * Get all staffs
   * */
  const getAllStaff = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(staffActions.staffList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setStaff(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Staff">
      {(props.requestStatus === reducerConstants.STAFF_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Staff </h4>
            </div>
            <div className=" col text_align">
              <Link
                className="link"
                data-cy="editstaff"
                to={'/staffadd/' + staff._id}
              >
                <i className="fas fa-pen pointer f18"></i>
              </Link>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    {' '}
                    <label>Name</label>{' '}
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col">
                    <p>
                      {staff.name}
                      {' ' + staff.last_name && staff.last_name}
                    </p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    {' '}
                    <label>Email</label>
                  </div>
                  <div className="">
                    <label>:</label>{' '}
                  </div>
                  <div className="col">
                    <p>{staff.email}</p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <label>Phone</label>{' '}
                  </div>
                  <div className="">
                    <label>:</label>
                  </div>
                  <div className="col">
                    <p>{staff.phone}</p>{' '}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-6">
                    <label>Role</label>
                  </div>
                  <div className="">
                    {' '}
                    <label>:</label>
                  </div>
                  <div className="col-6">
                    <p>{staff.role} </p>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, staffData } = state.staff;
  return { requestStatus, staffData };
}
const connectedStaff = connect(mapStateToProps)(Staff);
export { connectedStaff as Staff };
