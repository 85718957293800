import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingImg } from '../_components';
import { miscService, userService } from '../_services';
// import OtpInput from '../_components/OtpInput';
import { messageConstant, roleConstant } from '../_constants';

const OTP_TYPE = 'email';

const LoginWithEmail = () => {
  const [user, setUser] = useState({
    email: '',
    phone: '',
    role: '',
    otp: '',
  });
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otpSendSuccessfully, setOtpSendSuccessfully] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [resendOTPFlag, setResendOTPFlag] = useState(false);
  const [timerLeft, setTimeLeft] = useState(120);
  const [emailValidation, setEmailValidation] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  // Masked the Email Id

  // const maskEmail = email => {
  //   email = email.toLowerCase();
  //   const [emailPrefix, emailDomain] = email.split('@');
  //   const emailMask = emailPrefix
  //     .split('')
  //     .map((c, i, arr) => (i < 2 || i > arr.length - 3 ? c : '*'))
  //     .join('');
  //   return `${emailMask}@${emailDomain}`;
  // };

  // handle change to set email and password
  const handleChange = e => {
    setError({
      ...error,
      otp_error: '',
      email: '',
      common: '',
      isError: false,
    });
    user.email = user.email.toLowerCase();
    let { name, value } = e.target;
    //checking the OTP length
    if (name == 'otp' && value.length > 4) {
      return;
    }
    if (name === OTP_TYPE && !miscService.emailVerify(value)) {
      setError({ ...error, email: messageConstant.INVALID_EMAIL });
      setEmailValidation(false);
    } else {
      setEmailValidation(true);
    }

    setUser({
      ...user,
      [name]: value,
    });
  };

  // start the count Down
  useEffect(() => {
    const timerId = setInterval(() => {
      if (timerLeft > 0) {
        setTimeLeft(timerLeft - 1);
      }

      if (timerLeft === 0) {
        setTimerOn(false);
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timerLeft]);

  const resendOTP = async otp_type => {
    setResendOTPFlag(true);
    setTimeLeft(120);
    setTimerOn(true);
    setError({
      ...error,
      otp_error: '',
    });

    try {
      if (otp_type == OTP_TYPE) {
        const userData = {
          email: user.email,
          otp_type: OTP_TYPE,
        };

        await userService.sendOtpLogin(userData);
      }
    } catch (error) {
      setError({
        ...error,
        phone: typeof error == 'string' ? error : error.message,
      });
    }
  };

  // Replace all characters except the first two and last two with asterisks

  const loginsendOTP = async otp_type => {
    setOtpSendSuccessfully(false);
    setTimeLeft(120);
    setTimerOn(true);
    setError({
      ...error,
      otp_error: '',
    });

    if (otp_type === OTP_TYPE) {
      if (!user.email) {
        setError({
          ...error,
          email: messageConstant.EMAIL_REQUIRED,
        });
        return;
      }
      try {
        setIsLoading(true);
        const userData = {
          email: user.email,
          otp_type: OTP_TYPE,
        };

        const res = await userService.sendOtpLogin(userData);
        user.role = res.data.role;
        if (!res.data) {
          error.email = messageConstant.USER_NOT_FOUND;
          error.isError = true;
          setIsLoading(false);
          return;
        }
        if (
          res.data.role != roleConstant.EMPLOYER &&
          res.data.role != roleConstant.BUSINESS_UNIT &&
          res.data.role != roleConstant.STAFF
        ) {
          error.email = messageConstant.NOT_AUTHRIZED_USER;
          error.isError = true;
          setIsLoading(false);
          return;
        }

        if (res.error_code == 0 && res.data.email) {
          setIsLoading(false);
          setOtpSendSuccessfully(true);
        }
      } catch (error) {
        setIsLoading(false);
        setOtpSendSuccessfully(false);
        setError({
          ...error,
          common: error,
        });
      }
    }
  };

  const handleVerifyOtp = async (otp, otp_type) => {
    if (isLoadingVerify) {
      return;
    }
    setError({
      ...error,
      common: '',
    });
    setError({
      ...error,
      otp_error: '',
    });
    setIsLoadingVerify(true);

    try {
      const userData = {
        email: user.email,
        role: user.role,
        otp_type: otp_type,
        otp: otp, // Replace with the actual OTP value
      };

      const res = await userService.verifyLogin(userData);
      if (res.data && res.error_code === 0) {
        if (res.data.role === 4 || res.data.role === 6 || res.data.role === 7) {
          if (res.data.name != null) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/phonenumber';
          }
        } else {
          window.location.href = '/login';
        }
      }
      setIsLoadingVerify(false);
    } catch (error) {
      setIsLoadingVerify(false);
      setError({
        ...error,
        otp_error: error,
      });
    }
  };

  const handleBlur = () => {
    setIsInputFocused(false);
  };

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/recsy-temp-logo.svg" />
          </div>
        </div>
        <div className=" row justify-content-center">
          <div className="col-md-8 col-lg-6  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6 mx-auto">
                <div>
                  <h3
                    className="mb-4"
                    style={{
                      color: '#000',
                      fontWeight: '700',
                      fontSize: '24px',
                    }}
                  >
                    Sign In With OTP
                  </h3>

                  <div name="form">
                    <div>
                      <p className="label-login mb-1" htmlFor="email">
                        Email
                      </p>
                      <input
                        type="email"
                        className="flx-common-input-fields form-control"
                        name="email"
                        placeholder="  Your email"
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={user.email}
                        onChange={handleChange}
                      />
                      {user.email === '' && isInputFocused && (
                        <div className="text-danger mt-1">
                          {messageConstant.EMAIL_REQUIRED}
                        </div>
                      )}
                      {!isInputFocused &&
                        !emailValidation &&
                        user.email !== '' && (
                          <div className="help-block">{error.email}</div>
                        )}
                    </div>
                    {otpSendSuccessfully ? (
                      <div className="row justify-content-center otpform">
                        <div className="col-md-12">
                          <div className="card border-0">
                            <div className="">
                              {/* <h3
                                  className="mb-4"
                                  style={{
                                    color: '#000',
                                    fontWeight: '700',
                                    fontSize: '34px',
                                  }}
                                >
                                  Enter OTP
                                </h3> */}
                              <p
                                className="pt-2 mb-4"
                                style={{
                                  width: '100%',
                                  fontSize: '13px',
                                  fontFamily: 'Manrope',
                                  fontWeight: '600',
                                  color: '#001818',
                                }}
                              >
                                We have send you an OTP to above mail
                                {/* <br />
                                  to {maskEmail(user.email)} */}
                              </p>
                              <div
                                className={
                                  ' justify-content-center ' +
                                  (error.otp_error ? 'otp_error' : '')
                                }
                              >
                                <div>
                                  <p className="label-login mb-1" htmlFor="otp">
                                    OTP
                                  </p>
                                  <input
                                    type="number"
                                    className="flx-common-input-fields form-control otpfield"
                                    name="otp"
                                    placeholder="  Enter OTP"
                                    maxLength={4}
                                    value={user.otp}
                                    onChange={handleChange}
                                  />
                                  {error.otp && (
                                    <div className="text-danger mt-1">
                                      {error.otp}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {!!error.otp_error && (
                                <div
                                  className="alert alert-danger mt-2"
                                  role="alert"
                                >
                                  {error.otp_error}
                                </div>
                              )}
                              <div className="d-flex justify-content-center align-items-center mt-1 mb-2">
                                {!timerOn ? (
                                  <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                    <span className="flx-general-text">
                                      Didn&apos;t Received OTP?
                                    </span>
                                    <Link
                                      onClick={() => resendOTP(OTP_TYPE)}
                                      className="btn btn-link text-right"
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      <span className="flex-sign-up-text">
                                        Resend OTP
                                      </span>
                                    </Link>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                      <span className="flx-general-text">
                                        Didn&lsquo;t Received OTP ?
                                      </span>
                                      {timerLeft ? (
                                        <span>
                                          &nbsp;&nbsp;
                                          {` Resend OTP in ${timerLeft} seconds`}
                                        </span>
                                      ) : (
                                        <span
                                          className="inactive-link px-2"
                                          style={{
                                            color: 'gray',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          Resend OTP
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="form-group mt-2">
                                <button
                                  onClick={() =>
                                    handleVerifyOtp(user.otp, OTP_TYPE)
                                  }
                                  style={{
                                    fontWeight: '700',
                                    fontSize: '12px',
                                    borderRadius: '5px',
                                  }}
                                  className={
                                    'send-btn  ' +
                                    (user.otp.length == 4
                                      ? 'active-signup-btn'
                                      : 'disabled')
                                  }
                                  disabled={isLoadingVerify}
                                >
                                  {!isLoadingVerify ? 'Verify' : 'Verifing...'}
                                </button>
                                {isLoading && false && <LoadingImg />}
                              </div>

                              {/* <div className="d-flex justify-content-center align-items-center mt-4">
                              <Link
                                to="/loginwithphone"
                                className="btn btn-link text-right"
                                style={{ textDecoration: 'underline' }}
                              >
                                <span className="flex-sign-up-text">
                                  I Don&lsquo;t have access to this Email?
                                </span>
                              </Link>
                            </div> */}
                              {resendOTPFlag && (
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                  <Link
                                    to="/login"
                                    className="btn btn-link text-right"
                                    style={{ textDecoration: 'underline' }}
                                  >
                                    <span className="flex-sign-up-text">
                                      I Don&lsquo;t have access to this Email ?
                                    </span>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <span className="color-red">{error.common}</span>
                    {!otpSendSuccessfully ? (
                      <div className="form-group mt-2">
                        <button
                          onClick={() => loginsendOTP(OTP_TYPE)}
                          style={{
                            fontWeight: '700',
                            fontSize: '12px',
                            borderRadius: '5px',
                          }}
                          className={
                            'send-btn  ' +
                            (emailValidation ? 'active-signup-btn' : '')
                          }
                          disabled={isLoading || !emailValidation}
                        >
                          {!isLoading ? 'Get OTP' : 'Loading...'}
                        </button>
                        {isLoading && false && <LoadingImg />}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <Link
                      to="/loginwithphone"
                      className="btn btn-link text-center"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="flex-sign-up-text">
                        Send OTP to Phone?
                      </span>
                    </Link> */}
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    <span className="flx-general-text">
                      Don&lsquo;t have an account ?
                    </span>
                    <Link
                      to="/register"
                      className="btn btn-link text-right"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="flex-sign-up-text">Sign Up</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps() {
  // const { LoginWithPhone } = state.authentication;
  return {
    // LoginWithPhone,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginWithEmail);
export { connectedLoginPage as LoginWithEmail };
// export default LoginWithPhone;
