import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BusinessunitAdd } from '../BusinessUnit/BusinessunitAdd';
import { businessunitActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let businessunitRows = [];

const AdminBusinessunitlist = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [businessunitData, setBusinessunitData] = useState({ rows: [] });
  const [businessunitRows, setBusinessunitRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  // effect to call bunit
  useEffect(() => {
    getAllBusinessunit(1);
  }, [query]);
  /**
   * Get all businessunit
   **/
  const getAllBusinessunit = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(businessunitActions.businessunitList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = businessunitData => {
    let businessunitList = [];
    for (
      let j = 0;
      businessunitData &&
      businessunitData.rows &&
      j < businessunitData.rows.length;
      j = j + 1
    ) {
      businessunitList.push(
        <tr key={j}>
          <td className="username">
            <img
              className=" round-emp-image"
              src="/assets/img/un-avatar.png"
            ></img>
            {businessunitData.rows[j].name}{' '}
            {businessunitData.rows[j].last_name &&
              businessunitData.rows[j].last_name}
          </td>
          <td>
            {businessunitData &&
              businessunitData.rows &&
              businessunitData.rows[j].createdAt &&
              moment(businessunitData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          <td>{businessunitData.rows[j].email} </td>
          <td>{businessunitData.rows[j].address} </td>
          <td>{businessunitData.rows[j]['Employer.company_name']} </td>
          <td className="eventCell  eventView">
            <Link
              data-cy="editbunit"
              className="businessunit_view"
              to={'/businessunitadd/' + businessunitData.rows[j]._id}
            >
              {' '}
              Edit{' '}
            </Link>
          </td>
        </tr>,
      );
    }
    setBusinessunitRows(businessunitList);
    setBusinessunitData(businessunitData);
  };
  return (
    <div className="businessunitList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-10">
                <div className="page-title"> Businessunits</div>
              </div>
              <div className="col d-flex justify-content-end">
                <Link
                  className="link"
                  to={'/businessunitadd/0'}
                  data-cy="addbunit"
                >
                  <div className="btn btn-primary-blue float-right">
                    <span> Add businessunit</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead border-top1"
                    >
                      Name
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort('createdAt')}
                      className="sortHead"
                    >
                      Created
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'createdAt' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'createdAt' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Employer</th>
                    <th className="eventCell border-top2 eventView" />
                  </tr>
                </thead>
                <tbody>{businessunitRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {businessunitData &&
              businessunitData.rows &&
              businessunitData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllBusinessunit(data);
              }}
              paginationData={businessunitData}
            />
          </div>
          {showmodal && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setshowmodal(false)}>
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    <BusinessunitAdd id={query._id} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedAdminBusinessunitlist = connect(mapStateToProps)(
  AdminBusinessunitlist,
);
export { connectedAdminBusinessunitlist as AdminBusinessunitlist };
