const Constants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  USER_SUCCESS: 'USER_SUCCESS',
  USER_REQUEST: 'USER_REQUEST',
  USER_FAILURE: 'USER_FAILURE',

  SAVEUSER_REQUEST: 'SAVEUSER_REQUEST',
  SAVEUSER_SUCCESS: 'SAVEUSER_SUCCESS',
  SAVEUSER_FAILURE: 'SAVEUSER_FAILURE',

  EMPLOYER_SUCCESS: 'EMPLOYER_SUCCESS',
  EMPLOYER_REQUEST: 'EMPLOYER_REQUEST',
  EMPLOYER_FAILURE: 'EMPLOYER_FAILURE',

  AGENCY_SUCCESS: 'AGENCY_SUCCESS',
  AGENCY_REQUEST: 'AGENCY_REQUEST',
  AGENCY_FAILURE: 'AGENCY_FAILURE',

  DEMO_SUCCESS: 'DEMO_SUCCESS',
  DEMO_REQUEST: 'DEMO_REQUEST',
  DEMO_FAILURE: 'DEMO_FAILURE',
  SAMPLE_SUCCESS: 'SAMPLE_SUCCESS',
  SAMPLE_REQUEST: 'SAMPLE_REQUEST',
  SAMPLE_FAILURE: 'SAMPLE_FAILURE',

  CANDIDATE_SUCCESS: 'CANDIDATE_SUCCESS',
  CANDIDATE_REQUEST: 'CANDIDATE_REQUEST',
  CANDIDATE_FAILURE: 'CANDIDATE_FAILURE',

  AGENCYCANDIDATE_SUCCESS: 'CANDIDATE_SUCCESS',
  AGENCYCANDIDATE_REQUEST: 'CANDIDATE_REQUEST',
  AGENCYCANDIDATE_FAILURE: 'CANDIDATE_FAILURE',

  SHIFTS_SUCCESS: 'SHIFTS_SUCCESS',
  SHIFTS_REQUEST: 'SHIFTS_REQUEST',
  SHIFTS_FAILURE: 'SHIFTS_FAILURE',

  INVOICE_SUCCESS: 'INVOICE_SUCCESS',
  INVOICE_REQUEST: 'INVOICE_REQUEST',
  INVOICE_FAILURE: 'INVOICE_FAILURE',

  STAFF_SUCCESS: 'STAFF_SUCCESS',
  STAFF_REQUEST: 'STAFF_REQUEST',
  STAFF_FAILURE: 'STAFF_FAILURE',

  DOCUMENTS_REQUEST: 'DOCUMENTS_REQUEST',
  DOCUMENTS_SUCCESS: 'DOCUMENTS_SUCCESS',
  DOCUMENTS_FAILURE: 'DOCUMENTS_FAILURE',

  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_FAILURE: 'CATEGORY_FAILURE',

  VACANCYSHIFT_SUCCESS: 'VACANCYSHIFT_SUCCESS',
  VACANCYSHIFT_REQUEST: 'VACANCYSHIFT_REQUEST',
  VACANCYSHIFT_FAILURE: 'VACANCYSHIFT_FAILURE',

  BUSINESSUNIT_SUCCESS: 'BUSINESSUNIT_SUCCESS',
  BUSINESSUNIT_REQUEST: 'BUSINESSUNIT_REQUEST',
  BUSINESSUNIT_FAILURE: 'BUSINESSUNIT_FAILURE',

  BOOKING_SUCCESS: 'BOOKING_SUCCESS',
  BOOKING_REQUEST: 'BOOKING_REQUEST',
  BOOKING_FAILURE: 'BOOKING_FAILURE',

  PAYOUT_SUCCESS: 'PAYOUT_SUCCESS',
  PAYOUT_REQUEST: 'PAYOUT_REQUEST',
  PAYOUT_FAILURE: 'PAYOUT_FAILURE',

  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',

  SECTION_SUCCESS: 'SECTION_SUCCESS',
  SECTION_REQUEST: 'SECTION_REQUEST',
  SECTION_FAILURE: 'SECTION_FAILURE',
  ALLOCATECANDIDATE_SUCCESS: 'ALLOCATECANDIDATE_SUCCESS',
  ALLOCATECANDIDATE_REQUEST: 'ALLOCATECANDIDATE_REQUEST',
  ALLOCATECANDIDATE_FAILURE: 'ALLOCATECANDIDATE_FAILURE',

  BLOCKBOOKINGLIST_SUCCESS: 'BLOCKBOOKINGLIST_SUCCESS',
  BLOCKBOOKINGLIST_FAILURE: 'BLOCKBOOKINGLIST_FAILURE',

  NOTIFICATION_SUCCESS: 'NOTIFICATION_SUCCESS',
  NOTIFICATION_REQUEST: 'NOTIFICATION_REQUEST',
  NOTIFICATION_FAILURE: 'NOTIFICATION_FAILURE',

  PRICE_REQUEST: 'PRICE_REQUEST',
  PRICE_FAILURE: 'PRICE_FAILURE',
  PRICE_SUCCESS: 'PRICE_SUCCESS',

  LOG_FAILURE: 'LOG_FAILURE',
  LOG_REQUEST: 'LOG_REQUEST',
  LOG_SUCCESS: 'LOG_SUCCESS',

  BLOCKCANDIDATE_SUCCESS: 'BLOCKCANDIDATE_SUCCESS',
  BLOCKCANDIDATE_REQUEST: 'BLOCKCANDIDATE_REQUEST',
  BLOCKCANDIDATE_FAILURE: 'BLOCKCANDIDATE_FAILURE',

  REFERAL_SUCCESS: 'REFERAL_SUCCESS',
  REFERAL_REQUEST: 'REFERAL_REQUEST',
  REFERAL_FAILURE: 'REFERAL_FAILURE',

  EDUCATION_SUCCESS: 'EDUCATION_SUCCESS',
  EDUCATION_REQUEST: 'EDUCATION_REQUEST',
  EDUCATION_FAILURE: 'EDUCATION_FAILURE',

  EXPERIENCE_SUCCESS: 'EXPERIENCE_SUCCESS',
  EXPERIENCE_REQUEST: 'EXPERIENCE_REQUEST',
  EXPERIENCE_FAILURE: 'EXPERIENCE_FAILURE',

  QUALIFICATION_SUCCESS: 'QUALIFICATION_SUCCESS',
  QUALIFICATION_REQUEST: 'QUALIFICATION_REQUEST',
  QUALIFICATION_FAILURE: 'QUALIFICATION_FAILURE',
};

export const reducerConstants = Constants;
