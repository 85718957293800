import { BillingInfo } from './EmpProfileComponents/BillingInfo';
import { EmailChangeNew } from './EmpProfileComponents/EmailChangeNew';
import { EmailChangeOtp } from './EmpProfileComponents/EmailChangeOtp';
import { EmpProfileDocuments } from './EmpProfileComponents/EmpProfileDocuments';
import { LoginSecurity } from './EmpProfileComponents/LoginSecurity';
import { MyProfileInfo } from './EmpProfileComponents/MyProfileInfo';
export const EmpProfileMain = () => {
  const currentPage = 1;
  return (
    <>
      <div className="">{currentPage == 1 && <MyProfileInfo />}</div>
      <div className="">{currentPage == 2 && <BillingInfo />}</div>
      <div className="">{currentPage == 3 && <EmpProfileDocuments />}</div>
      <div className="">{currentPage == 4 && <LoginSecurity />}</div>
      <div className="">{currentPage == 5 && <EmailChangeOtp />}</div>
      <div className="">{currentPage == 6 && <EmailChangeNew />}</div>
    </>
  );
};
