import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { employerService } from '../_services';
export const employerActions = {
  employerAdd,
  employerList,
  getEmployerListforasync,
  employerListv2,
  employerDelete,
  dashbordemployerreport,
  checkemailExist,
  permanentemployerAdd,
  dashbordorganizationreport,
  addSubscription,
  employerslistforshift,
};

function permanentemployerAdd(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EMPLOYER_REQUEST, employerObj });

      employerService.permanentemployerAdd(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            localStorage.setItem('user', JSON.stringify(data.data));
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function employerAdd(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EMPLOYER_REQUEST, employerObj });

      employerService.employerAdd(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function addSubscription(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EMPLOYER_REQUEST, employerObj });

      employerService.addSubscription(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function dashbordemployerreport(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.dashbordemployerreport(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function checkemailExist(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.checkemailExist(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function getEmployerListforasync(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.getEmployerListforasync(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function employerList(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.employerList(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function employerListv2(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.employerListv2(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function employerDelete(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.employerDelete(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function dashbordorganizationreport(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.dashbordorganizationreport(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function employerslistforshift(employerObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        employerObj,
      });

      employerService.employerslistforshift(employerObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
