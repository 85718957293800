import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { businessunitService, categoryService } from '../_services';
import { AddLocation } from './AddLocation';

import { messageConstant } from '../_constants';
import { RateCard } from './RateCard';
import './datePicker.css';

const format = 'DD/MM/YYYY';
const PostAgency = () => {
  // const dispatch = useDispatch();
  const [error, setError] = useState({ common: '' });

  const [showModal, setShowModal] = useState('');
  const [employerId, setEmployerId] = useState(null);
  const [businessUnitList, setBusinessUnitList] = useState(null);
  const [jobTitleList, setJobTitleList] = useState(null);
  const [selectedData, setSelectedData] = useState({
    selectedBusinessUnit: null,
    selectedJobTitle: null,
    dates: null,
  });
  const [showRateCard, setShowRateCard] = useState(false);

  const handleDateChange = dates => {
    setError({
      ...error,
      date: '', // Clear any previous error message related to dates
    });

    const formattedDates = dates.map(date => date.format());

    setSelectedData(prev => ({
      ...prev,
      dates: formattedDates,
    }));
    // }
  };

  const today = new Date();
  const minSelectableDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
  );
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        // setIsAdmin(true);
      } else {
        if (user.role == 4) {
          if (user && user.employer_id) {
            setEmployerId(user.employer_id);
          }
        }
      }
    }
  }, []);

  const handleEditBusinessUnit = e => {
    e.preventDefault();
    setSelectedData(prev => ({ ...prev, selectedBusinessUnit: null }));
    setBusinessUnitList(null);
    getBusinessUnitEmployer();
  };

  const handleSelectBusinessName = event => {
    event.preventDefault();
    setError({
      ...error,
      businessUnit: '',
    });
    const selectUnit = businessUnitList.find(
      unit => unit._id == event.target.value,
    );
    setSelectedData(pre => ({ ...pre, selectedBusinessUnit: selectUnit }));
  };

  const getBusinessUnitEmployer = async () => {
    try {
      if (employerId !== null) {
        const res = await businessunitService.businessunitList({
          employer_id: employerId,
        });
        if (res.error_code === 0) {
          if (res?.data?.rows?.length > 0) {
            setBusinessUnitList(res.data.rows);
          }
          if (res?.data?.rows?.length <= 1) {
            setSelectedData(pre => ({
              ...pre,
              selectedBusinessUnit: res.data.rows[0],
            }));
          }
        }
      } else {
        setBusinessUnitList(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setBusinessUnitList(null);
    getBusinessUnitEmployer();
    getJobTitle();
  }, [employerId]);

  const getJobTitle = async () => {
    try {
      const res = await categoryService.jobTitleList();
      if (res.error_code === 0) {
        if (res?.data?.length > 0) {
          setJobTitleList(res.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedJobTitle = event => {
    setError({
      ...error,
      jobtitle: '',
      date: '',
    });
    const selectjob = jobTitleList.find(
      category => category._id == event.target.value,
    );
    setSelectedData(pre => ({ ...pre, selectedJobTitle: selectjob }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    // let isValid = true;
    const updatedError = { ...error };

    if (!selectedData.selectedBusinessUnit) {
      updatedError.businessUnit = messageConstant.BUSINESS_UNIT_ERROR;
      error.isError = true;
    }

    if (!selectedData.dates) {
      updatedError.date = messageConstant.DATE_ERROR;
      error.isError = true;
    }

    if (!selectedData.selectedJobTitle) {
      updatedError.jobtitle = messageConstant.JOB_TITLE_ERROR;
      error.isError = true;
    }

    setError(updatedError);
    if (error.isError) return;

    setShowRateCard(true);
  };

  const handleHideRateCard = () => {
    setShowRateCard(false);
  };

  // useEffect(() => {
  //   // setSelectedData(prevArray => [
  //   //   ...prevArray,
  //   //   { selectedBusinessUnit, selectedJobTitle, dates },
  //   // ]);
  //   localStorage.setItem('PostAgencyData', JSON.stringify(selectedData));
  // }, [selectedData]);
  console.log(selectedData.selectedBusinessUnit, `data`);
  return (
    <>
      {!showRateCard && (
        <div className="container flx-job-post-main-container">
          <h2 className="text-center">Post Vacancies</h2>
          <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
            <p className="text-center flx-recs-required">Add Job Details</p>
            <div className="container flx-job-post-job-details-section">
              <form>
                <div className="form-group">
                  <label
                    htmlFor="location"
                    className="flx-job-posts-labels flx-recs-required"
                  >
                    Business unit
                  </label>
                  {!selectedData.selectedBusinessUnit && (
                    <button
                      type="button"
                      className="flx-add-location-popup-form"
                      onClick={() => setShowModal('location')}
                    >
                      <i className="fas fa-plus flx-icon-plus"></i> Add New
                      Business Unit
                    </button>
                  )}
                  {(businessUnitList == null ||
                    businessUnitList?.length <= 1) && (
                    <input
                      type="text"
                      className="form-control"
                      id="flx-location"
                      value={
                        selectedData.selectedBusinessUnit
                          ? selectedData.selectedBusinessUnit.name
                          : ''
                      }
                      placeholder={
                        businessUnitList?.length > 0
                          ? ''
                          : `You haven't added any business unit`
                      }
                      disabled
                    />
                  )}
                  {businessUnitList?.length > 1 &&
                    !selectedData.selectedBusinessUnit && (
                      <select
                        className="form-control select-custom-style"
                        id="flx-location"
                        // onClick={handleDropdown}
                        onChange={handleSelectBusinessName}
                      >
                        <option
                          value=""
                          selected
                          disabled
                          style={{
                            display: !selectedData.selectedBusinessUnit
                              ? 'block'
                              : 'none',
                          }}
                        >
                          Select your business
                        </option>
                        {businessUnitList.map((list, key) => (
                          <option key={key} value={list._id}>
                            {list.name}
                          </option>
                        ))}
                      </select>
                    )}

                  {selectedData.selectedBusinessUnit && (
                    <div className="list-group flx-card-business-unit-info">
                      <div
                        className="list-group-item"
                        style={{
                          borderRadius: '11px',
                          border: '1px solid #ECECEC',
                          boxShadow: '0px 0px 20px 0px rgba(0, 0, 0.7, 0)',
                        }}
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-1 flx-added-location-info">
                            {selectedData.selectedBusinessUnit.name}
                          </h5>
                        </div>
                        <div className="d-md-flex justify-content-between align-items-center">
                          <p className="mb-0 flx-business-location-address">
                            {selectedData.selectedBusinessUnit.address}
                          </p>
                          <div>
                            <button
                              type="button"
                              className="btn flx-edit-link btn-sm mr-2"
                              onClick={e => handleEditBusinessUnit(e)}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="help-block">{error.businessUnit}</div>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="date"
                    className="flx-job-posts-labels flx-recs-required"
                  >
                    Choose date{' '}
                  </label>

                  <div>
                    <DatePicker
                      value={selectedData.dates}
                      onChange={date => {
                        handleDateChange(date);
                      }}
                      minDate={minSelectableDate}
                      id="flx-date"
                      multiple
                      sort
                      shadow={true}
                      style={{ width: '658px' }}
                      // arrow={false}
                      format={format}
                      placeholder="Select date from the calendar "
                      className="custom-calendar"
                      calendarPosition="bottom-center"
                    />
                  </div>
                  <div className="help-block">{error.date}</div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="jobTitle"
                    className="flx-job-posts-labels flx-recs-required"
                  >
                    Job Title{' '}
                  </label>

                  {(jobTitleList == null || jobTitleList?.length <= 1) && (
                    <input
                      type="text"
                      className="form-control"
                      id="flx-location"
                      value={jobTitleList ? jobTitleList.name : ''}
                      placeholder={`You have't added any job title`}
                    />
                  )}
                  {jobTitleList?.length > 0 && (
                    <select
                      className="form-control select-custom-style"
                      id="flx-location"
                      onChange={e => handleSelectedJobTitle(e)}
                    >
                      <option value="" selected disabled>
                        Add your job title
                      </option>
                      {jobTitleList.map((list, key) => (
                        <option value={list._id} key={key}>
                          {list.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <div className="help-block">{error.jobtitle}</div>
                </div>
              </form>
            </div>
            <div className="flx-job-post-nxt-back-btn-section">
              <button className="btn btn-sm flx-job-post-back-btn">Back</button>

              <button
                onClick={e => {
                  handleSubmit(e);
                  // const isValid = handleSubmit(e);
                  // if (isValid) {
                  //   const { dispatch } = props;
                  //   dispatch(postAgencyActions.setPostAgencyData(selectedData));
                  //   navigate('/ratecard');
                  // }
                }}
                className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
              >
                Save &amp; Next -&gt;
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal open up on add location click*/}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${showModal == 'location' ? 'show' : ''}`}
        style={{ display: showModal == 'location' ? 'block' : 'none' }}
      >
        <AddLocation handleClose={setShowModal} />
      </div>
      {showRateCard && (
        <RateCard
          postAgencyData={selectedData}
          businessunitList={businessUnitList}
          handleBusinessUnit={getBusinessUnitEmployer}
          handleHideRateCard={handleHideRateCard}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedPostAgency = connect(mapStateToProps)(PostAgency);
export { connectedPostAgency as PostAgency };
