import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { VacancyCalendar } from '../Calendar';
import { MapView } from '../Map';
import {
  bookingActions,
  businessunitActions,
  employerActions,
  vacancyshiftActions,
} from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { miscService } from '../_services';
import { VacancyshiftAdd } from './VacancyshiftAdd';

let carehomename = '';

const VacancyshiftList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [vacancyshiftData, setVacancyshiftData] = useState({ rows: [] });
  const [vacancyshiftRows, setVacancyshiftRows] = useState([]);
  const [organization, setorganization] = useState();
  const [calendar, setcalendar] = useState(false);
  const [shifts, setshifts] = useState(true);
  const [showmodal, setshowmodal] = useState(false);
  const [allocatevacancydetails, setallocatevacancydetails] = useState([]);
  const [allocatevacancymodal, setallocatevacancymodal] = useState(false);
  const [bookingvacancydetails, setbookingvacancydetails] = useState([]);
  const [viewdetails, setviewdetails] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [showAllocatemodal, setshowAllocatemodal] = useState(false);
  const [daymonthyear, setdaymonthyear] = useState({ a: '', b: '', c: '' });
  const [isParentEmployer, setisParentEmployer] = useState(false);
  const [organizationRows, setorganizationRows] = useState([]);
  const navigate = useNavigate();
  const [mapModal, setMapModal] = useState({
    modal: false,
    vacancyData: {},
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: parseInt(new URLSearchParams(location.search).get('page')) || 1,
      noOfItems: 8,
    },
  });
  let employer_id = 0;
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [vacancyshiftid, setvacancyshiftid] = useState(0);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [businessunitrows, setbusinessunitrows] = useState();
  const [IsEmployer, setIsEmployer] = useState(false);
  const [IsBunit, setIsBunit] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [IsAdmin, setIsAdmin] = useState(false);
  const [IsStaff, setIsStaff] = useState(false);

  const [selectedCandidatematch, setSelectedCandidateMatch] = useState([]);

  let isadmin = false;
  let isstaff = false;
  let isemployer = false;
  let isbunit = false;
  const { dispatch, vacancyshiftFilterDate, vacancyshiftFilterbusinessunit } =
    props;
  // debouncing for searchkey only - start
  let isfirsttime = useRef(true);
  const debouncedSearchFrom = useDebounce(query?.from_date, 500);
  const debouncedSearchTo = useDebounce(query?.to_date, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearchFrom || debouncedSearchTo) {
        getAllVacancyshift();
      }
    }
  }, [debouncedSearchFrom, debouncedSearchTo]);
  // debouncing end
  // To load filter options and user roles
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.employer_id && user.role == 4) {
        setIsEmployer(true);
        isemployer = true;
        employer_id = user.employer_id;
        if (user.is_parent) {
          setisParentEmployer(true);
          getAllParentEmployer();
        }
        carehomename = user.name;
        getAllbusinessunit();
      }
      if (user.role == 1) {
        setIsAdmin(true);
        isadmin = true;
      }
      if (user.role == 7) {
        setIsBunit(true);
        isbunit = true;
      }
      if (user.role == 6) {
        setIsStaff(true);
        isstaff = true;
      }
    }
  }, [
    vacancyshiftFilterDate,
    query,
    IsAdmin,
    IsStaff,
    vacancyshiftFilterbusinessunit,
    organization,
    debouncedSearchFrom,
  ]);
  // To load all vacancies
  useEffect(() => {
    dispatch({
      type: 'vacancyshiftFilterDate',
      data: moment().format('yyyy-MM'),
    });
  }, []);

  useEffect(() => {
    getAllVacancyshift(1);
  }, [
    query.pageVo.sort_order,
    query.pageVo.sort,
    query.pageVo.vacancyshiftFilterDate,
    vacancyshiftFilterbusinessunit,
  ]);
  // to load user roles and state bidding amount
  // to allocatevacancydetails amount
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        setIsEmployer(true);
        isemployer = true;
      }
      if (user.role == 1) {
        setIsAdmin(true);
      }
      if (user.role == 6) {
        setIsStaff(true);
      }
      if (user.role == 7) {
        setIsBunit(true);
      }
    }
    if (bookingvacancydetails.maximum_candidate_bidding_amount) {
      setallocatevacancydetails({
        ...allocatevacancydetails,
        amount: bookingvacancydetails.maximum_candidate_bidding_amount,
      });
    }
  }, [bookingvacancydetails]);
  // candidate selection for allocate vacancy
  const selectedcandidate = selectedOption => {
    setError({ common: '' });
    setSelectedCandidateMatch({ ...selectedOption });
    setallocatevacancydetails(allocatevacancydetails => ({
      ...allocatevacancydetails,
      candidate_userid: selectedOption.value,
      vacancy_id: bookingvacancydetails._id,
      employer_id: bookingvacancydetails.employer_id,
      booking_status: 0,
      applied_date: new Date().toISOString().slice(0, 10),
      amount: allocatevacancydetails.amount,
      candidate_id: selectedOption._id,
    }));
  };

  const selectedcandidatelist = () => {
    const isCategoryMatch =
      selectedCandidatematch.category === bookingvacancydetails.Category._id;

    const isOtherCategoryMatch = selectedCandidatematch.other_category.includes(
      bookingvacancydetails.Category._id,
    );
    if (isCategoryMatch || isOtherCategoryMatch) {
      allocatecandidateTovacancy(false);
    } else {
      setError({
        common:
          'Please select a candidate who has the same role as the role to be allocated ',
      });
    }
  };
  // handler for bidding amount change
  const biddingamountHandleChange = event => {
    setError({ common: '' });
    setallocatevacancydetails({
      ...allocatevacancydetails,
      amount: event.target.value,
    });
  };
  // for filter - get all parent employers in select box
  const getAllParentEmployer = () => {
    const { dispatch } = props;
    let newquery = {
      status: 1,
      is_parent: 0,
      getChildEmployers: true,
      select: ['_id', 'company_name'],
    };
    dispatch(employerActions.employerListv2(newquery)).then(res => {
      if (res) {
        let parentArray = [];
        for (let s = 0; s < res?.data?.rows?.length; s++) {
          parentArray.push(
            <option value={res?.data?.rows?.[s]._id}>
              {res?.data?.rows?.[s].company_name}
            </option>,
          );
        }
        setorganizationRows(parentArray);
      }
    });
  };
  // for filter - get all staff in select box
  // const getStafflist = inputvalue =>
  //   new Promise((resolve, reject) => {
  //     fetch(apiConstants.apiUrl + '/getstafflist', {
  //       method: 'post',
  //       headers: authHeader(),
  //       body: JSON.stringify({
  //         status: 1,
  //         searchKey: inputvalue,
  //         select: ['_id', 'name'],
  //       }),
  //     })
  //       .then(response =>
  //         response.json().then(({ data }) => {
  //           // adding all option to filter select
  //           if (data && data.rows && data.rows.length > 0) {
  //             let AllElement = { _id: 0, name: 'ALL' };
  //             let newArray = [AllElement, ...data.rows];
  //             resolve(
  //               newArray.map(({ _id, name }) => ({
  //                 value: _id,
  //                 label: name,
  //               })),
  //             );
  //           } else {
  //             // No options found
  //             resolve([]);
  //           }
  //         }),
  //       )
  //       .catch(reject);
  //   });

  // for filter - get all categories in select box
  const getCategorylist = inputValue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcategorylist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputValue,
          select: ['_id', 'name'],
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            // Check if there are any data results
            if (data && data.rows && data.rows.length > 0) {
              let AllElement = { _id: 0, name: 'ALL' };
              let newArray = [AllElement, ...data.rows];
              resolve(
                newArray.map(({ _id, name }) => ({
                  value: _id,
                  label: name,
                })),
              );
            } else {
              // No options found
              resolve([]);
            }
          }),
        )
        .catch(reject);
    });
  // for filter - sort vacancy list in select box
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
    console.log(setQuery, 'setQuery');
  };
  // for delete vacancy confirmation handler
  // popup action for delete comfirmation modal and set vacancy id
  const deletevacancyshiftmodal = _id => {
    setdeleteconfirmationmodal(true);
    setvacancyshiftid(_id);
  };
  // for display the edit vacancy modal
  // and set vacancy id
  const editVacancyShiftModal = _id => {
    setvacancyshiftid(_id);
    setshowmodal(true);
  };
  // for filter - get all business unit in select box

  const getAllbusinessunit = () => {
    const { dispatch } = props;
    let bquery = { employer_id: employer_id, select: ['_id', 'name'] };

    dispatch(businessunitActions.businessunitList(bquery)).then(res => {
      let businessunitData = res.data.rows;
      let businessunitlist = [];
      for (let i = 0; i < businessunitData.length; i++) {
        businessunitlist.push(
          <option value={businessunitData[i]._id}>
            {businessunitData[i].name}
          </option>,
        );
      }
      setbusinessunitrows(businessunitlist);
    });
  };

  const changePage = newPage => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('page', newPage);
    navigate(newUrl.pathname + newUrl.search, { replace: true });
  };

  // get vacancy list according to the query
  const getAllVacancyshift = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    //navigate(`${location.pathname}?page=${pageNo}`);
    changePage(pageNo);
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.from_date = vacancyshiftFilterDate;
    queryNew.business_unit = vacancyshiftFilterbusinessunit;
    if (!queryNew.employer_id) {
      queryNew.employer_id = employer_id;
    }
    if (organization?.organization_id) {
      queryNew.organization_id = organization?.organization_id;
    }
    if (isemployer || isbunit) {
      // query.publish_status = 1;
      queryNew.excld_select = [
        'price',
        'pushnotification_send',
        'work_name',
        'note',
        'UserId',
        'postcode',
        'lat',
        'long',
      ];
    }
    delete queryNew.select;
    dispatch(vacancyshiftActions.vacancyshiftListv2(queryNew)).then(
      res => {
        for (
          let s = 0;
          res.data && res.data.rows && s < res?.data?.rows?.length;
          s++
        ) {
          res.data.rows[s].x = 1;
        }
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // business unit filter change handler
  const businessunitchange = event => {
    const { value } = event.target;
    dispatch({
      type: 'vacancyshiftFilterbusinessunit',
      data: value,
    });
  };

  // staff filter change handler and a call for get vacancy list
  // const staff_change = selectedOption => {
  //   let newquery = query;
  //   setQuery((...prevState) => ({ ...prevState, st_id: selectedOption.value }));
  //   newquery.allocated_staff = selectedOption.value;
  //   getAllVacancyshift(1, newquery);
  // };

  // category filter change handler and a call for get vacancy list
  const category_change = selectedOption => {
    let newquery = query;
    setQuery((...prevState) => ({
      ...prevState,
      category_id: selectedOption.value,
    }));
    newquery.category = selectedOption.value;
    getAllVacancyshift(1, newquery);
  };

  // handler for from date and to date
  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'from_date' || name === 'to_date') {
      // Parse the dates to compare them
      const fromDate =
        name === 'from_date' ? new Date(value) : new Date(query.from_date);
      const toDate =
        name === 'to_date' ? new Date(value) : new Date(query.to_date);

      // Check if from_date is greater than to_date
      if (fromDate > toDate) {
        alert('From date cannot be greater than To date');
        setQuery({ ...query, to_date: '', from_date: '' });
        return; // Exit the function without updating the state
      }
    }

    isfirsttime.current = false;
    setQuery({ ...query, [name]: value });

    if (name == 'from_date') {
      dispatch({
        type: 'vacancyshiftFilterDate',
        data: value,
      });
    }
  };
  // organization filter change handler
  const organization_handleChange = event => {
    const { name, value } = event.target;
    setorganization({
      [name]: value,
    });
  };
  // handler for vacancy add modal
  const showsavemodal = value => {
    setshowmodal(value);
    setshowAllocatemodal(value);
    window.location.reload();
  };
  // to change the view of vacancy data in calendar view
  // and state the from date of current month
  const showcalendar = () => {
    setcalendar(true);
    let inputDate = vacancyshiftFilterDate;
    const parts = inputDate.split('-');
    if (parts.length === 3) {
      const [year, month] = parts;
      const outputDate = `${year}-${month}`;
      dispatch({
        type: 'vacancyshiftFilterDate',
        data: outputDate,
      });
    }
    setshifts(false);
  };
  // to change the view of vacancy data in list view
  const showshifts = () => {
    let inputDate = vacancyshiftFilterDate;
    const parts = inputDate.split('-');
    if (parts.length == 2) {
      const outputDate = `${inputDate}-01`;
      dispatch({
        type: 'vacancyshiftFilterDate',
        data: outputDate,
      });
    }
    setcalendar(false);
    setshifts(true);
  };
  // to hide the displayed state error if any after one minute
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // employer filter change handler and a call for get vacancy list
  const employer_change = selectedOption => {
    let newquery = query;
    setQuery((...prevState) => ({
      ...prevState,
      employer_id: selectedOption.value,
    }));
    newquery.employer_id = selectedOption.value;
    getAllVacancyshift(1, newquery);
  };
  // to allocate candidate to a vacancy and checking validation for bidding amount
  const allocatecandidateTovacancy = () => {
    setError({ common: '' });
    if (
      bookingvacancydetails.is_bidding_option_needed == 1 &&
      !allocatevacancydetails.amount
    ) {
      setError({ common: 'Bidding amount is required' });
      return;
    }
    if (
      bookingvacancydetails.is_bidding_option_needed == 1 &&
      allocatevacancydetails.amount
    ) {
      if (allocatevacancydetails.amount <= 0) {
        setError({ common: 'Bidding amount should be greater than zero' });
        return;
      }
      if (
        bookingvacancydetails.maximum_candidate_bidding_amount <
        allocatevacancydetails.amount
      ) {
        setError({
          common:
            'Bidding amount greater than maximum bidding amount' +
            ' ' +
            bookingvacancydetails.maximum_candidate_bidding_amount,
        });
        return;
      }
    }
    if (
      allocatevacancydetails.candidate_userid &&
      allocatevacancydetails.candidate_id
    ) {
      setIsLoading(true);
      dispatch(bookingActions.bookingAdd(allocatevacancydetails)).then(
        () => {
          setallocatevacancymodal(false);
          setIsLoading(false);
          getAllVacancyshift(query.pageVo.pageNo);
        },
        err => {
          setIsLoading(false);
          hideError();
          setallocatevacancydetails({
            ...allocatevacancydetails,
            candidate_userid: '',
            candidate_id: '',
          });
          setError({ common: err });
        },
      );
    } else {
      setError({ common: 'Choose a candidate' });
      setIsLoading(false);
    }
  };

  // to show allocate shift modal-
  // this function is passed as props to <vacancycalendar> component
  const showAllocateShift = (a, b, c) => {
    setdaymonthyear({ day: a, month: b, year: c });
    setshowAllocatemodal(true);
  };
  // to list vacancy data and pagination
  const setListAndPagination = vacancyshiftData => {
    let vacancyshiftList = [];

    for (
      let j = 0;
      vacancyshiftData?.rows && j < vacancyshiftData.rows.length;
      j += 1
    ) {
      const dt = new Date(vacancyshiftData.rows[j].from_date_string);

      const from_date = moment(dt).format('DD-MM-YYYY');
      const currentDate = moment();

      const from_date_with_time = moment(
        vacancyshiftData.rows[j].from_date_string +
          ' ' +
          vacancyshiftData.rows[j].from_time,
      ).format('YYYY-MM-DD HH:mm');

      let expired = false;
      if (moment(from_date_with_time).isSameOrBefore(currentDate)) {
        expired = true;
      }

      vacancyshiftList.push(
        <tr key={j}>
          <td className="font-11">
            {moment(from_date, 'DD-MM-YYYY').format('DD-MMM-YYYY')}
          </td>
          <td className="font-11">
            <p style={{ fontWeight: 'bold', color: '#183245' }}>
              {vacancyshiftData.rows[j].section_type}
            </p>
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(vacancyshiftData.rows[j].from_time)}-
              {miscService.time24to12(vacancyshiftData.rows[j].to_time)}
            </div>
          </td>
          {/* <td className="font-11" style={{ textTransform: 'capitalize' }}>
            {vacancyshiftData.rows[j].Staff.name
              ? vacancyshiftData.rows[j].Staff.name
              : '- - -'}
          </td> */}
          <td className="font-11" style={{ textTransform: 'capitalize' }}>
            {`${vacancyshiftData.rows[j].Employer.company_name.substring(
              0,
              10,
            )}(${vacancyshiftData.rows[j].Businessunit.name.substring(0, 10)})${
              vacancyshiftData.rows[j]?.Employer?.Parent &&
              Boolean(vacancyshiftData.rows[j]?.Employer?.Parent._id)
                ? `-${vacancyshiftData.rows[
                    j
                  ]?.Employer?.Parent?.company_name.substring(0, 10)}`
                : ''
            }${
              vacancyshiftData.rows[j].Employer.company_name.length > 10
                ? '...'
                : ''
            }`}
          </td>
          <td className="font-11" style={{ textTransform: 'capitalize' }}>
            {vacancyshiftData.rows[j].Category.name}
          </td>
          <td className="font-11">
            {' '}
            {vacancyshiftData.rows[j].new ? vacancyshiftData.rows[j].new : 0}
          </td>
          <td className="font-11">
            {vacancyshiftData.rows[j].confirmed
              ? vacancyshiftData.rows[j].confirmed
              : 0}
          </td>
          <td className="font-11">
            {vacancyshiftData.rows[j].no_of_openings
              ? vacancyshiftData.rows[j].no_of_openings
              : 0}
          </td>

          <td className="font-11">
            {' '}
            {vacancyshiftData.rows[j].booking
              ? vacancyshiftData.rows[j].booking
              : 0}
          </td>
          <td className="font-11">
            {' '}
            {vacancyshiftData.rows[j].rejected
              ? vacancyshiftData.rows[j].rejected
              : 0}
          </td>
          <td className="font-11">
            {vacancyshiftData.rows[j].confirmed
              ? vacancyshiftData.rows[j].confirmed
              : 0}
          </td>
          <td className="font-11">
            {vacancyshiftData.rows[j].attended
              ? vacancyshiftData.rows[j].attended
              : 0}
          </td>
          {(IsAdmin ||
            IsStaff ||
            isadmin ||
            isstaff ||
            isemployer ||
            IsEmployer) && (
            <td className="font-11">
              {vacancyshiftData.rows[j].publish_status == 1 ? (
                <span style={{ color: '#058ef8', fontWeight: 500 }}>
                  Published
                </span>
              ) : vacancyshiftData.rows[j].publish_status == 2 ? (
                <span style={{ color: '#14af33', fontWeight: 500 }}>
                  Only Subscribed Agencies
                </span>
              ) : (
                <span style={{ color: '#e13607', fontWeight: 500 }}>Draft</span>
              )}
            </td>
          )}

          <td>
            {moment(vacancyshiftData.rows[j].updatedAt).format(
              'DD MMM YYYY hh:mm a',
            )}
          </td>
          <td>
            {
              <div
                data-cy="viewvacancydetails"
                className="agency-viewdt"
                onClick={() => {
                  setviewdetails(vacancyshiftData.rows[j]),
                    setviewdetailsmodal(true);
                }}
              >
                View Details
              </div>
            }
          </td>
          {(IsAdmin || IsStaff || isadmin || isstaff) && (
            <td data-cy="vallocate">
              {vacancyshiftData.rows[j].confirmed >=
              vacancyshiftData.rows[j].no_of_openings ? (
                <div className="allocate-filled">Filled</div>
              ) : vacancyshiftData.rows[j].publish_status == 0 &&
                !IsAdmin &&
                !isadmin &&
                !IsStaff &&
                !isstaff ? (
                <div className="d-flex justify-content-center">
                  <p style={{ fontWeight: 'bold', color: '#2a6a9b' }}>Draft</p>
                </div>
              ) : !expired ? (
                <div
                  data-cy="allocatee"
                  className="allocate-viewdt"
                  onClick={() => {
                    setbookingvacancydetails(vacancyshiftData.rows[j]);
                    setallocatevacancymodal(true);
                  }}
                >
                  Allocate
                </div>
              ) : (
                <div className="font-red dtext-align fontwghtbold">
                  {' '}
                  Expired
                </div>
              )}
            </td>
          )}
          <td
            className="eventCell event Edit"
            style={{ cursor: 'pointer' }}
            data-cy="deletevacancy"
            onClick={() => {
              deletevacancyshiftmodal(vacancyshiftData.rows[j]._id);
            }}
          >
            <img src="/assets/img/delete.png"></img>
          </td>
          <td
            data-cy="editvacancy"
            className="eventCell event Edit"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              editVacancyShiftModal(vacancyshiftData.rows[j]._id);
            }}
          >
            <div className="editButton">Edit</div>
          </td>
          {/* {IsAdmin ||
            (IsStaff && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  editVacancyShiftModal(vacancyshiftData.rows[j]._id);
                }}
              >
                <div className="editButton">edit</div>
              </td>
            ))} */}
          <td>
            <Link
              data-cy="show bookings"
              style={{
                color: '#049bd7',
                textDecoration: 'none',
              }}
              // to={'/bookinglist/' + vacancyshiftData.rows[j]._id + '/0'}
              to={
                '/bookinglist/' +
                vacancyshiftData.rows[j]._id +
                '/0?fromVacancy=true'
              }
            >
              <div className="d-flex ">
                <div className="bookinganglearrow">Bookings</div>
                <div>
                  <i className="fas fa-angle-right"></i>
                </div>
              </div>
            </Link>
            {(isadmin || IsAdmin) && (
              <div
                style={{
                  color: '#049bd7',
                  textDecoration: 'none',
                  fontSize: 10,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  e.preventDefault();
                  setMapModal({
                    modal: true,
                    vacancyData: vacancyshiftData.rows[j],
                  });
                }}
              >
                <div className="d-flex mt-2">
                  <div className="bookinganglearrow mr-2">Map View</div>
                  <div>
                    <i className="fas fa-globe"></i>
                  </div>
                </div>
              </div>
            )}
          </td>
        </tr>,
      );
    }
    setVacancyshiftRows(vacancyshiftList);
    setVacancyshiftData(vacancyshiftData);
  };
  // to delete vacancy service call
  const deletevacancyshift = () => {
    const { dispatch } = props;
    if (isDeleteLoading) {
      return;
    }
    setIsDeleteLoading(true);
    dispatch(
      vacancyshiftActions.vacancyshiftDelete({
        _id: vacancyshiftid,
        status: 0,
      }),
    ).then(
      () => {
        setdeleteconfirmationmodal(false);
        setIsDeleteLoading(false);
        getAllVacancyshift(query.pageVo.pageNo);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // custom style for async select
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '200px',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="VacancyshiftList">
      <div className="col-md-12">
        <div className="card regular-table-with-color m-0">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <div className="page-title">Vacancy Shift</div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="filter-row ">
                {calendar && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        From
                      </label>
                    </div>
                    <div className={'mt-1'}>
                      <input
                        type="month"
                        className="form-control-carehome"
                        name="from_date"
                        placeholder="from_date"
                        value={
                          vacancyshiftFilterDate.match(/^\d{2}-\d{4}$/)
                            ? `${vacancyshiftFilterDate.split('-')[0]}-${
                                vacancyshiftFilterDate.split('-')[1]
                              }`
                            : vacancyshiftFilterDate
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                {!calendar && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        From
                      </label>
                    </div>
                    <div className={'mt-1'}>
                      <input
                        type="date"
                        className="form-control-carehome"
                        name="from_date"
                        placeholder="from_date"
                        value={query.from_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                {!calendar && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        To
                      </label>
                    </div>
                    <div className={'mt-1'}>
                      <input
                        type="date"
                        className="form-control-carehome"
                        name="to_date"
                        placeholder="to_date"
                        value={query.to_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                {(IsAdmin || isstaff) && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        Employer
                      </label>
                    </div>
                    <div className="mt-1" style={{ marginRight: '20px' }}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="employer_id"
                        onChange={employer_change}
                        placeholder={'Select Employer'}
                        cacheOptions
                        defaultOptions
                        className="selectemployer"
                        // isMulti
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getEmployerlist}
                      />
                    </div>
                  </div>
                )}
                {IsEmployer && !IsBunit && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        Business Unit
                      </label>
                    </div>
                    <div className={'mt-1'}>
                      <select
                        data-cy="selectbunit"
                        className="select col-md-12 select-carehome "
                        name="businessunit"
                        onChange={businessunitchange}
                      >
                        <option value="77" hidden={true}>
                          {' '}
                          Select Business Unit
                        </option>
                        <option value=""> All</option>
                        {businessunitrows}
                      </select>
                    </div>
                  </div>
                )}
                {/* {!IsStaff && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        Staff
                      </label>
                    </div>
                    <div className="mt-1" style={{ marginRight: '20px' }}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="allocated_staff"
                        onChange={staff_change}
                        placeholder={'select staff'}
                        cacheOptions
                        defaultOptions
                        className="selectstaff"
                        // isMulti
                        noOptionsMessage={() => 'No results found'}
                        loadOptions={getStafflist}
                      />
                    </div>
                  </div>
                )} */}
                <div className="filter-item2">
                  <div className="">
                    {' '}
                    <label className="ml-10" htmlFor="type">
                      Jobrole{' '}
                    </label>
                  </div>
                  <div className="mt-1" style={{ marginRight: '20px' }}>
                    <AsyncSelect
                      styles={customSelectStyles}
                      name="category"
                      onChange={category_change}
                      placeholder={'Select Category'}
                      cacheOptions
                      defaultOptions
                      className="selectcategory"
                      // isMulti
                      noOptionsMessage={() => 'No results found'}
                      loadOptions={getCategorylist}
                    />
                  </div>
                </div>
                {isParentEmployer && (
                  <div className="filter-item2">
                    <div className="">
                      {' '}
                      <label className="ml-10" htmlFor="type">
                        Organization
                      </label>
                    </div>
                    <div className={'mt-1'}>
                      <select
                        className="select col-md-12 select-carehome"
                        name="organization_id"
                        value={query.organization_id}
                        onChange={organization_handleChange}
                      >
                        <option value=""> All</option>
                        {organizationRows}
                      </select>
                    </div>
                  </div>
                )}
              </div>
              {/* FROM DATE PICKER======== */}
              <div className="button-row">
                <div className="space-row">
                  {calendar && (
                    <>
                      <div className=" ">
                        <div
                          onClick={() => showshifts()}
                          className={'active-icon-div mt-1'}
                        >
                          <img
                            src="/assets/img/bars_light.png"
                            style={{ height: '17px' }}
                          ></img>
                        </div>
                      </div>
                      <div className="flx-calendar-filter-section-vacancy">
                        <div
                          data-cy="calenderview"
                          // onClick={() => showcalendar()}
                          className={'icon-div mt-1'}
                        >
                          <img
                            src="/assets/img/cal_black.png"
                            style={{ height: '25px' }}
                          ></img>
                        </div>
                      </div>
                    </>
                  )}
                  {/*----- place active button ----- */}
                  {shifts && (
                    <>
                      <div className=" ">
                        <div
                          data-cy="listview"
                          // onClick={() => showshifts()}
                          className={'icon-div mt-1'}
                        >
                          <img
                            src="/assets/img/bars_dark.png"
                            style={{ height: '17px' }}
                          ></img>
                        </div>
                      </div>
                      <div className=" ">
                        <div
                          onClick={() => showcalendar()}
                          className={'active-icon-div mt-1 '}
                        >
                          {/* x */}
                          <img
                            src="/assets/img/cal_white.png"
                            style={{ height: '20px' }}
                          ></img>
                        </div>
                      </div>
                    </>
                  )}
                  {/* ------palce active button---- */}
                  <div className="">
                    <div
                      className="c btn btn-primary-blue float-right mt-1"
                      data-cy="addvacancy"
                      onClick={() => {
                        setvacancyshiftid(0);
                        setshowmodal(true);
                      }}
                    >
                      <img
                        src="/assets/img/pluss.png"
                        style={{ height: '12px' }}
                      ></img>
                      <p className="button-text"> Add Vacancies </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {shifts && (
            <div className="card-body  table-responsive main-card-body ">
              {!isLoading && (
                <table className="table table-hover flx-custom-table">
                  <thead>
                    <tr>
                      <th
                        onClick={() => handleSort('from_date_string')}
                        className="sortHead"
                      >
                        Date
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort === 'from_date_string' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort === 'from_date_string' &&
                              query.pageVo.sort_order === 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Shift</th>

                      {/* <th>Allocated Staff</th> */}
                      <th
                        onClick={() => handleSort('employer_name')}
                        className="sortHead border-top1"
                      >
                        Employer
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'employer_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'employer_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th
                        onClick={() => handleSort('category_name')}
                        className="sortHead border-top1"
                      >
                        Jobrole
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'category_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'category_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>New Shift</th>
                      <th>Confirmed Shift</th>
                      <th>Total Openings</th>

                      <th>
                        <div className="th-image">
                          <img src="/assets/img/booking.png"></img>
                          Booking
                        </div>
                      </th>
                      <th>
                        <div className="th-image">
                          {' '}
                          <img src="/assets/img/rejected.png"></img> Rejected
                        </div>
                      </th>
                      <th>
                        <div className="th-image">
                          <img src="/assets/img/confirmed.png"></img>
                          Confirmed
                        </div>
                      </th>
                      <th>
                        <div className="th-image">
                          <img src="/assets/img/attended.png"></img>
                          Attended
                        </div>{' '}
                      </th>
                      {(IsAdmin ||
                        IsStaff ||
                        isadmin ||
                        isstaff ||
                        isemployer ||
                        IsEmployer) && <th>Publish status</th>}
                      <th>Created date and time</th>
                      <th className="eventCell  eventEdit"></th>

                      <th className="eventCell  eventEdit"></th>
                      <th className="eventCell  eventEdit"></th>
                      {(IsAdmin || IsStaff || isadmin || isstaff) && (
                        <th className="eventCell  eventEdit"></th>
                      )}
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>{vacancyshiftRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {vacancyshiftRows &&
                vacancyshiftRows &&
                vacancyshiftRows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center">
                    {' '}
                    No upcoming vacancies, Please change date filter{' '}
                  </p>
                )}
            </div>
          )}
          {calendar && (
            <VacancyCalendar
              showAllocateShift={showAllocateShift}
              emp_id={query.employer_id}
              st_id={query.allocated_staff}
              category_id={query.category}
              IsEmployer={IsEmployer}
            />
          )}
          {!calendar && (
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllVacancyshift(data);
                }}
                paginationData={vacancyshiftData}
              />
            </div>
          )}
        </div>
      </div>
      {mapModal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div
            className="modal-dialog"
            style={{ maxWidth: '-webkit-fill-available' }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-content">
                  {mapModal.vacancyData.section_type} shift
                </div>
                <div className="close" style={{ marginTop: -6 }}>
                  <a
                    onClick={() =>
                      setMapModal({
                        modal: false,
                        vacancyData: {},
                      })
                    }
                  >
                    <i className="fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <MapView vacancyData={mapModal.vacancyData} />
              </div>
            </div>
          </div>
        </div>
      )}
      {showAllocatemodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowAllocatemodal(false)}>
                    <i className="fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <VacancyshiftAdd
                  daymonthyear={daymonthyear}
                  showsavemodal={showsavemodal}
                ></VacancyshiftAdd>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ----------- for vacancy add--------------------- */}
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className="fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <VacancyshiftAdd
                  showsavemodal={showsavemodal}
                  vacancy_id={vacancyshiftid}
                ></VacancyshiftAdd>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* -----------delete confirmation popup---------------------- */}
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setdeleteconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          data-cy="deletebutton"
                          onClick={() => deletevacancyshift()}
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* -----------view vacancy details in popup----------------------- */}
      {viewdetailsmodal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close-btn-viewDetails ">
                    <a
                      data-cy="closeviewmodal"
                      onClick={() => setviewdetailsmodal(false)}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <div className="bag">
                  <div className="d-flex flex-column ">
                    <div
                      className=" text-secondary"
                      style={{ lineHeight: '0px' }}
                    >
                      VACANCY ID
                    </div>
                    <div className="">
                      <label>{'FLX00' + viewdetails._id}</label>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-item-center ">
                    <div
                      className={'icon-div mt-1'}
                      style={{ color: '#20B9B7' }}
                    >
                      {/* <FontAwesomeIcon icon={faCalendar} size="lg" /> */}
                      <img
                        src="/assets/img/cal_black.png"
                        style={{ height: '20px', width: '20px' }}
                      ></img>
                    </div>
                    <div className="d-flex align-items-center justify-content-center ml-10 font-weight-bold">
                      {new Date(
                        viewdetails.from_date_string,
                      ).toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'long',
                        weekday: 'long',
                      })}
                    </div>
                  </div>
                  <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
                    <div className=" col-md-9 p-2 bd-highlight d-flex flex-row bd-highlight mb-3">
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight bluetag ">
                          {viewdetails.name + ' Shift'}
                        </div>
                      </div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight compny_name_agency">
                          {IsAdmin || IsStaff
                            ? viewdetails.Employer.company_name
                            : carehomename}
                        </div>
                      </div>
                      <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                        <div className="p-2 bd-highlight vacancy_type">
                          {viewdetails.is_bidding_option_needed == 1
                            ? 'Bidding'
                            : viewdetails.is_auto_booking_confirm == 1
                            ? 'Auto Booking Confirm'
                            : 'Manual Confirm'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dummy-view"></div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Vacancy ID</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{'FLX00' + viewdetails._id} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Shift</label>
                    </div>
                    <div className="">
                      {' '}
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{viewdetails.name} </p>{' '}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Business Unit</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.Businessunit.name} </p>{' '}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      {' '}
                      <label>Time</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails.from_time + '-' + viewdetails.to_time}{' '}
                      </p>{' '}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      {' '}
                      <label>No of Vacancy</label>
                    </div>
                    <div className="">
                      {' '}
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      {' '}
                      <p>{viewdetails.no_of_openings} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>City</label>{' '}
                    </div>
                    <div className="">
                      {' '}
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.city} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>County</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.county} </p>
                    </div>
                  </div>
                  {!IsEmployer && (
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Candidate Pay</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails.price} </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Employer Pay</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{viewdetails.employer_to_pay} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      {' '}
                      <label>Publish Status</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails.publish_status == 1
                          ? 'Public'
                          : viewdetails.publish_status == 2
                          ? 'Only Subscribed Agencies'
                          : 'Draft'}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      {' '}
                      <label>Break Paid</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>
                        {viewdetails.is_break_time_pay == 1 ? 'Yes' : 'No'}{' '}
                      </p>
                    </div>
                  </div>
                  {viewdetails.break_time >= 0 && (
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Break Time</label>
                      </div>
                      <div className="">
                        {' '}
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails.break_time} </p>
                      </div>
                    </div>
                  )}
                  {viewdetails.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Maximum candidate bidding amount</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-3">
                        <p>{viewdetails.maximum_candidate_bidding_amount} </p>
                      </div>
                    </div>
                  )}
                  {viewdetails.is_bidding_option_needed == 1 && (
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Bidding expire date</label>
                      </div>
                      <div className="">
                        {' '}
                        <label>:</label>
                      </div>
                      <div className="col-3">
                        <p>{viewdetails.bidding_expire_date} </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ----for allocate vacancy popup---------------- */}
      {allocatevacancymodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close close-btn-allocate">
                    <a
                      onClick={() => {
                        setallocatevacancymodal(false),
                          setError({ common: '' });
                      }}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      {!IsEmployer && (
                        <div className="row">
                          <div className="col-md-12 pr-1">
                            <label htmlFor="type">
                              Select allocate candidate
                            </label>
                            <AsyncSelect
                              className="allocatecandiadte"
                              name="candidate_id"
                              onChange={selectedcandidate}
                              cacheOptions
                              defaultOptions
                              loadOptions={getcandidatelist}
                            />
                            <div className="help-block"></div>
                          </div>
                        </div>
                      )}
                      {IsEmployer && (
                        <div className="row">
                          <div className="col-md-12 pr-1">
                            <label htmlFor="type">
                              Select allocate candidate
                            </label>
                            <AsyncSelect
                              className="allocatecandiadte"
                              name="candidate_id"
                              onChange={selectedcandidate}
                              cacheOptions
                              defaultOptions
                              loadOptions={getcandidatelist}
                            />
                            <div className="help-block"> </div>
                          </div>
                        </div>
                      )}
                      {bookingvacancydetails.is_bidding_option_needed == 1 && (
                        <div className="row">
                          <div className="col-md-12 pr-1">
                            <div className={'form-group '}>
                              <label htmlFor="type">Bidding amount</label>
                              <div className="form-group">
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control form-group-50px"
                                  placeholder="Bidding Amount"
                                  value={allocatevacancydetails.amount}
                                  onChange={biddingamountHandleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row d-flex justify-content-end mt-20">
                        <span className="color-red px-3 py-1">
                          {error.common}
                        </span>
                        <div className="d-flex justify-content-center ">
                          <button
                            data-cy="allocate"
                            className="button_delete_close width_100"
                            onClick={() => {
                              selectedcandidatelist();
                            }}
                          >
                            Allocate
                          </button>
                        </div>
                      </div>
                      <div> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// async select candidate list loader
const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(item => ({
              value: item['user_id'],
              label:
                item['name'] +
                ' ' +
                (item['last_name'] ? item['last_name'] : ' ') +
                ' ' +
                '(' +
                (item['Category.name'] ? item['Category.name'] : ' ') +
                ' ' +
                (item['city'] ? '-' + item['city'] : ' ') +
                ')',
              _id: item['_id'],
              category: item['category'],
              other_category: item['other_categories'],
            })),
          );
        }),
      )
      .catch(reject);
  });
// async select employer list loader
const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, company_name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });
function mapStateToProps(state) {
  const { misc } = state;
  return {
    vacancyshiftFilterDate: misc.vacancyshiftFilterDate,
    vacancyshiftFilterbusinessunit: misc.vacancyshiftFilterbusinessunit,
  };
}
const connectedVacancyshiftList = connect(mapStateToProps)(VacancyshiftList);
export { connectedVacancyshiftList as VacancyshiftList };
