/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     EMPLOYER_SUCCESS:'EMPLOYER_SUCCESS',
     EMPLOYER_REQUEST:'EMPLOYER_REQUEST',
     EMPLOYER_FAILURE:'EMPLOYER_FAILURE', 
    */
export function employer(
  state = { requestStatus: 0, employerData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'EMPLOYER_SUCCESS':
      return {
        ...state,
        requestStatus: 'EMPLOYER_SUCCESS',
        requestEmployerStatus: 'EMPLOYER_SUCCESS',
        employerData: action.data.data,
        data: action.data,
      };
    case 'EMPLOYER_FAILURE':
      return {
        ...state,
        requestStatus: 'EMPLOYER_FAILURE',
        requestEmployerStatus: 'EMPLOYER_FAILURE',
        employerData: {},
        data: action.data,
      };
    case 'EMPLOYER_REQUEST':
      return {
        ...state,
        requestStatus: 'EMPLOYER_REQUEST',
        requestEmployerStatus: 'EMPLOYER_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
