import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BusinessunitList } from '../BusinessUnit/BusinessunitList';
import { SectionList } from '../Section/SectionList';

const Settings = () => {
  let { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [shiftactive, setshiftactive] = useState(true);
  const [BUtactive, setBUactive] = useState(false);

  useEffect(() => {
    setListAndPagination();
    /**
     * Edit data using url query
     */
    if (id !== '0' && id !== 0) {
      query.user_id = id;
    }
  }, []);

  //---function to activate shift tag ----
  const activeshift = () => {
    setshiftactive(true);
    setBUactive(false);
  };

  const activeBU = () => {
    setBUactive(true);
    setshiftactive(false);
  };
  // list and paginate shift list
  const setListAndPagination = () => {
    let shiftList = [];
    for (let j = 0; j < 3; j = j + 1) {
      shiftList.push(
        <tr key={j} className="mt-2 ">
          <td
            className={
              j % 2 == 0
                ? 'd-flex justify-content-center td-blue td-b-radius-left p-0'
                : 'd-flex justify-content-center  p-0 td-grey td-b-radius-left'
            }
          >
            Morning{' '}
          </td>
          <td className={j % 2 == 0 ? '  td-blue  p-0' : '  td-grey  p-0'}>
            {' '}
            -{' '}
          </td>
          <td
            className={
              j % 2 == 0
                ? '  td-blue  p-0 td-b-radius-right'
                : ' p-0 td-grey td-b-radius-right'
            }
          >
            10 AM - 10 PM{' '}
          </td>
        </tr>,
      );
    }
    // setshiftRows(shiftList);
  };
  return (
    <div className="EmployerList">
      <div className="">
        <div className="col-md-12">
          <div className="card regular-table-with-color ">
            {
              <div className="card-header page-header ">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title">Settings</div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-4 ">
                    <div className="">
                      <div className="round-div">
                        <div className="col-md-6 p-0">
                          <div
                            data-cy="shiftstab"
                            className={
                              shiftactive
                                ? 'round-div-inside-active'
                                : 'round-div-inside-inactive'
                            }
                            onClick={activeshift}
                          >
                            {' '}
                            shifts
                          </div>{' '}
                        </div>
                        <div className="col-md-6 p-0">
                          <div
                            data-cy="bunittab"
                            className={
                              BUtactive
                                ? 'round-div-inside-active'
                                : 'round-div-inside-inactive'
                            }
                            onClick={activeBU}
                          >
                            Business unit
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {shiftactive && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="whitecard mt-5">
                          <SectionList isSettings={true} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"> </div>
                    <div className="form-group d-flex justify-content-end">
                      {' '}
                    </div>
                  </>
                )}
                {!shiftactive && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="whitecard mt-5">
                          <BusinessunitList
                            isSettings={true}
                            shiftactive={shiftactive}
                            setshiftactive={setshiftactive}
                          ></BusinessunitList>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"> </div>
                    <div className="form-group d-flex justify-content-end">
                      {' '}
                    </div>
                  </>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedSettings = connect(mapStateToProps)(Settings);
export { connectedSettings as Settings };
