import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const educationService = {
  educationAdd,
  educationList,
  educationDelete,
};

function educationAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addeducation', requestOptions).then(
    miscService.handleResponse,
  );
}
function educationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/geteducationList', requestOptions).then(
    miscService.handleResponse,
  );
}
function educationDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteeducation', requestOptions).then(
    miscService.handleResponse,
  );
}
