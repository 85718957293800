import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import {
  apiConstants,
  businessUnitPermissionsConstant,
} from '../../_constants';
import { authHeader } from '../../_helpers';
import { employerUserService } from '../../_services';

const AllocateModal = props => {
  const [agencyShift, setAgencyShift] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState({
    common: '',
    name: '',
    businessUnit: '',
  });

  const [query, setQuery] = useState({
    permissions: [],
  });

  const userData = JSON.parse(localStorage.getItem('user'));
  const employerId = userData.employer_id;

  const handleSubmit = event => {
    event.preventDefault();
    setError({ common: '', name: '', businessUnit: '' });
    let validationFailed = false;

    if (!query.employer_user_id) {
      setError(prevState => ({ ...prevState, name: 'Name is required' }));
      validationFailed = true;
    }

    if (!query.business_unit_id) {
      setError(prevState => ({
        ...prevState,
        businessUnit: 'Business Unit is required',
      }));
      validationFailed = true;
    }

    if (validationFailed) {
      return;
    }

    const requiredPermissions = [
      businessUnitPermissionsConstant.POST_VACANCY,
      businessUnitPermissionsConstant.AUTHORISE_SHIFT,
      businessUnitPermissionsConstant.PERMANENT_SHIFT,
      businessUnitPermissionsConstant.INTERNATIONAL,
    ];

    // Check if at least one access type is selected
    if (
      !requiredPermissions.some(permission =>
        query.permissions.includes(permission),
      )
    ) {
      setModalVisible(true);
      return;
    }
    setLoading(true);
    query.employer_id = employerId;
    setQuery(query);
    employerUserService.allocateBusinessUnit(query).then(
      res => {
        setLoading(false);
        props?.onSuccess();
        if (res && res.error_code == 0) {
          props?.callBackBusinessunits?.();
          props?.handleUserAdd?.();
        }
      },
      err => {
        setError({
          common: err,
        });
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (agencyShift) {
      setQuery(prevQuery => ({
        ...prevQuery,
        permissions: [
          ...prevQuery.permissions,
          businessUnitPermissionsConstant.POST_VACANCY,
        ],
      }));
    } else {
      setQuery(prevQuery => ({
        ...prevQuery,
        permissions: [],
      }));
    }
  }, [agencyShift]);

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target;
    let newValue = type === 'checkbox' ? checked : value;

    // If it's a checkbox and it's checked, add its corresponding integer to permissions array
    if (type === 'checkbox' && checked) {
      newValue = query.permissions.concat(parseInt(value));
    }
    // If it's a checkbox and it's unchecked, remove its corresponding integer from permissions array
    else if (type === 'checkbox' && !checked) {
      newValue = query.permissions.filter(
        permission => permission !== parseInt(value),
      );
    }

    // If it's a toggle switch, toggle the corresponding permission integer in permissions array
    if (type === 'toggle') {
      newValue = query.permissions.includes(parseInt(value))
        ? query.permissions.filter(permission => permission !== parseInt(value))
        : query.permissions.concat(parseInt(value));
    }

    setQuery({ ...query, [name]: newValue });
  };

  const handleUserChange = selectedOption => {
    setError({ ...error, name: '' });
    setQuery({ ...query, employer_user_id: selectedOption.value });
  };

  const handleBusinessUnitChange = selectedOption => {
    setError({ ...error, businessUnit: '' });
    setQuery({ ...query, business_unit_id: selectedOption.value });
  };

  const getEmployerUserList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/employer/user/list', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        employer_id: employerId,
        searchKey: inputvalue,
        status: 1,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        return data.rows.map(item => ({
          value: item.id,
          label: item.name,
        }));
      });
  };

  const getBusinessUnitList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        employer_id: employerId,
        searchKey: inputvalue,
        status: 1,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        let options = data.rows.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        if (options.length === 0) {
          return [];
        }
        // options.unshift({ value: 'all', label: 'All' });
        return options;
      });
  };

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      fontSize: '14px',
      lineHeight: '25px',
    }),
  };

  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header" style={{ alignItems: 'center' }}>
          <h5 className="modal-title" id="modalLabel">
            {props.title}
          </h5>
          <button
            type="button"
            className="close flx-closebutton-popup"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.setShowAllocateModal(false)}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        {modalVisible && (
          <div
            className="modal-dialog flx-modal-popup"
            role="document"
            tabIndex="-1"
            style={{
              position: 'absolute',
              zIndex: '1',
              right: '1px',
              borderRadius: '8px',
              width: '90%',
              marginRight: '20px',
            }}
          >
            <div
              className="modal-content"
              style={{ overflowY: 'scroll', borderRadius: '8px' }}
            >
              <div
                className="modal-header flx-add-shift-popup-header"
                style={{ paddingRight: '0px' }}
              >
                <button
                  type="button"
                  className="close flx-closebutton-popup flx-addshifttime-close-icon"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalVisible(false)}
                >
                  <span aria-hidden="true" className="flx-closeicon">
                    &times;
                  </span>
                </button>
              </div>
              <div className="accesstypeImage">
                <img
                  className="flx-emp-profile-menu-icons"
                  src={'/assets/img/AccessType.svg'}
                  alt="AccessType"
                />
              </div>
              <div className="modal-body">
                <h5 className="modal-title accesstypeMessage" id="modalLabel">
                  The user will not be able to access the system. Please select
                  the access type.
                </h5>
              </div>
              <div className="accesstypefooter">
                <span
                  className="flx-new-user-cancel pointer"
                  onClick={() => setModalVisible(false)}
                >
                  Ok
                </span>
              </div>
            </div>
          </div>
        )}
        <div className="modal-body flx-business-unit-body">
          <form>
            <div className="form-group">
              <label
                htmlFor="businessUnit"
                className="flx-job-posts-labels flx-recs-required"
              >
                Select User
              </label>
              <AsyncSelect
                styles={customSelectStyles}
                name="businessUnit"
                placeholder={'Select User'}
                cacheOptions
                defaultOptions
                className="selectemployer"
                noOptionsMessage={() => 'No results found'}
                loadOptions={getEmployerUserList}
                onChange={handleUserChange}
              />
              {error.name && <div className="text-danger">{error.name}</div>}
            </div>
            <div className="form-group">
              <label
                htmlFor="businessUnit"
                className="flx-job-posts-labels flx-recs-required"
              >
                Choose Business Unit
              </label>
              <AsyncSelect
                styles={customSelectStyles}
                name="businessUnit"
                placeholder={'Select Business Unit'}
                cacheOptions
                defaultOptions
                className=""
                noOptionsMessage={() => 'No results found'}
                loadOptions={getBusinessUnitList}
                onChange={handleBusinessUnitChange}
              />
              {error.businessUnit && (
                <div className="text-danger">{error.businessUnit}</div>
              )}
            </div>
            <div className="flx-toggle-container-add-new-user d-flex">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="agencyShift"
                  name="agencyShift"
                  checked={agencyShift}
                  onClick={() => setAgencyShift(!agencyShift)}
                />
                <label className="form-check-label mb-2" htmlFor="agencyShift">
                  Agency Shift
                </label>
              </div>
              {agencyShift && (
                <>
                  <div
                    className={`toggle-bar ${
                      query.permissions.includes(
                        businessUnitPermissionsConstant.POST_VACANCY,
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      handleInputChange({
                        target: {
                          name: 'permissions',
                          value: businessUnitPermissionsConstant.POST_VACANCY,
                          type: 'toggle',
                        },
                      })
                    }
                  >
                    <div
                      className={`toggle-circle ${
                        query.permissions.includes(
                          businessUnitPermissionsConstant.POST_VACANCY,
                        )
                          ? 'active'
                          : ''
                      }`}
                    ></div>
                    <span className="toggle-label">Post Vacancy</span>
                  </div>
                  <div
                    className={`toggle-bar ${
                      query.permissions.includes(
                        businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() =>
                      handleInputChange({
                        target: {
                          name: 'permissions',
                          value:
                            businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                          type: 'toggle',
                        },
                      })
                    }
                  >
                    <div
                      className={`toggle-circle ${
                        query.permissions.includes(
                          businessUnitPermissionsConstant.AUTHORISE_SHIFT,
                        )
                          ? 'active'
                          : ''
                      }`}
                    ></div>
                    <span className="toggle-label">Authorise Shift</span>
                  </div>
                </>
              )}
            </div>
            <div className="flx-checkbox-container-settings-create-new-user">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="permanentShift"
                  name="permissions"
                  value={businessUnitPermissionsConstant.PERMANENT_SHIFT}
                  checked={query.permissions.includes(
                    businessUnitPermissionsConstant.PERMANENT_SHIFT,
                  )}
                  onChange={handleInputChange}
                />
                <label
                  className="form-check-label mb-2"
                  htmlFor="permanentShift"
                >
                  Permanent Recruitment
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="international"
                  name="permissions"
                  value={businessUnitPermissionsConstant.INTERNATIONAL}
                  checked={query.permissions.includes(
                    businessUnitPermissionsConstant.INTERNATIONAL,
                  )}
                  onChange={handleInputChange}
                />
                <label
                  className="form-check-label mb-2"
                  htmlFor="international"
                >
                  International Recruitment
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer flx-popup-bottom">
          <span
            onClick={() => props.setShowAllocateModal(false)}
            className="flx-add-new-user pointer"
          >
            Cancel
          </span>
          <button
            type="button"
            className="btn btn-sm flx-popup-button float-end"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? 'Adding...' : 'Add Now'}
          </button>
        </div>
        {error.common && <div className="mt-3 text-danger">{error.common}</div>}
      </div>
    </div>
  );
};

export default AllocateModal;
