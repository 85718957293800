import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userActions } from '../_actions';

const UpdatePassword = props => {
  let navigate = useNavigate();
  const [state, setState] = useState({
    password: '',
    confirmpassword: '',
  });
  const [submitted, setsubmitted] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // call for change password action
  const handleSubmit = e => {
    e.preventDefault();
    setsubmitted(true);
    const { dispatch } = props;
    if (state.password && state.password === state.confirmpassword) {
      let password = state.password;
      dispatch(
        userActions.changePassword({ password: password, isUpdate: 1 }),
      ).then(() => {
        navigate('/login');
      });
    }
  };
  // state password and comfirm password
  const handlechange = e => {
    const { name, value } = e.target;
    setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePasswordToggle = field => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div className="CandidateAdd d-flex justify-content-center">
      <div className="col-lg-4 col-xl-4 col-md-8 col-sm-12">
        <div className="logo-box-auth">
          <div className="logo-box">
            <img src="/assets/img/recsy-temp-logo.svg" />
          </div>
        </div>
        <div className="card ">
          <div className="login-box mt-2">
            <form name="form">
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className="d-flex justify-content-center">
                    <div className="fp-image">
                      <div className="fp-iamge-in">
                        <img
                          className="fp-image-key"
                          src="/assets/img/key.png"
                        />
                      </div>
                    </div>
                  </div>
                  <h2 className="fpText mt-2">Create a new password</h2>

                  <div
                    className={
                      'form-group' +
                      (submitted && !state.password ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="new password" className="password-labels">
                      New password{' '}
                    </label>
                    <div className="d-flex flex-row bg-white input-group input-wrap-with-btn">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control border border-0 pl-1 fw-500 input-textarea"
                        name="password"
                        value={state.password}
                        onChange={handlechange}
                        placeholder="Enter new password"
                      />
                      <span
                        className="m-auto px-2"
                        onClick={() => handlePasswordToggle('password')}
                      >
                        {showPassword ? (
                          <img src="/assets/img/eyeclose.png" />
                        ) : (
                          <img src="/assets/img/eye.png" />
                        )}
                      </span>
                    </div>
                    {submitted && !state.password && (
                      <div className="help-block">new password is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1 mt-20">
                  <div
                    className={
                      'form-group' +
                      (submitted &&
                      (!state.confirmpassword ||
                        state.confirmpassword !== state.password)
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="username" className="password-labels">
                      Confirm password{' '}
                    </label>
                    <div className="d-flex flex-row bg-white input-group input-wrap-with-btn">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control border border-0 pl-1 fw-500 input-textarea"
                        name="confirmpassword"
                        value={state.confirmpassword}
                        onChange={handlechange}
                        placeholder="Re-enter the password"
                      />
                      <span
                        className="m-auto px-2"
                        onClick={() => handlePasswordToggle('confirmPassword')}
                      >
                        {showConfirmPassword ? (
                          <img src="/assets/img/eyeclose.png" />
                        ) : (
                          <img src="/assets/img/eye.png" />
                        )}
                      </span>
                    </div>
                    {submitted && !state.confirmpassword && (
                      <div className="help-block">
                        confirm password is required
                      </div>
                    )}
                    {submitted && state.confirmpassword !== state.password && (
                      <div className="help-block">
                        Password and confirmpassword not match
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <button onClick={handleSubmit} className="confirm-btn">
                  Reset Now
                </button>
                {props.requestStatus == 1 && (
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      {
                        setshowmodal(true);
                      }
                      props.setshowmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <div className="text-center">
                  <img
                    className=" border-rad-55"
                    src="/assets/img/image.png"
                  ></img>
                  <h3 className="font-weight-bold" style={{ fontSize: '20px' }}>
                    Password Changed
                  </h3>
                  <h6 style={{ fontSize: '11px' }}>
                    Your password has been changed successfully{' '}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {
    requestStatus,
  };
}

const connectedUpdatePassword = connect(mapStateToProps)(UpdatePassword);
export { connectedUpdatePassword as UpdatePassword };
