import React, { useEffect, useState } from 'react';
import { roleConstant } from '../../_constants';
import AgencyShiftTime from './AgencyShiftTime';
import OtherBenefits from './OtherBenefits';
import ShiftPriceTable from './ShiftPriceTable';

function EmployerSettingsAgencyShift() {
  const [currentPage, setCurrentPage] = useState(0);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const renderAgencyComponent = () => {
    switch (currentPage) {
      case 0:
        return <AgencyShiftTime />;
      case 1:
        return <OtherBenefits />;
      case 2:
        return <ShiftPriceTable />;
      default:
        return null;
    }
  };
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == roleConstant.ADMIN) {
        setisAdmin(true);
      }
      if (user.role == roleConstant.STAFF) {
        setisStaff(true);
      }
    }
  }, [isAdmin, isStaff]);

  return (
    <div className="col-12 flx-emp-profile-main-form">
      <h2 className="flx-company-info-form-heading">Agency Shift Settings</h2>

      <ul className="nav nav-tabs">
        {!isAdmin && !isStaff && (
          <li className="nav-item pointer" onClick={() => setCurrentPage(0)}>
            <div
              className={`flx-emp-ag-setting-items ${
                currentPage == 0 ? 'flx-emp-ag-setting-items-active' : ''
              }`}
            >
              Shift Time
            </div>
          </li>
        )}
        {!isAdmin && !isStaff && (
          <li className="nav-item pointer" onClick={() => setCurrentPage(1)}>
            <div
              className={`flx-emp-ag-setting-items ${
                currentPage == 1 ? 'flx-emp-ag-setting-items-active' : ''
              }`}
            >
              Other Benefits
            </div>
          </li>
        )}

        <li className="nav-item pointer" onClick={() => setCurrentPage(2)}>
          <div
            className={`flx-emp-ag-setting-items ${
              currentPage == 2 ? 'flx-emp-ag-setting-items-active' : ''
            }`}
          >
            Price Table
          </div>
        </li>
      </ul>
      <div>{renderAgencyComponent()}</div>
    </div>
  );
}

export default EmployerSettingsAgencyShift;
