import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { apiConstants, benefitsConstant } from '../../_constants';
import { authHeader } from '../../_helpers';
import { benefitsEmployerService } from '../../_services';

const AddJobRoleModal = props => {
  const [query, setQuery] = useState({
    jobRoles: [],
    benefits: [],
    payPerMile: '',
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [perMileValue, setPerMileValue] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const asyncSelectRef = useRef();

  const categoryList = inputvalue => {
    return fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        searchKey: inputvalue,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        let options = data.rows.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        if (options.length === 0) {
          return [];
        }
        // options.unshift({ value: 'all', label: 'All' });
        return options;
      });
  };

  useEffect(() => {
    if (query.payPerMile) {
      let perMileValue = query.payPerMile;
      query.benefits[0].has_price_per_mile = perMileValue;
      setQuery(query);
      setPerMileValue(perMileValue);
    }
  }, [query.payPerMile]);

  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      fontSize: '14px',
      lineHeight: '25px',
      innerWidth: '25px',
    }),
  };

  const handleCategoryChange = selectedOptions => {
    setError('');
    const selectedValues = selectedOptions.map(option => option.value);
    setQuery({ ...query, jobRoles: selectedValues });
    setSelectedCategories(selectedOptions.map(option => option.label));
  };

  const handleRemoveCategory = () => {
    setQuery({ ...query, jobRoles: [] });
    setSelectedCategories([]);
    if (asyncSelectRef.current && asyncSelectRef.current.select) {
      asyncSelectRef.current.select.clearValue();
    }
  };

  const handleCheckboxChange = event => {
    setError('');
    const { name, checked } = event.target;
    if (checked) {
      let benefitObject;
      if (name === 'travel reimbursement') {
        benefitObject = {
          benefit_master_id: benefitsConstant.REIMBURSEMENT,
          has_price_per_mile: perMileValue,
        };
      } else if (name === 'pick-up and drop-off') {
        benefitObject = { benefit_master_id: benefitsConstant.PICKUP_DROP };
      } else if (name === 'paid break') {
        benefitObject = { benefit_master_id: benefitsConstant.PAID_BREAK };
      }
      if (benefitObject) {
        setQuery(prevState => ({
          ...prevState,
          benefits: [...prevState.benefits, benefitObject],
        }));
      }
    } else {
      setQuery(prevState => ({
        ...prevState,
        benefits: prevState.benefits.filter(
          benefit =>
            (name !== 'travel reimbursement' ||
              benefit.benefit_master_id !== benefitsConstant.REIMBURSEMENT) &&
            (name !== 'pick-up and drop-off' ||
              benefit.benefit_master_id !== benefitsConstant.PICKUP_DROP) &&
            (name !== 'paid break' ||
              benefit.benefit_master_id !== benefitsConstant.PAID_BREAK),
        ),
      }));
    }
  };

  const handlePayPerMileChange = event => {
    const { value } = event.target;
    setQuery(prevState => ({
      ...prevState,
      payPerMile: value,
    }));
  };

  const handleSubmit = () => {
    setError('');
    const { jobRoles, benefits } = query;
    setLoading(true);
    if (jobRoles && jobRoles.length == 0) {
      setLoading(false);
      setError('Please choose a job role');
      return;
    }
    if (benefits && benefits.length == 0) {
      setLoading(false);
      setError('Please choose a benefit');
      return;
    }
    const formData = {
      jobRoles,
      benefits,
      employerId: props?.employerId,
    };
    benefitsEmployerService.addJobRole(formData).then(
      res => {
        if (res?.data !== null && !res?.message) {
          props?.onSuccess(res?.payload?.[0]);
          props.setShowModal(false);
          props?.onSuccess();
        } else {
          setLoading(false);
          setError(res?.message);
        }
      },
      () => {
        setError('Error updating the shift time status. Please try again.');
      },
    );
  };

  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modalLabel">
            Add Job Role
          </h5>
          <button
            type="button"
            className="close flx-closebutton-popup"
            data-dismiss="modal"
            onClick={() => props.setShowModal(false)}
            aria-label="Close"
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body flx-business-unit-body">
          <form>
            <div className="form-group">
              <label
                htmlFor="UserName"
                className="flx-job-posts-labels flx-recs-required"
              >
                Job Role
              </label>
              <AsyncSelect
                ref={asyncSelectRef}
                styles={customSelectStyles}
                name="jobRole"
                placeholder={'Select Job Role'}
                cacheOptions
                defaultOptions
                className="selectemployer"
                noOptionsMessage={() => 'No results found'}
                loadOptions={categoryList}
                onChange={handleCategoryChange}
                isMulti
              />
            </div>
            {selectedCategories.length > 0 && (
              <div className="flx-ag-title-selected-container d-flex">
                {selectedCategories.map((category, index) => (
                  <div key={index} className="flx-ag-title-selected">
                    {category}{' '}
                    <span
                      className="flx-ag-title-selected-close-icon pointer"
                      onClick={handleRemoveCategory}
                    >
                      &times;
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div className="flx-job-role-check-box-container mt-3 mb-3">
              <p className="flx-job-role-paid-break">Paid Break</p>
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="paidBreakCheckbox"
                  name="paid break"
                  checked={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id === benefitsConstant.PAID_BREAK,
                  )}
                  disabled={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id ===
                        benefitsConstant.REIMBURSEMENT ||
                      benefit.benefit_master_id ===
                        benefitsConstant.PICKUP_DROP,
                  )}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="paidBreakCheckbox">
                  Yes
                </label>
              </div>
            </div>
            <p className="flx-job-role-paid-break">Other Benefits</p>
            <div className="flx-job-role-check-box-container d-flex mt-3">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="travelReimbursementCheckbox"
                  name="travel reimbursement"
                  checked={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id ===
                      benefitsConstant.REIMBURSEMENT,
                  )}
                  disabled={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id ===
                        benefitsConstant.PAID_BREAK ||
                      benefit.benefit_master_id ===
                        benefitsConstant.PICKUP_DROP,
                  )}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="travelReimbursementCheckbox"
                >
                  Travel Reimbursement
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="pickUpAndDropOffCheckbox"
                  name="pick-up and drop-off"
                  checked={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id ===
                      benefitsConstant.PICKUP_DROP,
                  )}
                  disabled={query.benefits.some(
                    benefit =>
                      benefit.benefit_master_id ===
                        benefitsConstant.PAID_BREAK ||
                      benefit.benefit_master_id ===
                        benefitsConstant.REIMBURSEMENT,
                  )}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="pickUpAndDropOffCheckbox"
                >
                  Pick up & Drop off
                </label>
              </div>
            </div>
            {query.benefits.some(
              benefit =>
                benefit.benefit_master_id === benefitsConstant.REIMBURSEMENT,
            ) && (
              <div
                className="form-group d-flex flx-ag-settings-other-benefits-pay-per-mile-text"
                style={{ marginLeft: '0px', marginTop: '8px' }}
              >
                How much do you pay per mile
                <div className="input-group d-flex">
                  <div className="input-group-prepend">
                    <span className="input-group-text currency-sign-other-benefits">
                      £
                    </span>
                  </div>
                  <input
                    type="text"
                    className="flx-pay-per-mile-text-box"
                    value={query.payPerMile}
                    onChange={handlePayPerMileChange}
                  />
                </div>
              </div>
            )}
            <hr className="mt-5" />
            <span className="flx-job-role-footer-text">
              Please note: This benefits will apply to all the above mentioned
              job roles
            </span>
          </form>
        </div>
        {error && <p style={{ color: 'red', paddingLeft: '30px' }}>{error}</p>}

        <div className="modal-footer flx-popup-bottom">
          <button
            type="button"
            className="btn btn-sm flx-popup-button"
            style={{ justifyContent: 'flex-start' }}
            onClick={handleSubmit}
          >
            {loading ? 'Adding...' : 'Add'}
          </button>
          <span
            onClick={() => props.setShowModal(false)}
            className="flx-add-new-user pointer"
          >
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

export default AddJobRoleModal;
