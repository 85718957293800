import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { App } from './App/App.jsx';
import { store } from './_helpers';

// alert(process.env.REACT_APP_API_URL);
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV != 'development') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="150441162726-rnbf61osoasrnbgbc56bpcfnhgh6nrmr.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById('app'),
);
