import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const agencyService = {
  agencyAdd,
  agencyList,
  agencyListv2,
  agencyDelete,
  dashbordagencyreport,
  subscribedagencylist,
};

function agencyAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addagency', requestOptions).then(
    miscService.handleResponse,
  );
}
function agencyList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyList', requestOptions).then(
    miscService.handleResponse,
  );
}

function dashbordagencyreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordagencyreport',
    requestOptions,
  ).then(miscService.handleResponse);
}

function agencyListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyListv2', requestOptions).then(
    miscService.handleResponse,
  );
}
function subscribedagencylist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/subscribedagencylist',
    requestOptions,
  ).then(miscService.handleResponse);
}
function agencyDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteagency', requestOptions).then(
    miscService.handleResponse,
  );
}
