import React from 'react';

import { Navigate } from 'react-router-dom';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         localStorage.getItem('user') ? (
//           <Component {...props} />
//         ) : (
//           <Navigate
//             to={{ pathname: '/login', state: { from: props.location } }}
//           />
//         )
//       }
//     />
//   );
// };

function PrivateRoute({ children }) {
  const auth = localStorage.getItem('user');
  return auth ? <>{children}</> : <Navigate to="/login" />;
}
export { PrivateRoute };
