import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingImg } from '../_components';
import { roleConstant } from '../_constants';
import { userService } from '../_services';

const OTP_TYPE = 'phone';

// const phoneRegex = /^[0-9]{10}$/;

const LoginWithPhone = () => {
  const [user, setUser] = useState({
    email: '',
    phone: '',
    role: roleConstant.EMPLOYER,
    otp: '',
  });
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otpSendSuccessfully, setOtpSendSuccessfully] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [resendOTPFlag, setResendOTPFlag] = useState(false);
  const [timerLeft, setTimeLeft] = useState(60);
  // const [phoneNumber, setPhoneNumber] = useState('');

  // const maskCharacter = '*'; // You can customize this to any character you prefer

  // validate the phone number
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // handle change to set email and password
  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  // masked Phone Number
  const maskphonenumber = phonenumber =>
    phonenumber
      .split('')
      .map((c, i, arr) => (i < 2 || i > arr.length - 3 ? c : '*'))
      .join('');

  // effect to clear local storage data

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        localStorage.clear();
      }
    }
    const timerId = setInterval(() => {
      if (timerLeft > 0) {
        setTimeLeft(timerLeft - 1);
      }

      if (timerLeft === 0) {
        setTimerOn(false);
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timerLeft]);

  const resendOTP = async otp_type => {
    setResendOTPFlag(true);
    try {
      if (otp_type == OTP_TYPE) {
        const userData = {
          phone: '44' + user.phone,
          otp_type: OTP_TYPE,
        };

        await userService.sendOtpLogin(userData);

        setTimeLeft(60);
        setTimerOn(true);
      }
    } catch (error) {
      setError({
        ...error,
        phone: typeof error == 'string' ? error : error.message,
      });
      console.error('Falied to verify the OTP');
    }
  };

  const loginsendOTP = async otp_type => {
    setOtpSendSuccessfully(false);
    if (otp_type === OTP_TYPE) {
      if (!user.phone || user.phone.length != 10 || !valid_contact_number) {
        error.phone = 'Please enter a valid phone Number';
        error.isError = true;
        return;
      }
      try {
        setIsLoading(true);
        const userData = {
          phone: '44' + user.phone,
          otp_type: OTP_TYPE,
        };

        const res = await userService.sendOtpLogin(userData);

        if (!res.data) {
          error.phone = 'User Not found';
          error.isError = true;
          setIsLoading(false);
          return;
        }
        if (res.data.role != roleConstant.EMPLOYER) {
          error.phone = 'You are not authorized';
          error.isError = true;
          setIsLoading(false);
          return;
        }

        if (res.error_code == 0 && res.data.phone) {
          setIsLoading(false);
          setOtpSendSuccessfully(true);
        }
      } catch (error) {
        setIsLoading(false);
        setOtpSendSuccessfully(false);
        setError({
          ...error,
          phone: typeof error == 'string' ? error : error.message,
        });
        console.error('Falied to verify the OTP');
      }
    }
  };

  const handleVerifyOtp = async (otp, otp_type) => {
    if (isLoadingVerify) {
      return;
    }
    setError({
      ...error,
      common: '',
    });
    setIsLoadingVerify(true);

    try {
      const userData = {
        email: user.email,
        role: user.role,
        phone: '44' + user.phone,
        otp_type: otp_type,
        otp: otp, // Replace with the actual OTP value
      };
      const res = await userService.verifyLogin(userData);

      if (res.data && res.error_code == 0) {
        if (res.data.role == 4) {
          localStorage.setItem('user', JSON.stringify(res.data));
          window.location.href = '/dashboardemployer';
        } else {
          window.location.href = '/';
        }
      }
      setIsLoadingVerify(false);
    } catch (error) {
      setIsLoadingVerify(false);
      //   setotpSendSuccessfullyEmail(true);
      setError({
        ...error,
        otp_error: 'OTP is not valid or expired',
      });
      console.error('Falied to verify the OTP');
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/recsy-temp-logo.svg" />
          </div>
        </div>
        <div className=" row justify-content-center">
          <div className="col-md-8 col-lg-6  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6 mx-auto">
                {!otpSendSuccessfully ? (
                  <div>
                    <h3
                      className="mb-4"
                      style={{
                        color: '#000',
                        fontWeight: '700',
                        fontSize: '24px',
                      }}
                    >
                      Sign In With OTP
                    </h3>
                    <span className="color-red">{error.common}</span>

                    <div name="form">
                      <div>
                        <p className="label-login mb-1" htmlFor="phone">
                          Phone
                        </p>
                        <input
                          type="number"
                          className="flx-common-input-fields form-control"
                          name="phone"
                          value={user.phone}
                          onChange={handleChange}
                        />
                        {error.phone && (
                          <div className="text-danger mt-1">{error.phone}</div>
                        )}
                      </div>
                      <div className="form-group ">
                        <button
                          onClick={() => loginsendOTP(OTP_TYPE)}
                          style={{
                            fontWeight: '700',
                            fontSize: '12px',
                          }}
                          className={
                            'send-btn  ' +
                            (user.phone.length == 10
                              ? 'active-signup-btn'
                              : 'disabled')
                          }
                        >
                          {!isLoading ? 'Get OTP' : 'Loading...'}
                        </button>
                        {isLoading && false && <LoadingImg />}
                      </div>
                    </div>
                    <Link
                      to="/loginwithemail"
                      className="btn btn-link text-center"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="flex-sign-up-text">
                        Send OTP to Email?
                      </span>
                    </Link>
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <span className="flx-general-text">
                        Don&lsquo;t have an account?
                      </span>
                      <Link
                        to="/register"
                        className="btn btn-link text-right"
                        style={{ textDecoration: 'none' }}
                      >
                        <span className="flex-sign-up-text">Sign Up</span>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-10 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <h3
                              className="mb-4"
                              style={{
                                color: '#000',
                                fontWeight: '700',
                                fontSize: '34px',
                              }}
                            >
                              Enter OTP
                            </h3>
                            <p
                              className=" p-2 mb-4"
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP
                              <br />
                              to {maskphonenumber(user.phone)}
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-9 col-md-6 mb-4">
                                <input
                                  type="number"
                                  className="flx-common-input-fields form-control text-center"
                                  name="otp"
                                  value={user.otp}
                                  onChange={handleChange}
                                />
                                {error.otp && (
                                  <div className="text-danger mt-1">
                                    {error.otp}
                                  </div>
                                )}
                              </div>
                              <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                                {!timerOn ? (
                                  <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                    <span className="flx-general-text">
                                      Don&lsquo;t received code?
                                    </span>
                                    <Link
                                      onClick={() => resendOTP(OTP_TYPE)}
                                      className="btn btn-link text-right"
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      <span className="flex-sign-up-text">
                                        Resend OTP
                                      </span>
                                    </Link>
                                  </div>
                                ) : (
                                  <div>
                                    <p className="flx-general-text">
                                      We will resend Code in{' '}
                                      {timerLeft < 10
                                        ? `0${timerLeft}`
                                        : timerLeft}{' '}
                                      sec
                                    </p>
                                    <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                      <span className="flx-general-text">
                                        Don&lsquo;t received code?
                                      </span>
                                      <span
                                        className="inactive-link px-2"
                                        style={{
                                          color: 'gray',
                                          textDecoration: 'none',
                                        }}
                                      >
                                        Resend OTP
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <button
                                onClick={() =>
                                  handleVerifyOtp(user.otp, OTP_TYPE)
                                }
                                style={{
                                  fontWeight: '700',
                                  fontSize: '12px',
                                }}
                                className={
                                  'send-btn  ' +
                                  (user.otp.length > 3
                                    ? 'active-signup-btn'
                                    : 'disabled')
                                }
                              >
                                {!isLoadingVerify ? 'Verify' : 'Verifing...'}
                                {isLoading && false && <LoadingImg />}
                              </button>
                              {!!error.otp_error && (
                                <div
                                  className="alert alert-danger mt-2"
                                  role="alert"
                                >
                                  {error.otp_error}
                                </div>
                              )}

                              <div className="d-flex justify-content-center align-items-center mt-4">
                                <Link
                                  to="/loginwithemail"
                                  className="btn btn-link text-right"
                                  style={{ textDecoration: 'underline' }}
                                >
                                  <span className="flex-sign-up-text">
                                    I Don&lsquo;t have access to this number?
                                  </span>
                                </Link>
                              </div>
                              {resendOTPFlag && (
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                  <Link
                                    to="/login"
                                    className="btn btn-link text-right"
                                    style={{ textDecoration: 'underline' }}
                                  >
                                    <span className="flex-sign-up-text">
                                      I Don&lsquo;t have access to this Email &
                                      Phone?
                                    </span>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  // const { LoginWithPhone } = state.authentication;
  return {
    // LoginWithPhone,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginWithPhone);
export { connectedLoginPage as LoginWithPhone };
// export default LoginWithPhone;
