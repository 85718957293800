import React, { useState } from 'react';
import { connect } from 'react-redux';
import { businessunitService } from '../_services';

const AddLocation = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    locationName: '',
    contactPersonName: '',
    email: '',
    phone: '',
    postcode: '',
    address: '',
  });

  const [showContactPersonFields, setshowContactPersonFields] = useState(false);
  const [submitted] = useState(false);
  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(formData => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    const businessUnitData = {
      locationName: formData.locationName,
      name: formData.contactPersonName,
      email: formData.email,
      phone: formData.phone,
      postcode: formData.postcode,
      address: formData.address,
    };
    try {
      const res = businessunitService.businessunitAdd(businessUnitData);
      if (res.error_code === 0) {
        window.location.href = './postagency';
      } else {
        // window.location.href = '/';
      }
    } catch (error) {
      console.info(error);
    }
  };

  const handleCheckBox = () => {
    setshowContactPersonFields(!showContactPersonFields);
  };
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="modalLabel">
            Add New Business Unit
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
            style={{ background: '#e63946', border: 'none' }}
          >
            <span aria-hidden="true" className="flx-closeicon m-auto">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body flx-business-unit-body">
          <form>
            <div className="form-group">
              <label
                htmlFor="locationName"
                className="flx-job-posts-labels flx-recs-required"
              >
                Business unit name
              </label>
              <div
                className={
                  'form-group ' +
                  (submitted && !formData.locationName ? ' has-error' : '')
                }
              >
                <input
                  type="text"
                  className="form-control"
                  id="locationName"
                  name="locationName"
                  placeholder="My Location"
                  value={formData.locationName}
                  onChange={handleChange}
                />
                {submitted && !formData.locationName && (
                  <div className="help-block">Location name is required</div>
                )}
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                onChange={handleCheckBox}
              />
              <label
                className="form-check-label flx-business-unit-text-create-new-user"
                htmlFor="flexCheckDefault"
              >
                Create as a new user
              </label>
            </div>
            {showContactPersonFields && (
              <div className="form-group">
                <label
                  htmlFor="contactPerson"
                  className="flx-job-posts-labels flx-recs-required"
                >
                  Contact person
                </label>
                <br />
                <span className="flx-contact-text">
                  We will send different login details to this email
                </span>
                <div
                  className={
                    'form-group ' +
                    (submitted && !formData.contactPersonName
                      ? ' has-error'
                      : '')
                  }
                >
                  <input
                    type="name"
                    className="form-control"
                    id="contactPerson"
                    name="contactPersonName"
                    placeholder="Name"
                    value={formData.contactPersonName}
                    onChange={handleChange}
                  />
                  {submitted && !formData.locationName && (
                    <div className="help-block">
                      Contact person name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    'form-group ' +
                    (submitted && !formData.email ? ' has-error' : '')
                  }
                >
                  <input
                    type="email"
                    className="form-control"
                    id="contactPerson"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {submitted && formData.email && (
                    <div className="help-block">Email is required</div>
                  )}
                  <div
                    className={
                      'form-group ' +
                      (submitted && !formData.phone ? ' has-error' : '')
                    }
                  >
                    <div className="input-group mb-3 mt-4">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="flx-basic-addon1"
                        >
                          +44
                        </span>
                      </div>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="07888 435 355"
                        aria-label="Phone number"
                        aria-describedby="basic-addon1"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      {submitted && formData.phone && (
                        <div className="help-block">
                          Phone number is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="form-group">
              <label
                htmlFor="postcode"
                className="flx-job-posts-labels flx-recs-required"
              >
                Postcode{' '}
              </label>

              <div className="input-group">
                <div
                  className={
                    'form-group ' +
                    (submitted && !formData.postcode ? ' has-error' : '')
                  }
                >
                  <input
                    type="text"
                    className="form-control"
                    id="postcode"
                    placeholder="SS14 5EL"
                    name="postcode"
                    value={formData.postcode}
                    onChange={handleChange}
                  />
                  {submitted && formData.postcode && (
                    <div className="help-block">Postcode is required</div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="address"
                className="flx-job-posts-labels flx-recs-required"
              >
                Address
              </label>
              <button
                type="button"
                className="flx-add-location-popup-form flx_add_manual"
              >
                <i className="fas fa-plus flx-icon-plus"></i> Add manually
              </button>
              <div
                className={
                  'form-group ' +
                  (submitted && !formData.address ? ' has-error' : '')
                }
              >
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Type your address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
                {submitted && formData.address && (
                  <div className="help-block">Address is required</div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer flx-popup-bottom">
          <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-sm flx-popup-button float-end"
          >
            Add Now
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedAddLocation = connect(mapStateToProps)(AddLocation);
export { connectedAddLocation as AddLocation };
