import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { categoryActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { Category } from './Category';

const CategoryList = props => {
  console.log(props, 'jobprops');
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({ rows: [] });
  const [categoryRows, setCategoryRows] = useState([]);
  const [view_category_id, Setview_category_id] = useState();
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: parseInt(new URLSearchParams(location.search).get('page')) || 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  // effect to load categories
  useEffect(() => {
    getAllCategory(1);
  }, [query]);
  const changePage = newPage => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('page', newPage);

    navigate(newUrl.pathname + newUrl.search, { replace: true });
  };
  // ------get all categories -----------
  const getAllCategory = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    changePage(pageNo);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(categoryActions.categoryList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters and a call for category
  // when search key change
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, [name]: value }));
      getAllCategory();
    }
  };
  // list and pagination
  const setListAndPagination = categoryData => {
    let categoryList = [];
    for (
      let j = 0;
      categoryData && categoryData.rows && j < categoryData.rows.length;
      j = j + 1
    ) {
      categoryList.push(
        <tr key={j}>
          <td> {(categoryData.page - 1) * categoryData.limit + j + 1} </td>
          <td>{categoryData.rows[j].name} </td>
          <td>{categoryData.rows[j].morning_price} </td>
          <td>{categoryData.rows[j].evening_price} </td>
          <td>{categoryData.rows[j].night_price} </td>
          <td>{categoryData.rows[j].holiday_price} </td>
          <td>{categoryData.rows[j].day_price} </td>
          <td>{categoryData.rows[j].weekend_day_price} </td>
          <td>{categoryData.rows[j].weekend_night_price} </td>
          <td>{categoryData.rows[j].minimum_price} </td>
          <td>{categoryData.rows[j].minimum_employer_price} </td>
          <td>{categoryData.rows[j].vacancy_percentage} </td>
          <td className="eventCell  eventView">
            <div className="d-flex">
              <div
                data-cy="viewcategorydetails"
                className="allshift-viewdtt"
                onClick={() => {
                  Setview_category_id(categoryData.rows[j]._id),
                    setviewdetailsmodal(true);
                }}
              >
                view
              </div>
              <div>
                <a
                  data-cy="editcategory"
                  className="allshift-viewdtt"
                  href={'/categoryadd/' + categoryData.rows[j]._id}
                >
                  Edit
                </a>
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setCategoryRows(categoryList);
    setCategoryData(categoryData);
  };
  return (
    <div className="CategoryList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <div className="page-title">Jobrole List</div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-6 ">
                <p>
                  <div className="input-outer-div">
                    <input
                      className="search-input-div"
                      value={query.searchKey}
                      onChange={handleChange}
                      type="text"
                      name="searchKey"
                      aria-label="Search"
                      placeholder="Search here..."
                    />
                    <a className="search_bar">
                      <i className="fa fa-search in_bar" aria-hidden="true"></i>
                    </a>
                  </div>
                </p>
              </div>
              {/* FROM DATE PICKER======== */}
              <div className="col-md-6">
                <div className="row d-flex justify-content-end align-items-center">
                  <div className="col-md-3">
                    <div
                      data-cy="addcat"
                      className=" btn-primary-blue-nowidth float-right card-body2"
                      onClick={() => {
                        navigate('/categoryadd/0');
                      }}
                    >
                      Add Jobrole
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: '0px' }}>#</th>
                    <th>Name</th>
                    <th>Morning price</th>
                    <th>Evening price</th>
                    <th>Night Price</th>
                    <th>Holiday price</th>
                    <th>Day price</th>
                    <th>Weekend Day price</th>
                    <th>Weekend Night price</th>
                    <th>Minimum Price</th>
                    <th>Minimum Employer Price</th>
                    <th>Vacancy percentage</th>
                    <th
                      className="eventCell  eventView"
                      style={{ width: '250px' }}
                    />
                  </tr>
                </thead>
                <tbody>{categoryRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {categoryData &&
              categoryData.rows &&
              categoryData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllCategory(data);
              }}
              paginationData={categoryData}
            />
          </div>
        </div>
        {viewdetailsmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-sm ">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close">
                      <a
                        data-cy="hidemodal"
                        onClick={() => setviewdetailsmodal(false)}
                      >
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <Category id={view_category_id} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, categoryData } = state.category;
  return {
    requestStatus,
    categoryData,
  };
}

const connectedCategoryList = connect(mapStateToProps)(CategoryList);
export { connectedCategoryList as CategoryList };
