import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userActions } from '../_actions';
import { messageConstant } from '../_constants';
import { miscService } from '../_services';

const ForgotPassword = props => {
  let navigate = useNavigate();
  const [data, setdata] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [validEmail, setValidEmail] = useState(false);
  // call for send otp to mail
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (data.email && validEmail) {
      dispatch(userActions.sendOtp({ email: data.email, isweb: true })).then(
        () => {
          navigate('/emailverify?email=' + data.email);
        },
        () => {
          setTimeout(() => {
            setError({ common: ' ' });
          }, 3000);
        },
      );
    }
  };
  // handler for set email
  const changeText = e => {
    const { name, value } = e.target;

    if (name == 'email' && miscService.emailVerify(value)) {
      // alert(value);
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    setdata({
      [name]: value,
    });
  };
  return (
    <div className="row justify-content-center">
      <div className="logo-box-auth">
        <div className="logo-box" style={{ margin: '10px' }}>
          <img src="/assets/img/recsy-temp-logo.svg" />
        </div>
      </div>
      <div className="col-lg-4 col-xl-4 col-md-8 col-sm-12">
        <div className="forgotpassword-box">
          <div className="">
            <div className="d-flex justify-content-center">
              <div className="fp-image">
                <div className="fp-iamge-in">
                  <img className="fp-image-key" src="/assets/img/key.png" />
                </div>
              </div>
            </div>

            <h2 className="fpText">Forgot password</h2>
            <p className="fpdesc"> Will send an email with OTP</p>
            <form name="form" onSubmit={handleSubmit}>
              <div
                className={
                  'form-group mt-20' +
                  (submitted && !data.email ? ' has-error' : '')
                }
              >
                <label htmlFor="username" className="fp-email">
                  Email{' '}
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={data.email}
                  onChange={changeText}
                  placeholder="Email"
                />
                {!miscService.emailVerify(data.email) && data.email && (
                  <div className="help-block">
                    {messageConstant.INVALID_EMAIL}
                  </div>
                )}
                {submitted && !data.email && (
                  <div className="help-block">
                    {messageConstant.EMAIL_REQUIRED}
                  </div>
                )}
              </div>
              <div>
                <button className="send-btn" disabled={!validEmail}>
                  Send OTP
                </button>
              </div>
              <div className="justify-content-center">
                {/* <p className="fp-otp-phone">Send OTP to Phone Number?</p> */}

                <Link to="/login">
                  <p className="back-login-text">Back to log in.</p>
                </Link>
                <div className="form-group float-right">
                  <span className="color-red">{error.common}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedForgotpassword = connect(mapStateToProps)(ForgotPassword);
export { connectedForgotpassword as ForgotPassword };
