/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     DOCUMENTS_SUCCESS:'DOCUMENTS_SUCCESS',
     DOCUMENTS_REQUEST:'DOCUMENTS_REQUEST',
     DOCUMENTS_FAILURE:'DOCUMENTS_FAILURE', 
    */
export function documents(
  state = { requestStatus: 0, documentsData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'DOCUMENTS_SUCCESS':
      return {
        ...state,
        requestStatus: 'DOCUMENTS_SUCCESS',
        requestDocumentsStatus: 'DOCUMENTS_SUCCESS',
        documentsData: action.data.data,
        data: action.data,
      };
    case 'DOCUMENTS_FAILURE':
      return {
        ...state,
        requestStatus: 'DOCUMENTS_FAILURE',
        requestDocumentsStatus: 'DOCUMENTS_FAILURE',
        documentsData: {},
        data: action.data,
      };
    case 'DOCUMENTS_REQUEST':
      return {
        ...state,
        requestStatus: 'DOCUMENTS_REQUEST',
        requestDocumentsStatus: 'DOCUMENTS_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
