import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { invoiceActions, shiftsActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const InvoiceAgency = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({
    name: '',
  });
  const [printpdfLoading, setprintpdfLoading] = useState(false);
  const [downloadpdfLoading, setdownloadpdfLoading] = useState(false);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [Shiftrows, setShiftrows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  // effect to set query and load invoice and shifts
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllInvoice(1, query);
      getAllShifts(id);
    }
  }, []);
  //------set list and pageindation eith shift rows ----------
  const setListAndPagination = shiftDataList => {
    let shiftList = [];
    let shift_ids_array = [];
    for (let s = 0; s < shiftDataList.length; s++) {
      shift_ids_array.push(shiftDataList[s]._id);
      shiftList.push(
        <div className="row">
          <div className="col-md-2 border">
            <label className="">
              <b>
                {shiftDataList[s]['Candidate.name']
                  ? shiftDataList[s]['Candidate.name']
                  : shiftDataList[s]['Agencycandidate.name']}
              </b>
            </label>
          </div>
          <div className="col-md-2 border">
            <b>{shiftDataList[s].actual_hours_after_break_time} hrs</b>
          </div>
          <div className="col-md-2 border">
            <b>{shiftDataList[s]['Employer.company_name']} </b>
          </div>
          <div className="col-md-1 border">
            <b>
              {' '}
              {shiftDataList[s].attendance_check_in
                ? shiftDataList[s].attendance_check_in
                : shiftDataList[s].check_in_time_string}
            </b>
          </div>
          <div className="col-md-1 border">
            <b>
              {' '}
              {shiftDataList[s].attendance_check_out
                ? shiftDataList[s].attendance_check_out
                : shiftDataList[s].check_out_time_string}
            </b>
          </div>
          <div className="col-md-2 border">
            <b>£ {shiftDataList[s].employer_to_pay}</b>
          </div>
          <div className="col-md-2 border">
            <b>
              £{' '}
              {(
                shiftDataList[s].actual_hours_after_break_time *
                shiftDataList[s].employer_to_pay
              ).toFixed(2)}
            </b>
          </div>
        </div>,
      );
    }
    setShiftrows(shiftList);
    setselected_shiftIds(shift_ids_array);
  };
  // call for get shifts
  const getAllShifts = async invoice_id => {
    const { dispatch } = props;
    await dispatch(
      shiftsActions.shiftsList({ status: '', agency_invoice_id: invoice_id }),
    ).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data.rows);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //--------------print pdf function ------------
  const printPdf = () => {
    setprintpdfLoading(true);
    const { dispatch } = props;
    dispatch(
      shiftsActions.printAgencyinvoicePdf({
        agency_invoice_id: invoice._id,
        status: '',
      }),
    ).then(
      res => {
        setprintpdfLoading(false);
        if (res && res.data && res.data.output_file_name) {
          window.open(
            apiConstants.shiftpdfUrl + res.data.output_file_name,
            '_blank',
          );
        }
      },
      () => {
        setprintpdfLoading(false);
      },
    );
  };
  //-------------download shift as pdf ---------
  const printShiftpdf = () => {
    setdownloadpdfLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.printShiftPdf({ Ids: selected_shiftIds })).then(
      res => {
        setdownloadpdfLoading(false);
        if (res && res.data && res.data.output_file_name) {
          window.open(
            apiConstants.shiftpdfUrl + res.data.output_file_name,
            '_blank',
          );
        }
      },
      () => {
        setdownloadpdfLoading(false);
      },
    );
  };
  //-------------get invoice ----------
  const getAllInvoice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(invoiceActions.invoiceListAgency(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setInvoice(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Invoice">
      {(props.requestStatus === reducerConstants.INVOICE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header "></div>
          <div className="col-md-12">
            <div className="card-body">
              <div className="d-flex justify-content-end m-2">
                <div
                  className="row invoicePrintTag m-1"
                  data-cy="downloadshifts"
                  onClick={() => {
                    printShiftpdf();
                  }}
                >
                  <div className="">Download Shifts </div>
                  <div>
                    <img
                      style={{ height: 15, width: 15, marginLeft: 5 }}
                      src="/assets/img/pdf.png"
                    />
                  </div>
                  {downloadpdfLoading && (
                    <div>
                      <img
                        style={{ height: 23, width: 23 }}
                        src="/assets/img/1497.gif"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="row invoicePrintTag m-1"
                  data-cy="printshifts"
                  onClick={() => {
                    printPdf();
                  }}
                >
                  <div className="">Print </div>
                  <div>
                    <img
                      style={{ height: 15, width: 15, marginLeft: 5 }}
                      src="/assets/img/pdf.png"
                    />
                  </div>
                  {printpdfLoading && (
                    <div>
                      <img
                        style={{ height: 23, width: 23 }}
                        src="/assets/img/1497.gif"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row d-flex justify-content-between p-3 border-bot">
                    <div className="page-titile-content">
                      <h4 className="card-title">INVOICE</h4>
                    </div>
                    <div className="invoice-image-div">
                      <img
                        src="/assets/img/flexi_logo.png"
                        className="d-inline-block align-top logo-img"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className=" border-invoice">
                    <div className="col-md-3">
                      <label className="">To</label>
                    </div>
                    <div className="ml-4">
                      <p className="invoice_element_main">
                        <label className="invoice_desc ">FLEXIRECS Ltd.</label>
                      </p>
                      <p className="invoice_element">
                        <label className="invoice_desc">
                          10-12 Southernhay Basildon Essex SS14 1EL United
                          kingdom
                        </label>
                      </p>
                      <p className="invoice_element">
                        {/* {invoice["Agency.email"]} */}
                      </p>
                      <p className="invoice_element mb-10">
                        {/* {invoice["Agency.contact_number"]} */}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-4 ">
                      <label className="">Invoice No :</label>
                    </div>
                    <div className="col-md-6">
                      <b>INV0{invoice._id}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="">Issued :</label>
                    </div>
                    <div className="col-md-6">
                      <b>{moment(invoice.createdAt).format('YYYY-MM-DD')}</b>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="custom_card">
                    <div className="row">
                      <div className="col-md-8">
                        <label className="">From</label>
                      </div>
                    </div>
                    <div className="ml-4">
                      <p className="invoice_element_main">
                        {invoice['Agency.company_name']}
                      </p>
                      <p className="invoice_element">
                        {invoice['Agency.postcode'] +
                          ',' +
                          invoice['Agency.address']}
                        ,
                      </p>
                      <p className="invoice_element">
                        {invoice['Agency.email']}
                      </p>
                      <p className="invoice_element">
                        {invoice['Agency.contact_number']}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Candidate</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Total Hours</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Employer</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">check in</label>
                </div>
                <div className="col-md-1 bg-invoice-tab">
                  <label className="">Check out</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Amount/hr</label>
                </div>
                <div className="col-md-2 bg-invoice-tab">
                  <label className="">Total Amount</label>
                </div>
              </div>
              {/* //------------invoice single details ends herer ----------- */}
              {Shiftrows}
              <div className="row ">
                <div className="col-md-12 d-flex justify-content-end border">
                  <label className="f18 mr-4">SUB TOTAL :</label>
                  <label className="f18">
                    {' '}
                    £ {parseInt(invoice.total_amount)}
                  </label>
                </div>
              </div>
              <div className="row mt-3">
                {/* //-------file 1 download ------------- */}
                {invoice.file1 && (
                  <div className="downloadlinkinvoicefile ">
                    <a
                      className="downloadlink ml-0"
                      href={apiConstants.imgUrl + invoice.file1}
                    >
                      Download file 1{' '}
                    </a>
                  </div>
                )}
                {invoice.file2 && (
                  <div className="downloadlinkinvoicefile">
                    <a
                      className="downloadlink ml-0"
                      href={apiConstants.imgUrl + invoice.file2}
                    >
                      Download file 2{' '}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, invoiceData } = state.invoice;
  return {
    requestStatus,
    invoiceData,
  };
}

const connectedInvoiceAgency = connect(mapStateToProps)(InvoiceAgency);
export { connectedInvoiceAgency as InvoiceAgency };
