import React from 'react';
import { connect } from 'react-redux';
const Sample = () => {
  const handlePrint = () => {
    window.print();
    // return false;
  };
  return (
    <div>
      <button onClick={handlePrint}>Print this page</button>

      <div className="content-to-print">
        <div>Content for Page 1</div>

        <div className="page-break"></div>

        <div>Content for Page 2</div>
      </div>
    </div>
  );
};

function mapStateToProps() {}

const connectedSample = connect(mapStateToProps)(Sample);
export { connectedSample as Sample };
