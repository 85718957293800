import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';

export const employerUserService = {
  employerUserAdd,
  employerUserList,
  employer_userDelete,
  allocateBusinessUnit,
  activateUser,
};

function employerUserAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/employer/user/add', requestOptions).then(
    miscService.handleResponse,
  );
}
function employerUserList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/employer/user/list',
    requestOptions,
  ).then(miscService.handleResponse);
}
function employer_userDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deleteemployer_user',
    requestOptions,
  ).then(miscService.handleResponse);
}
function allocateBusinessUnit(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/allocatebusinessunit',
    requestOptions,
  ).then(miscService.handleResponse);
}

function activateUser(data) {
  console.log(data, 'Data for deactivate');
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/employer/user/activate',
    requestOptions,
  ).then(miscService.handleResponse);
}
