/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     SECTION_SUCCESS:'SECTION_SUCCESS',
     SECTION_REQUEST:'SECTION_REQUEST',
     SECTION_FAILURE:'SECTION_FAILURE', 
    */
export function section(
  state = { requestStatus: 0, sectionData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'SECTION_SUCCESS':
      return {
        ...state,
        requestStatus: 'SECTION_SUCCESS',
        requestSectionStatus: 'SECTION_SUCCESS',
        sectionData: action.data.data,
        data: action.data,
      };
    case 'SECTION_FAILURE':
      return {
        ...state,
        requestStatus: 'SECTION_FAILURE',
        requestSectionStatus: 'SECTION_FAILURE',
        sectionData: {},
        data: action.data,
      };
    case 'SECTION_REQUEST':
      return {
        ...state,
        requestStatus: 'SECTION_REQUEST',
        requestSectionStatus: 'SECTION_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
