import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { vacancyshiftActions } from '../_actions';

let employer_id = '';
let staff_id = '';
const VacancyCalendar = props => {
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 31,
    },
    from: new Date().toISOString().slice(0, 10),
  });
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const [vacancyshiftData, setVacancyshiftData] = useState([]);
  const [Openings, setOpenings] = useState([]);
  // const [showsection, setshowsection] = useState(true);

  const [showbookingModal, setshowbookingModal] = useState(false);
  const [selectedDate, setselectedDate] = useState({}); //on cliick a date in calendar the selected date is kept here---
  const [totalvaccancies, settotalvaccancies] = useState({});
  // eslint-disable-next-line no-unused-vars
  const funshowbookingModal = (
    day,
    month,
    year,
    totalvaccancies,
    total_custome,
    total_morning,
    total_night,
  ) => {
    setshowbookingModal(true);
    setselectedDate({ day: day, month: month, year: year });
    settotalvaccancies({
      totalvaccancies: totalvaccancies,
      total_custome: total_custome,
      total_morning: total_morning,
      total_night: total_night,
    });
  };
  const { vacancyshiftFilterDate, vacancyshiftFilterbusinessunit } = props;

  // console.log("aaaaaaaaaaaaaa",vacancyshiftFilterDate)

  const getColoumItem = (day, month, year) => {
    // let getdate = day + "-" + month + "-" + year
    let cday = day.toString().length == 1 ? '0' + day : day;
    let cmonth = month.toString().length == 1 ? '0' + month : month;
    let getdate = year + '-' + cmonth + '-' + cday;
    // let pgetdate = cday + '-' + cmonth + '-' + year;

    //  setshowsection(false)

    let value = '';
    let newvaccancies = '';
    let confirmed = 0;
    let total = 0;
    // let total_night = '';
    // let total_morning = '';
    // let total_custome = '';

    if (vacancyshiftData) {
      for (let i = 0; i < vacancyshiftData.length; i++) {
        console.log(
          vacancyshiftData[i].from_date_string,
          '===goolak==',
          getdate,
        );
        // ------check vacancy shifft date with each date of month-------------------
        if (vacancyshiftData[i].from_date_string == getdate) {
          value = true;
          console.log(
            vacancyshiftData[i].no_of_openigs,
            'vacccccccccc',
            vacancyshiftData[i].from_date_string,
          );
          // -------set no_of no_of_openings to newvaccancies for display calender
          newvaccancies = vacancyshiftData[i].new;
          confirmed = vacancyshiftData[i].confirmed;
          // total_night = vacancyshiftData[i].total_night;
          // total_custome = vacancyshiftData[i].total_custome;
          // total_morning = vacancyshiftData[i].total_morning;
          // total = vacancyshiftData[i].no_of_openigs
        }

        if (Openings[i]?.from_date_string == getdate) {
          total = Openings[i].no_of_openigs;
        }
        // else {
        //   value = false
        // }
      }
      // total=parseInt(newvaccancies)+parseInt(confirmed)
    }
    // console.log("wwwwwwwwvalue---------", value)

    //To find the date which are less than currentday for add vacancy in calendar
    const isDateLessThanCurrent = (day, month, year) => {
      const currentDate = new Date();
      const yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(currentDate.getDate() - 1);

      const selectedDate = new Date(year, month - 1, day);

      return selectedDate < yesterdayDate;
    };
    return (
      <div className="col Blockbooking">
        <div className="row d-flex align-items-center">
          <div className="col f18">
            {day.toString().length == 1 ? '0' + day : day}
          </div>
          <div className="col d-flex justify-content-end">
            <div>
              <i
                data-cy="addvacancy"
                onClick={() => {
                  if (
                    !props.IsEmployer ||
                    isDateLessThanCurrent(day, month, year)
                  ) {
                    props.showAllocateShift(day, month, year);
                  } else {
                    props.showAllocateShift(day, month, year);
                  }
                }}
                className="fa fa-plus-circle f18"
                style={{
                  fontSize: '16px',
                  color: ' #11918A',
                  cursor: 'pointer',
                  display:
                    props.IsEmployer && !isDateLessThanCurrent(day, month, year)
                      ? 'inline-block'
                      : !props.IsEmployer
                      ? 'inline-block'
                      : 'none',
                }}
              ></i>
            </div>
          </div>
        </div>

        {true && (
          <div className="row">
            <div className="col mt-3">
              {value && (
                <div>
                  {newvaccancies > 0 ? (
                    <Link
                      style={{ color: '#12716F', textDecoration: 'none' }}
                      to={'/bookinglist/0/' + getdate}
                    >
                      {' '}
                      <div
                        className="cal-div-red block-booking-tag mt-1"
                        // onClick={() => funshowbookingModal(day, month, year,total,total_custome,total_morning,total_night)}
                      >
                        New- {newvaccancies}
                      </div>{' '}
                    </Link>
                  ) : (
                    <div className="cal-div-red block-booking-tag mt-1">
                      New- {newvaccancies}
                    </div>
                  )}

                  {confirmed > 0 ? (
                    <Link
                      style={{ color: '#2196f3', textDecoration: 'none' }}
                      to={'/bookinglist/0/' + getdate}
                    >
                      {' '}
                      <div
                        className="cal-div-blue block-booking-tag mt-1" //onClick={() => funshowbookingModal(day, month, year)}
                      >
                        Confirmed - {confirmed}
                      </div>
                    </Link>
                  ) : (
                    <div className="cal-div-blue block-booking-tag mt-1">
                      Confirmed - {confirmed}
                    </div>
                  )}

                  <div
                    className="cal-div-green mt-1" //onClick={() => funshowbookingModal(day, month, year)}
                  >
                    Total - {total}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );

    // }
    // }
  };

  // function daysOfMonth(month, year) {
  function daysOfMonth() {
    let a = new Date(vacancyshiftFilterDate);
    let month = a.getMonth() + 1;
    let year = a.getFullYear();
    //

    let rows = 6;
    let cols = 6;
    let c = 1;

    let daysMonth = new Date(year, month, 0).getDate();
    let firstWeekno = new Date(month + '/1/' + year).getDay();
    let index = 0;

    //

    let tableRows = [];

    tableRows.push(
      <tr className="col-md-12 p-2 mt-2 bg-fff ">
        <td className="col-md-1 calendar-day bg-fff Cell">Sunday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">monday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">Tuesday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">Wednesday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">Thursday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">Friday</td>
        <td className="col-md-1 calendar-day bg-fff Cell">Saturday</td>
      </tr>,
    );

    for (let r = 0; r < rows; r++) {
      let tabeColoms = [];
      for (let i = 0; i <= cols; i++) {
        if (c <= daysMonth) {
          if (index >= firstWeekno) {
            //  _table += <td> {c} </td>;

            tabeColoms.push(
              <td className="c bg-fff Cell">
                {getColoumItem(c, month, year)}
              </td>,
            );
          } else {
            tabeColoms.push(<td className=" bg-fff Cell"></td>);
          }
        } else {
          break;
        }

        if (index >= firstWeekno) {
          c++;
        }
        index++;
      }

      {
        tabeColoms.length !== 0 &&
          tableRows.push(<tr className="h100 bg-fff">{tabeColoms}</tr>);
      }
    }

    return tableRows;
  }

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));

      employer_id = user.employer_id;
    }

    if (props.emp_id) {
      // alert("hello"+props.emp_id)
      // getAllVacancyshift(1);
    }

    getAllVacancyshift(1);
    /**
     * Edit data using url query
     */
  }, [
    vacancyshiftFilterDate,
    vacancyshiftFilterbusinessunit,
    props.emp_id,
    props.st_id,
    props.category_id,
  ]);

  const getAllVacancyshift = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // ------ get all vacancyshift by vacancyshiftfilterdate (vacancyfilterdate get from reducer)-----------------

    queryNew.from_date = vacancyshiftFilterDate;
    queryNew.business_unit = vacancyshiftFilterbusinessunit;
    queryNew.employer_id = employer_id;
    queryNew.allocated_staff = staff_id;

    if (props.emp_id) {
      queryNew.employer_id = props.emp_id;
    }
    if (props.st_id) {
      queryNew.allocated_staff = props.st_id;
    }
    if (props.category_id) {
      queryNew.category = props.category_id;
    }

    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(vacancyshiftActions.Vacancycalender(queryNew)).then(
      res => {
        setIsLoading(false);
        setVacancyshiftData(res.data[0]);
        setOpenings(res.data[1]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="Calendar">
      <div className="m-3">
        <table className="table customtable_calendar table-hover">
          {daysOfMonth()}
        </table>
      </div>

      {showbookingModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setshowbookingModal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="modal-body m-3">
                <div className="bag">
                  <div>
                    <div className="m-2">
                      <div className="row d-flex justify-content-between align-items-center">
                        <div className="  date-div">
                          {' '}
                          {selectedDate.day.toString().length == 1
                            ? '0' + selectedDate.day
                            : selectedDate.day}
                          -
                          {(selectedDate.month + 1).toString().length == 1
                            ? '0' + selectedDate.month
                            : selectedDate.month}
                          -{selectedDate.year}
                        </div>
                        <div className="btn-primary-blue-med ">
                          + Add Multiple shift
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dummy-view"></div>
                  {/* <h4 className="card-title">Add invoice </h4> */}
                  <div className="row">
                    <div className="m-2">
                      <div> Total vacancies </div>
                      <div> Morning Shift </div>
                      <div> Night Shift </div>
                      <div> Custome Shift </div>
                    </div>
                    <div className="m-2">
                      <div>-</div>
                      <div>-</div>
                      <div>-</div>
                    </div>
                    <div className="m-2">
                      <div className="row d-flex align-items-center ml-3">
                        <div className="fontbold">
                          {totalvaccancies.totalvaccancies}
                        </div>
                        {/* <div className="item-box-div-blue">25</div> */}
                      </div>
                      <div className="row d-flex align-items-center ml-3">
                        <div className="fontbold">
                          {totalvaccancies.totalvaccancies}
                        </div>
                        <div className="item-box-div-blue">
                          {totalvaccancies.total_morning}
                        </div>
                      </div>
                      <div className="row d-flex align-items-center ml-3">
                        <div className="fontbold">
                          {totalvaccancies.totalvaccancies}
                        </div>
                        <div className="item-box-div-black">
                          {totalvaccancies.total_night}
                        </div>
                      </div>
                      <div className="row d-flex align-items-center ml-3">
                        <div className="fontbold">
                          {totalvaccancies.totalvaccancies}
                        </div>
                        <div className="item-box-div-blue">
                          {totalvaccancies.total_custome}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { misc } = state;
  return {
    vacancyshiftFilterDate: misc.vacancyshiftFilterDate,
    vacancyshiftFilterbusinessunit: misc.vacancyshiftFilterbusinessunit,
  };
}

const connectedVacancyCalendar = connect(mapStateToProps)(VacancyCalendar);
export { connectedVacancyCalendar as VacancyCalendar };
