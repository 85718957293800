export const miscConstants = {
  REQUEST: 1,
  SUCCESS: 2,
  FAILURE: 3,
  CLEAR: 0,
  FILE_REQUEST: 11,
  FILE_SUCCESS: 12,
  FILE_FAILURE: 13,
  FILE_CLEAR: 10,
  // REACT_APP_API_KEY: 'AIzaSyD9v0lZyLTF8rJlummyhwfwzOM-Olp-oWs',
  REACT_APP_API_KEY: 'AIzaSyAAXagrJKwWSJh-gKLA0LRt__3vRKXQKgw',
  IS_MENU_OPEN: 'IS_MENU_OPEN',

  CURRENCY_SIGN: '$',
  CURRENCY: 'GBP',
};
