import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { invoiceActions, shiftsActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const Invoice = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [invoice, setInvoice] = useState({
    name: '',
  });
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [Shiftrows, setShiftrows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  // effect to load statequery and invoice list and shift list
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllInvoice(1, query);
      getAllShifts(id);
    }
  }, []);
  // list and paginate invoice data
  const setListAndPagination = shiftDataList => {
    let shiftList = [];
    let shift_ids_array = [];
    for (
      let s = 0;
      shiftDataList && shiftDataList && s < shiftDataList.length;
      s = s + 1
    ) {
      shift_ids_array.push(shiftDataList[s]._id);
      shiftList.push(
        <tr key={s}>
          <td>
            {shiftDataList[s]['Candidate.name']
              ? shiftDataList[s]['Candidate.name'] +
                ' ' +
                (shiftDataList[s]['Candidate.last_name']
                  ? shiftDataList[s]['Candidate.last_name']
                  : '')
              : shiftDataList[s]['Agencycandidate.name'] +
                '\n' +
                '(' +
                shiftDataList[s]['Agency.company_name'] +
                ')'}{' '}
          </td>
          <td>{shiftDataList[s].actual_hours_after_break_time} hrs </td>
          <td>{shiftDataList[s]['Employer.company_name']} </td>
          <td>{shiftDataList[s].check_in_date_string} </td>
          <td>
            {shiftDataList[s].attendance_check_in
              ? shiftDataList[s].attendance_check_in
              : shiftDataList[s].check_in_time_string}{' '}
          </td>
          <td>
            {shiftDataList[s].attendance_check_out
              ? shiftDataList[s].attendance_check_out
              : shiftDataList[s].check_out_time_string}{' '}
          </td>
          <td>
            {shiftDataList[s].candidate_signature_url ? (
              <img
                className="sign-image"
                src={
                  apiConstants.imgUrl + shiftDataList[s].candidate_signature_url
                }
              />
            ) : (
              'No'
            )}{' '}
          </td>
          <td>
            {shiftDataList[s].employer_signature_url ? (
              <img
                className="sign-image"
                src={
                  apiConstants.imgUrl + shiftDataList[s].employer_signature_url
                }
              />
            ) : (
              'No'
            )}{' '}
          </td>
          <td>£ {shiftDataList[s].employer_to_pay.toFixed(2)} </td>
          <td>
            £{' '}
            {(
              shiftDataList[s].actual_hours_after_break_time *
              shiftDataList[s].employer_to_pay
            ).toFixed(2)}{' '}
          </td>
        </tr>,
      );
    }
    setShiftrows(shiftList);
    setselected_shiftIds(shift_ids_array);
  };
  // call for get all shift invoices
  const getAllShifts = async invoice_id => {
    const { dispatch } = props;
    await dispatch(
      shiftsActions.shiftsListforviewinvoice({
        status: '',
        invoice_id: invoice_id,
      }),
    ).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data.rows);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // call for get invoices
  const getAllInvoice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(invoiceActions.invoiceList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setInvoice(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Invoice">
      {(props.requestStatus === reducerConstants.INVOICE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header "></div>
          <div className="col-md-12">
            <div className="card-body">
              <div className="d-flex justify-content-end m-2">
                <div className="row invoicePrintTag m-1">
                  <a
                    data-cy="downloadshifts"
                    href={`/shiftprint/1/${selected_shiftIds}/0/0/0`}
                    rel="noreferrer"
                  >
                    <p className="f-12 fontwghtbold"> Download shifts </p>
                  </a>
                  <div>
                    <img
                      style={{ height: 15, width: 15, marginLeft: 5 }}
                      src="/assets/img/pdf.png"
                    />
                  </div>
                </div>
                <div className="row invoicePrintTag m-1">
                  <a
                    data-cy="printshifts"
                    href={`/invoiceprint/${invoice._id}`}
                    rel="noreferrer"
                  >
                    <p className="f-12 fontwghtbold">Print </p>
                  </a>
                  <div>
                    <img
                      style={{ height: 15, width: 15, marginLeft: 5 }}
                      src="/assets/img/pdf.png"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row d-flex justify-content-between p-3 border-bot">
                    <div className="page-titile-content">
                      <h4 className="card-title">INVOICE</h4>
                    </div>
                    <div className="invoice-image-div">
                      <img
                        src="/assets/img/flexi_logo.png"
                        className="d-inline-block align-top logo-img"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className=" border-invoice">
                    <div className="col-md-3">
                      <label className="">To</label>
                    </div>
                    <div className="ml-4">
                      <p className="invoice_element_main">
                        {invoice['Employer.company_name']}
                      </p>
                      <p className="invoice_element">
                        {invoice['Employer.address']},
                      </p>
                      <p className="invoice_element">
                        {invoice['Employer.location']}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-4 ">
                      <label className="">Invoice No :</label>
                    </div>
                    <div className="col-md-6">
                      <b>INV0{invoice._id}</b>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="">Issued :</label>
                    </div>
                    <div className="col-md-6">
                      <b>{moment(invoice.createdAt).format('YYYY-MM-DD')}</b>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ">
                  <div className="custom_card">
                    <div className="row">
                      <div className="col-md-8">
                        <label className="">From</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 ml-2">
                        <label className="invoice_desc ">FLEXIRECS Ltd.</label>
                        <label className="invoice_desc">
                          {' '}
                          10-12 Southernhay Basildon Essex SS14 1EL United
                          kingdom{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-body  table-responsive main-card-body "
                style={{ padding: '0px' }}
              >
                {!isLoading && (
                  <table
                    className="table table-hover flx-custom-table"
                    style={{ background: '#a1a9aa87' }}
                  >
                    <thead>
                      <tr>
                        <th>Candidate/Agency</th>
                        <th>Total Hours</th>
                        <th>Employer</th>
                        <th>Check In Date</th>
                        <th>Check In</th>
                        <th>Check Out</th>
                        <th>Candidate Signature</th>
                        <th>Employer Signature</th>
                        <th>Amount/hr</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {' '}
                      {Shiftrows}
                      <tr>
                        <td colSpan={8}></td>
                        <td className="fontwghtbold" colSpan={1}>
                          SUB TOTAL :
                        </td>
                        <td className="fontwghtbold">
                          {' '}
                          £ {parseFloat(invoice.total_amount).toFixed(2)}
                        </td>
                      </tr>
                      ,
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, invoiceData } = state.invoice;
  return {
    requestStatus,
    invoiceData,
  };
}

const connectedInvoice = connect(mapStateToProps)(Invoice);
export { connectedInvoice as Invoice };
