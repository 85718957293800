import { format, getDate, getMonth, getYear } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { miscService } from '../_services';

const PostLive = props => {
  const navigate = useNavigate();

  const handleViewPost = e => {
    e.preventDefault();
    navigate(`/vacancyshiftlist`);
  };

  const handleBacktoDashBoard = e => {
    e.preventDefault();
    navigate(`/dashboardemployer`);
  };
  return (
    <>
      <div className="container my-2 flx-post-live-container">
        {props.vacancyPosting.map(shiftDetails =>
          shiftDetails.continueAddingShift.map((shiftInfo, index) => (
            <div key={index} className="flx-post-card">
              <div className="flx-post-header">Agency Shift</div>
              <div className="flx-post-button">
                <img
                  className="flx-post-card-icon"
                  src="/assets/img/pencil-post-live.svg"
                />
                <a
                  href="#"
                  className="flx-view-post-btn"
                  onClick={e => handleViewPost(e)}
                >
                  View Post
                  <i className="flx-view-post-right-icon fa fa-arrow-right"></i>
                </a>
              </div>
              <div className="flx-post-body">
                <h5 className="flx-post-card-job-role">
                  {shiftDetails.position.name}
                </h5>
                <p className="flx-post-card-shift-details">
                  {shiftDetails.selectedBusinessUnit.postcode}{' '}
                  {format(
                    miscService.dateConvertion(shiftDetails.date),
                    'dd-MMM-yyyy',
                  )}{' '}
                  - {shiftInfo.shift_time.from_time} to{' '}
                  {shiftInfo.shift_time.to_time}
                </p>
                <p className="flx-post-card-posted-date">
                  Posted:{' '}
                  {miscService.getMonthName(
                    getMonth(miscService.dateConvertion(shiftDetails.date)),
                  )}{' '}
                  {getDate(miscService.dateConvertion(shiftDetails.date))},{' '}
                  {getYear(miscService.dateConvertion(shiftDetails.date))}
                </p>
              </div>
            </div>
          )),
        )}
      </div>
      <div className="flx-post-card-card-footer-block text-center">
        <p className="flx-post-card-card-footer-block-text">
          Your shift is now published
        </p>
        <p className="flx-post-card-card-footer-block-sub-text">
          Please visit My Space later to view and accept the bookings
        </p>
        <button
          className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn"
          onClick={e => handleBacktoDashBoard(e)}
        >
          Back to my space
        </button>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return { requestStatus };
}

const connectedPostLive = connect(mapStateToProps)(PostLive);
export { connectedPostLive as PostLive };
