import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { PriceActions } from '../_actions';
import { LoadingImg, LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

let price_temp_array = [];
let category_temp_array = [];

const PriceAdd = props => {
  const { id } = useParams();

  const [PriceArray, setPriceArray] = useState([]);
  const [ExistWarning, setExistWarning] = useState('');

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query.employer_id = id;
      setQuery(query);
    }

    if (props.employer_id) {
      query.employer_id = props.employer_id;
      setQuery(query);
      getAllPrice(1, query);
    }

    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
    }
  }, []);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 3000);
  };

  const getAllPrice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (props.Selectedtype) {
      queryNew.type = props.Selectedtype;
    }
    dispatch(PriceActions.PriceList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          price_temp_array = res.data.rows;
          setCatAndPrice(price_temp_array, category_temp_array);
          setPriceArray(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const selectedcategory = selectedOption => {
    // let name = event.name;
    let found = false;
    for (let k = 0; k < PriceArray.length; k++) {
      if (selectedOption._id == PriceArray[k].category) {
        found = true;
        setExistWarning('Jobrole exist in list');
        setTimeout(() => {
          setExistWarning('');
        }, 2000);
      }
    }

    if (!found) {
      setCatAndPrice(price_temp_array, [selectedOption]);
    }
  };

  const setCatAndPrice = (pirceArray, catArray) => {
    if (!catArray || !catArray.length) {
      return;
    }
    if (!pirceArray) {
      pirceArray = [];
    }
    let newpirceArray = [];
    for (let i = 0; i < catArray.length; i++) {
      let isFound = false;
      for (let j = 0; j < pirceArray.length; j++) {
        if (pirceArray[j].category == catArray[i]._id) {
          isFound = true;
          pirceArray[j].cat_name = catArray[i].name;
          newpirceArray.push({ ...pirceArray[j] });
        }
      }
      //------------------if not found then add new category in price array
      if (!isFound) {
        newpirceArray.push({
          category: catArray[i]._id,
          cat_name: catArray[i].name
            ? catArray[i].name
            : catArray[i]['Category.name'],
          morning_price: catArray[i].morning_price,
          evening_price: catArray[i].evening_price,
          weekend_price: catArray[i].weekend_price,
          day_price: catArray[i].day_price,

          night_price: catArray[i].night_price,
          weekend_day_price: catArray[i].weekend_day_price,
          weekend_night_price: catArray[i].weekend_night_price,
          employer_id: props.employer_id,
          type: props.Selectedtype ? props.Selectedtype : 'block booking',
        });
      }
    }
    let NewPriceArray = PriceArray.concat(newpirceArray);
    setPriceArray(NewPriceArray);
  };

  const handleChange = (event, j) => {
    const { name, value } = event.target;
    // if (value < 10.42) {
    //   setError({
    //     common: 'Price should be greater than minimum price of10.42',
    //   });
    //   return;
    // }

    let newPrice = [...PriceArray];
    //---set if there is no j th element==========
    if (!newPrice[j]) {
      newPrice[j] = {};
    }
    newPrice[j][name] = value;
    setPriceArray(newPrice);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '206px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };

  const handleSubmit = event => {
    event.preventDefault();

    const { dispatch } = props;
    setIsLoading(true);

    for (let i = 0; i < PriceArray.length; i++) {
      if (PriceArray[i].morning_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Morning price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (PriceArray[i].day_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Day price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (PriceArray[i].evening_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Evening price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }

      if (PriceArray[i].night_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Night price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (
        PriceArray[i].holiday_price !== null &&
        PriceArray[i].holiday_price < 10.42
      ) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Holiday price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }

      if (PriceArray[i].weekend_day_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Weekend day price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
      if (PriceArray[i].weekend_night_price < 10.42) {
        setError({
          common:
            PriceArray[i]['cat_name'] +
            ' ' +
            'Weekend night price should be greater than minimum price of 10.42',
        });
        setIsLoading(false);
        return;
      }
    }

    dispatch(PriceActions.PriceAdd(PriceArray)).then(
      () => {
        props.getPriceData(PriceArray);
        setIsLoading(false);
      },
      err => {
        setError({
          common:
            err && err.message ? err.message : alertConstants.SERVER_ERROR,
        });
        hideError();
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="PriceAdd  d-flex justify-content-center">
      <div className="col-md-12  col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className="pb-4">
            <h4 className="card-title">
              Add Price {props.Selectedtype && props.Selectedtype}
            </h4>
          </div>
          <div className="">
            <form name="form">
              <div className="  table-responsive main-card-body ">
                {!isLoading && (
                  <>
                    <div className="col-md-6 mb-2">
                      <div className="row w100">
                        <AsyncSelect
                          styles={customStyles}
                          name="category"
                          className="categoryselect"
                          onChange={selectedcategory}
                          cacheOptions
                          defaultOptions
                          loadOptions={getcategorylist}
                        />
                      </div>
                    </div>
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: '0px' }}>Jobrole</th>
                          <th>Day</th>
                          <th>Morning</th>
                          <th>Night</th>
                          <th>Evening</th>

                          <th>weekend day</th>
                          <th>weekend night</th>
                          <th>Holiday</th>
                        </tr>
                      </thead>

                      <tbody>
                        {PriceArray.map((priceObject, key) => {
                          return (
                            <tr key={key}>
                              <td style={{ fontWeight: '700' }}>
                                {priceObject.cat_name
                                  ? priceObject.cat_name
                                  : priceObject['Category.name']}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="day_price"
                                  placeholder="day price"
                                  value={
                                    priceObject &&
                                    priceObject.day_price &&
                                    priceObject.day_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="morning_price"
                                  placeholder="morning price"
                                  value={
                                    priceObject &&
                                    priceObject.morning_price &&
                                    priceObject.morning_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="night_price"
                                  placeholder="night price"
                                  value={
                                    priceObject &&
                                    priceObject.night_price &&
                                    priceObject.night_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="evening_price"
                                  placeholder="evening price"
                                  value={
                                    priceObject &&
                                    priceObject.evening_price &&
                                    priceObject.evening_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>

                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="weekend_day_price"
                                  placeholder="weekend day price"
                                  value={
                                    priceObject &&
                                    priceObject.weekend_day_price &&
                                    priceObject.weekend_day_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="weekend_night_price"
                                  placeholder="weekend night price"
                                  value={
                                    priceObject &&
                                    priceObject.weekend_night_price &&
                                    priceObject.weekend_night_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>

                              <td>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="holiday_price"
                                  placeholder="holiday price"
                                  value={
                                    priceObject &&
                                    priceObject.holiday_price &&
                                    priceObject.holiday_price
                                  }
                                  onChange={e => {
                                    handleChange(e, key);
                                  }}
                                />
                              </td>

                              {!priceObject._id && (
                                <td>
                                  {' '}
                                  <a
                                    onClick={() => {
                                      PriceArray.splice(key, 1);
                                      setPriceArray([...PriceArray]);
                                    }}
                                  >
                                    {' '}
                                    X{' '}
                                  </a>{' '}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
                <div className="d-flex justify-content-start">
                  {ExistWarning && (
                    <div
                      style={{
                        background: '#ff9e9e',
                        height: 20,
                        alignItems: 'center',
                        padding: 5,
                        color: '#fff',
                        display: 'flex',
                        borderRadius: 2,
                        marginBottom: 10,
                      }}
                    >
                      {ExistWarning}!
                    </div>
                  )}
                </div>
                {!!isLoading && <LoadingImg />}
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button
                      data-cy="add_pricee"
                      onClick={handleSubmit}
                      className="btn btn-primary btn-wd"
                    >
                      Save
                      {props.requestStatus ===
                        reducerConstants.PRICE_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            resolve(
              data.rows.map(
                ({
                  _id,
                  name,
                  morning_price,
                  night_price,
                  evening_price,
                  day_price,
                  weekend_day_price,
                  holiday_price,
                  weekend_night_price,
                }) => ({
                  value: _id,
                  label: name,
                  _id: _id,
                  name: name,
                  morning_price: morning_price,
                  night_price: night_price,
                  evening_price: evening_price,
                  day_price: day_price,
                  weekend_day_price: weekend_day_price,
                  weekend_night_price: weekend_night_price,
                  holiday_price: holiday_price,
                }),
              ),
            );
          }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  // const { requestStatus, PriceData } = state.Price;
  return {
    // requestStatus,
    // PriceData
  };
}

const connectedPriceAdd = connect(mapStateToProps)(PriceAdd);
export { connectedPriceAdd as PriceAdd };
