import { useState } from 'react';
import { IncludePendingSignOffPopup } from '../Shifts';
export const ShiftSignOffReminderPopup = () => {
  const [showModal, setShowModal] = useState('');
  return (
    <>
      <div className="modal-dialog flx-modal-popup" role="document">
        <div className="modal-content">
          <div className="modal-header flx-invoice-close-icon">
            <button
              type="button"
              className="close flx-closebutton-popup"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="flx-closeicon">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            <p className="flx-popup-body flx-invoice-decline-text text-center">
              Initiate shift sign-off reminders for selected candidates?
            </p>

            <div className="mt-2 text-center">
              <label htmlFor="reason" className="form-label">
                This action will trigger an email to the employer containing
                candidate and shift details.
              </label>
            </div>
          </div>
          <div className="flx-popup-bottom">
            <div className="flx-popup-buttons-invoice-confirm">
              <div className="modal-footer flx-popup-bottom flx-add-shift-popup mb-10">
                <span className="flx-add-shift-cancel">Cancel</span>
                <button
                  type="button"
                  className="btn btn-sm flx-popup-button flx-send-reminder-button"
                  onClick={() => setShowModal('includependingsignoffpopup')}
                >
                  Yes, Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal open up on add location click*/}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          showModal == 'includependingsignoffpopup' ? 'show' : ''
        }`}
        style={{
          display: showModal == 'includependingsignoffpopup' ? 'block' : 'none',
        }}
      >
        <IncludePendingSignOffPopup />
        <a onClick={() => setShowModal('')}>x</a>
      </div>
    </>
  );
};
