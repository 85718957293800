/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { qualificationActions } from '../_actions';
import { apiConstants } from '../_constants';
import { QualificationAndTrainingAdd } from './QualificationAndTrainingAdd';

const QualificationAndTraining = props => {
  const [qualificationDataRows, setQualificationDataRows] = useState([]);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [QulaificationAddModal, setQulaificationAddModal] = useState(false);
  const [TrainingAddModal, setTrainingAddModal] = useState(false);
  const [selectedQualificationId, setselectedQualificationId] = useState(null);
  // const [selectedTrainingId, setselectedTrainingId] = useState(null);

  useEffect(() => {
    if (props && props.candidate_id) {
      query.candidate_id = props.candidate_id;

      setQuery(query);
      getQualification0rTraining(1, query);
    }
  }, []);

  const getQualification0rTraining = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    if (props && props.mode) {
      queryNew.mode = props.mode;
    }
    const { dispatch } = props;

    dispatch(qualificationActions.qualificationList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setQualificationlist(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const setQualificationlist = qualificationdata => {
    let qualificationlist = [];
    for (let k = 0; k < qualificationdata.length; k = k + 1) {
      qualificationlist.push(
        <div
          className="educard_border mt-3 p-2"
          data-cy="certificationandtrainingcard"
        >
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              <div className=" experiencedetails">
                <p className="mb-0 " style={{ fontWeight: '500' }}>
                  {qualificationdata[k].level}
                </p>
                <p className="stream">
                  completed date : {qualificationdata[k].completed_date_string}
                </p>
                <p className="mb-0" style={{ textTransform: 'capitalize' }}>
                  {qualificationdata[k].description}
                </p>
                {qualificationdata[k].proof_document && (
                  <div className="col-md-1 proof_document d-flex align-items-center">
                    {qualificationdata[k] &&
                      qualificationdata[k].proof_document &&
                      qualificationdata[k].proof_document}
                    <div className="w20picon">
                      <div className="downloadlink">
                        <a
                          className="downloadlink ml-0"
                          href={
                            apiConstants.imgUrl +
                            qualificationdata[k].proof_document
                          }
                        >
                          <img
                            style={{ height: 20, width: 20 }}
                            src="/assets/img/download.png"
                          />
                          {/* <p>Download</p> */}
                        </a>
                      </div>
                    </div>
                    {/* <img
                      className=" float-right"
                      style={{
                        marginTop: '8px',
                      }}
                      src="/assets/img/Subtract.png"
                    /> */}
                  </div>
                )}
              </div>
              <div className="d-flex">
                <div
                  data-cy="editcertificationandtraining"
                  className="AddBtn"
                  onClick={() => {
                    setselectedQualificationId(qualificationdata[k]._id),
                      setQulaificationAddModal(true);
                  }}
                >
                  edit
                </div>
              </div>
            </div>
          </div>
        </div>,
      );
    }

    setQualificationDataRows([qualificationlist]);
  };

  //--------------get qulaificatiiom add response function -----------
  const getQalificationAddResponse = () => {
    setQulaificationAddModal(false);
    getQualification0rTraining();
  };

  //--------------get qulaificatiiom add response function -----------

  const getTrainingAddResponse = () => {
    setTrainingAddModal(false);
    getQualification0rTraining();
  };

  return (
    <div className="CandidateAdd" data-cy="certificationandtrainingview">
      <div className="row">
        <div className="col-md-12 ">
          {isLoading && <span></span>}
          {/* <div className=" qualificationList"> */}
          <div className="">
            {qualificationDataRows.length > 0 ? (
              qualificationDataRows
            ) : (
              <p className="mb-0" style={{ textAlign: 'center' }}>
                No result found
              </p>
            )}
          </div>
          {props.mode == 1 && props?.hideedit && (
            <div className="addBtnFooter">
              <div
                data-cy="addtrainingandcertification"
                className="AddBtn"
                onClick={() => {
                  setQulaificationAddModal(true),
                    setselectedQualificationId(null);
                }}
              >
                Add
              </div>
            </div>
          )}
          {props.mode == 2 && props?.hideedit && (
            <div className="addBtnFooter">
              <div
                data-cy="addtrining"
                className="AddBtn"
                onClick={() => {
                  setTrainingAddModal(true), setselectedQualificationId(null);
                }}
              >
                Add
              </div>
            </div>
          )}
        </div>
        {/* //--------------qulaifiacation add modal section --------------- */}
        {QulaificationAddModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setQulaificationAddModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <QualificationAndTrainingAdd
                    candidate_id={props.candidate_id}
                    mode={1}
                    hideaddqualificationmodal={getQalificationAddResponse}
                    qualification_id={selectedQualificationId}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* /////-----------training add modal section screeen -------------- */}
        {TrainingAddModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setTrainingAddModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <QualificationAndTrainingAdd
                    candidate_id={props.candidate_id}
                    mode={2}
                    hideaddqualificationmodal={getTrainingAddResponse}
                    qualification_id={selectedQualificationId}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedQualificationAndTraining = connect(mapStateToProps)(
  QualificationAndTraining,
);
export { connectedQualificationAndTraining as QualificationAndTraining };
