import React from 'react';

export const DocumentView = ({ url }) => {
  const isImage = /\.(jpg|jpeg|png)$/.test(url);
  const isPdf = /\.pdf$/.test(url);

  if (isImage) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          minHeight: '70vh',
        }}
      >
        <img
          style={{
            width: '100%',
            height: 'auto',
            maxHeight: '80vh',
            objectFit: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
            objectPosition: 'center',
          }}
          src={url}
          alt="Document"
        />
      </div>
    );
  } else if (isPdf) {
    return <iframe title="PDF Viewer" src={url} width="100%" height="500px" />;
  } else if (!isImage && !isPdf) {
    return (
      <iframe
        title="Google Doc Viewer"
        src={`https://docs.google.com/gview?url=${encodeURIComponent(
          url,
        )}&embedded=true`}
        width="100%"
        height="500px"
      />
    );
  } else {
    return <div>Unsupported document format</div>;
  }
};
