import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { blockcandidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';
const Candidateblock = props => {
  const { id } = useParams();

  const [blockcandidate, setblockcandidate] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllBlockcandidate(1, query);
    }
  }, []);

  /**
   * Get all bloackcandidates
   * */
  const getAllBlockcandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    const { dispatch } = props;
    dispatch(blockcandidateActions.blockcandidateList(queryNew)).then(res => {
      if (res && res.data.rows && res.data.rows.length > 0) {
        setblockcandidate(res?.data?.rows?.[0]);
      }
    });
  };

  return (
    <div className="Allocatecandidate">
      {props.requestStatus === reducerConstants.BLOCKCANDIDATE_REQUEST && (
        <LoadingModal />
      )}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Blocked Candidate </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <label> Candidate Name</label>
              </div>
              <div className="">
                <label>:</label>
              </div>

              <div className="col">
                <p>{blockcandidate['Candidate.name']}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-sm-6">
                <label> Carehome</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{blockcandidate['Employer.company_name']}</p>
              </div>
            </div>
            <div className="row">
              <Link
                className="link"
                to={'/blockcandidateadd/' + blockcandidate._id}
                style={{ marginLeft: '15px' }}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  // const { requestStatus } = state.Candidateblock;
  // return {
  //   requestStatus,
  //   allocatecandidateData
  // };
}

const connectedCandidateblock = connect(mapStateToProps)(Candidateblock);
export { connectedCandidateblock as Candidateblock };
