import React from 'react';
import { connect } from 'react-redux';
const EmployerPendingSignOff = () => {
  return (
    <div className="container-fluid flx-filtered-pending-sign-off-container">
      <h4 className="flx-filtered-pending-sign-off-title">Pending Sign Off</h4>
      <p className="flx-filtered-pending-sign-off-description">
        Pending Sign Off for All Candidate Shift Details
      </p>
      <div className="input-group flx-search-box-pending-sign-off-candidates-shift">
        <span className="input-group-text search-icon-settings-area">
          <i className="fa fa-search flx-settings-search-icon"></i>
        </span>
        <input
          type="text"
          className="form-control flx-search-box flx-ag-shift-search-box"
          placeholder="Search Candidates"
        />
      </div>
      <div className="d-flex flx-approve-sign-off-container">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="select-all" />
          <label className="form-check-label" htmlFor="select-all">
            Select All
          </label>
        </div>
        <button
          type="button"
          className="flx-emp-approve-sign-off-button-disabled flx-emp-approve-sign-off-button-enabled"
        >
          Approve Sign Off
        </button>
      </div>

      <div className="flx-table-sett-user-responsive">
        <table
          className="table flx-user-settings-table flx-employer-filtered-pending-sign-off-table mt-3"
          style={{ borderRadius: '10px' }}
        >
          <thead>
            <tr>
              <th></th>
              <th>Name</th>

              <th>Shift From</th>
              <th>Shift To</th>
              <th>Shift</th>
              <th>Check In</th>
              <th>Check Out</th>
              <th>Break</th>
              <th>Hours</th>
              <th>Employer</th>
              <th>Created</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr className="flx-selected-div-pending-sign-off">
              <td>
                <input type="checkbox" aria-label="select" checked />
              </td>
              <td data-title="Name">
                <img
                  className="flx-emp-invoicing-icons"
                  src="/assets/img/shift-sign-off-demo-image.png"
                />
                Abraham Mark
              </td>
              <td data-title="From" className="flx-employer-other-fields-td">
                12-2-2023
              </td>
              <td data-title="To" className="flx-employer-other-fields-td">
                20-4-2024
              </td>
              <td data-title="Morning" className="flx-employer-other-fields-td">
                Morning
              </td>
              <td
                data-title="Check In"
                className="flx-employer-other-fields-td"
              >
                9.0
              </td>
              <td
                data-title="Check Out"
                className="flx-employer-other-fields-td"
              >
                16.0
              </td>
              <td data-title="Break" className="flx-employer-other-fields-td">
                30m
              </td>
              <td data-title="Hours" className="flx-employer-other-fields-td">
                8
              </td>
              <td
                data-title="Employer"
                className="flx-employer-other-fields-td"
              >
                Rehab
              </td>
              <td data-title="Created" className="flx-employer-other-fields-td">
                12-Jun-24
              </td>
              <td data-title="Status" className="flx-employer-status-fields-td">
                1st Reminder
              </td>
              <td className="flx-employer-other-fields-td">
                <img
                  className=" "
                  src="/assets/img/edit-icon-pending-sign-off.svg"
                />
                <span className="flx-pending-sign-off-cancel-link">Cancel</span>
              </td>
            </tr>

            <tr>
              <td>
                <input type="checkbox" aria-label="select" />
              </td>
              <td data-title="Name">
                <img
                  className="flx-emp-invoicing-icons"
                  src="/assets/img/shift-sign-off-demo-image.png"
                />
                Suzenne
              </td>
              <td data-title="From" className="flx-employer-other-fields-td">
                12-2-2023
              </td>
              <td data-title="To" className="flx-employer-other-fields-td">
                20-4-2024
              </td>
              <td data-title="Shift" className="flx-employer-other-fields-td">
                Morning
              </td>
              <td
                data-title="Check In"
                className="flx-employer-other-fields-td"
              >
                9.0
              </td>
              <td
                data-title="Check Out"
                className="flx-employer-other-fields-td"
              >
                16.0
              </td>
              <td data-title="Break" className="flx-employer-other-fields-td">
                30m
              </td>
              <td data-title="Hours" className="flx-employer-other-fields-td">
                8
              </td>
              <td
                data-title="Employer"
                className="flx-employer-other-fields-td"
              >
                Rehab
              </td>
              <td data-title="Created" className="flx-employer-other-fields-td">
                12-Jun-24
              </td>
              <td data-title="Status" className="flx-employer-status-fields-td">
                1st Reminder
              </td>
              <td className="flx-employer-other-fields-td">
                <img
                  className=" "
                  src="/assets/img/edit-icon-pending-sign-off.svg"
                />
                <span className="flx-pending-sign-off-cancel-link">Cancel</span>
              </td>
            </tr>

            <tr>
              <td>
                <input type="checkbox" aria-label="select" />
              </td>
              <td data-title="Name">
                <img
                  className="flx-emp-invoicing-icons"
                  src="/assets/img/shift-sign-off-demo-image.png"
                />
                Lilly
              </td>
              <td data-title="From" className="flx-employer-other-fields-td">
                12-2-2023
              </td>
              <td data-title="To" className="flx-employer-other-fields-td">
                20-4-2024
              </td>
              <td data-title="Shift" className="flx-employer-other-fields-td">
                Morning
              </td>
              <td
                data-title="Check In"
                className="flx-employer-other-fields-td"
              >
                9.0
              </td>
              <td
                data-title="Check Out"
                className="flx-employer-other-fields-td"
              >
                16.0
              </td>
              <td data-title="Break" className="flx-employer-other-fields-td">
                30m
              </td>
              <td data-title="Hours" className="flx-employer-other-fields-td">
                8
              </td>
              <td
                data-title="Employer"
                className="flx-employer-other-fields-td"
              >
                Rehab
              </td>
              <td data-title="Created" className="flx-employer-other-fields-td">
                12-Jun-24
              </td>
              <td data-title="Status" className="flx-employer-status-fields-td">
                1st Reminder
              </td>
              <td className="flx-employer-other-fields-td">
                <img
                  className=" "
                  src="/assets/img/edit-icon-pending-sign-off.svg"
                />
                <span className="flx-pending-sign-off-cancel-link">Cancel</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { requestStatus } = state;
  return { requestStatus };
}
const connectedSignOff = connect(mapStateToProps)(EmployerPendingSignOff);
export { connectedSignOff as EmployerPendingSignOff };
