import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { agencycandidateActions, categoryActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { FileUpload } from '../fileupload/FileUpload';

const AgencyCandidateAdd = props => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [candidate, setCandidate] = useState({
    name: '',
    ni_number: '',
    address: '',
    address_line2: '',
    postcode: '',
    location: '',
    gender: '',
    working_limit: '',
    email: '',
    contact_number: '',
    experience: '',
    about: '',
    active_status: 1,
    vaccination_status: '',
    other_documents: '',
    category: '',
    night_price: '',
    evening_price: 0,
    weekend_night_price: '',
    weekend_day_price: '',
    visatype: '',
    role: 3,
    is_temp_candidate: 1,
    response_status: '',
    ni_hour: 20,
  });
  const currentdate = new Date().toISOString().slice(0, 10);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [Disabled, setDisabled] = useState(false);
  const [rating, setRating] = useState(0);
  const [other_categories, setother_categories] = useState([]);
  const [isEditcategory, setisEditcategory] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addratingmodal, setaddratingmodal] = useState(false);
  const [categoriesrows, setcategoriesrows] = useState();
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [countries, setCountries] = useState();
  const [countriesData, setCountriesData] = useState([]);
  const [error, setError] = useState({ common: '' });
  let startdate = moment().subtract(15, 'years').format('YYYY-MM-DD');
  let subcategories = [];
  useEffect(() => {
    /**
     * Edit data using url query
     */
    getAllcategories(1);
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllAgencycandidate(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllAgencycandidate(1, query);
    }
    fetch('/assets/countries.json')
      .then(response => response.json())
      .then(data => {
        setCountriesData(data);
      });
  }, []);

  useEffect(() => {
    if (!candidate._id) {
      candidate.active_status = 1;
    }
    let countrylist = [];
    let data = countriesData;
    let isFound = false;
    for (let i = 0; i < data.length; i++) {
      // console.log('>>>>>>>>>>>>>>>', candidate.nationality, data[i].name)
      if (candidate.nationality == data[i].name) {
        isFound = true;
      }
      countrylist.push(
        <option
          value={data[i].value}
          // eslint-disable-next-line react/no-unknown-property
          test={isFound ? 'true' : 'false'}
          selected={candidate.nationality == data[i].name}
        >
          {data[i].name}
        </option>,
      );
    }
    if (!isFound) {
      countrylist.unshift(
        <option selected={'true'}>Select Nationality</option>,
      );
    }
    setCountries(countrylist);
  }, [countriesData]);

  useEffect(() => {
    loadaddress();
    if (candidate.other_categories) {
      setother_categories(candidate.other_categories);
    }
  }, [candidate._id]);
  // to load address according to postcode
  const loadaddress = async () => {
    const res = candidate?.postcode?.replace(/ /g, '');
    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let addresses_rows = [];
        let regex = /\s+,/g;
        let newarray = response.addresses[0].split(',');
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setCandidate({
          ...candidate,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: candidate.postcode,
        });
      }
    }
  };
  // deactivate reason change
  const deactivatereasonChange = event => {
    const { name, value } = event.target;
    setCandidate({
      ...candidate,
      [name]: value,
    });
  };
  // to hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // handler for category select
  const selectedCategory = (selectedOption, event) => {
    let name = event.name;
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      if (selectedOption[i].value != candidate.category) {
        let obj = selectedOption[i].value;
        SelectedValues.push(obj);
        setCandidate(prevState => ({
          ...prevState,
          [name]: SelectedValues,
        }));
      }
    }
  };

  /**
   * Get all candidates
   * */
  const getAllAgencycandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(agencycandidateActions.agencycandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          if (!res?.data?.rows?.[0].active_status) {
            res.data.rows[0].active_status = 1;
          }
          setCandidate(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // get upload response handler
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setCandidate(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
    }
    setshowmodalfileupload(false);
  };
  //-------get all categories for select drop doen----
  const getAllcategories = () => {
    const { dispatch } = props;
    dispatch(categoryActions.categoryList(query)).then(res => {
      let categoryData = res.data.rows;
      let categorylist = [];
      for (let i = 0; i < categoryData.length; i++) {
        categorylist.push(
          <option value={categoryData[i]._id}>{categoryData[i].name}</option>,
        );
      }
      setcategoriesrows(categorylist);
    });
  };
  // handler for state candidate details
  const handleChange = event => {
    setError({ common: '' });
    const { name, value } = event.target;

    if (name == 'contact_number' && value.length > 11) {
      return;
    }
    if (name == 'secondary_number' && value.length > 11) {
      return;
    }
    //---accept only valid price---
    if (
      name == 'morning_price' ||
      name == 'night_price' ||
      name == 'day_price' ||
      name == 'evening_price' ||
      name == 'weekend_day_price' ||
      name == 'weekend_night_price'
    ) {
      if (value == '-' || value < 0 || value == '0') {
        return;
      }
    }
    setCandidate({
      ...candidate,
      [name]: value,
    });
  };
  // handler for change postcode
  const handleChangepostcode = async event => {
    event.preventDefault();
    let { name, value } = event.target;
    let post_code = value.replace(/ /g, '');
    setCandidate({
      ...candidate,
      [name]: post_code,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        // results geometry location
        let newarray = response.addresses[0].split(',');

        let regex = /\s+,/g;
        // let result = myString.replace(regex, "");
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setCandidate({
          ...candidate,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: post_code,
        });
      }
    }
  };
  // to validate postcode
  const valid_postcode = str_postcode => {
    let postcode = str_postcode?.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    let regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return regex.test(postcode);
  };
  // to validate contact number
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // to validate Ni Number
  function validateNINumber(niNumber) {
    // Remove spaces and convert to uppercase
    niNumber = niNumber.replace(/\s/g, '').toUpperCase();
    // Regular expression to match NI number format
    const niRegex = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/;
    return niRegex.test(niNumber);
  }

  // submit action to add candidate
  const handleSubmit = event => {
    setSubmitted(true);
    setDisabled(true);
    event.preventDefault();
    const { dispatch } = props;
    if (
      candidate.name &&
      candidate.ni_number &&
      candidate.address &&
      candidate.experience &&
      candidate.contact_number &&
      candidate.email &&
      candidate.working_limit &&
      candidate.postcode &&
      candidate.gender &&
      candidate.vaccination_status &&
      candidate.night_price &&
      candidate.day_price &&
      candidate.weekend_day_price &&
      candidate.weekend_night_price &&
      candidate.morning_price &&
      candidate.dob
    ) {
      if (
        candidate.night_price <= 0 ||
        candidate.day_price <= 0 ||
        candidate.weekend_price <= 0
      ) {
        setError({ common: 'Enter a valid price' });
        setDisabled(false);
        return;
      }
      if (candidate.ni_number) {
        let checkni_number = validateNINumber(candidate.ni_number);
        if (!checkni_number) {
          setError({ common: 'Enter a valid NI Number' });
          setDisabled(false);
          return;
        }
      }
      let postcode = valid_postcode(candidate.postcode);
      if (postcode == false) {
        setError({ common: 'postcode not valid' });
        setDisabled(false);
        return;
      }
      let contact_number = valid_contact_number(candidate.contact_number);
      if (contact_number == false) {
        setError({ common: 'Phone number not valid' });
        setDisabled(false);
        return;
      }
      if (candidate.secondary_number) {
        let secondary_number = valid_contact_number(candidate.secondary_number);
        if (secondary_number == false) {
          setError({ common: 'Secondary number not valid' });
          setDisabled(false);
          return;
        }
      }
      if (candidate.dob) {
        if (candidate.dob > currentdate) {
          setError({ common: 'Choose a valid date of birth' });
          setDisabled(false);
          return;
        }
      }
      if (rating) {
        candidate.staff_rating = rating;
      }
      dispatch(agencycandidateActions.agencycandidateAdd(candidate)).then(
        () => {
          navigate('/agencycandidateslist');
          setIsLoading(false);
        },
        err => {
          setError({
            common: err && err ? err : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
          setDisabled(false);
        },
      );
    } else {
      setError({ common: 'Please complete the form!' });
      setDisabled(false);
    }
  };
  // show file upload modal open
  const showfileupload = () => {
    setshowmodalfileupload(true);
  };
  // hide file upload modal
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };
  return (
    <div className="CandidateAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card ">
          <div className=" page-header ">
            <div className="page-title-content">
              <p className="card-title">
                {candidate && candidate._id ? 'Update' : 'Add'} Agency Candidate{' '}
              </p>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.ni_number ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">NI Number / Reference Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ni_number"
                      placeholder=""
                      value={candidate.ni_number}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.ni_number && (
                      <div className="help-block">NI Number is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.name ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Name</label>
                    <input
                      type="name"
                      className="form-control"
                      name="name"
                      placeholder=""
                      value={candidate.name}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.name && (
                      <div className="help-block">Name is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.last_name ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      placeholder=""
                      value={candidate.last_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Postcode</label>
                    <input
                      type="postcode"
                      className="form-control"
                      name="postcode"
                      placeholder="postcode "
                      value={candidate.postcode}
                      onChange={handleChangepostcode}
                    />
                    {submitted && !candidate.postcode && (
                      <div className="help-block">postcode is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Visa type</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.visatype ? ' has-error' : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectDesign"
                      name="visatype"
                      value={candidate.visatype}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'Student'}>Student</option>
                      <option value={'Citizen'}>Citizen</option>
                      <option value={'PR'}>PR</option>
                      <option value={'Dependant'}>Dependant</option>
                      <option value={'Skill Worker'}>Skill Worker</option>
                    </select>
                    {submitted && !candidate.visatype && (
                      <div className="help-block">Visa type is required</div>
                    )}
                  </div>
                </div>
              </div>
              <label htmlFor="type">Address</label>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.address ? ' has-error' : '')
                    }
                  >
                    <select
                      className="form-control"
                      name="address"
                      placeholder="select address"
                      value={candidate.address}
                      onChange={handleChange}
                    >
                      <option value={''}>select address</option>
                      {PostcodeObject.addresses}
                    </select>
                    {/*Required*/}
                    {submitted && !candidate.address && (
                      <div className="help-block">address is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div className={'form-group '}>
                    <input
                      className="form-control"
                      name="address_line2"
                      placeholder="Address line 2"
                      value={candidate.address_line2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Gender</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.gender ? ' has-error' : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectDesign"
                      name="gender"
                      value={candidate.gender}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'Male'}>Male</option>
                      <option value={'Female'}>Female</option>
                    </select>
                    {submitted && !candidate.gender && (
                      <div className="help-block">Gender is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Working limit</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.working_limit
                        ? ' has-error'
                        : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectDesign"
                      name="working_limit"
                      value={candidate.working_limit}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'full time'}>Full time</option>
                      <option value={'part time'}>Part time</option>
                      <option value={'student part time'}>
                        Student term time
                      </option>
                    </select>
                    {submitted && !candidate.working_limit && (
                      <div className="help-block">
                        Working Limit is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.email ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder=""
                      value={candidate.email}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.email && (
                      <div className="help-block">Email is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.contact_number
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Mobile</label>
                    <input
                      type="number"
                      step="any"
                      className="form-control"
                      name="contact_number"
                      placeholder=""
                      value={candidate.contact_number}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.contact_number && (
                      <div className="help-block">
                        Contact Number is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.secondary_number
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Secondary Number</label>
                    <input
                      type="number"
                      className="form-control"
                      name="secondary_number"
                      placeholder=""
                      value={candidate.secondary_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Experience</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.experience ? ' has-error' : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectDesign"
                      name="experience"
                      value={candidate.experience}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'4 month'}>4 month</option>
                      <option value={'6 month'}>6 month</option>
                      <option value={'8 month'}>8 month</option>
                      <option value={'1 year'}>1 year</option>
                      <option value={'2 year'}>2 year</option>
                      <option value={'3 year'}>3 year</option>
                      <option value={'4 year'}>4 year</option>
                      <option value={'More than 4 year'}>
                        More than 4 year
                      </option>
                    </select>
                    {submitted && !candidate.experience && (
                      <div className="help-block">Experience is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <label htmlFor="type">Job role</label>
                  <div
                    className={
                      '' +
                      (submitted && !candidate.category ? ' has-error' : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectDesign"
                      name="category"
                      value={candidate.category}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      {categoriesrows}
                    </select>
                    {submitted && !candidate.category && (
                      <div className="help-block">Jobrole is required</div>
                    )}
                  </div>
                </div>
              </div>
              {candidate.category && (
                <div className="row">
                  <div className="col-md-6 pr-1">
                    <label htmlFor="type">Other Categories</label>
                    <div>
                      {candidate._id && isEditcategory && (
                        <AsyncSelect
                          name="other_categories"
                          onChange={selectedCategory}
                          cacheOptions
                          defaultOptions
                          defaultInputValue={
                            candidate &&
                            candidate['Category.name'] &&
                            candidate['Category.name']
                          }
                          isMulti
                          loadOptions={getcategorylist}
                        />
                      )}
                      {!candidate._id && !isEditcategory && (
                        <AsyncSelect
                          name="other_categories"
                          onChange={selectedCategory}
                          cacheOptions
                          defaultOptions
                          isMulti
                          defaultInputValue={
                            candidate &&
                            candidate['Category.name'] &&
                            candidate['Category.name']
                          }
                          loadOptions={getcategorylist}
                        />
                      )}
                      {candidate._id && !isEditcategory && (
                        <div className="col">
                          <div className="row">
                            <div className=" col-md-10 select_box1">
                              {' '}
                              {other_categories.map(i => {
                                subcategories.push(i.name + ' ,' + ' ');
                              })}
                              <p>{subcategories}</p>
                            </div>
                            <div
                              className="edit_text"
                              onClick={() => {
                                setisEditcategory(true);
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* ---------age and nationality -------- */}
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.dob ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">DOB</label>
                    <input
                      type="date"
                      className="form-control"
                      name="dob"
                      max={startdate}
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      placeholder="DOB"
                      value={candidate.dob}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.dob && (
                      <div className="help-block">DOB is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.nationality ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Nationality</label>
                    <select
                      className="select col-md-12 selectDesign"
                      name="nationality"
                      onChange={handleChange}
                    >
                      {countries}
                    </select>
                    {submitted && !candidate.nationality && (
                      <div className="help-block">Nationality is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <h5 className="boldtext">Price</h5>
              </div>
              <div className="row">
                <div className="col-md-2 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.morning_price
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Morning </label>
                    <input
                      type="number"
                      step="any"
                      className="form-control"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      name="morning_price"
                      placeholder=""
                      value={candidate.morning_price}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.morning_price && (
                      <div className="help-block">Moring price is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-2 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.day_price ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Day </label>
                    <input
                      type="number"
                      step="any"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="form-control"
                      name="day_price"
                      placeholder=""
                      value={candidate.day_price}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.day_price && (
                      <div className="help-block">Day price is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-2 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.night_price ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Night </label>
                    <input
                      type="number"
                      step="any"
                      className="form-control"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      name="night_price"
                      placeholder=""
                      value={candidate.night_price}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.night_price && (
                      <div className="help-block">Night_price is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-2 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.weekend_day_price
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Weekend day</label>
                    <input
                      type="number"
                      step="any"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="form-control"
                      name="weekend_day_price"
                      placeholder=""
                      value={candidate.weekend_day_price}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.weekend_day_price && (
                      <div className="help-block">
                        Weekend day price is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-2 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.weekend_night_price
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Weekend night </label>
                    <input
                      type="number"
                      step="any"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="form-control"
                      name="weekend_night_price"
                      placeholder=""
                      value={candidate.weekend_night_price}
                      onChange={handleChange}
                    />
                    {submitted && !candidate.weekend_night_price && (
                      <div className="help-block">
                        Weekend price is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">About</label>
                    <textarea
                      name="about"
                      className="form-control"
                      placeholder=""
                      value={candidate.about}
                      onChange={handleChange}
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type" style={{ marginRight: '20px' }}>
                      Status
                    </label>
                    <label style={{ marginRight: '20px' }}>
                      <input
                        style={{ marginRight: '5px' }}
                        type="radio"
                        value={1}
                        checked={candidate.active_status == 1}
                      />
                      Active
                    </label>
                  </div>
                </div>
              </div>
              {candidate.active_status == 0 && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        {' '}
                        Select reason for deactivate
                      </label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="deactive_reason"
                        value={candidate.deactive_reason}
                        onChange={deactivatereasonChange}
                      >
                        <option value={''}>Select</option>
                        <option value={'Accidentally added shift'}>
                          Temporary deactivate{' '}
                        </option>
                        <option value={'Emergency cancellation'}>
                          Emergency deactivate
                        </option>
                        <option value="Other"> Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.vaccination_status
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Are you Vaccinated</label>
                    <select
                      className="select col-md-12 selectDesign"
                      name="vaccination_status"
                      value={candidate.vaccination_status}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'First Dose'}>First Dose</option>
                      <option value={'Second Dose'}>Second Dose</option>
                      <option value={'Partially Vaccinated'}>
                        Partially Vaccinated
                      </option>
                      <option value={'Fully Vaccinated'}>
                        Fully Vaccinated
                      </option>
                      <option value={'Not Vaccinated'}>Not Vaccinated</option>
                    </select>
                    {submitted && !candidate.vaccination_status && (
                      <div className="help-block">
                        vaccination_status is required
                      </div>
                    )}
                  </div>
                </div>
                {/* //----------ni hours start -------- */}
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.nationality ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Ni Hour</label>
                    <input
                      type="number"
                      step="any"
                      onKeyDown={e =>
                        ['e', 'E', '+', '-'].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="form-control"
                      name="ni_hour"
                      placeholder=""
                      value={candidate.ni_hour}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {/* //------response status --------- */}
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.service_type ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">Service Sector</label>
                    <select
                      className="select col-md-12 selectDesign"
                      name="service_type"
                      value={candidate.service_type}
                      onChange={handleChange}
                    >
                      <option value=""> select</option>
                      <option value={'Hospitality'}>Hospitality</option>
                      <option value={'Health Care'}>Health Care</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !candidate.response_status
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Response status</label>
                    <input
                      type="text"
                      className="form-control"
                      name="response_status"
                      placeholder=""
                      value={candidate.response_status}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <label htmlFor="type">Upload Profile image</label>
                  <div className="row m-0">
                    <div
                      className="col-md-10  filenames  "
                      style={{ maxWidth: 'none' }}
                    >
                      <div className="row filerow">
                        <div className="col-md-8" style={{ lineHeight: 2 }}>
                          {' '}
                          {candidate && candidate.profileimage
                            ? candidate.profileimage
                            : 'no image uploaded'}{' '}
                        </div>
                        <div className="col-md-3 float-right">
                          {' '}
                          <div
                            className="changebutton m-2 p-3"
                            onClick={showfileupload}
                          >
                            {' '}
                            Change{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col align-self-center">
                      <div style={{ marginLeft: '10px', marginBottom: 10 }}>
                        {candidate && candidate.profileimage ? (
                          <img
                            className=" round-emp-image"
                            src={apiConstants.imgUrl + candidate.profileimage}
                          />
                        ) : (
                          <img
                            className=" round-emp-image"
                            src="/assets/img/recsy-fav-icon.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button
                      disabled={Disabled}
                      onClick={handleSubmit}
                      className="btn bluebutton pl-2 pr-2"
                    >
                      {candidate && candidate._id ? 'Update' : 'Add'} Candidate
                      {props.requestStatus ===
                        reducerConstants.CANDIDATE_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'Profile image'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addratingmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setaddratingmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <label htmlFor="type">Rating</label>
                            <div className="star-rating">
                              {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                  <button
                                    type="button"
                                    key={index}
                                    className={
                                      index <= candidate.staff_rating
                                        ? 'on'
                                        : 'off'
                                    }
                                    onClick={() => {
                                      setRating(index),
                                        setCandidate({
                                          ...candidate,
                                          staff_rating: index,
                                        });
                                    }}
                                  >
                                    <span className="star">&#9733;</span>
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="type">Review</label>
                          <textarea
                            className="form-control col-md-12"
                            name="staff_rating_note"
                            placeholder="Review"
                            value={candidate.staff_rating_note}
                            onChange={handleChange}
                            rows="4"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end mt-20">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="button_delete_close width_100"
                            onClick={() => setaddratingmodal(false)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for category list in select
const getcategorylist = category =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      // body: JSON.stringify({ searchKey: inputValue,category:['Water purifier','Water treatment plan']}),
      body: JSON.stringify({ status: 1, select: ['_id', 'name'] }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            // for(let j=0 ;j<data.rows.length;j++){
            //   if(data.rows[j] == category){
            //     delete data.rows[j]
            //   }
            // }

            resolve(
              data.rows
                .filter(id => id._id != category)
                .map(({ _id, name }) => ({
                  value: _id,
                  label: name,
                })),
            );
          }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedAgencyCandidateAdd =
  connect(mapStateToProps)(AgencyCandidateAdd);
export { connectedAgencyCandidateAdd as AgencyCandidateAdd };
