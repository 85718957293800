import React from 'react';

export class Navbar extends React.Component {
  render() {
    return (
      <div>
        <div className="header-bar col-md-12">
          <div className="header-right">
            <a href="/">Home</a>
            <a href="/">Categories </a>
            {/* <a >General Services </a>
                    <a >Featured </a> */}
            <a href="/">Contact us </a>
            <a href="/">About US </a>
          </div>
          <div>Logout</div>
        </div>
      </div>
    );
  }
}
