export const AccountIDPrompt = ({
  onCancel,
  onGoogleLogin,
  onMicrosoftLogin,
}) => {
  const handleClick = onMicrosoftLogin ? onMicrosoftLogin : onGoogleLogin;
  const loginType = onMicrosoftLogin ? 'Microsoft' : 'Google';

  return (
    <div className="modal-dialog flx-modal-popup mainmodal" role="document">
      <div className="modal-content ">
        <div className="modal-header">
          <h5 className="flx-email-change-req-modal-title" id="modalLabel"></h5>
          <button
            type="button"
            className="close flx-closebutton-popup  my-1"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onCancel}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body pr-4">
          <p
            className="flx-popup-body flx-popup-body-email-change-req  pl-2 pt-3"
            style={{ fontWeight: '500', fontSize: '14px' }}
          >
            This will link your {loginType} account to your login. Would you
            like to continue ?
          </p>
        </div>
        <div
          className="flx-popup-bottom rightHandside "
          style={{ padding: '16px' }}
        >
          <button className="flx-popup-email-change-cancel" onClick={onCancel}>
            No
          </button>
          <button
            type="button"
            className="btn btn-sm flx-popup-button "
            onClick={handleClick}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
