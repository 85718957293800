import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { blockcandidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
const CandidateblockAdd = props => {
  const { id } = useParams();

  const [blockcandidate, setBlockcandidate] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (id != undefined) {
      query._id = id;
      setQuery(query);
      getAllBlockcandidate(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllBlockcandidate(1, query);
    }
  }, []);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  /**
   * Get all allocatecandidates
   * */
  const getAllBlockcandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(blockcandidateActions.blockcandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setBlockcandidate(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;

    if (blockcandidate.candidate_id && blockcandidate.employer_id) {
      setIsLoading(true);
      dispatch(blockcandidateActions.blockcandidateAdd(blockcandidate)).then(
        () => {
          setIsLoading(false);
          props.showsavemodal(false);
        },
        err => {
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          hideError();
          setIsLoading(false);
        },
      );
    }
  };

  const selecteduser = selectedOption => {
    setBlockcandidate(prevState => ({
      ...prevState,
      candidate_id: selectedOption.value,
    }));
  };

  const selectedcarehome = selectedOption => {
    setBlockcandidate(prevState => ({
      ...prevState,
      employer_id: selectedOption.value,
    }));
  };

  return (
    <div className="AllocatecandidateAdd">
      <div className="col-md-12 d-flex justify-content-center">
        {isLoading && <LoadingModal />}
        <div className="card col-md-12">
          <div className=" page-header ">
            <div className="page-title-content">
              <p className="card-title">Block Candidate</p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !blockcandidate.candidate_id
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">
                      user : <span>*</span>
                    </label>
                    <AsyncSelect
                      className="selectuser"
                      name="candidate_id"
                      onChange={selecteduser}
                      cacheOptions
                      defaultOptions
                      loadOptions={getcandidatelist}
                    />
                    {submitted && !blockcandidate.candidate_id && (
                      <div className="help-block">user is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !blockcandidate.employer_id
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">
                      carehome : <span>*</span>
                    </label>
                    <AsyncSelect
                      className="selectcarehome"
                      name="employer_id"
                      onChange={selectedcarehome}
                      cacheOptions
                      defaultOptions
                      loadOptions={getcarehomelist}
                    />
                    {submitted && !blockcandidate.employer_id && (
                      <div className="help-block">carehome is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="float-right">
                      <button
                        className="btn btn-primary"
                        data-cy="rejectcandidate"
                      >
                        Save
                        {props.requestStatus ===
                          reducerConstants.BLOCKCANDIDATE_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        isverified: 1,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.candidate.data.rows.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcarehomelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus } = state.candidateblock;
  return {
    requestStatus,
  };
}

const connectedCandidateblockAdd = connect(mapStateToProps)(CandidateblockAdd);
export { connectedCandidateblockAdd as CandidateblockAdd };
