import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

let user_role = '';

const AttendaceBloackbooking = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [AttendanceLoading, setAttendanceLoading] = useState(false);
  const [ShiftsData, setShiftsData] = useState({ rows: [] });
  const [employerRows, setEmployerRows] = useState([]);

  const [Isemployer, setIsemployer] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [shiftId, setshiftId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [shiftIdList, setShiftIdList] = useState([]);

  const [fromdate, setfromdate] = useState(
    moment().subtract(30, 'd').format('YYYY-MM-DD'),
  );
  const [todate, settodate] = useState(new Date().toISOString().slice(0, 10));

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 30,
    },
    // searchKey: "",
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        // employer = user.employer_id;
        setIsemployer(true);
      }
    }
    if (props.shifts_Ids) {
      getAllShifts();
    }

    console.log(
      'beforeeeeee',
      moment().subtract(30, 'd').format('YYYY-MM-DD'),
      fromdate,
    );
  }, [props.shifts_Ids]);

  useEffect(() => {
    if ((fromdate || todate) && !props.shifts_Ids) {
      let newquery = query;
      newquery.from_date = fromdate;
      newquery.end_date = todate;

      getAllShifts(1, newquery);
    }
  }, [
    fromdate,
    todate,
    query.employer_id,
    query.candidate_id,
    query.agency_id,
  ]);

  useEffect(() => {
    if (shiftIdList.length) {
      getAllShifts(1);
    }
  }, [shiftIdList]);

  useEffect(() => {
    if (props.shiftId) {
      setshiftId(props.shiftId);
      setQuery({ ...query, _id: props.shiftId });
    }
  }, []);

  /**
   * Get all shift
   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // setQuery(query);
    setIsLoading(true);
    queryNew.pageVo.shiftIdList = shiftIdList;

    //----get  employer id from localstorage ------
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    if (query.employer_id) {
      queryNew.employer_id;
    }
    if (props.shifts_Ids) {
      // queryNew._id=props.shifts_Ids
      queryNew.pageVo._ids = props.shifts_Ids;
    }

    if (fromdate && Isemployer) {
      queryNew.from_date = fromdate;
    }
    if (todate && Isemployer) {
      queryNew.end_date = todate;
    }

    // queryNew.is_attended = 0;
    queryNew.isAttendanceList = true;
    queryNew.is_attended = 0;
    // queryNew.is_accepted=1

    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);

        for (let i = 0; i < res?.data?.rows?.length; i++) {
          res.data.rows[i].is_generate_time_sheet = true;
          res.data.rows[i].is_attended = 1;
          res.data.rows[i].check_in = res.data.rows[i].check_in_time_string;
          res.data.rows[i].check_out = res.data.rows[i].check_out_time_string;
          res.data.rows[i].check_in_time_string = res?.data?.rows?.[i]
            .attendance_check_in
            ? res.data.rows[i].attendance_check_in
            : res.data.rows[i].check_in_time_string;
          res.data.rows[i].check_out_time_string = res?.data?.rows?.[i]
            .attendance_check_out
            ? res.data.rows[i].attendance_check_out
            : res.data.rows[i].check_out_time_string;
        }
        setShiftsData({ rows: res.data.rows });
        setListAndPagination(res.data);

        // setShiftsData(res.data)
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const selectedallocatecandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };

  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };
  const agency_change = selectedOption => {
    setQuery(prevState => ({
      ...prevState,
      agency_id: selectedOption.value,
      candidate_id: 0,
    }));
  };

  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setQuery(
      prevState => ({
        ...prevState,
        [name]: selectedOption.value,
      }),
      // selectedcategoryy: selectedOption
    );
  };

  const handleChange = (event, j, shiftsdataa) => {
    const { name, value, checked } = event.target;

    if (name == 'check_in_time_string') {
      shiftsdataa.rows[j].check_in_time_string = value;
    } else if (name == 'check_out_time_string') {
      shiftsdataa.rows[j].check_out_time_string = value;
    } else if (name == 'is_generate_time_sheet') {
      if (checked == true) {
        shiftsdataa.rows[j].is_attended = 1;
        shiftsdataa.rows[j].absent = 0;
      }
      shiftsdataa.rows[j].is_generate_time_sheet = !!checked;
    } else if (name == 'absent') {
      if (checked == true) {
        shiftsdataa.rows[j].is_generate_time_sheet = false;
        shiftsdataa.rows[j].is_attended = -1;
        shiftsdataa.rows[j].absent = !!checked;
      } else {
        shiftsdataa.rows[j].absent = !!checked;
        shiftsdataa.rows[j].is_attended = 1;
      }
    }

    setShiftsData(shiftsdataa);
    setListAndPagination(shiftsdataa);
  };

  /**
   *
   *  set pagination and list rows
   */

  //   to remove attendance item from list

  //---to remove item from attendace list ---
  const removShiftItem = (data, j) => {
    let newshiftShiftsData = data;

    newshiftShiftsData.rows.splice(j, 1);
    setShiftsData(newshiftShiftsData);
    setListAndPagination(newshiftShiftsData);
  };

  //----call add attendace----
  const handleSubmitAttendance = async () => {
    setAttendanceLoading(true);

    if (ShiftsData && ShiftsData.rows && ShiftsData.rows.length > 0) {
      //----to get employer id from local storage---
      let employer_id = '';
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.employer_id) {
          // eslint-disable-next-line no-unused-vars
          employer_id = user.employer_id;
        }
      }
      let queryNew = [];
      let ShiftsDataNew = ShiftsData.rows;

      for (let i = 0; i < ShiftsDataNew.length; i++) {
        //  if (ShiftsDataNew[i]._id) {
        if (
          !ShiftsDataNew[i].check_in_time_string ||
          !ShiftsDataNew[i].check_out_time_string
        ) {
          alert('Please add check in and check out time');
          return;
        }
        if (ShiftsDataNew[i].is_attended == 1) {
          queryNew[i] = {
            _id: ShiftsDataNew[i]._id,
            is_generate_time_sheet: ShiftsDataNew[i].is_generate_time_sheet,
            check_in_time_string: ShiftsDataNew[i].check_in_time_string,
            check_out_time_string: ShiftsDataNew[i].check_out_time_string,
            check_in_date_string: ShiftsDataNew[i].check_in_date_string,
            check_out_date_string: ShiftsDataNew[i].check_out_date_string,
            is_attended: ShiftsDataNew[i].is_attended,
          };
        } else {
          queryNew[i] = {
            _id: ShiftsDataNew[i]._id,
            is_generate_time_sheet: ShiftsDataNew[i].is_generate_time_sheet,
            // check_in_time_string: ShiftsDataNew[i].check_in_time_string,
            // check_out_time_string: ShiftsDataNew[i].check_out_time_string,
            // check_in_date_string: ShiftsDataNew[i].check_in_date_string,
            // check_out_date_string: ShiftsDataNew[i].check_out_date_string,
            is_attended: ShiftsDataNew[i].is_attended,
          };
        }
      }

      const { dispatch } = props;
      await dispatch(shiftsActions.addAttendance(queryNew)).then(
        () => {
          setAttendanceLoading(false);

          if (props.showAttendancemodal) {
            props.showAttendancemodal(false);
          }

          if (props.showTimesheetModal) {
            props.showTimesheetModal(false);
          }
        },
        () => {
          setIsLoading(false);
          setAttendanceLoading(false);
        },
      );
    }
  };

  const setListAndPagination = ShiftsData => {
    let employerList = [];
    for (
      let j = 0;
      ShiftsData && ShiftsData.rows && j < ShiftsData.rows.length;
      //    j < 10;
      j = j + 1
    ) {
      employerList.push(
        <tr key={j}>
          {/* cursor chages to default token FLXR-88  */}
          <td style={{ cursor: 'default' }}>
            {ShiftsData.rows[j] &&
            ShiftsData.rows[j]['Candidate.profileimage'] ? (
              <img
                className=" round-emp-image"
                src={
                  apiConstants.imgUrl +
                  ShiftsData.rows[j]['Candidate.profileimage']
                }
              />
            ) : (
              <img
                className="staff_img"
                src="/assets/img/recsy-fav-icon.png"
              ></img>
            )}
            {ShiftsData.rows[j]['Candidate.name']
              ? ShiftsData.rows[j]['Candidate.name'] +
                ' ' +
                (ShiftsData.rows[j]['Candidate.last_name']
                  ? ShiftsData.rows[j]['Candidate.last_name']
                  : '')
              : ShiftsData.rows[j]['Agencycandidate.name'] +
                '(' +
                ShiftsData.rows[j]['Agency.company_name'] +
                ')'}
          </td>
          <td style={{ cursor: 'default' }}>
            {moment(ShiftsData.rows[j].check_in_date_string).format(
              'YYYY-MMM-DD',
            )}
          </td>

          <td style={{ cursor: 'default' }}>
            <div>{ShiftsData.rows[j].section_type}</div>
            <div style={{ fontSize: '10px' }}>
              {/* {ShiftsData.rows[j].check_in_time_string} -{' '}
              {ShiftsData.rows[j].check_out_time_string} */}
              {ShiftsData.rows[j].check_in} - {ShiftsData.rows[j].check_out}
            </div>
          </td>

          <td>
            {/* 22 */}
            <input
              type="time"
              className="form-control"
              name="check_in_time_string"
              placeholder="from time"
              value={ShiftsData.rows[j].check_in_time_string}
              onChange={e => handleChange(e, j, ShiftsData)}
              // disabled
            />
          </td>

          <td>
            {/* 22 */}
            <input
              type="time"
              className="form-control"
              name="check_out_time_string"
              placeholder="from time"
              value={ShiftsData.rows[j].check_out_time_string}
              onChange={e => handleChange(e, j, ShiftsData)}
              // disabled
            />
          </td>
          <td>
            <input
              // type="time"
              type="checkbox"
              className="form-control"
              style={{ height: '15px' }}
              name="is_generate_time_sheet"
              checked={ShiftsData.rows[j].is_generate_time_sheet}
              onChange={e => handleChange(e, j, ShiftsData)}
              // disabled
            />
          </td>
          <td>
            <input
              // type="time"
              type="checkbox"
              className="form-control"
              style={{ height: '15px' }}
              name="absent"
              checked={ShiftsData.rows[j].absent}
              onChange={e => handleChange(e, j, ShiftsData)}
              // disabled
            />
          </td>
          <td>
            {/* {ShiftsData.rows[j].check_out_time_string} */}
            <div
              className="ml-2"
              onClick={() => {
                removShiftItem(ShiftsData, j);
              }}
            >
              {/* <button className="btn td_btn_save ">save</button> */}

              <i className=" fa fa-times-circle red fs-18"></i>
            </div>
          </td>
        </tr>,
      );
    }
    setEmployerRows(employerList);
    setShiftsData(ShiftsData);
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '45px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };

  return (
    <div className="employerList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8">
                  <div className="page-title">
                    <h4 className="font-weight-light">
                      Add Attendance and Timesheet{' '}
                    </h4>
                  </div>
                </div>
              </div>

              {/* -------------------hidefilter props---allshiftslist------------------ */}

              {!props.hidefilter == 1 && (
                <div className="row mt-20 mb-10">
                  <div className="col-md-6">
                    <h4 className="font-weight-bold"></h4>
                  </div>
                  <div className="col-md-5">
                    {/* <button className="btn btn-green">Upload excel</button> */}
                  </div>
                </div>
              )}
              {(!props.hidefilter == 1 || props.hideemployerfilter == 1) && (
                <div className="row d-flex">
                  {!props.hidefilter == 1 && (
                    <div className="col-md-5 d-flex align-items-end">
                      <div className="d-flex flex-column ">
                        <div>
                          {' '}
                          <label>From date</label>
                        </div>
                        <div>
                          <input
                            type="date"
                            className="form-control form-group-50px"
                            name="from_date"
                            placeholder="From"
                            value={fromdate}
                            onChange={event => {
                              let { value } = event.target;

                              // value = value.toString()
                              // let timesplitted = value.split("T");
                              let newquery = { ...query };
                              delete newquery._id;
                              newquery.from_date = value;
                              setQuery(newquery);
                              setfromdate(value);
                              //return timesplitted[0];
                            }}
                          />
                        </div>
                      </div>

                      <div className="d-flex flex-column ml-30">
                        <div>
                          <label>To date</label>
                        </div>
                        <div>
                          <input
                            type="date"
                            className="form-control form-group-50px"
                            name="end_date"
                            placeholder="From"
                            value={todate}
                            // value={fromdate}
                            onChange={event => {
                              let { value } = event.target;
                              let newquery = { ...query };
                              delete newquery._id;
                              newquery.end_date = value;
                              setQuery(newquery);
                              settodate(value);
                              //return timesplitted[0];
                            }}
                          />
                        </div>
                      </div>

                      {!props.hideemployerfilter == 1 && !Isemployer && (
                        <div className="col-md-6 ">
                          <AsyncSelect
                            styles={customStyles}
                            name="employer_id"
                            onChange={setEmployer}
                            placeholder={'select Employer'}
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerlist}
                            noOptionsMessage={() => 'No results found'}
                            className="selectemployer"
                          />
                        </div>
                      )}
                      {(user_role == 1 || user_role == 6 || user_role == 4) && (
                        <div className="col-6">
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedcandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getcandidatelist}
                            className="selectcandidate"
                          />
                        </div>
                      )}
                      {user_role != 1 && user_role != 6 && user_role != 4 && (
                        <div className="col-6">
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedallocatecandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            className="allocatedcandiadtee"
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getshiftscandidatelist}
                          />
                        </div>
                      )}
                      {
                        <div className="col-6">
                          <AsyncSelect
                            styles={customStyles}
                            name="agency_id"
                            onChange={agency_change}
                            placeholder="select agency"
                            cacheOptions
                            defaultOptions
                            className="selectagency"
                            // isMulti
                            noOptionsMessage={() => 'No results found'}
                            loadOptions={getAgencylist}
                          />
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="card-body  table-responsive main-card-body ">
              {!isLoading && (
                <table className="table flx-custom-table">
                  <thead>
                    <tr>
                      {/* <th className="border-top1">Id</th> */}
                      <th>Name</th>
                      <th>Date</th>
                      <th>Section</th>
                      <th>Check in time</th>
                      <th>Check out time</th>
                      <th>Generate timesheet</th>
                      <th>Absent</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>{employerRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}

              {ShiftsData &&
                ShiftsData.rows &&
                ShiftsData.rows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
            {console.log('shiftdataa', ShiftsData)}

            {/* //save attendace blue button--- */}
            {ShiftsData && ShiftsData.rows && ShiftsData.rows.length > 0 && (
              <div className="row">
                <div className=" col-md-12 d-flex justify-content-end">
                  <button
                    data-cy="addtimesheet"
                    className="btn btn-primary-blue "
                    onClick={handleSubmitAttendance}
                  >
                    Save
                    {AttendanceLoading && (
                      <div className="lds-ring ml-2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            )}
            {/* {Error && <p className="red">{Error}</p>} */}
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                paginationData={ShiftsData}
              />
            </div>
            <span style={{ color: '#808080', fontSize: '9px', height: '15px' }}>
              {' '}
              * max 30 items are displayed{' '}
            </span>
            <span style={{ color: '#808080', fontSize: '9px' }}>
              {' '}
              * Each page is verified seperately
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(item => ({
                value: item['_id'],
                label: `${item['name']} ${item['last_name'] ?? ''}`,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });

const getshiftscandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    let employer_id;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, name: 'ALL' };
            let newArray = [AllElement].concat(data.rows);
            resolve(
              newArray.map(i => ({
                value: i['_id'],
                label:
                  i['name'] +
                  (i['last_name'] ? i['last_name'] : '') +
                  (i['Category'] || i['city'] ? '(' : '') +
                  (i['Category'] ? i['Category']['name'] : '') +
                  (i['Category'] && i['city'] ? '-' : '') +
                  (i['city'] ? i['city'] : '') +
                  (i['Category'] || i['city'] ? ')' : ''),
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });

const getAgencylist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, select: ['_id', 'company_name'] }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, company_name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });

const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      // body: JSON.stringify({ searchKey: inputValue,category:['Water purifier','Water treatment plan']}),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // Check if there are any data results
          if (data && data.rows && data.rows.length > 0) {
            let AllElement = { _id: 0, company_name: 'ALL' };
            let newArray = [AllElement, ...data.rows];
            resolve(
              newArray.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          } else {
            // No options found
            resolve([]);
          }
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  // const { requestStatus, ShiftsData } = state.employer;
  return {
    // requestStatus,
    // ShiftsData
  };
}

const connectedAttendaceBloackbooking = connect(mapStateToProps)(
  AttendaceBloackbooking,
);
export { connectedAttendaceBloackbooking as AttendaceBloackbooking };
