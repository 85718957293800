import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { demoActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, reducerConstants } from '../_constants';
const DemoAdd = props => {
  const { id } = useParams();

  const [demo, setDemo] = useState({
    name: '',
    description: '',
  });

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    /**
     * Edit data using url query
     */
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllDemo(1, query);
    }

    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllDemo(1, query);
    }
  }, []);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  /**
   * Get all demos
   * */
  const getAllDemo = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(demoActions.demoList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setDemo(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setDemo({
      ...demo,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsLoading(true);
    const { dispatch } = props;
    if (demo.name) {
      dispatch(demoActions.demoAdd(demo)).then(
        () => {
          props.history.goBack();
          setIsLoading(false);
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    }
  };

  return (
    <div className="DemoAdd">
      <div className="col-md-8 col-md-offset-2">
        {isLoading && <LoadingModal />}

        <div className="card">
          <div className="card-header page-header ">
            <div className="card-header page-header">
              <div className="page-title-content">
                <h4 className="card-title">Add demo </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Demo name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={demo.name}
                      onChange={handleChange}
                    />
                    {/*Required*/}{' '}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="parent">parent</label>
                    <select
                      className="form-control"
                      name="parent"
                      value={demo.parent}
                      onChange={handleChange}
                    >
                      <option value=""> Please select parent</option>
                      <option value="1">1</option>
                      <option value="2"> 2</option>
                    </select>
                    {/*Required*/}{' '}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button className="btn btn-primary btn-wd">
                      Save
                      {props.requestStatus ===
                        reducerConstants.DEMO_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, demoData } = state.demo;
  return {
    requestStatus,
    demoData,
  };
}

const connectedDemoAdd = connect(mapStateToProps)(DemoAdd);
export { connectedDemoAdd as DemoAdd };
