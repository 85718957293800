export const EmpDashboard = () => {
  return (
    <>
      {/* Welcome Header */}
      <div className="flx-dashboard-welcome-section text-center container-fluid">
        <p className="flx-dashboard-welcome-text">
          Welcome,
          <span className="flx-dashboard-company-name"> My Company.</span>
        </p>
        <p className="flx-dashboard-p-text">
          Discover your next pool of skilled workforce here
        </p>
        <a className="btn flx-recs-primary-btn btn-lg" href="#" role="button">
          Start Your First Job Post
        </a>
      </div>
      <div className="container">
        <div className="flx-dashboard-progress-section text-center overlay-text">
          <button type="button" className="close" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <p className="flx-profile-text">
            Your profile is 75% complete. Please complete your profile to
            accelerate your hiring process.{' '}
            <a href="#" className="flx-recs-link">
              Complete Now
            </a>
          </p>
          <p className="flx-link-text-notice">
            Please add your address to complete
          </p>
          <div className="progress flx-recs-progress-bar">
            <div
              className="progress-bar flx-recs-progress-bar-fill"
              role="progressbar"
              style={{ width: '75%' }}
              aria-valuenow={75}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </div>
        {/* Services Section */}
        <h2 className="flx-get-started">Get start with</h2>
        <div className="row text-center">
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a href="#" className="btn flx-dashboard-btn-1">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a
                  href="#"
                  className="btn flx-recs-primary-btn flx-dashboard-btn-4"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a href="#" className="btn flx-dashboard-btn-2">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a
                  href="#"
                  className="btn flx-recs-primary-btn flx-dashboard-btn-4"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a href="#" className="btn flx-dashboard-btn-2">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card h-100 flx-dashboard-sections">
              <div className="card-body">
                <h5 className="card-title">Agency Shift</h5>
                <p className="card-text">
                  Are you looking for Short-term, flexible candidates for
                  specific needs or projects.
                </p>
                <a href="#" className="btn flx-dashboard-btn-1">
                  Get Started
                </a>
              </div>
            </div>
          </div>
          {/* Repeat for other services */}
          {/* Remember to replace with your actual content */}
        </div>
        {/* Assistance Section */}
        {/*<div class="card mt-4">
    <div class="card-body text-center">
        <h5 class="card-title">We're here to assist you!</h5>
        <p class="card-text">If you have any questions or concerns regarding the portal, please don't hesitate to reach out to us.</p>
        <a href="#" class="btn btn-primary">Call Us</a>
    </div>
      </div>*/}
        <div className="row">
          <div className="col-12 ">
            <div className="p-3 border flx-assistance">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>We&lsquo;re here to assist you!</h5>
                  <p>
                    If you have any questions or concerns regarding the portal,
                    please don&lsquo;t hesitate to reach out to us.
                  </p>
                </div>
                <div>
                  <button type="button" className="btn btn-flx-secondary">
                    Call Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
