export function sample(
  state = { requestStatus: 0, sampleData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'SAMPLE_SUCCESS':
      return {
        ...state,
        requestStatus: 'SAMPLE_SUCCESS',
        requestSampleStatus: 'SAMPLE_SUCCESS',
        sampleData: action.data.data,
        data: action.data,
      };

    case 'SAMPLE_FAILURE':
      return {
        ...state,
        requestStatus: 'SAMPLE_FAILURE',
        requestSampleStatus: 'SAMPLE_FAILURE',
        sampleData: {},
        data: action.data,
      };

    case 'SAMPLE_REQUEST':
      return {
        ...state,
        requestStatus: 'SAMPLE_REQUEST',
        requestSampleStatus: 'SAMPLE_REQUEST',
        data: action.data,
      };

    default:
      return state;
  }
}
