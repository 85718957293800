import { SettingsAgencyOtherBenefits } from './EmployerSettings/SettingsAgencyOtherBenefits';
import { SettingsAgencyPriceTable } from './EmployerSettings/SettingsAgencyPriceTable';
import { SettingsAgencyShiftDefault } from './EmployerSettings/SettingsAgencyShiftDefault';
import { SettingsAgencyYourShift } from './EmployerSettings/SettingsAgencyYourShift';
import { SettingsBusUnitCreate } from './EmployerSettings/SettingsBusUnitCreate';
import { SettingsBusUnitEdit } from './EmployerSettings/SettingsBusUnitEdit';
import { SettingsCompanyInfo } from './EmployerSettings/SettingsCompanyInfo';
import { SettingsConnectMobApp } from './EmployerSettings/SettingsConnectMobApp';
import { SettingsUser } from './EmployerSettings/SettingsUser';
export const EmpSettingsMain = () => {
  const currentPage = 9;
  return (
    <>
      <div className="">{currentPage == 1 && <SettingsUser />}</div>
      <div className="">{currentPage == 2 && <SettingsBusUnitCreate />}</div>
      <div className="">{currentPage == 3 && <SettingsBusUnitEdit />}</div>
      <div className="">
        {currentPage == 4 && <SettingsAgencyShiftDefault />}
      </div>
      <div className="">{currentPage == 5 && <SettingsAgencyYourShift />}</div>
      <div className="">
        {currentPage == 6 && <SettingsAgencyOtherBenefits />}
      </div>
      <div className="">{currentPage == 7 && <SettingsAgencyPriceTable />}</div>
      <div className="">{currentPage == 8 && <SettingsConnectMobApp />}</div>
      <div className="">{currentPage == 9 && <SettingsCompanyInfo />}</div>
    </>
  );
};
