import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { components } from 'react-select';

import AsyncSelect from 'react-select/async';
import { businessunitActions } from '../_actions';
import { apiConstants, roleConstant } from '../_constants';
import { authHeader } from '../_helpers';
import styles from './AllShiftList.module.css';
import { ApproveSignOffPopup } from './ApproveSignOffPopup';
import { ShiftSignOffCancelPopup } from './ShiftSignOffCancelPopup';
import { ShiftSignOffReminderPopup } from './ShiftSignOffReminderPopup';

let user_role = '';
let employer_id = '';
const PendingSignOff = () => {
  const [showModal, setShowModal] = useState('');
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState([]);

  const [filteredEmployers, setFilteredEmployers] = useState([]);
  const [businessUnitFilterRows, setBusinessUnitFilterRows] = useState([]);
  const [allBusinessUnits, setAllBusinessUnits] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
  // const [shiftsData, setShiftsData] = useState({ rows: [] });

  const dispatch = useDispatch();

  let isfirsttime = useRef(true);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
      }
      if (user.role == roleConstant.ADMIN) {
        setisAdmin(true);
      }
      if (user.role == roleConstant.STAFF) {
        setisStaff(true);
      }
      getBusinessUnits(user.employer_id);
    }
  }, []);
  console.log(user_role, 'user_role', employer_id, isAdmin, isStaff);

  const customStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      background: '#f1f2f4',
      borderRadius: '12px',
      padding: '0px 8px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
      fontWeight: '500',
      overflow: 'hidden',
      color: 'black',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),

    menuList: base => ({
      ...base,
      backgroundColor: '#ffffff',
      width: '200px',
      boxShadow: '0px 4px 19px 0px #00000014',
      borderRadius: '10px',
    }),
    multiValue: base => ({
      ...base,
      borderRadius: '12px',
      display: 'inline-flex',
      backgroundColor: 'none',
      alignItems: 'center',
      margin: '2px',
    }),
    multiValueLabel: base => ({
      ...base,
      fontSize: '10px',
      minWidth: '100px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: 'none',
    }),
    multiValueRemove: base => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      borderRadius: '12px',
      display: 'flex',
      alignItems: 'flex-start',
    }),
    dropdownIndicator: base => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#ffffff'
        : // : state.isFocused
          // ? '#ffffff'
          '#ffffff',
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000000',
    }),
  };

  // get checkbox
  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label style={{ paddingLeft: '5px' }}>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    let updatedQuery = { ...query, [name]: value };
    if (name === 'from_date' || name === 'to_date') {
      const fromDate =
        name === 'from_date' ? new Date(value) : new Date(query.from_date);
      const toDate =
        name === 'to_date' ? new Date(value) : new Date(query.to_date);

      if (fromDate > toDate) {
        alert('From date cannot be greater than To date');
        updatedQuery = { ...query, [name]: '' };
        setQuery(updatedQuery);
        return;
      }
    }
    isfirsttime.current = false;
    setQuery(updatedQuery);
    const { from_date, to_date } = updatedQuery;
    getEmployers(value, from_date, to_date);
  };

  const getEmployers = (inputvalue, from_date, to_date) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getemployers', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          from_date: from_date,
          to_date: to_date,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            if (data && data.rows && data.rows.length > 0) {
              let employersData = data.rows.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              }));
              resolve(employersData);
              setFilteredEmployers(employersData);
            } else {
              reject([]);
            }
          }),
        )
        .catch(reject);
    });

  const handleEmployer = selectedOption => {
    setSelectedEmployer(selectedOption);
    if (selectedOption.length > 0) {
      const selectedValues = selectedOption.map(option => option.value);
      setQuery(prevState => ({
        ...prevState,
        employer_id: selectedValues,
      }));
      getAllBusinessUnitOptions(selectedValues);
    }
  };

  useEffect(() => {
    getBusinessUnits();
    getEmployers();
  }, []);

  // get all business units
  const getBusinessUnits = inputvalue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getbusinessunitList', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            if (data && data.rows && data.rows.length > 0) {
              const options = data.rows.map(({ _id, name }) => ({
                value: _id,
                label: name,
              }));
              setAllBusinessUnits(options);
              setBusinessUnitFilterRows(options);
              resolve(options);
            } else {
              reject([]);
            }
          }),
        )
        .catch(reject);
    });

  // get all business unit for filter based on Employer
  const getAllBusinessUnitOptions = employer_id => {
    return new Promise(resolve => {
      dispatch(
        businessunitActions.businessunitList({
          employer_id:
            employer_id && employer_id ? employer_id.join(',') : employer_id,
          status: 1,
          select: ['_id', 'name'],
        }),
      ).then(res => {
        let allData = res.data.rows;
        const options =
          allData &&
          allData.map(unit => ({
            value: unit._id,
            label: unit.name,
          }));
        setBusinessUnitFilterRows(options);
        resolve(options);
      });
    });
  };

  // handle business units
  const handleBusinessUnits = selectedOptions => {
    const selectedUnits = selectedOptions
      ? selectedOptions?.map(unit => unit.value)
      : [];
    setQuery(prevState => ({
      ...prevState,
      business_unit: selectedUnits,
    }));
    setSelectedBusinessUnit(selectedOptions);
  };

  //set Pending sign off list and pagination
  // const setListAndPagination = () => {

  // }
  return (
    <>
      <div>
        <div className="container-fluid">
          <div className="row align-items-center justify-content-between ">
            <div className="row">
              <div className="col-auto flx-main-filters-invoice ">
                <div
                  className={styles.dateInputWrapper}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <input
                    type="date"
                    name="from_date"
                    value={query.from_date}
                    onChange={handleChange}
                    //   className="form-control flx-date-filter"
                    className={`${styles.boldPlaceholder} ${
                      query.from_date ? '' : styles.emptyDate
                    } `}
                    style={{
                      marginTop: '20px',
                      height: '38px',
                      width: '100%',
                      border: 'none',
                      background: '#F1F2F4',
                      position: 'relative',
                      borderRadius: '12px',
                      fontSize: '12px',
                      padding: '4px 10px',
                      marginRight: '10px',
                      color: query.from_date ? 'inherit' : 'transparent',
                    }}
                  />
                  {!query.from_date && (
                    <label
                      className={styles.datePlaceholder}
                      htmlFor="from_date"
                    >
                      Shifts From
                    </label>
                  )}
                </div>
              </div>

              <div className="col-auto  flx-main-filters-invoice ">
                <div
                  className={styles.dateInputWrapper}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <input
                    type="date"
                    // className="form-control flx-date-filter"
                    name="to_date"
                    value={query.to_date}
                    onChange={handleChange}
                    className={` ${styles.boldPlaceholder} ${
                      query.to_date ? '' : styles.emptyDate
                    }`}
                    data-cy="shift-date"
                    style={{
                      marginTop: '20px',
                      height: '38px',
                      width: '100%',
                      border: 'none',
                      background: '#F1F2F4',
                      position: 'relative',
                      borderRadius: '12px',
                      fontSize: '12px',
                      padding: '4px 10px',
                      marginRight: '10px',
                      color: query.to_date ? 'inherit' : 'transparent',
                    }}
                  />
                  {!query.to_date && (
                    <label className={styles.datePlaceholder} htmlFor="to_date">
                      Shifts To
                    </label>
                  )}
                </div>
              </div>

              {(user_role == 1 || user_role == 6) && (
                <div className="col-auto flx-main-filters-invoice flx-main-filters-invoice2 ">
                  {/* <select
                className="form-control flx-ag-price-table-bus-unit-filter" // This applies Bootstrap styling
              >
                <option value="">Employer</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select> */}
                  <AsyncSelect
                    styles={customStyles}
                    name="employer"
                    placeholder="Employer"
                    cacheOptions
                    defaultOptions={filteredEmployers}
                    loadOptions={getEmployers}
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => 'No results found'}
                    isMulti
                    components={{
                      Option,
                    }}
                    value={selectedEmployer}
                    onChange={handleEmployer}
                    hideSelectedOptions={false}
                  />
                </div>
              )}

              {(user_role == 1 || user_role == 6) && (
                <div className="col-auto flx-main-filters-invoice flx-main-filters-invoice2">
                  {/* <select
                className="form-control flx-ag-price-table-bus-unit-filter" // This applies Bootstrap styling
              >
                <option value="">Business Unit</option>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </select> */}
                  <AsyncSelect
                    styles={customStyles}
                    name="business_unit"
                    placeholder="Business Unit"
                    cacheOptions
                    defaultOptions={
                      selectedEmployer.length > 0
                        ? businessUnitFilterRows
                        : allBusinessUnits
                    }
                    loadOptions={getBusinessUnits}
                    onChange={handleBusinessUnits}
                    isMulti={true}
                    noOptionsMessage={() => 'No results found'}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    value={selectedBusinessUnit}
                  />
                </div>
              )}

              {user_role == 4 && (
                <div className="col-auto flx-main-filters-invoice">
                  <select
                    className="form-control flx-ag-price-table-bus-unit-filter" // This applies Bootstrap styling
                  >
                    <option value="">Agency</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              )}
            </div>

            {/* NOTE : Approve Sign off button only available in employer's Pending sign off tab  */}
            <div className="row">
              {user_role == 4 && (
                <div className="col-auto  btns-pending-sign-off">
                  <button
                    type="button"
                    className="shift-sign-off-reminder-button-disabled shift-sign-off-reminder-button"
                    onClick={() => setShowModal('approvesignoffpopup')}
                  >
                    Approve Sign Off
                  </button>
                </div>
              )}

              <div className="col-auto  ">
                <button
                  type="button"
                  className="shift-sign-off-reminder-button-disabled shift-sign-off-reminder-button"
                  onClick={() => setShowModal('shiftsignoffreminderpopup')}
                >
                  Shift Sign Off Reminder
                </button>
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="shift-sign-off-reminder-sent-button"
                >
                  Reminder Sent{' '}
                  <span className="recsy-reminders-sent-total-number">0</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="mt-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="select-all"
              />
              <label
                className="shift-sign-off-form-check form-check-label"
                htmlFor="select-all"
              >
                Select All
              </label>
            </div>
          </div>
          {/*table design */}
          <div className="flx-sign-off-reminder-container">
            <i className="flx-check-circle fa fa-check-circle"></i>Shift sign
            off reminders have been successfully iniated for the selected
            candidates
          </div>
          <div className="flx-table-sett-user-responsive">
            <table
              className="table flx-user-settings-table mt-3"
              style={{ borderRadius: '10px' }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>

                  <th>Shift From</th>
                  <th>Shift To</th>
                  <th>Shift</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Break</th>
                  <th>Hours</th>
                  <th>Employer</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="flx-selected-div-pending-sign-off">
                  <td>
                    <input type="checkbox" aria-label="select" checked />
                  </td>
                  <td data-title="Name">
                    <img
                      className="flx-emp-invoicing-icons"
                      src="/assets/img/shift-sign-off-demo-image.png"
                    />
                    Abraham Mark
                  </td>
                  <td
                    data-title="From"
                    className="flx-employer-other-fields-td"
                  >
                    12-2-2023
                  </td>
                  <td data-title="To" className="flx-employer-other-fields-td">
                    20-4-2024
                  </td>
                  <td
                    data-title="Morning"
                    className="flx-employer-other-fields-td"
                  >
                    Morning
                  </td>
                  <td
                    data-title="Check In"
                    className="flx-employer-other-fields-td"
                  >
                    9.0
                  </td>
                  <td
                    data-title="Check Out"
                    className="flx-employer-other-fields-td"
                  >
                    16.0
                  </td>
                  <td
                    data-title="Break"
                    className="flx-employer-other-fields-td"
                  >
                    30m
                  </td>
                  <td
                    data-title="Hours"
                    className="flx-employer-other-fields-td"
                  >
                    8
                  </td>
                  <td
                    data-title="Employer"
                    className="flx-employer-other-fields-td"
                  >
                    Rehab
                  </td>
                  <td
                    data-title="Created"
                    className="flx-employer-other-fields-td"
                  >
                    12-Jun-24
                  </td>
                  <td
                    data-title="Status"
                    className="flx-employer-other-fields-td"
                  >
                    ---
                  </td>
                  <td className="flx-employer-other-fields-td">
                    <img
                      className=" "
                      src="/assets/img/edit-icon-pending-sign-off.svg"
                    />
                    <span
                      className="flx-pending-sign-off-cancel-link"
                      onClick={() => setShowModal('shiftsignoffcancelpopup')}
                    >
                      Cancel
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <input type="checkbox" aria-label="select" />
                  </td>
                  <td data-title="Name">
                    <img
                      className="flx-emp-invoicing-icons"
                      src="/assets/img/shift-sign-off-demo-image.png"
                    />
                    Suzenne
                  </td>
                  <td
                    data-title="From"
                    className="flx-employer-other-fields-td"
                  >
                    12-2-2023
                  </td>
                  <td data-title="To" className="flx-employer-other-fields-td">
                    20-4-2024
                  </td>
                  <td
                    data-title="Shift"
                    className="flx-employer-other-fields-td"
                  >
                    Morning
                  </td>
                  <td
                    data-title="Check In"
                    className="flx-employer-other-fields-td"
                  >
                    9.0
                  </td>
                  <td
                    data-title="Check Out"
                    className="flx-employer-other-fields-td"
                  >
                    16.0
                  </td>
                  <td
                    data-title="Break"
                    className="flx-employer-other-fields-td"
                  >
                    30m
                  </td>
                  <td
                    data-title="Hours"
                    className="flx-employer-other-fields-td"
                  >
                    8
                  </td>
                  <td
                    data-title="Employer"
                    className="flx-employer-other-fields-td"
                  >
                    Rehab
                  </td>
                  <td
                    data-title="Created"
                    className="flx-employer-other-fields-td"
                  >
                    12-Jun-24
                  </td>
                  <td
                    data-title="Status"
                    className="flx-employer-other-fields-td"
                  >
                    ---
                  </td>
                  <td className="flx-employer-other-fields-td">
                    <img
                      className=" "
                      src="/assets/img/edit-icon-pending-sign-off.svg"
                    />
                    <span className="flx-pending-sign-off-cancel-link">
                      Cancel
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <input type="checkbox" aria-label="select" />
                  </td>
                  <td data-title="Name">
                    <img
                      className="flx-emp-invoicing-icons"
                      src="/assets/img/shift-sign-off-demo-image.png"
                    />
                    Lilly
                  </td>
                  <td
                    data-title="From"
                    className="flx-employer-other-fields-td"
                  >
                    12-2-2023
                  </td>
                  <td data-title="To" className="flx-employer-other-fields-td">
                    20-4-2024
                  </td>
                  <td
                    data-title="Shift"
                    className="flx-employer-other-fields-td"
                  >
                    Morning
                  </td>
                  <td
                    data-title="Check In"
                    className="flx-employer-other-fields-td"
                  >
                    9.0
                  </td>
                  <td
                    data-title="Check Out"
                    className="flx-employer-other-fields-td"
                  >
                    16.0
                  </td>
                  <td
                    data-title="Break"
                    className="flx-employer-other-fields-td"
                  >
                    30m
                  </td>
                  <td
                    data-title="Hours"
                    className="flx-employer-other-fields-td"
                  >
                    8
                  </td>
                  <td
                    data-title="Employer"
                    className="flx-employer-other-fields-td"
                  >
                    Rehab
                  </td>
                  <td
                    data-title="Created"
                    className="flx-employer-other-fields-td"
                  >
                    12-Jun-24
                  </td>
                  <td
                    data-title="Status"
                    className="flx-employer-other-fields-td"
                  >
                    ---
                  </td>
                  <td className="flx-employer-other-fields-td">
                    <img
                      className=" "
                      src="/assets/img/edit-icon-pending-sign-off.svg"
                    />
                    <span className="flx-pending-sign-off-cancel-link">
                      Cancel
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Modal open up on add location click*/}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          showModal == 'shiftsignoffreminderpopup' ? 'show' : ''
        }`}
        style={{
          display: showModal == 'shiftsignoffreminderpopup' ? 'block' : 'none',
        }}
      >
        <ShiftSignOffReminderPopup />
        <a onClick={() => setShowModal('')}>x</a>
      </div>
      {/* Modal open up on cancel shift sign off*/}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          showModal == 'shiftsignoffcancelpopup' ? 'show' : ''
        }`}
        style={{
          display: showModal == 'shiftsignoffcancelpopup' ? 'block' : 'none',
        }}
      >
        <ShiftSignOffCancelPopup />
        <a onClick={() => setShowModal('')}>x</a>
      </div>
      {/* Modal open up on approve sign off*/}
      <div
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        className={`modal fade ${
          showModal == 'approvesignoffpopup' ? 'show' : ''
        }`}
        style={{
          display: showModal == 'approvesignoffpopup' ? 'block' : 'none',
        }}
      >
        <ApproveSignOffPopup />
        <a onClick={() => setShowModal('')}>x</a>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedPendingSignOff = connect(mapStateToProps)(PendingSignOff);
export { connectedPendingSignOff as PendingSignOff };
