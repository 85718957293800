import React from 'react';

const MobileAppConnect = () => {
  return (
    <div className="col-12 flx-emp-profile-main-form">
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
            <div className="phone-image-container d-block d-lg-none mx-auto">
              <img
                className="flx-emp-profile-imgs"
                src="/assets/img/mob-app-col-img.svg"
                alt="Mobile App"
              />
            </div>
            <h4 className="flx-ag-shift-mob-app-h4">
              Connect with mobile app and make your process more easy
            </h4>
            <p className="flx-ag-shift-mob-app-text-secondary">
              Scan and connect with your FlexiRecs mobile application
            </p>
            <div className="qr-code-container">
              <img
                className="flx-emp-profile-imgs-qr"
                src="/assets/img/mob-app-qr-code.svg"
                alt="QR Code"
              />
              <p className="flx-scan-text">Scan now to login</p>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <div className="phone-image-container d-none d-lg-block">
              <img
                className="flx-emp-profile-imgs"
                src="/assets/img/mob-app-col-img.svg"
                alt="Mobile App"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container flx-mob-app-steps-container d-block d-lg-flex">
        {/* Step 1 */}
        <div className="col mb-4">
          <div className="step-icon mb-3">
            <img
              className="flx-emp-profile-imgs"
              src="/assets/img/ellipse-demo-mob-app.svg"
              alt="Step 1"
            />
          </div>
          <h5>Step 1</h5>
          <p>Download FlexiRecs app from play store/Appstore</p>
        </div>

        {/* Step 2 */}
        <div className="col mb-4">
          <div className="step-icon mb-3">
            <img
              className="flx-emp-profile-imgs"
              src="/assets/img/ellipse-demo-mob-app.svg"
              alt="Step 2"
            />
          </div>
          <h5>Step 2</h5>
          <p>Scan the QR Code from the login page</p>
        </div>

        {/* Step 3 */}
        <div className="col mb-4">
          <div className="step-icon mb-3">
            <img
              className="flx-emp-profile-imgs"
              src="/assets/img/ellipse-demo-mob-app.svg"
              alt="Step 3"
            />
          </div>
          <h5>Step 3</h5>
          <p>Ready to use</p>
        </div>
      </div>
    </div>
  );
};

export default MobileAppConnect;
