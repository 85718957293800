import React from 'react';
import BillingInfo from './EmployerSettingsContentComponents/BillingInfo';
import EmployerCompanyInfo from './EmployerSettingsContentComponents/EmployerCompanyInfo';
import EmployerSettingsAgencyShift from './EmployerSettingsContentComponents/EmployerSettingsAgencyShift';
import EmployerSettingsBusinessUnits from './EmployerSettingsContentComponents/EmployerSettingsBusinessUnits';
import EmployerSettingsUser from './EmployerSettingsContentComponents/EmployerSettingsUser';
import MobileAppConnect from './EmployerSettingsContentComponents/MobileAppConnect';
import ProfileDocument from './EmployerSettingsContentComponents/ProfileDocument';

const EmployerSettingsContent = props => {
  const renderSettingsComponent = () => {
    switch (props.currentPage) {
      case 1:
        return (
          <EmployerCompanyInfo
            id={props?.id}
            profile={props?.profile}
            setprofile={props?.setprofile}
          />
        );
      case 2:
        return (
          <BillingInfo
            billingAddressChange={props.billingAddressChange}
            id={props?.id}
            profile={props?.profile}
          />
        );
      case 3:
        return <ProfileDocument id={props?.id} profile={props?.profile} />;
      case 4:
        return <EmployerSettingsUser />;
      case 5:
        return <EmployerSettingsBusinessUnits />;
      case 6:
        return <EmployerSettingsAgencyShift />;
      case 7:
        return <MobileAppConnect />;
      default:
        return null;
    }
  };

  return <div>{renderSettingsComponent()}</div>;
};

export default EmployerSettingsContent;
