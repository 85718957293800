import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const qualificationService = {
  qualificationAdd,
  qualificationList,
  qualificationDelete,
};

function qualificationAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addqualification', requestOptions).then(
    miscService.handleResponse,
  );
}
function qualificationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getqualificationlist',
    requestOptions,
  ).then(miscService.handleResponse);
}
function qualificationDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deletequalification',
    requestOptions,
  ).then(miscService.handleResponse);
}
