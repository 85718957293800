import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { bookingService } from '../_services';
export const bookingActions = {
  bookingAdd,
  bookingList,
  bookingDelete,
  confirmBooking,
  // updatebooking
};

function bookingAdd(bookingObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.BOOKING_REQUEST, bookingObj });

      bookingService.bookingAdd(bookingObj).then(
        data => {
          dispatch({
            type: reducerConstants.BOOKING_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.message);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.BOOKING_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function confirmBooking(bookingObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.BOOKING_REQUEST, bookingObj });

      bookingService.confirmBooking(bookingObj).then(
        data => {
          dispatch({
            type: reducerConstants.BOOKING_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.BOOKING_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function bookingList(bookingObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BOOKING_REQUEST,
        bookingObj,
      });

      bookingService.bookingList(bookingObj).then(
        data => {
          dispatch({
            type: reducerConstants.BOOKING_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BOOKING_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function bookingDelete(bookingObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.BOOKING_REQUEST,
        bookingObj,
      });

      bookingService.bookingDelete(bookingObj).then(
        data => {
          dispatch({
            type: reducerConstants.BOOKING_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BOOKING_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
