// MSAL imports
import { PublicClientApplication } from '@azure/msal-browser';

export const loginRequest = {
  scopes: ['User.Read'],
};

export const msalConfig = {
  auth: {
    clientId: '2e41f07e-9803-403c-95cf-97973aad59d0',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    temporaryCacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false,
    claimsBasedCachingEnabled: true,
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();
});

msalInstance.handleRedirectPromise();
