import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const ReferalService = {
  ReferalAdd,
  ReferalList,
  ReferalDelete,
};

function ReferalAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addReferal', requestOptions).then(
    miscService.handleResponse,
  );
}
function ReferalList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getreferallist', requestOptions).then(
    miscService.handleResponse,
  );
}
function ReferalDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteReferal', requestOptions).then(
    miscService.handleResponse,
  );
}
