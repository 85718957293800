export const MyProfileInfo = () => {
  return (
    <>
      <div className="flx-company-info container mt-3">
        <div className="flx-company-info-row row d-block d-md-flex">
          {/* Sidebar for user profile options */}
          <div className="col-md-4 mb-3 d-none d-md-block">
            <div className="d-flex flex-column">
              <div
                className="flx-company-info initials-circle d-flex align-items-center justify-content-center"
                style={{ width: '50px', height: '50px' }}
              >
                AM
              </div>
              <a href="#" className="flx-change-link-profile">
                Change
              </a>

              <h3 className="flx-company-name-text">Company Name</h3>
              <ul className="flx-company-info-settings-main-links">
                <div className="flx-emp-profile-menu-icons d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/Company info.svg"
                  />

                  <li className="flx-company-info-settings-li flx-company-info-settings-li-active">
                    My Profile
                  </li>
                </div>
                <div className="flx-emp-profile-menu-icons d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/Biiling info.svg"
                  />
                  <li className="flx-company-info-settings-li">Billing Info</li>
                </div>
                <div className="flx-emp-profile-menu-icons d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/Documents.svg"
                  />
                  <li className="flx-company-info-settings-li">Documents</li>
                </div>
                <div className="flx-emp-profile-menu-icons d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/Login.svg
                    "
                  />
                  <li className="flx-company-info-settings-li">
                    Login & Security
                  </li>
                </div>
              </ul>
              {/* Progress bar (Bootstrap progress bar or custom) */}
              <div className="flx-pgs-bar-text">
                <p className="flx-complete-profile-text">Complete profile</p>
                <p className="flx-complete-profile-subtext">
                  Add your address to complete
                </p>
              </div>
              <div className="flx-progress-bar-and-text-company-info d-flex">
                <div className="progress w-100 flx-progress-company-info">
                  <div
                    className="progress-bar flx-progress-bar-comp-info"
                    role="progressbar"
                    style={{ width: '75%' }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <span className="flx-progress-bar-text">75%</span>
              </div>
            </div>
          </div>
          <div className="flx-emp-profile-vertical-hr d-none d-md-block"></div>
          {/* Sidebar turned as a scrollable menu on smaller screens */}
          <div className="container-fluid flx-emp-profile-mobile-menu-container d-block d-md-none">
            <div className="row flx-emp-profile-mobile-menu-row">
              <div className="col flx-emp-profile-mobile-menu-col">
                <div className="d-flex flex-row flex-nowrap">
                  <div className="flx-emp-profile-mob-menu-icon-set flx-emp-profile-mob-menu-icon-set_active d-flex">
                    <img
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/comp-info.svg"
                    />
                    <span className="flx-emp-profile-mob-menu-text">
                      My Profile
                    </span>
                  </div>
                  <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                    <img
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/billing.svg"
                    />
                    Billing Info
                  </div>
                  <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                    <img
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/emp-doc.svg"
                    />
                    Documents
                  </div>
                  <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                    <img
                      className="flx-emp-profile-menu-icons"
                      src="/assets/img/login-sec.svg"
                    />
                    Login & Security
                  </div>

                  {/* Add more menu items here */}
                </div>
              </div>
            </div>
          </div>
          {/* Sidebar turned as a scrollable menu on smaller screens ends */}
          {/* Main content for editing profile information */}
          <div className="col-md-6 flx-emp-profile-main-form">
            <h2 className="flx-company-info-form-heading">My Profile</h2>
            <form>
              <div className="mb-3">
                <label
                  htmlFor="companyName"
                  className="form-label company-info-labels"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control flx-comp-settings-form-control"
                  id="ProfileName"
                  placeholder="Enter your name"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="postcode"
                  className="form-label company-info-labels"
                >
                  Designation
                </label>

                <input
                  type="text"
                  className="form-control flx-comp-settings-form-control"
                  id="Designation"
                  placeholder="Enter your designation"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="form-label company-info-labels"
                >
                  Email
                </label>{' '}
                <i className="fa fa-info-circle company-info-icon-tooltip"></i>
                <input
                  type="text"
                  className="form-control flx-comp-settings-form-control"
                  id="email"
                  placeholder="Enter email"
                />
              </div>
              <label htmlFor="phone" className="form-label company-info-labels">
                Phone
              </label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="flx-basic-addon1">
                    +44
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control flx-comp-settings-form-control"
                  placeholder="07888 435 355"
                  aria-label="Phone number"
                  aria-describedby="basic-addon1"
                />
              </div>

              <button
                type="submit"
                className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
