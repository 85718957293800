import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { PriceAdd } from '../Price/PriceAdd';
import { employerActions, userActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const Employer = props => {
  const { id } = useParams();
  const qrRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qrDownloadUrl, setQrDownloadUrl] = useState('');
  const [isAdmin, setisAdmin] = useState('');
  const [isStaff, setisStaff] = useState('');
  const [showmodalpriceadd, setshowmodalpriceadd] = useState(false);
  const [Selectedtype, setSelectedtype] = useState('xx');
  const [isEmployer, setisEmployer] = useState(false);
  const [employer, setEmployer] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  // effect for get employer
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllEmployer(1, query);
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 6) {
        setisStaff(true);
      }
      if (user.role == 4) {
        setisEmployer(true);
      }
    }
  }, []);
  /**
   * Qrcode download button
   */
  const setQrDownloadBtn = () => {
    const canvas = document.querySelector('.HpQrcode > canvas');
    setQrDownloadUrl(canvas.toDataURL());
    qrRef.href = canvas.toDataURL();
    qrRef.download = 'employer' + '-QR.png';
  };
  // price data from price add component
  const getPriceData = () => {
    setshowmodalpriceadd(false);
  };
  // to reset user credentials via mail ------
  const sendResetUserCredentials = () => {
    const { dispatch } = props;
    let temp = confirm(
      'Are you sure you want to send reset password  to this user?',
    );
    if (!temp) {
      return;
    }
    if (employer && employer.user_id) {
      dispatch(
        userActions.sendResetUserCredentials({ _id: employer.user_id }),
      ).then(function () {
        alert('Success');
      });
    }
  };
  /**
   * Get all employers
   * */
  const getAllEmployer = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.getonlyparentdetails = true;
    dispatch(employerActions.employerListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setEmployer(res?.data?.rows?.[0]);
          setEmployer(prevState => ({
            ...prevState,
            // key_contact: JSON.parse(res?.data?.rows?.[0].key_contact),
          }));
          setTimeout(() => {
            setQrDownloadBtn();
          });
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Employer">
      {(props.requestStatus === reducerConstants.EMPLOYER_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12 employer_card_color ">
        <div className="card bgcolorth mt-5">
          <div className="card-body">
            <div className="m-3">
              <div className="row d-flex justify-content-between ">
                <div className="col-md-6">
                  <div className="row d-flex align-items-center ">
                    <div className="col-md-2">
                      <div className="employer-image">
                        {employer && employer.profileimage ? (
                          <img
                            className=" round-emp-image-single"
                            src={apiConstants.imgUrl + employer.profileimage}
                          />
                        ) : (
                          <img
                            className="round-emp-image-single"
                            src="/assets/img/recsy-fav-icon.png"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <p className="bold f18">{employer.company_name} </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-4  ">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-phone green_border"></i>
                        <p className="emp-phone">
                          {employer &&
                            employer.contact_number &&
                            employer.contact_number}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-envelope  red_border"></i>
                        <p className="emp-phone">
                          {employer && employer.email && employer.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isEmployer && (
                <div className="top-border-bot">
                  {employer._id && (
                    <div className="bgred">
                      <button
                        data-cy="addpricetable"
                        className="btn-price-blue"
                        onClick={() => {
                          setSelectedtype(''), setshowmodalpriceadd(true);
                        }}
                      >
                        {' '}
                        + Price table{' '}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
            {(isAdmin || isStaff) && (
              <div className=" col text_align">
                <Link
                  data-cy="edittemployer"
                  className="link"
                  to={'/employeradd/' + employer._id}
                >
                  <i className="fas fa-pen pointer f18"></i>
                </Link>
              </div>
            )}
            <div className="col-md-12">
              <div className="row">
                {/* //-----------employer details section -------------------------- */}
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Name</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{employer.company_name} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Address</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{employer.address && employer.address}</p>
                      <p style={{ lineHeight: '5px' }}>
                        {employer.location && employer.location}
                      </p>
                      <p>&nbsp;{employer.postcode && employer.postcode}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Website</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{employer.website && employer.website}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Priority</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>
                        {!!employer.priority && employer.priority == 1
                          ? 'Normal'
                          : employer.priority == 2
                          ? 'Medium'
                          : employer.priority == 3
                          ? 'High'
                          : ''}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>City</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{employer.city} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Postcode</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{employer.postcode} </p>
                    </div>
                  </div>
                  {employer.lat && employer.long && (
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Location</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col">
                        <a
                          href={
                            'https://www.google.com/maps/search/?api=1&query=' +
                            employer.lat +
                            ',' +
                            employer.long
                          }
                        >
                          Open location
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {/* //-------------qr code section --------------- */}
                <div className="col-md-4">
                  <div className="row">
                    <div className="">
                      {/* //---------------------qr code view -------------- */}
                      <div className="HpQrcode">
                        <QRCodeCanvas value={employer.employer_key} />
                      </div>
                      {/* //-----------doenload qr code ---------------- */}
                      <a
                        href={qrDownloadUrl}
                        download="FILENAME.jpg"
                        ref={qrRef}
                      >
                        Download QR Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {employer &&
              !!employer.key_contact &&
              !!employer.key_contact.length &&
              !!employer.key_contact[0] &&
              !!employer.key_contact[0].key_name && (
                <div className="col-md-8">
                  <div className="card-dark mt-4 pl-4">
                    <div className="mt-3">
                      <h4 className="">Key Contact 1</h4>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>Name</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {employer &&
                              employer.key_contact &&
                              employer.key_contact[0] &&
                              employer.key_contact[0].key_name &&
                              employer.key_contact[0].key_name}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>email</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {employer &&
                              employer.key_contact &&
                              employer.key_contact[0] &&
                              employer.key_contact[0].key_email &&
                              employer.key_contact[0].key_email}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>Phone</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {employer &&
                              employer.key_contact &&
                              employer.key_contact[0] &&
                              employer.key_contact[0].key_phone &&
                              employer.key_contact[0].key_phone}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>Position</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col">
                          <p>
                            {employer &&
                              employer.key_contact &&
                              employer.key_contact[0] &&
                              employer.key_contact[0].key_position &&
                              employer.key_contact[0].key_position}{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="card m-5">
          <div className="d-flex ">
            <div
              onClick={sendResetUserCredentials}
              className=" btn-primary-blue-nowidth"
            >
              {' '}
              Reset login cridentials and send to mail
            </div>
          </div>
        </div>
        {showmodalpriceadd && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setshowmodalpriceadd(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="">
                    <PriceAdd
                      Selectedtype={Selectedtype}
                      employer_id={employer._id}
                      getPriceData={getPriceData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedEmployer = connect(mapStateToProps)(Employer);
export { connectedEmployer as Employer };
