/* eslint-disable no-undef */
import { authHeader, authHeaderMulti } from '../_helpers';

import { parse } from 'date-fns';
import moment from 'moment';
import { apiConstants } from '../_constants';

function handleResponse(response) {
  if (response.status === 401) {
    logout();
    window.location.reload(true);
    throw new Error('Unauthorized');
  }
  return response.text().then(text => {
    let data = {};
    try {
      data = text && JSON.parse(text);
    } catch (e) {
      return Promise.reject(new Error('Server Error'));
    }

    if (data.error_code !== 0 && data.error_code !== undefined) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.message;
      return Promise.reject(error, data);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function getUrlVars() {
  let vars = {};
  // let  parts = window.location.href.replace(
  //   /[?&]+([^=&]+)=([^&]*)/gi,
  //   function (m, key, value) {
  //     vars[key] = value;
  //   },
  // );
  return vars;
}

function fileupload(file, name, document_type, query) {
  //

  let data = new FormData();

  data.append('file', file);
  data.append('document', document_type);

  data.append('query', JSON.stringify(query));
  data.append('name', name);

  // data.getAll("user-naem")

  const requestOptions = {
    method: 'POST',
    body: data,
    headers: authHeaderMulti(),
  };
  return fetch(apiConstants.apiUrl + '/fileUploads3', requestOptions).then(
    handleResponse,
  );
}

function s3frontEndUploadUrl(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/s3frontEndUploadUrl',
    requestOptions,
  ).then(handleResponse);
}

function countries() {
  const requestOptions = {
    method: 'GET',
  };

  return (
    fetch(apiConstants.apiUrl + '/country.json', requestOptions)
      //.then(handleResponse)
      .then(country => country.json())
      .then(data => {
        return data;
      })
  );
}

function getAllActiveCountry(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/api/v1/getcountryList',
    requestOptions,
  ).then(handleResponse);
}
function sharabletoken(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/sharabletoken', requestOptions).then(
    handleResponse,
  );
}
function shorturl(stringurl) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ longUrl: stringurl }),
  };

  return fetch(
    ' https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyB0UktWR7xDNvmpdvZfHMk2Lw4EjYvDtak',
    requestOptions,
  ).then(data => {
    return data;
  });
}

function otpverification(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/api/v1/otpverify', requestOptions).then(
    handleResponse,
  );
}

function getdashbaorddata(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/api/v1/getdashbaorddata',
    requestOptions,
  ).then(handleResponse);
}

//let  csv is the CSV file with headers
function csvJSON(csv) {
  let lines = csv.split('\n');

  let result = [];

  let headers = lines[0].split(',');

  for (let i = 1; i < lines.length; i++) {
    let obj = {};
    let currentline = lines[i].split(',');

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
}

// function ConvertKeysToLowerCase(obj) {
//
//   if (obj instanceof Array) {
//     for (let  i in obj) {
//       obj[i] = keysToLowerCase(obj[i]);
//     }
//   }
//   if (
//     !typeof obj === 'object' ||
//     typeof obj === 'string' ||
//     typeof obj === 'number' ||
//     typeof obj === 'boolean'
//   ) {
//     return obj;
//   }
//   let  keys = Object.keys(obj);
//   let  n = keys.length;
//   let  lowKey;
//   while (n--) {
//     let  key = keys[n];
//     if (key === (lowKey = key.toLowerCase())) continue;
//     obj[lowKey] = keysToLowerCase(obj[key]);
//     delete obj[key];
//   }
//   return obj;
// }

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function formatTime(date) {
  let d = new Date(date),
    hours = d.getHours(),
    minutes = '' + d.getMinutes(),
    a = ' AM';

  if (hours > 12) {
    hours = '' + hours - 12;
    a = ' PM';
  } else if (hours == 0) {
    hours = '12';
  } else {
    hours = '' + hours;
  }

  if (minutes.length < 2) minutes = '0' + minutes;

  return hours + ':' + minutes + a;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getgraphData(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getgraphData', requestOptions).then(
    handleResponse,
  );
}

//---------get document/file url if http url return it else append url and return --------------
function documentUrl(document_name) {
  let firstFourChars = document_name.substring(0, 4); // or string.slice(0, 4);

  if (firstFourChars == 'http') {
    return document_name;
  } else {
    let doc_name = apiConstants.imgUrl + document_name;
    return doc_name;
  }
}

function getDashbordSharelink(data) {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // };
  let requestOptions = {
    method: 'POST',
    headers: {
      // 'x-access-token': data.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getsharelink', requestOptions)
    .then(handleResponse)
    .then(response => {
      // login successful if there's a jwt token in the response
      if (response.data) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response;
    });
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusMiles = 3958.8; // Radius of the Earth in miles

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;

  // Calculate the differences between latitudes and longitudes
  const latDiff = lat2Rad - lat1Rad;
  const lonDiff = lon2Rad - lon1Rad;

  // Calculate the distance using the Haversine formula
  const a =
    Math.sin(latDiff / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceMiles = earthRadiusMiles * c;

  return distanceMiles;
}

const time24to12 = time => {
  if (time) {
    const number = moment(time, ['HH:mm']).format('hh:mm a');

    return number;
  } else {
    return null;
  }
};
const getUTCTime = date => {
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

const emailVerify = email => {
  const emailRegex =
    /^(?!\.)(?!.*\.\.)[a-zA-Z0-9"_.+-]+[^.]@(?:[^-][a-zA-Z0-9.-]+(?!\.web$)\.[a-zA-Z]{2,6}|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\])$/;
  return emailRegex.test(email);
};

// function isWithin24Hours(date) {
//   const now = new Date();

//   const difference = Math.abs(date - now);

//   const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

//   return difference <= millisecondsIn24Hours;
// }

function dateConvertion(String) {
  const parsedDate = parse(String, 'dd/MM/yyyy', new Date());

  return parsedDate;
}

function getMonthName(month) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[parseInt(month, 10)];
}

const currentTimeCalculation = time => {
  const currDate = new Date(); // Current date
  const nextDate = new Date(currDate + 24 * 60 * 60 * 1000);
  const totalSeconds =
    currDate.getHours() * 60 * 60 + // hours to seconds
    currDate.getMinutes() * 60 + // minutes to seconds
    currDate.getSeconds();

  const nextDayTotalSeconds =
    nextDate.getHours() * 60 * 60 + // hours to seconds
    nextDate.getMinutes() * 60 + // minutes to seconds
    nextDate.getSeconds();

  // Calculate hours and minutes
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  // next Day calculation
  const nexthDayHours = Math.floor(nextDayTotalSeconds / 3600);
  const nexthDayMinutes = Math.floor((nextDayTotalSeconds % 3600) / 60);

  // Format hours and minutes to 'hh:mm'
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
  const NextDayFormattedTime = `${nexthDayHours
    .toString()
    .padStart(2, '0')}:${nexthDayMinutes.toString().padStart(2, '0')}`;

  if (time <= formattedTime) {
    return true;
  } else if (time <= NextDayFormattedTime) return false;
};

const emergencyShift = time => {
  const currDate = new Date(); // Current date

  const startTime = new Date(
    currDate.getFullYear(),
    currDate.getMonth(),
    currDate.getDate(),
    time?.split(':')[0],
    time?.split(':')[1],
  );
  const nextDate = new Date(currDate + 24 * 60 * 60 * 1000);

  const nextDayTotalInMilliseconds = nextDate.getTime();

  // asuming the day startTime is Next Day
  if (nextDayTotalInMilliseconds > startTime) {
    return true;
  } else false;
};

// const TimeTravelCostCalculator = () => {
//   // Define price rates for each time interval
//   const priceRates = [
//     { startTime: '20:00', endTime: '22:00', rate: 8 },
//     { startTime: '22:00', endTime: '04:00', rate: 10 },
//     { startTime: '04:00', endTime: '08:00', rate: 6 },
//   ];

//   const calculateCost = () => {
//     if (!startTime || !endTime) {
//       alert('Please enter start and end times.');
//       return;
//     }

//     // Initialize total cost
//     let cost = 0;

//     // Convert start and end times to Date objects
//     const start = new Date(`2000-01-01T${startTime}`);
//     const end = new Date(`2000-01-01T${endTime}`);

//     // Iterate through price rates to calculate cost for each interval
//     for (const { startTime, endTime, rate } of priceRates) {
//       const intervalStart = new Date(`2000-01-01T${startTime}`);
//       const intervalEnd = new Date(`2000-01-01T${endTime}`);

//       // Calculate the overlap between shift and current interval
//       const shiftStart = start < intervalStart ? intervalStart : start;
//       const shiftEnd = end > intervalEnd ? intervalEnd : end;
//       const shiftDuration = (shiftEnd - shiftStart) / (1000 * 60 * 60); // Duration in hours

//       if (shiftDuration > 0) {
//         cost += shiftDuration * rate;
//       }
//     }

//     setTotalCost(cost);
//   };
// };

//Calculating the difference for the shift time
const calculateTimeDifference = (checkInTime, checkOutTime) => {
  let start = '2023-03-13' + ' ' + checkInTime;
  let end = ' 2023-03-14' + ' ' + checkOutTime;
  const startTime = moment(start, 'YYYY-MM-DD hh:mm');
  const endTime = moment(end, 'YYYY-MM-DD hh:mm');
  const duration = moment.duration(endTime.diff(startTime));
  let hours = '';
  // eslint-disable-next-line no-unused-vars
  return (hours = duration._data.hours);
};

export const miscService = {
  getMonthName,
  countries,
  shorturl,
  fileupload,
  otpverification,
  getdashbaorddata,
  sharabletoken,
  documentUrl,
  csvJSON,
  handleResponse,
  // ConvertKeysToLowerCase,
  getUrlVars,
  b64toBlob,
  getAllActiveCountry,
  formatDate,
  formatTime,
  getParameterByName,
  s3frontEndUploadUrl,
  getgraphData,
  getDashbordSharelink,
  calculateDistance,
  time24to12,
  getUTCTime,
  emailVerify,
  // isWithin24Hours,
  dateConvertion,
  currentTimeCalculation,
  emergencyShift,
  // TimeTravelCostCalculator,
  calculateTimeDifference,
};
