import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { allocatecandidateService } from '../_services';
export const allocatecandidateActions = {
  allocatecandidateAdd,
  allocatecandidateList,
  allocatecandidateDelete,
  blockBookingList,
};

function allocatecandidateAdd(allocatecandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.ALLOCATECANDIDATE_REQUEST,
        allocatecandidateObj,
      });

      allocatecandidateService.allocatecandidateAdd(allocatecandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.ALLOCATECANDIDATE_SUCCESS,
            data,
          });

          if (data.error_code == 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.ALLOCATECANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function allocatecandidateList(allocatecandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.ALLOCATECANDIDATE_REQUEST,
        allocatecandidateObj,
      });

      allocatecandidateService.allocatecandidateList(allocatecandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.ALLOCATECANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.ALLOCATECANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

//get all block booking data from allocate candidate table and shifts table
// @author harshad

function blockBookingList(allocatecandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.ALLOCATECANDIDATE_REQUEST,
        allocatecandidateObj,
      });

      allocatecandidateService.blockBookingList(allocatecandidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.BLOCKBOOKINGLIST_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.BLOCKBOOKINGLIST_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function allocatecandidateDelete(allocatecandidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.ALLOCATECANDIDATE_REQUEST,
        allocatecandidateObj,
      });

      allocatecandidateService
        .allocatecandidateDelete(allocatecandidateObj)
        .then(
          data => {
            dispatch({
              type: reducerConstants.ALLOCATECANDIDATE_SUCCESS,
              data,
            });
            resolve(data);
          },
          error => {
            dispatch({
              type: reducerConstants.ALLOCATECANDIDATE_FAILURE,
              error,
            });
            reject(error);
            dispatch(alertActions.error(error?.toString()));
          },
        );
    });
}
