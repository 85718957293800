import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ReferalActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let ReferalRows = [];

const ReferalList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [ReferalData, setReferalData] = useState({ rows: [] });
  const [ReferalRows, setReferalRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  // effect get refereal list call
  useEffect(() => {
    getAllReferal(1);
  }, [query]);
  /**
   * Get all Referals
   **/
  const getAllReferal = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(ReferalActions.ReferalList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = ReferalData => {
    let ReferalList = [];
    for (
      let j = 0;
      ReferalData && ReferalData.rows && j < ReferalData.rows.length;
      j = j + 1
    ) {
      ReferalList.push(
        <tr key={j}>
          <td>
            {ReferalData &&
              ReferalData.rows &&
              ReferalData.rows[j].createdAt &&
              moment(ReferalData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          <td>{ReferalData.rows[j]['User.name']} </td>
          <td>{ReferalData.rows[j]['Parent.name']} </td>
          <td>{ReferalData.rows[j].referal_status ? 'sucess' : 'pending'} </td>
          <td>£{ReferalData.rows[j].amount} </td>
        </tr>,
      );
    }
    setReferalRows(ReferalList);
    setReferalData(ReferalData);
  };
  return (
    <div className="ReferalList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-10">
                <div className="page-title"> Referals</div>
              </div>
              <div className="col d-flex justify-content-end"></div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSort('createdAt')}
                      className="sortHead"
                    >
                      Created
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'createdAt' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'createdAt' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Refered joinee</th>
                    <th>Refered By</th>
                    <th>Ref Status</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>{ReferalRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {ReferalData &&
              ReferalData.rows &&
              ReferalData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllReferal(data);
              }}
              paginationData={ReferalData}
            />
          </div>
          {showmodal && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setshowmodal(false)}>
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedReferalList = connect(mapStateToProps)(ReferalList);
export { connectedReferalList as ReferalList };
