import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { documentsService } from '../_services';
export const documentsActions = {
  documentsAdd,
  documentsList,
  documentsDelete,
};

function documentsAdd(documentsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.DOCUMENTS_REQUEST, documentsObj });

      documentsService.documentsAdd(documentsObj).then(
        data => {
          dispatch({
            type: reducerConstants.DOCUMENTS_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.DOCUMENTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function documentsList(documentsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.DOCUMENTS_REQUEST,
        documentsObj,
      });

      documentsService.documentsList(documentsObj).then(
        data => {
          dispatch({
            type: reducerConstants.DOCUMENTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.DOCUMENTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function documentsDelete(documentsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.DOCUMENTS_REQUEST,
        documentsObj,
      });

      documentsService.documentsDelete(documentsObj).then(
        data => {
          dispatch({
            type: reducerConstants.DOCUMENTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.DOCUMENTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
