import React, { useEffect, useState } from 'react';
import { LoadingImg } from '../_components';
import { userService } from '../_services';
import EmployerSettingsContent from './EmployerSettingsContent';
import EmployerSettingsMenu from './EmployerSettingsMenu';

const EmployerSettingsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  let id = null;
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setprofile] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });

  const billingAddressChange = () => {
    getAllEmployerDetails(1, query);
  };

  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllEmployerDetails(1, query);
    }
  }, []);

  const userData = JSON.parse(localStorage.getItem('user'));
  id = userData._id;

  const getAllEmployerDetails = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    userService.userList(queryNew).then(
      res => {
        setIsLoading(false);
        if (res?.data?.rows) {
          setprofile(res?.data?.rows[0]);
        }
      },
      err => {
        setIsLoading(false);
        setError('Error fetching employer details. Please try again.');
        console.error('Error fetching employer details:', err);
      },
    );
  };

  return (
    <div className="flx-company-info container mt-3">
      {!isLoading ? (
        <div className="flx-company-info-row row d-block d-lg-flex">
          <div className=" ">
            <EmployerSettingsMenu
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
          <div className="flx-emp-profile-vertical-hr d-none d-lg-block"></div>
          <div style={{ width: '100%' }}>
            <EmployerSettingsContent
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              billingAddressChange={billingAddressChange}
              id={id}
              profile={profile}
              setprofile={setprofile}
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      ) : (
        <LoadingImg />
      )}
    </div>
  );
};

export default EmployerSettingsPage;
