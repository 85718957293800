export function vacancyshift(
  state = { requestStatus: 0, vacancyshiftData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'VACANCYSHIFT_SUCCESS':
      return {
        ...state,
        requestStatus: 'VACANCYSHIFT_SUCCESS',
        requestVacancyshiftStatus: 'VACANCYSHIFT_SUCCESS',
        vacancyshiftData: action.data.data,
        data: action.data,
      };
    case 'VACANCYSHIFT_FAILURE':
      return {
        ...state,
        requestStatus: 'VACANCYSHIFT_FAILURE',
        requestVacancyshiftStatus: 'VACANCYSHIFT_FAILURE',
        vacancyshiftData: {},
        data: action.data,
      };
    case 'VACANCYSHIFT_REQUEST':
      return {
        ...state,
        requestStatus: 'VACANCYSHIFT_REQUEST',
        requestVacancyshiftStatus: 'VACANCYSHIFT_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
