import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const demoService = {
  demoAdd,
  demoList,
  demoDelete,
};

function demoAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/adddemo', requestOptions).then(
    miscService.handleResponse,
  );
}
function demoList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getdemoList', requestOptions).then(
    miscService.handleResponse,
  );
}
function demoDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletedemo', requestOptions).then(
    miscService.handleResponse,
  );
}
