/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { employerActions, staffActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { FileUpload } from '../fileupload/FileUpload';
const PermenentemployerAddd = props => {
  let navigate = useNavigate();
  const [employer, setEmployer] = useState({
    name: '',
    pay_break: 0,
    description: '',
    key_contact: [{}],
    postcode: '',
    is_vat: 0,
    break_time: 30,
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const [permenentemployer, setpermenentemployer] = useState(false);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clownCounter, setClownCounter] = useState(0);
  const [error, setError] = useState({ common: '' });
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [staffrows, setstaffrows] = useState();

  useEffect(() => {
    if (props && props.employer) {
      setEmployer(props.employer);
    }
    if (props && props.permenentemployer) {
      setpermenentemployer(true);
    }
    getAllstaff();
  }, []);

  useEffect(() => {
    loadaddress();
  }, [permenentemployer]);

  //----------load address using postal code----------
  const loadaddress = async () => {
    setQuery(query);
    const res = employer.postcode?.replace(/ /g, '');

    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let addresses_rows = [];
        let regex = /\s+,/g;
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });

        setEmployer({
          ...employer,
          lat: response.latitude,
          long: response.longitude,
          // county: newarray[6],
          // city: newarray[5],
          postcode: employer.postcode,
        });
      }
    }
  };
  // const checkemailexist = () => {
  //   const { dispatch } = props;
  //   if (employer.email) {
  //     dispatch(
  //       employerActions.checkemailExist({
  //         email: employer.email,
  //       }),
  //     ).then(
  //       res => {
  //         setIsLoading(false);

  //         if (
  //           res &&
  //           res?.data?.data?.rows &&
  //           res?.data?.data?.rows?.length > 0
  //         ) {
  //           setEmployer(res.data.data.rows[0]);
  //           setpermenentemployer(true);
  //           setstage(2);
  //         } else if (res && res?.message == 'already registered email') {
  //           setError({ common: res.message });
  //         } else {
  //           setstage(2);
  //         }
  //       },
  //       () => {
  //         setIsLoading(false);
  //       },
  //     );
  //   } else {
  //     setError({ common: 'please enter email' });
  //   }
  // };

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  const showfileupload = () => {
    setshowmodalfileupload(true);
  };

  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };

  // ----to remove keycontact array element ----
  // @params = i (array element index)
  const removeKeyElement = i => {
    let newkeycontact = employer.key_contact ? employer.key_contact : [];

    // newkeycontact = newkeycontact;
    if (newkeycontact[0] && newkeycontact[0].name) {
      newkeycontact.splice(i, 1);
      setEmployer(prevState => ({ ...prevState, key_contact: newkeycontact }));
    }
    setClownCounter(clownCounter - 1);
  };

  //---------get all categories for select drop doen----

  // const getAllcategories = () => {
  //   const { dispatch } = props;
  //   dispatch(categoryActions.categoryList(query)).then(res => {
  //
  //     let categoryData = res.data.rows;
  //     let categorylist = [];
  //     for (let i = 0; i < categoryData.length; i++) {
  //       categorylist.push(
  //         <option value={categoryData[i]._id}>{categoryData[i].name}</option>,
  //       );
  //     }
  //     // setcategoriesrows(categorylist);
  //   });
  // };

  const getAllstaff = () => {
    const { dispatch } = props;
    dispatch(staffActions.staffList()).then(res => {
      let staffData = res.data.rows;
      let stafflist = [];
      for (let i = 0; i < staffData.length; i++) {
        stafflist.push(
          <option value={staffData[i]._id}>{staffData[i].name}</option>,
        );
      }
      setstaffrows(stafflist);
    });
  };

  /**
   * Get all employers
   * */
  // const getAllEmployer = (pageNo, queryTemp) => {
  //   let queryNew = queryTemp ? queryTemp : query;
  //   queryNew.pageVo.pageNo = pageNo;
  //   setQuery(query);
  //   setIsLoading(true);
  //   const { dispatch } = props;
  //   dispatch(employerActions.employerListv2(queryNew)).then(
  //     res => {
  //       setIsLoading(false);
  //       if (res && res.data.rows && res.data.rows.length > 0) {
  //         setEmployer(res?.data?.rows?.[0]);
  //         setEmployer(prevState => ({
  //           ...prevState,
  //           key_contact: res?.data?.rows?.[0].key_contact,
  //         }));
  //         if (res?.data?.rows?.[0].key_contact) {
  //           console.log(
  //             res?.data?.rows?.[0].key_contact.length,
  //             'loosin urslf',
  //             res?.data?.rows?.[0].key_contact,
  //           );
  //           setClownCounter(res?.data?.rows?.[0].key_contact.length);
  //         }
  //       }
  //     },
  //     () => {
  //       setIsLoading(false);
  //     },
  //   );
  // };
  ///---------handle change array------
  const handleChange = event => {
    setError({ common: '' });
    const { name, value, checked } = event.target;

    if (name == 'contact_number' && value.length > 11) {
      return;
    }

    if (
      name == 'key_name' ||
      name == 'key_position' ||
      name == 'key_email' ||
      name == 'key_phone'
    ) {
      //--------set to key_contact object -------
      setEmployer({
        ...employer,
        key_contact: { ...employer.key_contact, [name]: value },
      });
    } else if (name == 'contact_number' || name == 'secondary_number') {
      if (value.length >= 12) {
        return;
      } else {
        setEmployer({
          ...employer,
          [name]: value,
        });
      }
    } else if (name == 'is_vat') {
      if (checked) {
        setEmployer({
          ...employer,
          [name]: 1,
        });
      } else {
        setEmployer({
          ...employer,
          [name]: 0,
        });
      }
    } else {
      //--------set to employer state variable-----------
      setEmployer({
        ...employer,
        [name]: value,
      });
    }
  };

  //-------key contact change ----------
  const handleChangekeycontact = (event, i) => {
    const { name, value } = event.target;
    let newkeycontact = employer.key_contact ? employer.key_contact : [];
    if (newkeycontact && newkeycontact[i]) {
      newkeycontact[i][name] = value;
    } else {
      newkeycontact[i] = {};
      newkeycontact[i][name] = value;
    }
    setEmployer({
      ...employer,
      key_contact: newkeycontact,
    });
  };

  //----------check if valid postcode ------
  const valid_postcode = str_postcode => {
    let postcode = str_postcode?.toUpperCase();
    postcode = postcode?.toString().replace(/\s/g, '');
    let regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;

    return regex.test(postcode);
  };

  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };

  //--------gte file upload response ---------
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setEmployer(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
    }
    setshowmodalfileupload(false);
    // window.location.reload()
  };

  //-----------postcode change-----------
  const handleChangePostcode = async event => {
    event.preventDefault();
    let { name, value } = event.target;
    value = value.replace(/[^\w\s]/gi, '');

    let post_code = value.replace(/ /g, ''); //remove space

    setEmployer({
      ...employer,
      [name]: post_code,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();

      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        const regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setEmployer({
          ...employer,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: post_code,
        });
      }
    } else if (employer._id) {
      setEmployer({
        ...employer,
        [name]: value,
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;

    if (employer.company_phone && !employer.contact_number) {
      employer.contact_number = employer.company_phone;
    }
    if (!employer.email && employer.company_email) {
      employer.email = employer.company_email;
    }
    if (!employer.cdm_staff && employer.staff_id) {
      employer.cdm_staff = employer.staff_id;
    }
    if (
      employer.company_name &&
      employer.email &&
      employer.contact_number &&
      employer.address &&
      employer.postcode
    ) {
      if (!employer._id && !permenentemployer) {
        if (!employer.password && !employer.confirm_password) {
          setError({ common: 'Enter a password' });
          return;
        }
      }
      if (employer.is_vat && !employer.vat_percentage) {
        setError({ common: 'please enter vat percentage' });
        return;
      }

      setError({ common: '' });

      let postcode = valid_postcode(employer.postcode);
      if (postcode == false) {
        setError({ common: 'Enter a valid postcode' });
        return;
      }
      if (
        employer.password != employer.confirm_password &&
        !employer._id &&
        !permenentemployer
      ) {
        setError({ common: 'Password does not match' });
        return;
      }
      let contact_number_validate = valid_contact_number(
        employer.contact_number,
      );
      if (employer.secondary_number) {
        let secondary_number = valid_contact_number(employer.secondary_number);
        if (secondary_number == false) {
          setError({ common: 'Secondary number not valid' });
          // setDisabled(false);
          return;
        }
      }

      if (contact_number_validate == false) {
        setError({ common: 'Enter a valid phone number' });
        return;
      }
      if (
        (employer.is_vat == 1 && employer.vat_percentage <= 0) ||
        employer.vat_percentage > 100
      ) {
        setError({ common: ' Vat percentage not valid' });
        return;
      }
      if (
        employer.vacancy_percentage <= 0 ||
        employer.vacancy_percentage > 80
      ) {
        setError({ common: ' Vacancy percentage not valid' });
        return;
      }

      setIsLoading(true);

      // JSON.stringify(newEmployer.key_contact);

      // -----register employer from login page--------no default businessunit,------add businessunit----------
      if (employer.active_status == 0) {
        employer.addbusinessunit = 1;
      }
      employer.active_status = 1;

      if (permenentemployer) {
        employer.permenentemployer = true;
      }
      employer.permenentemployer = true;
      dispatch(employerActions.permanentemployerAdd(employer)).then(
        // dispatch(employerActions.employerAdd(employer)).then(
        () => {
          navigate('/dashboardemployer');
          setIsLoading(false);
        },
        err => {
          setError({
            common: err ? err : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
    }
  };

  return (
    <div className="EmployerAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2 ">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className="  page-header ">
            <div className="page-title-content">
              {/* <img
                  src="/assets/img/eyeclose.png"
                  style={{ height: '25px' }}
                  onClick={() => {
                    setstage(1);
                  }}
                ></img> */}

              {/* <p className="card-title">
                {employer && employer._id ? 'Update' : 'Add'} employer{' '}
              </p> */}
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit} autoComplete="off">
              <div>
                <div className="row">
                  <div className="col-md-12 ">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Company name</label>
                      <input
                        type="company_name  "
                        className="form-control"
                        name="company_name"
                        placeholder="company name"
                        value={employer.company_name}
                        onChange={handleChange}
                      />
                      {submitted && !employer.company_name && (
                        <div className="help-block">
                          company name is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {!permenentemployer && (
                    <div className="col-md-6 ">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="email"
                          disabled
                          value={
                            employer.email
                              ? employer.email
                              : employer.company_email
                          }
                          onChange={handleChange}
                        />
                        {submitted && !employer.email && (
                          <div className="help-block">email is required</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={!permenentemployer ? 'col-md-6 ' : 'col-md-12'}
                  >
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">contact number</label>
                      <input
                        type="number"
                        step="any"
                        className="form-control"
                        name="contact_number"
                        placeholder="contact number"
                        value={
                          employer.contact_number
                            ? employer.contact_number
                            : employer.company_phone
                        }
                        onChange={handleChange}
                      />
                      {submitted && !employer.contact_number && (
                        <div className="help-block">
                          contact number is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6  ">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Select Priority</label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="priority"
                        value={employer.priority}
                        onChange={handleChange}
                      >
                        <option value=""> select</option>
                        <option value={'1'}>1</option>
                        <option value={'2'}>2</option>
                      </select>
                      {submitted && !employer.priority && (
                        <div className="help-block">
                          priority required is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Select Tier</label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="tier"
                        placeholder="select tier"
                        value={employer.tier}
                        onChange={handleChange}
                      >
                        <option value=""> select</option>
                        <option value={'1'}>Tier 1</option>
                        <option selected={true} value={'2'}>
                          Tier 2
                        </option>
                      </select>
                      {submitted && !employer.tier && (
                        <div className="help-block">tier is required</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Select CDM Staff</label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="cdm_staff"
                        value={
                          employer.cdm_staff
                            ? employer.cdm_staff
                            : employer.staff_id
                        }
                        onChange={handleChange}
                      >
                        <option value=""> select Staff</option>
                        {staffrows}
                      </select>
                      {submitted && !employer.cdm_staff && (
                        <div className="help-block">cdm staff is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Scope</label>
                      <input
                        type="scope"
                        className="form-control"
                        name="scope"
                        placeholder="scope"
                        value={employer.scope}
                        onChange={handleChange}
                      />
                      {submitted && !employer.scope && (
                        <div className="help-block">scope is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Postcode</label>
                      <input
                        type="text"
                        className="form-control"
                        name="postcode"
                        placeholder="postcode "
                        value={employer.postcode}
                        onChange={handleChangePostcode}
                      />
                      {submitted && !employer.postcode && (
                        <div className="help-block">postcode is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Address</label>
                      <select
                        className="form-control"
                        name="address"
                        placeholder="address"
                        value={employer.address}
                        onChange={handleChange}
                      >
                        <option value={''}>select</option>
                        {PostcodeObject.addresses}
                      </select>
                      {submitted && !employer.address && (
                        <div className="help-block">address is required</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mb-4'}>
                      <input
                        type="address2"
                        className="form-control"
                        name="address2"
                        placeholder="Address2 "
                        value={employer.address2}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mb-4'}>
                      <input
                        type="number"
                        className="form-control"
                        name="secondary_number"
                        placeholder="secondary number"
                        value={employer.secondary_number}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mt-3'}>
                      <label htmlFor="type">Website</label>
                      <input
                        type="Website"
                        className="form-control"
                        name="website"
                        placeholder="website"
                        value={employer.website}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mt-3'}>
                      <label htmlFor="type"> Job Description</label>
                      <input
                        type="job_description"
                        className="form-control"
                        name="job_description"
                        placeholder="Job description"
                        value={employer.job_description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group mt-3' +
                        (submitted && !employer.service_type
                          ? ' has-error'
                          : '')
                      }
                    >
                      <label htmlFor="type">Service Sector</label>
                      <select
                        className="select col-md-12 selectDesign"
                        name="service_type"
                        value={employer.service_type}
                        onChange={handleChange}
                      >
                        <option value=""> select</option>
                        <option value={'Hospitality'}>Hospitality</option>
                        <option value={'Health Care'}>Health Care</option>

                        {/* <option value={""}></option> */}
                      </select>

                      {/*Required*/}
                      {/* {submitted && !employer.service_type && (
                      <div className="help-block">
                        service_type is required
                      </div>
                    )} */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mt-3'}>
                      <label htmlFor="type">Other info</label>
                      <input
                        type="other_info"
                        className="form-control"
                        name="other_info"
                        placeholder="other info"
                        value={employer.other_info}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mt-3'}>
                      <label htmlFor="type">Vacancy percentage</label>
                      <input
                        type="number"
                        className="form-control"
                        name="vacancy_percentage"
                        placeholder="vacancy percentage"
                        value={employer.vacancy_percentage}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type" className="mr-2">
                        Is vat applicable
                      </label>

                      <input
                        type="checkbox"
                        className="mr-2 ml-4"
                        // className="form-control"
                        name="is_vat"
                        placeholder="is_vat"
                        // value={1}
                        // checked={employer.pay_break == 1}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type" className="mr-2">
                        Break paid
                      </label>

                      <input
                        type="radio"
                        className="mr-2 ml-4"
                        // className="form-control"
                        name="pay_break"
                        placeholder="pay_break"
                        value={1}
                        checked={employer.pay_break == 1}
                        onChange={handleChange}
                      />
                      <label className="mr-2 ">Yes</label>

                      <input
                        type="radio"
                        className="mr-2"
                        name="pay_break"
                        placeholder="pay_break"
                        value={0}
                        checked={employer.pay_break == 0}
                        onChange={handleChange}
                      />
                      <label className="mr-2">No</label>
                    </div>
                  </div>
                </div>

                {employer.is_vat == 1 && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">Vat Percentage</label>
                        <input
                          type="number"
                          step="any"
                          className="form-control form-group-50px"
                          name="vat_percentage"
                          placeholder="Vat Percentage "
                          value={employer.vat_percentage}
                          onChange={handleChange}
                        />

                        {submitted && !employer.vat_percentage && (
                          <div className="help-block">
                            Vat Percentage is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {employer.pay_break == 1 && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">Break time</label>
                        <input
                          type="number"
                          step="any"
                          className="form-control form-group-50px"
                          name="break_time"
                          placeholder="Break Time "
                          value={employer.break_time}
                          onChange={handleChange}
                        />

                        {submitted && !employer.break_time && (
                          <div className="help-block">
                            Break_time is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* //------------key contact section -------------- */}
                <div className="row">
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mt-4'}>
                      <label htmlFor="type" className="mr-2">
                        Key contact
                      </label>
                      <i
                        onClick={() => {
                          setClownCounter(clownCounter + 1);
                        }}
                        className="fa fa-plus-circle"
                        style={{
                          fontSize: '26px',
                          color: ' #3388EB',
                          cursor: 'pointer',
                        }}
                      ></i>
                    </div>
                    {Array.from(
                      { length: clownCounter },
                      (_unused, index) => index + 1,
                    ).map((e, i) => (
                      <div key={i}>
                        <div className="col-md-12">
                          <div className="row ">
                            <div className="col-md-6">
                              <label>Keycontact {i + 1}</label>
                            </div>
                            <div className="col-md-6">
                              <div className="d-flex justify-content-end">
                                <i
                                  onClick={() => removeKeyElement(i)}
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: '22px',
                                    color: ' #787878',
                                    cursor: 'pointer',
                                  }}
                                ></i>{' '}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 pr-1">
                              <div className={'form-group mb-4'}>
                                <label htmlFor="type">Name</label>
                                <input
                                  type="name"
                                  className="form-control"
                                  name="key_name"
                                  placeholder="name"
                                  value={
                                    employer.key_contact &&
                                    employer.key_contact[i] &&
                                    employer.key_contact[i].key_name
                                  }
                                  onChange={e => handleChangekeycontact(e, i)}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 pr-1">
                              <div className={'form-group mb-4'}>
                                <label htmlFor="type">email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="key_email"
                                  placeholder="email"
                                  value={
                                    employer.key_contact &&
                                    employer.key_contact[i] &&
                                    employer.key_contact[i].key_email
                                  }
                                  onChange={e => handleChangekeycontact(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 pr-1">
                              <div className={'form-group mb-4'}>
                                <label htmlFor="type">phone</label>
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="key_phone"
                                  placeholder="phone"
                                  value={
                                    employer.key_contact &&
                                    employer.key_contact[i] &&
                                    employer.key_contact[i].key_phone
                                  }
                                  onChange={e => handleChangekeycontact(e, i)}
                                />
                              </div>
                            </div>

                            <div className="col-md-6 pr-1">
                              <div className={'form-group mb-4'}>
                                <label htmlFor="type">position</label>
                                <input
                                  type="position"
                                  className="form-control"
                                  name="key_position"
                                  placeholder="position"
                                  value={
                                    employer.key_contact &&
                                    employer.key_contact[i] &&
                                    employer.key_contact[i].key_position
                                  }
                                  onChange={e => handleChangekeycontact(e, i)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group mt-3'}>
                      <label htmlFor="type">Preference</label>
                      <textarea
                        name="preference"
                        placeholder="preference"
                        value={employer.preference}
                        onChange={handleChange}
                        id="preference"
                        className="form-control"
                        rows="4"
                        cols="50"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 ">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Upload Profile image</label>
                      <div className="d-flex">
                        <div className="col-md-8 d-flex justify-content-between align-items-center filenames">
                          <div>
                            {employer && employer.profileimage
                              ? employer.profileimage
                              : 'no image uploaded'}
                          </div>
                          <div className="float-right">
                            {' '}
                            <div
                              className="changebutton m-2 p-3"
                              onClick={showfileupload}
                            >
                              Change
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                          }}
                        >
                          {employer && employer.profileimage ? (
                            <img
                              className=" round-emp-image"
                              src={apiConstants.imgUrl + employer.profileimage}
                            />
                          ) : (
                            <img
                              className=" round-emp-image"
                              src="/assets/img/recsy-fav-icon.png"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 pr-1">
                    <span className="color-red">{error.common}</span>
                    <div className="form-group d-flex justify-content-end">
                      <button className="btn bluebutton btn-wd mt-2">
                        Update
                        {props.requestStatus ===
                          reducerConstants.EMPLOYER_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* //-----------file uplaod modal view --------------- */}
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'Profile image'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedPermenentemployerAddd = connect(mapStateToProps)(
  PermenentemployerAddd,
);
export { connectedPermenentemployerAddd as PermenentemployerAddd };
