import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { userActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const User = props => {
  let { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ name: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllUser(1, query);
    }
  }, []);
  /**
   * Get all users
   * */
  const getAllUser = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(userActions.userList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setUser(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="User">
      {(props.requestStatus === reducerConstants.USER_REQUEST || isLoading) && (
        <LoadingModal />
      )}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">User view</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">email</label>
              </div>
              <div className="col-md-6">
                <b>{user.email}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">phone</label>
              </div>
              <div className="col-md-6">
                <b>{user.phone}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">password</label>
              </div>
              <div className="col-md-6">
                <b>{user.password}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Profile_pic</label>
              </div>
              <div className="col-md-6">
                <b>{user.Profile_pic}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Postcode</label>
              </div>
              <div className="col-md-6">
                <b>{user.Postcode}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">status</label>
              </div>
              <div className="col-md-6">
                <b>{user.status}</b>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <Link className="link" to={'/useradd/' + user._id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}
const connectedUser = connect(mapStateToProps)(User);
export { connectedUser as User };
