import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { categoryActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Category = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });

  // loader for get all category
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllCategory(1, query);
    }
    if (props && props.id) {
      query._id = props.id;
      setQuery(query);
      getAllCategory(1, query);
    }
  }, []);
  /**
   * Get all categorys
   * */
  const getAllCategory = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(categoryActions.categoryList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setCategory(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Category">
      {((props &&
        props.requestStatus &&
        props.requestStatus === reducerConstants.CATEGORY_REQUEST) ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Jobrole view</h4>
            </div>
          </div>
          <div className=" col text_align">
            <Link
              data-cy="editcat"
              className="link"
              to={'/categoryadd/' + category._id}
            >
              <i className="fas fa-pen pointer f18"></i>
            </Link>
          </div>
          <div className="card-body ">
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Name</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Morning price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.morning_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Evening Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.evening_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Night Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.night_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Holiday Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.holiday_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Day Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.day_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Weekend Day Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.weekend_day_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Weekend Night Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.weekend_night_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Minimum Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.minimum_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Minimum Employer Price</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.minimum_employer_price}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                <label>Vacancy Percentage</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{category.vacancy_percentage}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedCategory = connect(mapStateToProps)(Category);
export { connectedCategory as Category };
