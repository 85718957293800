import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const benefitsEmployerService = {
  addJobRole,
  //   benefitsList,
  //   benefitsDelete,
  //   changeStatusBenefits,
};

function addJobRole(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/benefitemployer/jobs',
    requestOptions,
  ).then(miscService.handleResponse);
}
// function benefitsList(data) {
//   const queryParams = new URLSearchParams(data).toString();
//   const url = apiConstants.apiUrl + '/benefitemployer?' + queryParams;

//   const requestOptions = {
//     method: 'GET',
//     headers: authHeader(),
//   };

//   return fetch(url, requestOptions).then(miscService.handleResponse);
// }

// function benefitsDelete(data) {
//   const requestOptions = {
//     method: 'DELETE',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(apiConstants.apiUrl + '/benefitmaster/:id', requestOptions).then(
//     miscService.handleResponse,
//   );
// }
// function changeStatusBenefits(data) {
//   console.log(data, 'Benefit status change==>4156');
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/changestatusbenefits',
//     requestOptions,
//   ).then(miscService.handleResponse);
// }
