import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { candidateActions, payoutActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

const PayoutAdd = props => {
  const { id } = useParams();
  const [payout, setpayout] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shiftdetails, setshiftdetails] = useState({});
  const [error, setError] = useState({ common: '' });
  // effect to call total shifts
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
    }
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
    }
    if (payout.from && payout.to && payout.user) {
      gettotalshifts();
    }
  }, [payout.from, payout.to, payout.user]);
  // get shift data
  const gettotalshifts = () => {
    const { dispatch } = props;
    dispatch(candidateActions.candidateshifts(payout)).then(
      res => {
        setIsLoading(false);
        setshiftdetails(res.data[0][0]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // handler for select user from select box
  const selecteduser = selectedOption => {
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      setpayout(prevState => ({
        ...prevState,
        user: SelectedValues,
      }));
    }
  };
  // handler for date selection
  const handleChange = event => {
    const { name, value } = event.target;
    setpayout({
      ...payout,
      [name]: value,
    });
  };
  // handler for shift timings
  const totalshift = () => {
    const { name, value } = event.target;
    setshiftdetails({
      ...shiftdetails,
      [name]: value,
    });
  };
  // submit action for payout add
  const handleSubmit = event => {
    setSubmitted(true);
    event.preventDefault();
    const { dispatch } = props;
    if (
      payout.from &&
      payout.to &&
      payout.user &&
      shiftdetails &&
      shiftdetails.total_hours &&
      shiftdetails.total_hours
    ) {
      let queryNew = {};
      queryNew.from = payout.from;
      queryNew.to = payout.to;
      queryNew.user = payout.user;
      queryNew.total_night = shiftdetails.total_night;
      queryNew.total_custome = shiftdetails.total_custome;
      queryNew.total_morning = shiftdetails.total_morning;
      queryNew.total_hours = shiftdetails.total_hours;
      dispatch(payoutActions.payoutAdd(queryNew)).then(
        res => {
          if (res) {
            props.removepopup();
            setIsLoading(false);
          }
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'No available shift' });
    }
  };
  return (
    <div className="CandidateAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card ">
          <div className=" page-header ">
            <div className="row">
              <div className="col-md-8">
                <div className="page-title">Generate payout</div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6 ">
                  <label htmlFor="Candidate_search">Candidate search</label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 ">
                  <div className={'form-group '}>
                    <AsyncSelect
                      name="user"
                      onChange={selecteduser}
                      cacheOptions
                      defaultOptions
                      isMulti
                      loadOptions={getcandidatelist}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="card-title">Select date</label>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !payout.from ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="from"
                      placeholder=""
                      value={payout.from}
                      onChange={handleChange}
                    />
                    {submitted && !payout.from && (
                      <div className="help-block">from date is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !payout.to ? ' has-error' : '')
                    }
                  >
                    <label htmlFor="type">To</label>
                    <input
                      type="date"
                      className="form-control"
                      name="to"
                      placeholder=""
                      value={payout.to}
                      onChange={handleChange}
                    />
                    {submitted && !payout.to && (
                      <div className="help-block">to date is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 pr-1">
                  <label htmlFor="type">Night Shift</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !shiftdetails.total_night
                        ? ' has-error'
                        : '')
                    }
                  >
                    <input
                      type="total_night"
                      className="form-control"
                      name="total_night"
                      placeholder=""
                      value={
                        shiftdetails &&
                        shiftdetails.total_night &&
                        shiftdetails.total_night
                      }
                      onChange={totalshift}
                    />
                    {submitted && !shiftdetails.total_night && (
                      <div className="help-block">Night Shift is required</div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 pr-1">
                  <label htmlFor="type">Morning Shift</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !shiftdetails.total_morning
                        ? ' has-error'
                        : '')
                    }
                  >
                    <input
                      type="total_morning"
                      className="form-control"
                      name="total_morning"
                      placeholder=""
                      value={
                        shiftdetails &&
                        shiftdetails.total_morning &&
                        shiftdetails.total_morning
                      }
                      onChange={totalshift}
                    />
                    {submitted && !shiftdetails.total_morning && (
                      <div className="help-block">
                        Morning Shift is required
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 pr-1">
                  <label htmlFor="type">Custom Shift</label>
                  <div
                    className={
                      'form-group ' +
                      (submitted && !shiftdetails.total_custome
                        ? ' has-error'
                        : '')
                    }
                  >
                    <input
                      type="total_custome"
                      className="form-control"
                      name="total_custome"
                      placeholder=""
                      value={
                        shiftdetails &&
                        shiftdetails.total_custome &&
                        shiftdetails.total_custome
                      }
                      onChange={totalshift}
                    />
                    {submitted && !shiftdetails.total_custome && (
                      <div className="help-block">
                        Custome Shift is required
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted &&
                      shiftdetails &&
                      shiftdetails.total_hours &&
                      !shiftdetails.total_hours
                        ? ' has-error'
                        : '')
                    }
                  >
                    <label htmlFor="type">Total working hrs</label>
                    <input
                      type="total_hours"
                      className="form-control"
                      name="total_hours"
                      placeholder=""
                      value={
                        shiftdetails &&
                        shiftdetails.total_hours &&
                        shiftdetails.total_hours &&
                        shiftdetails.total_hours
                      }
                      onChange={totalshift}
                      disabled
                    />
                    {submitted &&
                      shiftdetails &&
                      shiftdetails.total_hours &&
                      !shiftdetails.total_hours && (
                        <div className="help-block">
                          Working hrs is required
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !shiftdetails.note ? ' has-error' : '')
                    }
                  >
                    <textarea
                      name="note"
                      className="form-control"
                      placeholder="Add note"
                      value={shiftdetails.note}
                      onChange={totalshift}
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="float-right">
                      {' '}
                      <button className="btn btn-primary">
                        Save
                        {props.requestStatus ===
                          reducerConstants.CANDIDATE_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

// loader to get candidates for select box
const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.candidate.data.rows.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedPayoutAdd = connect(mapStateToProps)(PayoutAdd);
export { connectedPayoutAdd as PayoutAdd };
