import React, { useEffect, useState } from 'react';
import { bookingActions, candidateActions } from '../_actions';
import { miscService } from '../_services';

const InterestedCandidate = ({
  dispatch,
  postcode,
  isFullScreen,
  vacancy_details,
  setSelectedCandidateLocation,
  onSuccess,
}) => {
  const [candidateData, setCandidateData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [candidateIndex, setCandidateIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getallAvailablecandidate(1, { postcode: postcode });
  }, []);
  useEffect(() => {
    setSelectedCandidateLocation({
      lat: candidateData?.[candidateIndex]?.lat,
      long: candidateData?.[candidateIndex]?.long,
      name:
        candidateData?.[candidateIndex]?.name +
        ' ' +
        candidateData?.[candidateIndex]?.last_name,
    });
  }, [candidateIndex]);
  //--- only for progress bar ---
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => prev + 1);
      //   if (!candidateData?.[candidateIndex + 1]?.name) {
      //     clearInterval(interval);
      //   }
      if (candidateIndex >= candidateData.length) {
        setCandidateIndex(0);
      }
      if (progress >= 30) {
        setCandidateIndex(candidateIndex + 1);
        setProgress(0);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [progress, candidateIndex]);
  //--- to get available candidate in the vacancy location ---
  //--- using vacancy postcode to get candidate ---
  const getallAvailablecandidate = page => {
    let queryNew = {
      pageVo: {
        pageNo: page,
        noOfItems: 30,
      },
    };

    // queryNew.postcode = query.postcode;
    queryNew.status = 1;
    dispatch(candidateActions.candidateListv2(queryNew)).then(res => {
      // onTimeoutRenderCandidate(res.data.candidate.data);
      setCandidateData(res.data.candidate.data.rows);
    });
  };
  //--- allocate candidate to vacancy ---
  const allocatecandidateTovacancy = candidatedetails => {
    let allocation_data = {
      applied_date: new Date().toISOString().slice(0, 10),
      booking_status: 0,
      candidate_id: candidatedetails._id,
      candidate_userid: candidatedetails.user_id,
      employer_id: vacancy_details.employer_id,
      vacancy_id: vacancy_details._id,
    };

    if (allocation_data.candidate_userid && allocation_data.candidate_id) {
      setIsLoading(true);
      dispatch(bookingActions.bookingAdd(allocation_data)).then(
        res => {
          setIsLoading(false);
          onSuccess(res);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };
  return (
    <div
      className="col-md-12"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <div
        className={
          isFullScreen
            ? 'map-interestedApplicantBarFull'
            : 'map-interestedApplicantBar'
        }
      >
        <div className="row m-0" style={{ borderBottom: '1px solid #e8e8e8' }}>
          <div className="col-md-12 ml-2 mr-4 bookings-heading">
            Interested Applicants
          </div>
        </div>
        {candidateIndex <= candidateData.length && candidateData.length != 0 ? (
          <>
            <div>
              <div className="progress-bar" style={{ borderRadius: 5 }}>
                <div
                  className="progress"
                  style={{ width: `${(progress / 30) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="map-bookinglistHeading">
              <div className="bookings-heading ml-1">
                <img
                  src="/assets/img/recsy-fav-icon.png"
                  style={{ height: 30, width: 30 }}
                />
                {candidateData?.[candidateIndex]?.name +
                  ' ' +
                  candidateData?.[candidateIndex]?.last_name}
              </div>
              <p>
                {miscService.calculateDistance(
                  vacancy_details?.lat,
                  vacancy_details.long,
                  candidateData?.[candidateIndex]?.lat,
                  candidateData?.[candidateIndex]?.long,
                )}{' '}
                miles away
              </p>
              <div className="d-flex mt-2" style={{ gap: '1rem' }}>
                <div
                  className="map-bookingChildSelectButton"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    !isLoading &&
                      allocatecandidateTovacancy(candidateData[candidateIndex]);
                  }}
                >
                  Allocate
                  <i className="fa fa-arrow-right" />
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="pl-3">No candidates available</p>
        )}
      </div>
    </div>
  );
};

export default InterestedCandidate;
