import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { educationService } from '../_services';
export const educationActions = {
  educationAdd,
  educationList,
  educationDelete,
};

function educationAdd(educationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.EDUCATION_REQUEST, educationObj });

      educationService.educationAdd(educationObj).then(
        data => {
          dispatch({
            type: reducerConstants.EDUCATION_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.EDUCATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function educationList(educationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EDUCATION_REQUEST,
        educationObj,
      });

      educationService.educationList(educationObj).then(
        data => {
          dispatch({
            type: reducerConstants.EDUCATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EDUCATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function educationDelete(educationObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EDUCATION_REQUEST,
        educationObj,
      });

      educationService.educationDelete(educationObj).then(
        data => {
          dispatch({
            type: reducerConstants.EDUCATION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EDUCATION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
