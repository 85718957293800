import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const agencycandidateService = {
  agencycandidateAdd,
  agencycandidateList,
  agencycandidateDelete,
};

function agencycandidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/addagencycandidate',
    requestOptions,
  ).then(miscService.handleResponse);
}

function agencycandidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getagencycandidateList',
    requestOptions,
  ).then(miscService.handleResponse);
}

function agencycandidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deleteagencycandidate',
    requestOptions,
  ).then(miscService.handleResponse);
}
