import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShiftsAdd, ShiftsList } from '../Shifts';
import { candidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const ViewProfile = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [iscomponent, setiscomponent] = useState(1);
  const [candidate, setCandidate] = useState({});
  // eslint-disable-next-line no-unused-vars
  let age = '';
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
      is_worked: true,
    },
    is_candidate_with_docs: true,
  });
  let candidate_id = id;
  // effect to call get candidate info
  useEffect(() => {
    if (id !== undefined) {
      query._id = id;
      setQuery(query);
      getAllCandidate(1, query);
    }
  }, []);
  // to get age
  function getAge(dateString) {
    if (dateString) {
      // converting dateString in the format 'DD-MM-YYYY'
      const dateMoment = moment(dateString, 'DD-MM-YYYY');
      // Define the birthdate in a format like 'YYYY-MM-DD'
      const birthdate = dateMoment.format('YYYY-MM-DD');
      // Calculate the current date
      const currentDate = moment();
      // Calculate the age
      const age = currentDate.diff(moment(birthdate), 'years');
      return age;
    } else {
      return '--';
    }
  }
  /**
   * Get all candidates
   * */
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);

    const { dispatch } = props;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        if (res && res.data) {
          setCandidate(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // open or close shift modal
  const showAddshiftmodal = value => {
    setshowmodal(value);
  };
  return (
    <div className="Candidate">
      {props.requestStatus === reducerConstants.CANDIDATE_REQUEST ||
        (isLoading && <LoadingModal />)}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <div className="d-flex mt-20">
                <div style={{ marginTop: '-5px' }}>
                  <img
                    src="/assets/img/eyeclose.png"
                    onClick={() => {
                      props.history.goBack();
                    }}
                    style={{ height: '25px', cursor: 'pointer' }}
                  ></img>
                </div>
                <div>
                  <h4 className="txt_up font-weight-bold ml-10">
                    {candidate.name + ' '}
                    {candidate.last_name ? candidate.last_name : ''} profile
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body disp">
            <div className="row d-flex mb-10">
              <div className="col-md-7 d-flex table_box_shadow">
                <div className="col-md-9 pd15">
                  {candidate.is_worked != 0 && (
                    <div className="d-flex">
                      <div className="workedcandidate">
                        {' '}
                        {candidate.is_worked != 0 ? (
                          <p style={{ fontWeight: 'bold' }}>
                            Already worked candidate
                          </p>
                        ) : (
                          ''
                        )}{' '}
                      </div>
                      <div>
                        {' '}
                        <img
                          className="tick-mark"
                          src="/assets/img/tick-mark2.png"
                        />
                      </div>
                    </div>
                  )}
                  <h5 className="txt_up" style={{ color: 'black' }}>
                    {candidate.name + ' '}
                    {candidate.last_name ? candidate.last_name : ''}
                  </h5>
                  <i className="fas fa-phone"></i> {candidate.contact_number}
                  <br />
                  <i className="fas fa-envelope"></i> {candidate.email}
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Age</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{getAge(candidate.dob)}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Nationality</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate.nationality}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Jobrole</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate['Category.name']}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Address</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate.address}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>City</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate.city}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Postcode</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate.postcode}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <label>Experience</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{candidate.experience}</p>
                    </div>
                  </div>
                  {candidate.lat && candidate.long && (
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Location</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col">
                        <a
                          href={
                            'https://www.google.com/maps/search/?api=1&query=' +
                            candidate.lat +
                            ',' +
                            candidate.long
                          }
                        >
                          Open location
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-between pd15">
                  {candidate.profileimage ? (
                    <img
                      className=" round-emp-image-single"
                      src={apiConstants.imgUrl + candidate.profileimage}
                    />
                  ) : (
                    <img
                      className=" round-emp-image-single"
                      src="/assets/img/recsy-fav-icon.png"
                    />
                  )}
                  <div></div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                {' '}
                <div className="col-md-12">
                  <p className="float-right shiftstyle">Assign Shift</p>
                  <img
                    className="float-right"
                    src="/assets/img/Plus.png"
                    style={{ height: '25px', cursor: 'pointer' }}
                    onClick={() => setshowmodal(true)}
                  ></img>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <button
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 1
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(1)}
              >
                {' '}
                Upcoming Shift
              </button>
              <button
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 2
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(2)}
              >
                {' '}
                Complete Shift
              </button>
              <button
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 3
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(3)}
              >
                {' '}
                Ongoing Shift
              </button>
              <button
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 4
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(4)}
              >
                Time Sheet
              </button>
            </div>
            {iscomponent == 1 && (
              <div>
                <ShiftsList
                  shifttype={'upcomingshift'}
                  // candidate_id={query._id}
                  candidate_id={candidate_id}
                />
              </div>
            )}
            {iscomponent == 2 && (
              <div>
                <ShiftsList
                  shifttype={'completeshift'}
                  candidate_id={query._id}
                />
              </div>
            )}
            {iscomponent == 3 && (
              <div>
                <ShiftsList
                  shifttype={'ongoingshift'}
                  candidate_id={query._id}
                />
              </div>
            )}
            {iscomponent == 4 && (
              <div>
                <ShiftsList shifttype={'timesheet'} candidate_id={query._id} />
              </div>
            )}
            {showmodal && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="close">
                        <a onClick={() => setshowmodal(false)}>
                          <i className=" fa fa-times-circle red fs-18"></i>
                        </a>
                      </div>
                    </div>
                    <div className="modal-body">
                      <ShiftsAdd
                        showAddshiftmodal={showAddshiftmodal}
                        id={query._id}
                        candidate_id={query._id}
                        user_id={candidate.user_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedViewProfile = connect(mapStateToProps)(ViewProfile);
export { connectedViewProfile as ViewProfile };
