export const RegPopup = ({ onCancel, onAnotherAction }) => {
  return (
    <div className="modal-dialog flx-modal-popup mainmodal" role="document">
      <div className="modal-content ">
        <div className="modal-header">
          <h5 className="flx-email-change-req-modal-title" id="modalLabel"></h5>
          <button
            type="button"
            className="close flx-closebutton-popup my-1"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onAnotherAction}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body pr-4">
          <div
            className="flx-popup-body flx-popup-body-email-change-req pl-2 pt-3"
            style={{ fontWeight: '500', fontSize: '14px' }}
          >
            You have already registered using this email. Please use sign in to
            access your account.
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ padding: '16px 16px 16px 0px' }}
        >
          <a
            className="btn btn-sm flx-popup-button textColor"
            onClick={onCancel}
          >
            Ok
          </a>
        </div>
      </div>
    </div>
  );
};
