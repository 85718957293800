export function candidateblock(
  state = {
    requestStatus: 0,
    blockcandidateData: {},
    blockbookingData: {},
    data: {},
  },
  action,
) {
  switch (action.type) {
    case 'BLOCKCANDIDATE_SUCCESS':
      return {
        ...state,
        requestStatus: 'BLOCKCANDIDATE_SUCCESS',
        requestBlockcandidateStatus: 'BLOCKCANDIDATE_SUCCESS',
        blockcandidateData: action.data.data,
        data: action.data,
      };
    case 'BLOCKCANDIDATE_FAILURE':
      return {
        ...state,
        requestStatus: 'BLOCKCANDIDATE_FAILURE',
        requestBlockcandidateStatus: 'BLOCKCANDIDATE_FAILURE',
        blockcandidateData: {},
        data: action.data,
      };
    case 'BLOCKCANDIDATE_REQUEST':
      return {
        ...state,
        requestStatus: 'BLOCKCANDIDATE_REQUEST',
        requestBlockcandidateStatus: 'BLOCKCANDIDATE_REQUEST',
        data: action.data,
      };

    default:
      return state;
  }
}
