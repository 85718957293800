import React from 'react';

const EmailChangeConfirmPopup = (props) => {
    return (
        <div className="modal-dialog flx-modal-popup" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="flx-email-change-req-modal-title" id="modalLabel">
                        Please confirm new email
                    </h5>
                    <button
                        type="button"
                        className="close flx-closebutton-popup"
                        aria-label="Close"
                        onClick={() => props?.handleClosePopup()}
                    >
                        <span aria-hidden="true" className="flx-closeicon">
                            &times;
                        </span>
                    </button>
                </div>
                <div className="modal-body">
                    <p className="flx-popup-body flx-popup-body-email-change-req">
                        After confirmation, an OTP will be sent to the new email for updating your user account.
                    </p>
                </div>
                <div className="flx-popup-bottom flx-change-email-popup-footer">
                    <button type="button" className="btn btn-sm flx-popup-button" onClick={(e) => { e?.preventDefault(), props?.handleSubmit(), props?.handleClosePopup() }}>
                        Confirm
                    </button>
                    <a onClick={() => props?.handleClosePopup()} className="flx-popup-email-change-cancel">
                        Cancel
                    </a>
                </div>
            </div>
        </div>
    );
};

export default EmailChangeConfirmPopup;
