import React, { useRef, useState } from 'react';
import { alertActions } from '../_actions';
import { LoadingImg } from '../_components';
import { apiConstants } from '../_constants';
import { miscService } from '../_services';

const ProfileUpload = props => {
  const { profile, setShowmodalfileEdit, getuploadresponse } = props;

  const [isLoading, setisLoading] = useState(false);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);

  const fileInputRef = useRef(null);

  const handleProfilePictureChangeButton = () => {
    fileInputRef.current.click();
  };

  const handleProfilePicture = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setProfilePicture(reader.result);
    };
    reader.readAsDataURL(file);
    setSelectedProfilePicture(file);
  };

  const handleProfilePictureSave = event => {
    event.preventDefault();
    setisLoading(true);
    if (selectedProfilePicture) {
      const { dispatch } = props;
      let ext = '';
      ext = selectedProfilePicture.name.split('.').pop();
      miscService
        .s3frontEndUploadUrl({
          type: 'document',
          mime_type: selectedProfilePicture.type,
          ext,
        })
        .then(
          async res => {
            let signedurl = res.data.signedUrl;
            let file_name = res.data.filename;

            fetch(signedurl, {
              method: 'PUT',
              headers: {
                'Content-Type': selectedProfilePicture.type,
                'Content-Disposition': 'inline',
              },
              body: selectedProfilePicture,
            })
              .then(response => response.text())
              .catch(() => {
                setisLoading(false);
                dispatch(alertActions.error('File Upload Failed'));
              })
              .then(res => {
                if (res !== undefined) {
                  getuploadresponse({
                    file_name: file_name,
                    file: selectedProfilePicture,
                  });
                  setShowmodalfileEdit(false);
                  setisLoading(false);
                }
              });
          },
          () => {
            setisLoading(false);
            dispatch(alertActions.error('File Upload Failed'));
          },
        );
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', background: 'rgb(10 11 11 / 50%)' }}
    >
      <div className="modal-dialog" style={{ width: '477px' }}>
        <div className="modal-content">
          <div className="modal-header" style={{ padding: '0' }}>
            <div className="close" onClick={() => setShowmodalfileEdit(false)}>
              <i className="fa fa-times-circle red fs-18"></i>
            </div>
          </div>
          <div className="modal-body">
            <div
              className="mb-4"
              style={{ fontSize: '16px', textAlign: 'center' }}
            >
              Upload Profile Picture
            </div>
            <div
              className="mb-2"
              style={{
                position: 'relative',
                border: '1px dashed rgba(0, 0, 0, 0.25)',
                borderRadius: '8.23px',
                height: '193px',
                padding: '20px 170px',
              }}
            >
              {(profile && profile.profileimage) || profilePicture ? (
                <>
                  <img
                    className="profile-emp-image"
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    src={
                      profilePicture
                        ? profilePicture
                        : apiConstants.imgUrl +
                          (profile.profileimage || profile.profileimage)
                    }
                  />
                </>
              ) : (
                <div
                  className="flx-company-info initials-circle d-flex align-items-center justify-content-center"
                  style={{
                    width: '81px',
                    height: '78px',
                    position: 'relative',
                    borderRadius: '18px',
                  }}
                >
                  {profile.name ? profile.name[0].toUpperCase() : ''}
                </div>
              )}
              <label htmlFor="fileInput">
                {!isLoading && (
                  <button
                    className="mt-3 ml-2 view-profile-btn"
                    onClick={handleProfilePictureChangeButton}
                  >
                    Change
                  </button>
                )}

                <input
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  onChange={handleProfilePicture}
                  style={{ display: 'none' }}
                />
              </label>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <LoadingImg />
              </div>
            ) : (
              selectedProfilePicture && (
                <div className="d-flex justify-content-center mt-4 ">
                  <button
                    className=""
                    onClick={() => setShowmodalfileEdit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-3"
                    style={{
                      background: '#1AC8FF',
                      borderRadius: '6px',
                      padding: '5px 20px',
                      color: '#FFFFFF',
                    }}
                    onClick={handleProfilePictureSave}
                  >
                    Save
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpload;
