import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const Samp = () => {
  useEffect(() => {}, []);
  return <div className="StaffList">hello world</div>;
};

function mapStateToProps() {
  //   const { requestStatus, LogData } = state.staff;
  return {
    // requestStatus,
    // LogData
  };
}

const connectedSamp = connect(mapStateToProps)(Samp);
export { connectedSamp as Samp };
