import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { employerActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

const DashbordOrganizationReport = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState({ rows: [] });
  const [organizationRows, setOrganizationRows] = useState([]);
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [showallshifts, setshowalshifts] = useState(false);
  const [organization_id, setorganizationid] = useState();
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  // effect to call organisation details and having query dependencies
  useEffect(() => {
    if (props && props.ishomepage) {
      query.pageVo.noOfItems = 3;
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4) {
        getAllOrganization(1);
      }
    }
  }, [query.from_date, query.end_date, query.organization_id]);
  // handler for set organization
  const setOrganization = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  /**
   * Get all organizations
   **/
  const getAllOrganization = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew.organization_id == 0) {
      delete queryNew.organization_id;
    }
    dispatch(employerActions.dashbordorganizationreport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = organizationData => {
    let organizationList = [];
    for (
      let j = 0;
      organizationData &&
      organizationData.rows &&
      organizationData.rows.length &&
      j < organizationData.rows.length;
      j = j + 1
    ) {
      organizationList.push(
        <tr key={j} className="rowcenter">
          <td>
            <div className=" d-flex align-items-center ml-2 txt-start">
              <div className=" border-rad-55 ml-2 ">
                {organizationData &&
                organizationData.rows[j] &&
                organizationData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={
                      apiConstants.imgUrl +
                      organizationData.rows[j].profileimage
                    }
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
              </div>
              <div className=" ml-2 w120">
                <p className="emp_name username">
                  {organizationData.rows[j].company_name}
                </p>
                <div className=" d-flex align-items-center">
                  <div className="location-text">
                    <span>{organizationData.rows[j].email}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td>
            {' '}
            {organizationData.rows[j].no_of_shifts
              ? organizationData.rows[j].no_of_shifts
              : 0}
          </td>
          <td>
            {' '}
            £
            {organizationData.rows[j].avg_price
              ? organizationData.rows[j].avg_price
              : 0}
          </td>
          <td style={{ color: '#ec5832' }}>
            {' '}
            {organizationData.rows[j].spend
              ? organizationData.rows[j].spend
              : 0}
          </td>
          {!props.ishomepage && (
            <td>
              <div
                className="left-right-arrow"
                onClick={() => {
                  setorganizationid(organizationData.rows[j]._id),
                    setshowalshifts(true);
                }}
              >
                <i className="fas fa-angle-right"></i>
              </div>
            </td>
          )}
        </tr>,
      );
    }
    setOrganizationRows(organizationList);
    setOrganizationData(organizationData);
  };
  // custom styles for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '45px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="EmployerList m-2">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color  ">
            {!props.ishomepage && (
              <div className="row">
                <div className="card-header page-header ">
                  <div className="page-title" style={{ fontSize: 20 }}>
                    {' '}
                    Organisation Report
                  </div>
                </div>
              </div>
            )}
            {!props.ishomepage && (
              <div className="  filter-row">
                <div className="flex-item3">
                  <div>
                    {' '}
                    <label>From date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-group-50px"
                      name="from_date"
                      placeholder="From"
                      value={fromdate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.from_date = value;
                        setQuery(newquery);
                        setfromdate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>To date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-group-50px"
                      name="end_date"
                      placeholder="From"
                      value={todate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.end_date = value;
                        setQuery(newquery);
                        settodate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>Organization</label>
                  </div>
                  <AsyncSelect
                    styles={customStyles}
                    name="organization_id"
                    className="selectorganisation"
                    onChange={setOrganization}
                    placeholder={'select organization'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getOrganizationlist}
                  />
                </div>
              </div>
            )}
            <div
              className={
                !props.isHomepage
                  ? '  table-responsive main-card-body '
                  : ' table-responsive main-card-body '
              }
              style={{ minHeight: '0px' }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {props && props.ishomepage && (
                  <>
                    <div>
                      {' '}
                      <label style={{ fontSize: 20 }}>
                        ORGANISATION REPORT
                      </label>
                    </div>
                    <div>
                      <Link to="/dashbordorganizationsreport">
                        <button className="blackbutton">View All</button>
                      </Link>
                    </div>
                  </>
                )}{' '}
              </div>
              {!isLoading && (
                <div className=" page-header">
                  <table className="table table-hover mt-2">
                    <thead>
                      <tr className="dash-head">
                        <th className=" border-top6-left">Name</th>
                        <th>No of Shift</th>
                        <th>Average Price </th>
                        <th className={props.ishomepage && 'border-top6'}>
                          Spend{' '}
                        </th>
                        {!props.ishomepage && <th className="border-top6"></th>}
                      </tr>
                    </thead>
                    <tbody>{organizationRows}</tbody>
                  </table>
                </div>
              )}
              {!!isLoading && <LoadingImg />}
              {organizationRows &&
                organizationRows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
            {!props.ishomepage && (
              <div className="card-footer">
                <Pagination
                  pageOnClick={data => {
                    getAllOrganization(data);
                  }}
                  paginationData={organizationData}
                />
              </div>
            )}
          </div>
        </div>
        {showallshifts && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close">
                      <a onClick={() => setshowalshifts(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <AllShiftsList
                    organization_id={organization_id}
                    from_date={fromdate}
                    to_date={todate}
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getOrganizationlist = inputvalue =>
  new Promise((resolve, reject) => {
    let parent_id = '';
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id && user.role == 4) {
        parent_id = user.employer_id;
      }
    }
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        parent_id: parent_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedDashbordOrganizationReport = connect(mapStateToProps)(
  DashbordOrganizationReport,
);
export { connectedDashbordOrganizationReport as DashbordOrganizationReport };
