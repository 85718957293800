import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { userService } from '../_services';
export const userActions = {
  userAdd,
  userList,
  userDelete,
  login,
  logout,
  register,
  changePassword,
  sendOtp,
  verifyOtp,
  sendResetUserCredentials,
  registerCandidate,
};

function logout() {
  userService.logout();
  return { type: reducerConstants.LOGOUT };
}

function register(user) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request(user));

      userService.register(user).then(
        user => {
          dispatch(success(user));

          // localStorage.setItem('user', JSON.stringify(user.data));

          resolve(user.data);

          // dispatch(alertActions.success(data.message.toString()));

          // alert(user);
          // history.push('/');
        },
        error => {
          reject(error);
          dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });

  function request(user) {
    return { type: reducerConstants.REGISTER_REQUEST, user };
  }

  function success(user) {
    return { type: reducerConstants.REGISTER_SUCCESS, user };
  }

  function failure(error) {
    return { type: reducerConstants.REGISTER_FAILURE, error };
  }
}

//----------register candidate thru referal ------------
function registerCandidate(user) {
  // console.log(user)
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request(user));

      userService.registerCandidate(user).then(
        user => {
          dispatch(success(user));

          //

          //  await AsyncStorage.setItem('user',JSON.stringify(user.data));

          resolve(user);
          // alert(user);
          // history.push('/');
        },
        error => {
          reject(error);
          // dispatch(failure(error?.toString()));
          // dispatch(alertActions.error(error?.toString()));
        },
      );
    });

  function request(user) {
    return { type: reducerConstants.REGISTER_REQUEST, user };
  }

  function success(user) {
    return { type: reducerConstants.REGISTER_SUCCESS, user };
  }

  // function failure(error) {
  //   return { type: reducerConstants.REGISTER_FAILURE, error };
  // }
}

// function registerDetails(user) {
//   return dispatch =>
//     new Promise((resolve, reject) => {
//       userService.registerDetails(user).then(
//         user => {
//           resolve(user);
//         },
//         error => {
//           reject(error);

//           // dispatch(alertActions.error(error?.toString()));
//         },
//       );
//     });
// }

//-------reg candiate end shere ---------

function login(username, password) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request({ username }));

      userService.login(username, password).then(
        user => {
          dispatch(success(user));

          localStorage.setItem('user', JSON.stringify(user.data));

          resolve(user.data);
          // alert(user);
          // history.push('/');
        },
        error => {
          reject(error);
          dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });

  function request(user) {
    return { type: reducerConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: reducerConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: reducerConstants.LOGIN_FAILURE, error };
  }
}

function userAdd(userObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.USER_REQUEST, userObj });

      userService.userAdd(userObj).then(
        data => {
          dispatch({
            type: reducerConstants.USER_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.USER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function userList(userObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.USER_REQUEST,
        userObj,
      });

      userService.userList(userObj).then(
        data => {
          dispatch({
            type: reducerConstants.USER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.USER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function userDelete(userObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.USER_REQUEST,
        userObj,
      });

      userService.userDelete(userObj).then(
        data => {
          dispatch({
            type: reducerConstants.USER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.USER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function changePassword(data) {
  // return dispatch => {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request({}));

      userService.changePassword(data).then(
        data => {
          //
          // dispatch(success(data));
          resolve(data);
          //  history.push('/resetPassword');
        },
        error => {
          // dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
          reject(data);
        },
      );
    });

  // REQUEST: 1,
  // SUCCESS: 2,
  // FAILURE: 3
  function request(data) {
    return { type: reducerConstants.REQUEST, data };
  }
}

////////////send otp fucntion ----------
function sendOtp(data) {
  // return dispatch => {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request({}));

      userService.sendOtp(data).then(
        data => {
          //
          // dispatch(success(data));
          resolve(data);
          //  history.push('/resetPassword');
        },
        error => {
          // dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
          reject(data);
        },
      );
    });

  // REQUEST: 1,
  // SUCCESS: 2,
  // FAILURE: 3
  function request(data) {
    return { type: reducerConstants.REQUEST, data };
  }
}

function verifyOtp(data) {
  // return dispatch => {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch(request({}));

      userService.verifyOtp(data).then(
        data => {
          //
          // dispatch(success(data));
          resolve(data);
          //  history.push('/resetPassword');
        },
        error => {
          // dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
          reject(data);
        },
      );
    });

  // REQUEST: 1,
  // SUCCESS: 2,
  // FAILURE: 3
  function request(data) {
    return { type: reducerConstants.REQUEST, data };
  }
}

function sendResetUserCredentials(data) {
  return dispatch =>
    new Promise((resolve, reject) => {
      userService.sendResetUserCredentials(data).then(
        data => {
          //   dispatch(alertActions.success(data));
          resolve(data);
        },
        error => {
          // dispatch(failure(error?.toString()));
          dispatch(alertActions.error(error?.toString()));
          reject(data);
        },
      );
    });
}
