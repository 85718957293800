import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { candidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { DocumentView } from '../_components/DocumentView';
import { apiConstants, reducerConstants } from '../_constants';

const ProfileDetailsPublic = props => {
  const { token } = useParams();
  const [candidate, setcandidate] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });
  const [printpdfLoading, setprintpdfLoading] = useState(false);
  const [showprintbutton, setshowprintbutton] = useState(true);
  const [showDocument, setshowDocument] = useState({ modal: false, url: '' });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [showimage, setshowimage] = useState({ modal: false, url: '' });
  // effect to call candidate
  useEffect(() => {
    if (!isExpired) {
      getAllCandidate(1, query);
    }
  }, []);
  const downloadFile = (url, filename) => {
    setIsLoading(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        setIsLoading(false);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        setshowDocument({ modal: 'true', url: filename });
        setIsLoading(false);
      });
  };
  //effect to open print screen
  useEffect(() => {
    const handleAfterPrint = () => {
      setshowprintbutton(true);
    };
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);
  /**
   * Get user
   * */
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    query.token = token;
    if (!isExpired) {
      dispatch(candidateActions.candidateProfilePublic(queryNew)).then(
        res => {
          setIsLoading(false);
          if (res && res.data.rows && res.data.rows.length > 0) {
            setcandidate(res?.data?.rows?.[0]);
          }
        },
        () => {
          setIsExpired(true);
          setIsLoading(false);
        },
      );
    }
  };
  // to show document/file in modal
  const getViewUrl = url => {
    if (url) {
      return (
        <div
          style={{ color: '#5588dd', cursor: 'pointer' }}
          onClick={() => {
            setshowDocument({ modal: 'true', url: url });
          }}
        >
          View
        </div>
      );
    } else {
      return '';
    }
  };
  // print pdf file
  const printPdf = () => {
    setshowprintbutton(false);
    setprintpdfLoading(true);
    setTimeout(() => {
      window.print();
      setshowprintbutton(false);
      setprintpdfLoading(false);
    }, 1000);
  };
  return (
    <div className="Candidate">
      {(props.requestStatus === reducerConstants.CANDIDATE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <div className="d-flex mt-20">
                <div style={{ marginTop: '-5px' }}></div>
              </div>
            </div>
          </div>
          {!isExpired && (
            <div className="card-body">
              <div className="row col-md-12 d-flex table_box_shadow bgf7f5f266 justify-content-center align-items-center">
                {showprintbutton && (
                  <div className="row col-md-12 d-flex justify-content-end mt-10">
                    <div
                      className="row invoicePrintTag m-1"
                      onClick={() => {
                        setshowprintbutton(false);
                        printPdf();
                      }}
                    >
                      <div className="">Print </div>
                      <div>
                        <img
                          style={{ height: 15, width: 15, marginLeft: 5 }}
                          src="/assets/img/pdf.png"
                        />
                      </div>
                      {printpdfLoading && (
                        <div>
                          <img
                            style={{ height: 23, width: 23 }}
                            src="/assets/img/1497.gif"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="row col-md-12">
                  <div className="col-md-12 d-flex pd-10">
                    <div className="col-md-10">
                      <div className="d-flex align-items-center">
                        {candidate.profileimage ? (
                          <img
                            className=" square-emp-image-single"
                            src={apiConstants.imgUrl + candidate.profileimage}
                          />
                        ) : (
                          <img
                            className=" square-emp-image-single"
                            src="/assets/img/un-avatar.png"
                          />
                        )}
                        <div style={{ lineHeight: '0px' }}>
                          <div>
                            {' '}
                            <h5
                              style={{
                                color: 'black',
                                textTransform: 'capitalize',
                              }}
                            >
                              {candidate.name} {candidate.last_name}
                              {candidate.lastname}
                            </h5>
                          </div>
                          <div>
                            {' '}
                            <p
                              style={{
                                color: 'black',
                                textTransform: 'capitalize',
                                fontSize: '14px',
                              }}
                            >
                              {candidate.service_type}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      {' '}
                      <img
                        className="logooflexi"
                        src="/assets/img/recsy-temp-logo.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="row col-md-12 p-10">
                  <div className="col-md-12 publicdetails d-flex">
                    <div className="col-md-2">
                      <p>Ni number</p>
                      <p style={{ fontWeight: 'bold' }}>
                        {candidate.ni_number}
                      </p>
                    </div>
                    <div className="col-md-4">
                      <p>Address</p>
                      <p style={{ fontWeight: 'bold' }}>{candidate.address}</p>
                    </div>
                    <div className="col-md-2">
                      Email
                      <p style={{ fontWeight: 'bold' }}>{candidate.email}</p>
                    </div>
                    <div className="col-md-2">
                      Phone{' '}
                      <p style={{ fontWeight: 'bold' }}>
                        {candidate.contact_number}
                      </p>
                    </div>
                    <div className="col-md-2">
                      Experience
                      <p style={{ fontWeight: 'bold' }}>
                        {candidate.experience}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12  p-10">
                  <div className="d-flex col-md-12 p-10 justify-content-center">
                    <div className="col-md-6 bgwhite mb-30 ">
                      <br />
                      {candidate &&
                        candidate.reference1 &&
                        candidate.reference1.reference1Name && (
                          <div className="col-md-11">
                            <h6>REFERENCE </h6>
                          </div>
                        )}
                      {/* reference1Contact
                      reference1Email
                      reference1Name  */}
                      {candidate.reference1 &&
                        candidate.reference1.reference1Email && (
                          <div className="col-md-11">
                            <p>
                              <b>Reference 1:</b>
                            </p>
                            {candidate.reference1.reference1Name && (
                              <p> {candidate.reference1.reference1Name} </p>
                            )}
                            {candidate.reference1.reference1Contact && (
                              <p> {candidate.reference1.reference1Contact} </p>
                            )}
                            {candidate.reference1.reference1Email && (
                              <p> {candidate.reference1.reference1Email} </p>
                            )}
                          </div>
                        )}
                      {candidate.reference2 &&
                        candidate.reference2.reference2Email && (
                          <div className="col-md-11">
                            <p>
                              <b>Reference 2:</b>
                            </p>
                            {candidate.reference2 &&
                              candidate.reference2.reference2Name && (
                                <p> {candidate.reference2.reference2Name} </p>
                              )}
                            {candidate.reference2 &&
                              candidate.reference2.reference2Contact && (
                                <p>{candidate.reference2.reference2Contact} </p>
                              )}
                            {candidate.reference2 &&
                              candidate.reference2.reference2Email && (
                                <p> {candidate.reference2.reference2Email} </p>
                              )}
                          </div>
                        )}
                      <hr />
                      <div className="col-md-11">
                        <h6>DOCUMENTS</h6>
                      </div>
                      {candidate &&
                        candidate.document &&
                        candidate.document.length == 0 && (
                          <div className="col-md-11">
                            {' '}
                            <p className="nodata">No Data Found</p>
                          </div>
                        )}
                      {candidate &&
                        candidate.document &&
                        candidate.document.map(i => {
                          return (
                            <div
                              key={i.document_name}
                              className="row col-md-12 d-flex justify-content-center align-items-center"
                            >
                              <div className=" col-md-11 borderridge d-flex  p-10">
                                <div className="col-md-6 col-sm-6">
                                  <p>
                                    {i.document_mode
                                      ? i.document_mode
                                          .replace(/_/g, ' ')
                                          .toUpperCase()
                                      : ' '}
                                  </p>
                                </div>
                                <div className="col-md-4 d-flex justify-content-end">
                                  {getViewUrl(i.document_name)}
                                  <div
                                    className="downloadlink ml-0 cursorPoint"
                                    // download="CustomFileName.jpg"
                                    onClick={() =>
                                      downloadFile(
                                        apiConstants.imgUrl + i.document_name,
                                        i.document_name,
                                      )
                                    }
                                  >
                                    <img
                                      style={{
                                        height: 20,
                                        width: 20,
                                        marginLeft: '10px',
                                      }}
                                      src="/assets/img/black-download.png"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="col-md-11">
                        <hr />
                        <h6>EDUCATION</h6>
                      </div>
                      {candidate &&
                        candidate.education &&
                        candidate.education.length == 0 && (
                          <div className="col-md-11">
                            {' '}
                            <p className="nodata">No Data Found</p>
                          </div>
                        )}
                      {candidate &&
                        candidate.education &&
                        candidate.education.map(i => {
                          return (
                            <div key={i._id} className=" col-md-12 p-10">
                              <div className="educard_border_ridge mt-3">
                                <div className="card-body">
                                  <div className=" d-flex justify-content-between">
                                    <div>
                                      <p className="collegename">
                                        {i.college_university}
                                      </p>
                                      <p className="stream">
                                        {i.level_of_education},{' '}
                                        {i.field_of_study}
                                      </p>
                                      <p style={{}}>
                                        {moment(i.from_date).format('YYYY')}-
                                        {i.to_date &&
                                          moment(i.to_date).format('YYYY')}
                                      </p>
                                      <p
                                        style={{
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        {i.location}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="col-md-11">
                        <hr />
                        <h6>EXPERIENCE</h6>
                      </div>
                      {candidate &&
                        candidate.experiencedata &&
                        candidate.experiencedata.length == 0 && (
                          <div className="col-md-11">
                            <p className="nodata">No Data Found</p>
                          </div>
                        )}
                      {candidate &&
                        candidate.experiencedata &&
                        candidate.experiencedata.map((i, index) => {
                          return (
                            <div key={index} className=" col-md-12 p-10">
                              <div className="educard_border_ridge mt-3 p-2 ">
                                <div className="card-body">
                                  <div className="row d-flex justify-content-between">
                                    <div className=" experiencedetails">
                                      <div className="d-flex">
                                        <div className="mr-10">
                                          <p
                                            className="collegename mb-0"
                                            style={{
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {i.job_title}
                                          </p>
                                        </div>
                                        <div
                                          className=""
                                          style={{ alignSelf: 'center' }}
                                        >
                                          {' '}
                                          {i.is_verified == 1 && (
                                            <img
                                              className=" float-right"
                                              style={{
                                                height: '10px',
                                                width: '10px',
                                              }}
                                              src="/assets/img/Subtract.png"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <p className="mb-0" style={{}}>
                                        {i.description}
                                      </p>
                                      <span
                                        className="mb-0 "
                                        style={{ fontWeight: '500' }}
                                      >
                                        {i.company_name}
                                      </span>
                                      <p className="stream">
                                        {moment(i.from_date).format('MMM YYYY')}{' '}
                                        -{' '}
                                        {i.to_date
                                          ? moment(i.to_date).format('MMM YYYY')
                                          : 'Present' +
                                            '(' +
                                            moment(
                                              i.from_date,
                                              'YYYYMMDD',
                                            ).toNow() +
                                            ')'}
                                      </p>
                                      <p className="stream">{i.location}</p>
                                      <p className="stream">
                                        {i.reference_description}{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="col-md-6 bgwhite mb-30">
                      <div className="d-flex div-light-grey justify-content-between mt-20 ">
                        <div className=" ">
                          Proof of Eligibility to Work in the UK
                        </div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Bank Statement</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">References Received</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Personal Interview Conducted</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Health Declaration Completed</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Contract Issued</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">ID Badge Issued</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-11">
                        {' '}
                        <h6 className="mt-20">TRAINING</h6>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Moving and Handling</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Health and Safety</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Fire Awareness</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Infection Control</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">First Aid</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Food Hygiene</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Challenging Behaviour</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Basic Life Support</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Administration of Medication</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Safequarding Adults</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">Control & Restraint</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">NVQ Level 2</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" ">NVQ Level 3</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-11">
                        {' '}
                        <h6 className="mt-20">VACCINATION DETAILS</h6>
                      </div>
                      <div className="d-flex div-light-grey justify-content-between ">
                        <div className=" ">Covid 19Dose 1</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex div-dark-grey justify-content-between">
                        <div className=" "> Covid 19Dose 2</div>
                        <div>
                          <div className="d-flex justify-content-center tickround">
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                style={{
                                  height: '15px',
                                  width: '15px',
                                }}
                                src="/assets/img/tick.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isExpired && (
            <div style={{ alignSelf: 'center' }}>
              {' '}
              <img className="errorimage" src="/assets/img/errorpage.png" />
              <p className="expiredtext">
                Link expired, please contact flexirecs
              </p>
            </div>
          )}
        </div>
      </div>
      {showDocument.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog " style={{ maxWidth: '90%' }}>
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setshowDocument({ modal: false })}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <DocumentView
                  url={apiConstants.imgUrl + showDocument.url}
                  onClose={() => setshowDocument({ modal: false })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showimage.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setshowimage({ modal: false })}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <img
                  className="viewimage"
                  src={apiConstants.imgUrl + showimage.url}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}

const connectedProfileDetailsPublic =
  connect(mapStateToProps)(ProfileDetailsPublic);
export { connectedProfileDetailsPublic as ProfileDetailsPublic };
