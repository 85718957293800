export const EmployerCompanyInfo = () => {
  return (
    <div className="flx-company-info container mt-3">
      <i className="fa fa-arrow-left"></i>{' '}
      <span className="flx-view-employer-back-text">Back</span>
      <div className="flx-employer-banner-full-width align-items-center d-flex">
        <div className="profile-container d-flex align-items-center col-md-4">
          <div className="flx-employer-profile-logo-container col-md-3">
            <img
              className="flx-logo-add-employer-img"
              src="/assets/img/recsy-fav-Icon.png"
              alt="Logo"
            />
            <p className="change-text-p">Change</p>
          </div>
          <div className="profile-info-container col-md-9">
            <div className="flx-empl-profile-name">Rehability Care</div>
            <div className="profile-date">Added on Feb-11-2024</div>
          </div>
        </div>

        <div className="flx-phone-container d-flex col-md-3">
          <img
            className="flx-logo-add-employer-phone-img"
            src="/assets/img/flx-phone-icon.svg"
            alt="Logo"
          />
          <div className="profile-info-container col-md-9">
            <div className="profile-name">Phone</div>
            <div className="profile-details-view-employer">98786589010</div>
          </div>
        </div>

        <div className="flx-phone-container d-flex col-md-3">
          <img
            className="flx-logo-add-employer-phone-img"
            src="/assets/img/flx-email-info-icon.svg"
            alt="Logo"
          />
          <div className="profile-info-container col-md-9">
            <div className="profile-name">Email</div>
            <div className="profile-details-view-employer">
              info@companyname.co.uk
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <select className="flx-actions-view-employer-header">
            <option value="">Actions</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
            {/* Add more options as needed */}
          </select>
        </div>
      </div>
      <div className="invoice-mode-badge">Attention needed</div>
      <div className="flx-employer-banner-full-width flx-billing-info-full-width align-items-center d-flex">
        <div className="profile-container d-flex align-items-center col-md-7">
          <div className="flx-employer-profile-logo-container col-md-1">
            <img
              className=""
              src="/assets/img/billing-mode-icon.svg"
              alt="Logo"
            />
          </div>
          <div className="profile-info-container col-md-11">
            <div className="employer-billing-mode-text">
              Employer has requested to change their billing mode to SLA
            </div>
            <div className="employer-billing-mode-explanation">
              Please review and process this request accordingly
            </div>
          </div>
          <div className="col-md-2">
            <button className="flx-invoice-mode-decline-button">Decline</button>
          </div>
          <div className="col-md-3">
            <button className="flx-invoice-mode-change-button">
              Change to Monthly Invoicing
            </button>
          </div>
        </div>
      </div>
      <div className="flx-company-info-row row d-block d-md-flex">
        {/* Sidebar for user profile options */}
        <div className="col-md-3 mb-3 d-none d-md-block">
          <div className="d-flex flex-column">
            <ul className="flx-company-info-settings-main-links">
              <div className="flx-emp-profile-menu-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Company info.svg"
                />

                <li className="flx-company-info-settings-li flx-company-info-settings-li-active">
                  Company Info
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Biiling info.svg"
                />
                <li className="flx-company-info-settings-li">Billing Info</li>
              </div>
              <div className="flx-emp-profile-menu-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Documents.svg"
                />
                <li className="flx-company-info-settings-li">Documents</li>
              </div>
              <div className="flx-emp-profile-menu-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Login.svg
                "
                />
                <li className="flx-company-info-settings-li">
                  Login & Security
                </li>
              </div>
            </ul>
            {/* Progress bar (Bootstrap progress bar or custom) */}
          </div>
        </div>
        <div className="flx-emp-profile-vertical-hr d-none d-md-block"></div>
        {/* Sidebar turned as a scrollable menu on smaller screens */}
        <div className="container-fluid flx-emp-profile-mobile-menu-container d-block d-md-none">
          <div className="row flx-emp-profile-mobile-menu-row">
            <div className="col flx-emp-profile-mobile-menu-col">
              <div className="d-flex flex-row flex-nowrap">
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-profile-mob-menu-icon-set_active d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/comp-info.svg"
                  />
                  <span className="flx-emp-profile-mob-menu-text">
                    My Profile
                  </span>
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/billing.svg"
                  />
                  Billing Info
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/emp-doc.svg"
                  />
                  Documents
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/login-sec.svg"
                  />
                  Login & Security
                </div>

                {/* Add more menu items here */}
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar turned as a scrollable menu on smaller screens ends */}
        {/* Main content for editing profile information */}
        <div className="col-md-6 flx-emp-profile-main-form">
          <h2 className="flx-company-info-form-heading">Company info</h2>
          <form>
            <div className="mb-3">
              <label
                htmlFor="companyName"
                className="form-label company-info-labels"
              >
                Company name
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="companyName"
                placeholder="My Company"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="postcode"
                className="form-label company-info-labels"
              >
                Postcode
              </label>
              <i className="fa fa-info-circle company-info-icon-tooltip"></i>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="postcode"
                placeholder="Enter postcode"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="postcode"
                className="form-label company-info-labels"
              >
                Select Address
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id=""
                placeholder="Type your address"
              />
            </div>
            <div className="mb-3 ml-3">
              <input
                className="form-check-input flx-comp-settings-form-control"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />

              <label
                className="form-check-label company-info-labels"
                htmlFor="flexCheckDefault"
              >
                This is my billing address
              </label>
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Contact Person
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="Enter full name"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Email
              </label>{' '}
              <i className="fa fa-info-circle company-info-icon-tooltip"></i>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="Enter email"
              />
            </div>
            <label htmlFor="website" className="form-label company-info-labels">
              Phone
            </label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="flx-basic-addon1">
                  +44
                </span>
              </div>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                placeholder="07888 435 355"
                aria-label="Phone number"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Website
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="www.company.com"
              />
            </div>
            <button
              type="submit"
              className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
