import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const payoutService = {
  payoutAdd,
  payoutList,
  payoutDelete,
  payoutBulkGenerate,
  generatenewpayout,
  getcandidatedetails,
  generatesinglepayout,
};

function payoutAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addpayout2', requestOptions).then(
    miscService.handleResponse,
  );
}
function payoutBulkGenerate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/payoutbulkgenerate',
    requestOptions,
  ).then(miscService.handleResponse);
}
function payoutList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getpayoutList', requestOptions).then(
    miscService.handleResponse,
  );
}
function payoutDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletepayout', requestOptions).then(
    miscService.handleResponse,
  );
}

function generatenewpayout(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/generatenewpayout', requestOptions).then(
    miscService.handleResponse,
  );
}

function generatesinglepayout(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/generatesinglepayout',
    requestOptions,
  ).then(miscService.handleResponse);
}

function getcandidatedetails(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidatedetails',
    requestOptions,
  ).then(miscService.handleResponse);
}
