import React from 'react';
export class LoadingImg extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loadingimg">
        <img className="" src="/assets/img/load_green.gif" />
      </div>
    );
  }
}
