/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
    */
export function price(
  state = { requestStatus: 0, priceData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'PRICE_SUCCESS':
      return {
        ...state,
        requestStatus: 'PRICE_SUCCESS',
        requestPriceStatus: 'PRICE_SUCCESS',
        priceData: action.data.data,
        data: action.data,
      };
    case 'PRICE_FAILURE':
      return {
        ...state,
        requestStatus: 'PRICE_FAILURE',
        requestPriceStatus: 'PRICE_FAILURE',
        priceData: {},
        data: action.data,
      };
    case 'PRICE_REQUEST':
      return {
        ...state,
        requestStatus: 'PRICE_REQUEST',
        requestPriceStatus: 'PRICE_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
