export const RateCardDetails = ({ handleCloseModal, detailsOfRateCard }) => {
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header flx-ratecard-details-popup-header">
          {detailsOfRateCard?.rate_card_type && (
            <div className="flx-ratecard-popup-title">
              {detailsOfRateCard?.rate_card_type}
            </div>
          )}
          <button
            type="button"
            className="close flx-closebutton-popup flx-ratecard-details-close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleCloseModal}
          >
            <span aria-hidden="true" className="flx-closeicon ">
              &times;
            </span>
          </button>
          <p className="flx-ratecard-popup-text">
            Providing a base selection of candidates tailored to the
            organizations needs.
          </p>
        </div>
        <div className="modal-body">
          <div className="payment-breakdown">
            <h5 className="flx-ratecard-details-popup-table-heading">
              Payment breakdown
            </h5>
            <div className="flx-table-res-container">
              <table className="table flx-ratecard-details-table">
                <tbody>
                  <tr className="flx-ratecard-details-heading">
                    <th>Hourly rate</th>
                    <th>Employer NI</th>
                    <th>Holiday pay</th>
                    <th>Pension</th>
                    <th>Admin fee</th>
                    <th>Your fee</th>
                  </tr>
                  <tr>
                    <td data-title="Hourly rate">
                      £ {detailsOfRateCard?.hourly_rate}
                    </td>
                    <td data-title="Employer NI">
                      £ {detailsOfRateCard?.employer_nil}
                    </td>
                    <td data-title="Holiday pay">
                      £ {detailsOfRateCard?.holiday_price}
                    </td>
                    <td data-title="Pension">£ {detailsOfRateCard?.pension}</td>
                    <td data-title="Admin fee">
                      £{' '}
                      {detailsOfRateCard?.admin_fee_is_percentage
                        ? `${detailsOfRateCard?.admin_fee} %`
                        : `${detailsOfRateCard?.admin_fee} p`}
                    </td>
                    <td data-title="Your fee">£ {detailsOfRateCard?.price}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal-footer flx-popup-bottom"></div>
      </div>
    </div>
  );
};
