import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AllShiftsList } from '../Shifts';
import { categoryActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

let employer_id = '';

const DashbordCategoryReport = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({ rows: [] });
  const [categoryRows, setCategoryRows] = useState([]);
  const [categories, setcategories] = useState([]);
  const [category_id, setcategoryid] = useState();
  const [fromdate, setfromdate] = useState();
  const [todate, settodate] = useState();
  const [showallshifts, setshowalshifts] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
  });
  // effect to load category
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.employer_id) {
        employer_id = user.employer_id;
      }
    }
    getAllCategory(1);
  }, [query.from_date, query.end_date, query.category]);
  /**
   * Get all Category
   **/
  const getAllCategory = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    dispatch(categoryActions.dashbordCategoryReport(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
        setcategories(res?.data?.rows);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // state query for selected category
  const setCategory = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = categoryData => {
    let categories = [];
    for (
      let j = 0;
      categoryData &&
      categoryData.rows &&
      categoryData.rows.length &&
      j < categoryData.rows.length;
      j = j + 1
    ) {
      categories.push(
        <tr key={j}>
          <td>{categoryData.rows[j].name}</td>
          <td>
            {' '}
            {categoryData.rows[j].no_of_shifts
              ? categoryData.rows[j].no_of_shifts
              : 0}
          </td>
          <td>
            <div
              className="left-right-arrow"
              onClick={() => {
                setcategoryid(categoryData.rows[j]._id), setshowalshifts(true);
              }}
            >
              <i className="fas fa-angle-right"></i>
            </div>
          </td>
        </tr>,
      );
    }
    setCategoryRows(categories);
    setCategoryData(categories);
  };
  // custom styles for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '45px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="d-flex justify-content-center">
      <div className="col-md-12 ">
        <div className="  card regular-table-with-color ">
          {!props.ishomepage && (
            <div className="row">
              <div className="card-header page-header ">
                <div className="page-title " style={{ fontSize: 20 }}>
                  {' '}
                  Jobrole Report
                </div>
              </div>
            </div>
          )}
          {!props.ishomepage && (
            <div className=" mb-2">
              <div className="filter-row">
                <div className="flex-item3 ">
                  <div>
                    {' '}
                    <label>From date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-group-50px"
                      name="from_date"
                      placeholder="From"
                      value={fromdate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.from_date = value;
                        setQuery(newquery);
                        setfromdate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3">
                  <div>
                    <label>To date</label>
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control form-group-50px"
                      name="end_date"
                      placeholder="From"
                      value={todate}
                      onChange={event => {
                        let { value } = event.target;
                        let newquery = { ...query };
                        delete newquery._id;
                        newquery.end_date = value;
                        setQuery(newquery);
                        settodate(value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-item3 ">
                  <div>
                    <label>category</label>
                  </div>
                  <AsyncSelect
                    styles={customStyles}
                    name="category"
                    onChange={setCategory}
                    placeholder={'select category'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getCategorylist}
                  />
                </div>
              </div>
            </div>
          )}
          <div className=" ">
            <div
              className={
                !props.ishomepage
                  ? '  table-responsive main-card-body '
                  : '  table-responsive  '
              }
            >
              <div className="d-flex justify-content-between align-items-center">
                {props.ishomepage && (
                  <div>
                    {' '}
                    <label style={{ fontSize: 20 }}>CATEGORIES REPORT</label>
                  </div>
                )}
                {props && props.ishomepage && (
                  <div>
                    <Link to="/dashbordcategoryreport">
                      <button className="blackbutton">View All</button>
                    </Link>
                  </div>
                )}
              </div>
              {!isLoading && !props.ishomepage && (
                <div className="">
                  <table
                    className="table table-hover"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th className="border-top1">Name</th>
                        <th>No of Shift</th>
                        <th className="border-top2"></th>
                      </tr>
                    </thead>
                    <tbody>{categoryRows}</tbody>
                  </table>
                </div>
              )}
              {!!isLoading && <LoadingImg />}
              {categoryRows && categoryRows.length === 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
          </div>
          {props && props.ishomepage && (
            <div className="d-flex flex-row mt-2 flex-wrap catrow">
              {categories?.map(i => {
                return (
                  <div className="cat_item" key={i}>
                    <p style={{ textTransform: 'capitalize' }}>
                      {i.name ? i.name : ''}
                    </p>
                    <div style={{ fontWeight: 'bold' }}>
                      {i.no_of_shifts ? i.no_of_shifts : 0}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!props.ishomepage && (
            <div className="card-footer">
              <Pagination
                pageOnClick={() => {
                  // getAllBusinessunit(data);
                }}
                paginationData={categoryData}
              />
            </div>
          )}
        </div>
        {showallshifts && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-large">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close">
                      <a onClick={() => setshowalshifts(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <AllShiftsList
                    category={category_id}
                    from_date={fromdate}
                    to_date={todate}
                    status="1"
                  ></AllShiftsList>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// loader for categories in select option
const getCategorylist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, categoryData } = state.category;
  return {
    requestStatus,
    categoryData,
  };
}

const connectedDashbordCategoryReport = connect(mapStateToProps)(
  DashbordCategoryReport,
);
export { connectedDashbordCategoryReport as DashbordCategoryReport };
