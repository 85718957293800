//import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Agency } from '../Agency/Agency';
import { AgencyAdd } from '../Agency/AgencyAdd';
import { AgencyList } from '../Agency/AgencyList';
import { DashbordAgencyReport } from '../Agency/DshbordAgencyReport';
import { SubscribedAgencyList } from '../Agency/SubscribedAgencyList';
import { AgencyCandidate } from '../AgencyCandidate/AgencyCandidate';
import { AgencyCandidateAdd } from '../AgencyCandidate/AgencyCandidateAdd';
import { AgencyCandidateList } from '../AgencyCandidate/AgencyCandidateList';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { AgencycandidateViewprofile } from '../AgencyCandidate/AgencycandidateViewprofile';
import {
  EmailVerify,
  ForgotPassword,
  LoginPage,
  LoginWithEmail,
  LoginWithPhone,
  PhoneNumber,
  ResetPassword,
  UpdatePassword,
} from '../AuthPage';
import { BlockBookinglist } from '../Block_booking';
import { ViewProfile } from '../Block_booking/ViewProfile';
import { AgencyBookingList, BookingList } from '../Booking';
import { AdminBusinessunitlist } from '../BusinessUnit/AdminBusinessunitlist';
import { Businessunit } from '../BusinessUnit/Businessunit';
import { BusinessunitAdd } from '../BusinessUnit/BusinessunitAdd';
import { BusinessunitList } from '../BusinessUnit/BusinessunitList';
import { DashbordBunitReport } from '../BusinessUnit/DashbordBunitReport';
import { BlockBookingListView, BlockListcalendar } from '../Calendar';
import {
  Candidate,
  CandidateAdd,
  CandidateList,
  CandidateProfile,
  CandidateRegister,
  ProfileDetailsPublic,
} from '../Candidate';
import { CandidateblockList } from '../CandidateBlock';
import {
  Candidateemployerallocation,
  CandidateemployerallocationAdd,
  CandidateemployerallocationList,
} from '../CandidateemployerAllocation';
import { Category, CategoryAdd, CategoryList } from '../Category';
import { DashbordCategoryReport } from '../Category/DashbordCategoryReport';
import { Demo, DemoAdd, DemoList } from '../Demo';
import { EmployeesList } from '../Employees';
import {
  Employer,
  EmployerAdd,
  EmployerList,
  ViewEmployerMainPage,
} from '../Employer';
import { DashbordCarehomeReport } from '../Employer/DashbordCarehomeReport';
import { EmpProfileMain } from '../EmployerProfile/EmpProfileMain';
import {
  DashbordOrganizationReport,
  Dashbordnew,
  EmpDashboard,
  HomePage,
} from '../HomePage';

import { Totalpricegraph } from '../HomePage/Totalpricegraph';
import {
  AgencyInvoiceList,
  Invoice,
  InvoiceAdd,
  InvoiceAddAgency,
  InvoiceAgency,
  InvoiceList,
  InvoicePrint,
} from '../Invoice';
import {
  AddNewShift,
  JobSelection,
  PostAgency,
  PostLive,
  RateCard,
  SampleCards,
  ShiftSummary,
} from '../Job';
import { LogList } from '../Log';
import { MapView } from '../Map';
import { NotificationList } from '../Notification';
import { PayoutPrint, ShiftExistList } from '../Payout';
import { Payout } from '../Payout/Payout';
import { PayoutAdd } from '../Payout/PayoutAdd';
import { PayoutList } from '../Payout/PayoutList';
import { Price, PriceAdd, PriceList } from '../Price';
import { ProfileDetails } from '../Profile';
import { ReferalList } from '../Referal';
import { RegisterPage } from '../RegisterPage/RegisterPage';
import { ReportList } from '../Report';
import { Sample, SampleAdd, SampleList } from '../Sample';
import { Section, SectionAdd, SectionList } from '../Section';
import {
  AllShiftsList,
  EmployerPendingSignOff,
  ShiftPrint,
  Shiftdetails,
  Shifts,
  ShiftsAdd,
} from '../Shifts';
import { AgencyShifts } from '../Shifts/AgencyShifts';
import { Shiftmenu } from '../Shifts/Shiftmenu';
import { Staff, StaffAdd, StaffList } from '../Staff';
import { TimesheetList } from '../TimeSheet';
import { CarehometimeSheet } from '../TimeSheet/CarehometimeSheet';
import { User, UserAdd, UserList } from '../Users';

import { AdminShiftMain } from '../AdminShift/';
import { EmpSettingsMain } from '../EmployerProfile/EmpSettingsMain';
import EmployerProfilePage from '../EmployerProfilePage/EmployerProfilePage';
import EmployerSettingsPage from '../EmployerSettings/EmployerSettingsPage';
import { AddLocation } from '../Job/AddLocation';
import { AgencyvacancyList } from '../VacancyShift/AgencyvacancyList';
import { Vacancyshift } from '../VacancyShift/Vacancyshift';
import { VacancyshiftAdd } from '../VacancyShift/VacancyshiftAdd';
import { VacancyshiftList } from '../VacancyShift/VacancyshiftList';
import { alertActions } from '../_actions';
import {
  MainMenu,
  OtpverficationModal,
  Payments,
  PrivateRoute,
  ReferalSuccess,
  Samp,
  Sidebar,
} from '../_components';
import { history } from '../_helpers';
import { miscService } from '../_services';
import { Fileview } from '../fileupload';
import { FileUpload } from '../fileupload/FileUpload';
import { Settings } from '../settings';

// import {
//   AgencyvacancyList,
//   Vacancyshift,
//   VacancyshiftAdd,
//   VacancyshiftList,
// } from '../VacancyShift';

// eslint-disable-next-line no-unused-vars
let url_params = {};

const App = props => {
  const [isSidebarshow, setIsSidebarshow] = useState(true);
  // const [isShowAlert, setIsShowAlert] = useState(false);
  const [currenturl, setCurrenturl] = useState('');

  useEffect(() => {
    //

    //---------sidebar hide conditions-----------
    if (
      (window?.location?.pathname && window.location.pathname === '/login') ||
      window.location?.pathname.toLowerCase() === '/forgotpassword' ||
      window.location?.pathname.toLowerCase() === '/emailverify' ||
      window.location?.pathname.toLowerCase() === '/phonenumber' ||
      window.location?.pathname.toLowerCase() == '/registercandidate' ||
      window.location?.pathname.toLowerCase() == '/referalsuccess' ||
      window.location?.pathname.split('/')[1] == 'registercandidate' ||
      location.pathname === '/register' ||
      !localStorage.getItem('user')
    ) {
      setIsSidebarshow(false);
    }

    history.listen(location => {
      const { dispatch } = props;
      url_params = miscService.getUrlVars();
      setTimeout(function () {
        if (miscService.getParameterByName('errorMessage', location.search)) {
          dispatch(
            alertActions.error(
              miscService.getParameterByName('errorMessage', location.search),
            ),
          );
        }
        if (miscService.getParameterByName('successMessage', location.search)) {
          dispatch(
            alertActions.success(
              miscService.getParameterByName('successMessage', location.search),
            ),
          );
        }
      }, 3000);
      if (
        (location.pathname && location.pathname?.toLowerCase() === '/login') ||
        location.pathname?.toLowerCase() === '/forgotPassword' ||
        location.pathname?.toLowerCase() === '/emailverify' ||
        location.pathname?.toLowerCase() === '/phonenumber' ||
        location.pathname?.toLowerCase() === '/register' ||
        !localStorage.getItem('user')
      ) {
        setIsSidebarshow(false);
      }
      // else {
      //   setIsSidebarshow(true);
      // }
      setCurrenturl(location.pathname);

      dispatch(alertActions.clear());
    });
  }, []);

  //-----------------alert message----------------
  useEffect(() => {
    //--------------show alert -------------

    //--------hide alert--------------
    let hideMessageAfter = 30000;
    if (props.alert.type == 'alert-success') {
      hideMessageAfter = 4000;
    }
    if (
      props.alert.message &&
      (props.alert.message.includes('Oops!') ||
        props.alert.message.includes('TypeError'))
    ) {
      hideMessageAfter = 3000;
    }
    if (props.alert && props.alert.message) {
      const { dispatch } = props;
      setTimeout(() => {
        dispatch(alertActions.error(''));
      }, hideMessageAfter);
    }
  }, [props.alert.message]);

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Parse the URL to get query parameters
    const urlParams = new URLSearchParams(new URL(currentUrl).search);

    // Get the value of the "token" parameter
    const token = urlParams.get('token');

    if (token) {
      // Do something with the token, such as storing it in state or using it

      getDashbordData(1, {
        status: 1,
        token: token,
      });
    }
  }, []);

  // useEffect(() => {
  //   const { isrouteKey } = props;
  //   routeKey = isrouteKey;
  // }, [props.isrouteKey]);

  //---------close menu when responsive---------
  const closeMenu = () => {
    const { dispatch } = props;
    dispatch({ type: 'isMenuOpen', data: false });
  };

  //
  const getDashbordData = (pageNo, queryTemp) => {
    // setIsLoading(true);

    miscService.getDashbordSharelink(queryTemp).then(
      res => {
        if (res) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          window.location.href = '/';
        }
      },
      () => {
        // setIsLoading(false);
      },
    );
    // window.location.reload;
  };
  const toggleMenu = event => {
    const { dispatch, isMenuOpen } = props;

    if (
      event.target.closest('.dropdown-menu') ||
      event.target.closest('.tab')
    ) {
      return;
    }

    if (isMenuOpen) {
      dispatch({
        type: 'isMenuOpen',
        data: false,
      });
    }
  };
  return (
    <div className="wrapper" onClick={toggleMenu}>
      <Router history={history}>
        {/* <Fragment> */}
        <div>
          {isSidebarshow && (
            <MainMenu
              isMenuOpen={props.isMenuOpen}
              currenturl={currenturl}
            ></MainMenu>
          )}
          {isSidebarshow && <Sidebar currenturl={currenturl}></Sidebar>}
          <div
            className={
              'main-panel ' +
              (isSidebarshow
                ? 'showsidebar '
                : 'hiddensidebar bg-dark no-padding')
            }
            onClick={() => closeMenu}
          >
            <div className="content ">
              {/* //------------alert div---------- */}
              <div className="">
                {props.alert.message && (
                  <div>
                    {props.alert.type == 'alert-success' && (
                      <div className="alert alertboxCustomSuccess">
                        <span
                          onClick={() => props.dispatch(alertActions.error(''))}
                          className="alert-close"
                        >
                          X
                        </span>
                        <p>
                          {props.alert.message
                            ? props.alert.message
                            : 'SUCCESS'}
                        </p>
                      </div>
                    )}
                    {props.alert.type == 'alert-danger' && (
                      <div className="alert alertboxCustomError">
                        <span
                          className="alert-close"
                          onClick={() => props.dispatch(alertActions.error(''))}
                        >
                          X
                        </span>

                        <p> {props.alert.message}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* //-----------alert div ends ------------- */}
              <div className="">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <PrivateRoute>
                        <HomePage />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/register" element={<RegisterPage />} />
                  <Route path="/forgotPassword" element={<ForgotPassword />} />
                  <Route path="/updatepassword" element={<UpdatePassword />} />
                  <Route path="/phonenumber" element={<PhoneNumber />} />
                  <Route path="/loginwithemail" element={<LoginWithEmail />} />
                  <Route path="/loginwithphone" element={<LoginWithPhone />} />
                  <Route
                    path="/otpvaerification"
                    element={<OtpverficationModal />}
                  />
                  <Route
                    path="/resetpassword/:id"
                    element={<ResetPassword />}
                  />
                  <Route path="/emailverify" element={<EmailVerify />} />
                  <Route
                    path="/candidateprofilepublic/:token"
                    element={<ProfileDetailsPublic />}
                  />
                  <Route
                    path="/user/:id"
                    element={
                      <PrivateRoute>
                        <User />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/useradd/:id"
                    element={
                      <PrivateRoute>
                        <UserAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/useradd/:id"
                    element={
                      <PrivateRoute>
                        <UserAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/userslist"
                    element={
                      <PrivateRoute>
                        <UserList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/candidate/:id"
                    element={
                      <PrivateRoute>
                        <Candidate />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/candidateadd/:id"
                    element={
                      <PrivateRoute>
                        <CandidateAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/registercandidate/:id"
                    element={<CandidateRegister />}
                  />
                  <Route
                    path="/candidateslist"
                    element={
                      <PrivateRoute>
                        <CandidateList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/candidateprofile/:id?/:agency?/:bookingid?"
                    element={
                      <PrivateRoute>
                        <CandidateProfile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blockcandidate"
                    element={
                      <PrivateRoute>
                        <CandidateblockList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/payments"
                    element={
                      <PrivateRoute>
                        <Payments key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/uploadfile"
                    element={
                      <PrivateRoute>
                        <FileUpload />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blocklistcalendar"
                    element={
                      <PrivateRoute>
                        <BlockListcalendar />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blockbookingListview"
                    element={
                      <PrivateRoute>
                        <BlockBookingListView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/demo/:id"
                    element={
                      <PrivateRoute>
                        <Demo />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/demoadd/:id"
                    element={
                      <PrivateRoute>
                        <DemoAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/demolist"
                    element={
                      <PrivateRoute>
                        <DemoList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/employer/:id"
                    element={
                      <PrivateRoute>
                        <Employer />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/employeradd/:id"
                    element={
                      <PrivateRoute>
                        <EmployerAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/employerlist"
                    element={
                      <PrivateRoute>
                        <EmployerList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashbordemployerreport"
                    element={
                      <PrivateRoute>
                        <DashbordCarehomeReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agency/:id"
                    element={
                      <PrivateRoute>
                        <Agency />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencyadd/:id"
                    element={
                      <PrivateRoute>
                        <AgencyAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencylist"
                    element={
                      <PrivateRoute>
                        <AgencyList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/subscribedagencylist"
                    element={
                      <PrivateRoute>
                        <SubscribedAgencyList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashbordagencyreport"
                    element={
                      <PrivateRoute>
                        <DashbordAgencyReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashbordorganizationsreport"
                    element={
                      <PrivateRoute>
                        <DashbordOrganizationReport></DashbordOrganizationReport>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profiledetails/:id"
                    element={
                      <PrivateRoute>
                        <ProfileDetails />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/employeeslist"
                    element={
                      <PrivateRoute>
                        <EmployeesList />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/viewprofile/:id" element={<ViewProfile />} />
                  <Route
                    path="/shift/:id"
                    element={
                      <PrivateRoute>
                        <Shifts />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/shiftadd/:id" element={<ShiftsAdd />} />
                  {/* <Route
                    path="/shiftlist"
                    element={
                      <PrivateRoute>
                        <ShiftsList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  /> */}
                  <Route
                    path="/emppendingsignoff" //?id=jkhju
                    element={<EmployerPendingSignOff />}
                  />
                  <Route
                    path="/allshiftlist/:id"
                    element={<AllShiftsList key={props.isrouteKey} />}
                  />

                  <Route
                    path="/shiftdetails"
                    element={
                      <PrivateRoute>
                        <Shiftdetails />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/samp"
                    element={
                      <PrivateRoute>
                        <Samp />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/referalsuccess"
                    element={
                      <PrivateRoute>
                        <ReferalSuccess />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/payoutadd/:id"
                    element={
                      <PrivateRoute>
                        <PayoutAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/payoutlist"
                    element={
                      <PrivateRoute>
                        <PayoutList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/payout/:id"
                    element={
                      <PrivateRoute>
                        <Payout />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sample/:id"
                    element={
                      <PrivateRoute>
                        <Sample />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sampleadd/:id"
                    element={
                      <PrivateRoute>
                        <SampleAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/samplelist"
                    element={
                      <PrivateRoute>
                        <SampleList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/notificationlist"
                    element={
                      <PrivateRoute>
                        <NotificationList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoice/:id"
                    element={
                      <PrivateRoute>
                        <Invoice />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/empprofilemain"
                    element={
                      <PrivateRoute>
                        <EmpProfileMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoiceagency/:id"
                    element={
                      <PrivateRoute>
                        <InvoiceAgency />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoiceadd/:id"
                    element={
                      <PrivateRoute>
                        <InvoiceAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoiceaddagency/:id"
                    element={
                      <PrivateRoute>
                        <InvoiceAddAgency />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoicelist"
                    element={
                      <PrivateRoute>
                        <InvoiceList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencyinvoicelist"
                    element={
                      <PrivateRoute>
                        <AgencyInvoiceList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/staff/:id"
                    element={
                      <PrivateRoute>
                        <Staff />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/staffadd/:id"
                    element={
                      <PrivateRoute>
                        <StaffAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/stafflist"
                    element={
                      <PrivateRoute>
                        <StaffList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/referallist"
                    element={
                      <PrivateRoute>
                        <ReferalList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/loglist"
                    element={
                      <PrivateRoute>
                        <LogList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/category/:id"
                    element={
                      <PrivateRoute>
                        <Category />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/categoryadd/:id"
                    element={
                      <PrivateRoute>
                        <CategoryAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/categorylist"
                    element={
                      <PrivateRoute>
                        <CategoryList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashbordcategoryreport"
                    element={
                      <PrivateRoute>
                        <DashbordCategoryReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/price/:id"
                    element={
                      <PrivateRoute>
                        <Price />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/priceadd/:id"
                    element={
                      <PrivateRoute>
                        <PriceAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/pricelist"
                    element={
                      <PrivateRoute>
                        <PriceList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/businessunit/:id"
                    element={
                      <PrivateRoute>
                        <Businessunit />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/businessunitadd/:id"
                    element={
                      <PrivateRoute>
                        <BusinessunitAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/businessunitlist"
                    element={
                      <PrivateRoute>
                        <BusinessunitList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/businessunit"
                    element={
                      <PrivateRoute>
                        <AdminBusinessunitlist />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashbordbunitreport"
                    element={
                      <PrivateRoute>
                        <DashbordBunitReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/vacancyshift"
                    element={
                      <PrivateRoute>
                        <Vacancyshift />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/vacancyshiftadd/:id"
                    element={
                      <PrivateRoute>
                        <VacancyshiftAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/shiftprint/:invoiceprint/:data/:employerid/:fromdate/:todate"
                    element={
                      <PrivateRoute>
                        <ShiftPrint />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/invoiceprint/:invoiceid"
                    element={
                      <PrivateRoute>
                        <InvoicePrint />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/payoutprint/:payoutid"
                    element={
                      <PrivateRoute>
                        <PayoutPrint />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/fileview/:url"
                    element={
                      <PrivateRoute>
                        <Fileview />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/vacancyshiftlist"
                    element={
                      <PrivateRoute>
                        <VacancyshiftList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sectionlist"
                    element={
                      <PrivateRoute>
                        <SectionList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sectionadd/:id"
                    element={
                      <PrivateRoute>
                        <SectionAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/section"
                    element={
                      <PrivateRoute>
                        <Section />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/shiftExistlist"
                    element={
                      <PrivateRoute>
                        <ShiftExistList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/allocatecandidate/:id"
                    element={
                      <PrivateRoute>
                        <Candidateemployerallocation />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/allocatecandidateadd/:id"
                    element={
                      <PrivateRoute>
                        <CandidateemployerallocationAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/allocatecandidatelist"
                    element={
                      <PrivateRoute>
                        <CandidateemployerallocationList
                          key={props.isrouteKey}
                        />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/blockbookinglist"
                    element={
                      <PrivateRoute>
                        <BlockBookinglist />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/shiftlist"
                    element={
                      <PrivateRoute>
                        <Shiftmenu />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <PrivateRoute>
                        <Settings />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/vacancylist"
                    element={
                      <PrivateRoute>
                        <VacancyshiftList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/AgencyvacancyList"
                    element={
                      <PrivateRoute>
                        <AgencyvacancyList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/bookinglist/:id?/:date?"
                    element={
                      <PrivateRoute>
                        <BookingList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencybookinglist/:id?/:date?"
                    element={
                      <PrivateRoute>
                        <AgencyBookingList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/reportlist"
                    element={
                      <PrivateRoute>
                        <ReportList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/carehometimesheet"
                    element={
                      <PrivateRoute>
                        <CarehometimeSheet />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/timesheetlist"
                    element={
                      <PrivateRoute>
                        <TimesheetList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencyShifts/:id"
                    element={
                      <PrivateRoute>
                        <AgencyShifts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencyviewprofile/:id?/:agency?"
                    element={
                      <PrivateRoute>
                        <AgencycandidateViewprofile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencycandiadteviewprofile/:id"
                    element={
                      <PrivateRoute>
                        <AgencyCandidateProfile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencycandidateslist"
                    element={
                      <PrivateRoute>
                        <AgencyCandidateList key={props.isrouteKey} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencycandidateadd/:id"
                    element={
                      <PrivateRoute>
                        <AgencyCandidateAdd />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/agencycandidate/:id"
                    element={
                      <PrivateRoute>
                        <AgencyCandidate />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/dashboardemployer"
                    element={
                      <PrivateRoute>
                        <Dashbordnew />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/empdashboard"
                    element={
                      <PrivateRoute>
                        <EmpDashboard />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                    path="/jobpost"
                    element={
                      <PrivateRoute>
                        <JobPost />
                      </PrivateRoute>
                    }
                  /> */}
                  <Route
                    path="/shiftsignoff"
                    element={
                      <PrivateRoute>
                        <AdminShiftMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/jobselection"
                    element={
                      <PrivateRoute>
                        <JobSelection />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/postagency"
                    element={
                      <PrivateRoute>
                        <PostAgency />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/addlocation"
                    element={
                      <PrivateRoute>
                        <AddLocation />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/ratecard"
                    element={
                      <PrivateRoute>
                        <RateCard />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/addnewshift"
                    element={
                      <PrivateRoute>
                        <AddNewShift />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/samplecard"
                    element={
                      <PrivateRoute>
                        <SampleCards />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/shiftsummary"
                    element={
                      <PrivateRoute>
                        <ShiftSummary />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/postlive"
                    element={
                      <PrivateRoute>
                        <PostLive />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/empprofile"
                    element={
                      <PrivateRoute>
                        <EmployerProfilePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/empsettings"
                    element={
                      <PrivateRoute>
                        <EmployerSettingsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/empsettingsmain"
                    element={
                      <PrivateRoute>
                        <EmpSettingsMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/viewemployermain"
                    element={
                      <PrivateRoute>
                        <ViewEmployerMainPage />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="/mapview/:id/:lat/:long"
                    element={
                      <PrivateRoute>
                        <MapView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/totalpricegraph"
                    element={
                      <PrivateRoute>
                        <Totalpricegraph />
                      </PrivateRoute>
                    }
                  />
                  <Route render={() => <h1>404 Error</h1>} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
        {/* </Fragment> */}
      </Router>
    </div>
  );
};

function mapStateToProps(state) {
  const { alert, misc } = state;
  return {
    alert,
    isSidebarOpen: misc.isSidebarOpen,
    isMenuOpen: misc.isMenuOpen,
    isrouteKey: misc.isrouteKey,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
