import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { sectionService } from '../_services';
export const sectionActions = {
  sectionAdd,
  sectionList,
  sectionDelete,
  getgraphData,
};
function sectionAdd(sectionObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SECTION_REQUEST, sectionObj });
      sectionService.sectionAdd(sectionObj).then(
        data => {
          dispatch({
            type: reducerConstants.SECTION_SUCCESS,
            data,
          });
          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);
            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.SECTION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function sectionList(sectionObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SECTION_REQUEST,
        sectionObj,
      });
      sectionService.sectionList(sectionObj).then(
        data => {
          dispatch({
            type: reducerConstants.SECTION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SECTION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function sectionDelete(sectionObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SECTION_REQUEST,
        sectionObj,
      });
      sectionService.sectionDelete(sectionObj).then(
        data => {
          dispatch({
            type: reducerConstants.SECTION_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SECTION_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function getgraphData(graphObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.EMPLOYER_REQUEST,
        graphObj,
      });
      sectionService.getgraphData(graphObj).then(
        data => {
          dispatch({
            type: reducerConstants.EMPLOYER_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.EMPLOYER_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
