import React, { useEffect, useState } from 'react';
import { documentsService } from '../../_services';

const ProfileDocument = () => {
    const [query, setQuery] = useState({
        pageVo: {
            pageNo: 1,
            noOfItems: 8,
        },
    });

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        getAllDocumentList(1);
    }, []);

    const handleChange = event => {
        const { name, value } = event.target;

        if (name === 'searchKey') {
            query.searchKey = value
            setQuery(query)
            getAllDocumentList(1, query);
        }
    };


    const getAllDocumentList = (pageNo, queryTemp) => {
        let queryNew = queryTemp ? queryTemp : query;
        queryNew.pageVo.pageNo = pageNo;

        setQuery(queryNew);

        documentsService.documentsList(queryNew).then(
            res => {
                setDocuments(res.data.rows);
                setLoading(false);
            },
            error => {
                console.error('Error fetching document list:', error);
                setError('Error fetching document list. Please try again.');
                setLoading(false);
            },
        );
    };

    return (
        <div className="col-md-10 flx-emp-profile-main-form">
            <h2 className="flx-company-info-form-heading">Documents</h2>
            <form>
                <div className="flx-search-bar-container mb-3 d-flex">
                    <i className="flx-search-class fa fa-search"></i>
                    <input
                        type="text"
                        className="flx-profile-search-docs form-control"
                        id="flx-emp-profile-doc-search"
                        name='searchKey'
                        onChange={handleChange}
                        placeholder="Search"
                    />
                </div>

                {loading && <p>Loading documents...</p>}

                {error && <p style={{ color: 'red' }}>{error}</p>}

                {!loading && !error && documents.length === 0 && <p>No documents available.</p>}

                {!loading && !error && documents.length > 0 && (
                    <div className="row">
                        {documents.map((document, index) => (
                            <div key={index} className="d-flex col-md-10 mb-2">
                                <div className="row flx-doc-components-billing">
                                    <div className="flx-doc-comp-sla-section mr-4">
                                        <i className="fa fa-file sla-doc-icon"></i>
                                        <span className="flx-sla-doc-name">
                                            {document.document_mode.length > 30
                                                ? `${document.document_mode.substring(0, 30)}...`
                                                : document.document_mode}
                                        </span>
                                    </div>
                                    <div className="d-flex flx-doc-comp-buttons mr-3">
                                        <div className='row'>
                                            <a href={`https://flexirecs.s3.us-east-2.amazonaws.com/${document.document_name}`} target="_blank" className="flx-sla-doc-view-button" rel="noreferrer">View</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </form>
        </div>
    );
};

export default ProfileDocument;
