import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const sampleService = {
  sampleAdd,
  sampleList,
  sampleDelete,
};

function sampleAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addsample', requestOptions).then(
    miscService.handleResponse,
  );
}
function sampleList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getsampleList', requestOptions).then(
    miscService.handleResponse,
  );
}
function sampleDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletesample', requestOptions).then(
    miscService.handleResponse,
  );
}
