/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     CANDIDATE_SUCCESS:'CANDIDATE_SUCCESS',
     CANDIDATE_REQUEST:'CANDIDATE_REQUEST',
     CANDIDATE_FAILURE:'CANDIDATE_FAILURE', 
    */
export function candidate(
  state = { requestStatus: 0, candidateData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'CANDIDATE_SUCCESS':
      return {
        ...state,
        requestStatus: 'CANDIDATE_SUCCESS',
        requestCandidateStatus: 'CANDIDATE_SUCCESS',
        candidateData: action.data.data,
        data: action.data,
      };
    case 'CANDIDATE_FAILURE':
      return {
        ...state,
        requestStatus: 'CANDIDATE_FAILURE',
        requestCandidateStatus: 'CANDIDATE_FAILURE',
        candidateData: {},
        data: action.data,
      };
    case 'CANDIDATE_REQUEST':
      return {
        ...state,
        requestStatus: 'CANDIDATE_REQUEST',
        requestCandidateStatus: 'CANDIDATE_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
