import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sampleActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, reducerConstants } from '../_constants';

const SampleAdd = props => {
  const { id } = useParams();

  const [sample, setSample] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    /**
     * Edit data using url query
     */
    if (id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllSample(1, query);
    }

    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllSample(1, query);
    }
  }, []);

  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  /**
   * Get all samples
   * */
  const getAllSample = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(sampleActions.sampleList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setSample(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setSample({
      ...sample,
      [name]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    // setSubmitted(true);

    const { dispatch } = props;

    setIsLoading(true);
    dispatch(sampleActions.sampleAdd(sample)).then(
      () => {
        props.history.goBack();
        setIsLoading(false);
      },
      err => {
        setError({
          common:
            err && err.message ? err.message : alertConstants.SERVER_ERROR,
        });
        hideError();
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="SampleAdd">
      <div className="col-md-8 col-md-offset-2">
        {isLoading && <LoadingModal />}

        <div className="card">
          <div className="card-header page-header ">
            <div className="card-header page-header">
              <div className="page-title-content">
                <h4 className="card-title">Add sample </h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={sample.email}
                      onChange={handleChange}
                    />
                    {/*Required*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Swaxirprion</label>
                    <input
                      type="description"
                      className="form-control"
                      name="description"
                      placeholder="Enter descriptiuon"
                      value={sample.description}
                      onChange={handleChange}
                    />
                    {/*Required*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button className="btn btn-primary btn-wd">
                      Save
                      {props.requestStatus ===
                        reducerConstants.SAMPLE_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, sampleData } = state.sample;
  return {
    requestStatus,
    sampleData,
  };
}

const connectedSampleAdd = connect(mapStateToProps)(SampleAdd);
export { connectedSampleAdd as SampleAdd };
