import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AttendaceBloackbooking } from '../Block_booking/AttendanceBloackbooking';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';
import { Shifts } from './Shifts';

// let shiftsRows = [];
let employer_id = '';
let agency_id = '';
let user_role = '';

const AgencyShifts = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [shiftverificationmodal, setshiftverificationmodal] = useState(false);
  const [timesheetmodal, settimesheetmodal] = useState(false);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [cancelconfirmationmodal, setcancelconfirmationmodal] = useState(false);
  const [restoreconfirmationmodal, setrestoreconfirmationmodal] =
    useState(false);
  const [shiftid, setshiftid] = useState([]);
  const [restoredetails, setrestoredetails] = useState([]);
  const [canceltype, setcanceltype] = useState();
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [printpdfLoading, setprintpdfLoading] = useState(false);
  const [shiftidsCount, setshiftidsCount] = useState(0);

  const [TrashChecked, setTrashChecked] = useState(false);
  const [CancelChecked, setCancelChecked] = useState(false);
  const [view_shift_id, Setview_shift_id] = useState();
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isagency, setisagency] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [AllSelected, setAllSelected] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 100,
    },
  });
  // effect to call get all shifts
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.agency_id && user.role == 5) {
        setisagency(true);
        agency_id = user.agency_id;
      }
    }
    if (id) {
      if (id == 'upcomingshifts') {
        query.from_date = new Date().toISOString().slice(0, 10);
      }
    }
    getAllShifts(query.pageVo.pageNo);
  }, [query, selected_shiftIds.length, isagency]);
  // modal handler -  timesheet modal
  const showTimesheetModalfunction = value => {
    settimesheetmodal(value);
    getAllShifts(query.pageVo.pageNo);
    setselected_shiftIds([]);
    setshiftidsCount(0);
  };

  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    if (agency_id) {
      queryNew.agency_id = agency_id;
    }
    if (id == 'unverified') {
      queryNew.is_verified = 0;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        for (
          let s = 0;
          res.data && res.data.rows && s < res?.data?.rows?.length;
          s++
        ) {
          res.data.rows[s].x = 1;
        }
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // shit verify confirmation
  const confirmshiftverification = () => {
    const { dispatch } = props;
    dispatch(
      shiftsActions.updateShift({
        _id: shiftverificationmodal.id,
        is_accepted: 1,
      }),
    ).then(function () {
      setshiftverificationmodal({ id: '', modal: false });
      getAllShifts(query.pageVo.pageNo);
    });
  };
  // handler for employer selection
  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setselected_shiftIds([]);
    setshiftidsCount(0);
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  // handler for candidate selection
  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  //--------show delete shift modal show----------------
  const deleteShiftModalShow = shift_id => {
    setshiftid(shift_id);
    setdeleteconfirmationmodal(true);
  };
  // to cancel shift modal
  const cancelShiftModalShow = shift_id => {
    setshiftid(shift_id);
    setcancelconfirmationmodal(true);
  };
  // restore shift modal show
  const restoreShiftModalShow = restoredata => {
    setrestoredetails({
      agency_id: restoredata.agency_id,
      agency_candidate_id: restoredata.agency_candidate_id,
      section_type: restoredata.section_type,
      _id: restoredata._id,
      check_in_date_string: restoredata.check_in_date_string,
    });
    setrestoreconfirmationmodal(true);
  };
  //-------- delete shift function ----------------
  const deleteshift = () => {
    const { dispatch } = props;
    let new_selectedShiftIds = selected_shiftIds;
    for (let k = 0; k < new_selectedShiftIds.length; k++) {
      if (new_selectedShiftIds[k] == shiftid) {
        new_selectedShiftIds.splice(k, 1);
      }
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    dispatch(shiftsActions.shiftsDelete({ _id: shiftid })).then(res => {
      if (res) {
        setdeleteconfirmationmodal(false);
        getAllShifts(query.pageVo.pageNo);
      }
    });
  };
  //----------all selected ------------
  const allcheckedhandle = value => {
    let new_selectedShiftIds = [];
    if (value) {
      let newCandi = shiftsData.rows;
      for (let c = 0; c < newCandi.length; c++) {
        new_selectedShiftIds.push(newCandi[c]._id);
      }
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    setAllSelected(value);
  };
  // category selection handler
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
  };
  // to cancel shift
  const cancelshift = () => {
    const { dispatch } = props;
    let newquery = {};
    setSubmitted(true);
    if (shiftid && canceltype) {
      newquery._id = shiftid;
      newquery.cancel_type = canceltype;
      dispatch(shiftsActions.shiftsCancel(newquery)).then(res => {
        if (res) {
          setcancelconfirmationmodal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    }
  };
  // to restore shift
  const restoreshift = () => {
    const { dispatch } = props;
    setSubmitted(true);
    if (restoredetails) {
      let newquery = restoredetails;
      dispatch(shiftsActions.shiftsRestore(newquery)).then(res => {
        if (res) {
          setrestoreconfirmationmodal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    }
  };
  //----------hadle check select item in row
  const handle2 = (name, shift_id, payoutdata) => {
    let new_selectedShiftIds = selected_shiftIds;
    let new_selected_payout_data = shiftsData.rows;
    let found = false;
    for (let p = 0; p < new_selectedShiftIds.length; p++) {
      if (new_selectedShiftIds[p] == shift_id) {
        new_selectedShiftIds.splice(p, 1);
        found = true;
      }
    }

    for (let s = 0; s < new_selected_payout_data.length; s++) {
      if (new_selected_payout_data[s].id == payoutdata._id) {
        new_selected_payout_data.splice(s, 1);
      }
    }
    if (!found) {
      new_selectedShiftIds.push(shift_id);
    }

    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
  };
  // to check if shift has been selected
  const check_ifSelected2 = _id => {
    let found = false;
    for (let s = 0; s < selected_shiftIds.length; s++) {
      if (selected_shiftIds[s] == _id) {
        found = true;
      }
    }

    return found;
  };

  let shiftsList = [];
  // to list and paginate shift data
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j} className="row-body2">
          {
            <td>
              {shiftsData.rows[j].status == 1 && (
                <div
                  className="pointer"
                  onClick={() =>
                    handle2(
                      'shift_checked',
                      shiftsData.rows[j]._id,
                      shiftsData.rows[j],
                    )
                  }
                >
                  {check_ifSelected2(shiftsData.rows[j]._id) ? (
                    <img className="check_style" src="/assets/img/check1.png" />
                  ) : (
                    <img
                      className="check_style"
                      src="/assets/img/un_check1.png"
                    />
                  )}
                </div>
              )}
            </td>
          }
          <td>
            <div className="">
              <Link
                to={
                  '/agencycandiadteviewprofile/' +
                  shiftsData.rows[j].agency_candidate_id +
                  '/0'
                }
              >
                <div
                  style={{
                    color: 'black',
                    fontSize: '12px',
                    flexDirection: 'row',
                    display: 'flex',
                  }}
                >
                  {shiftsData.rows[j]['Agencycandidate.profileimage'] ? (
                    <img
                      className="round-emp-image"
                      src={
                        apiConstants.imgUrl +
                        shiftsData.rows[j]['Agencycandidate.profileimage']
                      }
                    />
                  ) : (
                    <img
                      className=" round-emp-image"
                      src="/assets/img/recsy-fav-icon.png"
                    />
                  )}
                  <p className="username">
                    {shiftsData.rows[j]['Agencycandidate.name'] &&
                      shiftsData.rows[j]['Agencycandidate.name']}
                  </p>
                </div>
              </Link>
            </div>
          </td>
          <td style={{ width: '100px' }}>
            {moment(shiftsData.rows[j].check_in_date_string).format(
              'YYYY-MMM-DD',
            )}
          </td>
          <td style={{ width: '110px' }}>
            {moment(shiftsData.rows[j].check_out_date_string).format(
              'YYYY-MMM-DD',
            )}
          </td>
          <td>
            <div style={{ fontWeight: 'bold', color: '#183245' }}>
              {shiftsData.rows[j]['Section.section_type']}
            </div>
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(shiftsData.rows[j].check_in_time_string)}{' '}
              -{' '}
              {miscService.time24to12(shiftsData.rows[j].check_out_time_string)}
            </div>
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_in)}
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_out)}
          </td>
          <td>{shiftsData.rows[j].break_time}</td>
          <td>{shiftsData.rows[j].total_hours}</td>
          {(user_role == 1 || user_role == 6) && (
            <td>{shiftsData.rows[j].price} </td>
          )}
          {(user_role == 1 || user_role == 6) && (
            <td> {shiftsData.rows[j].employer_to_pay}</td>
          )}
          {shiftsData.rows[j].is_attended != -1 && (
            <td className="">
              {' '}
              {shiftsData.rows[j].is_verified ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fa fa-times"></i>
              )}
            </td>
          )}
          {shiftsData.rows[j].is_attended == -1 && (
            <td className="absent"> {'Absent'}</td>
          )}
          <td>
            {shiftsData.rows[j].invoice_id &&
            shiftsData.rows[j].invoice_generated == 1 ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </td>
          <td>{shiftsData.rows[j]['Employer.company_name']}</td>
          <td>
            {shiftsData &&
              shiftsData.rows &&
              shiftsData.rows[j].createdAt &&
              moment(shiftsData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          <td>
            <div
              className="allshift-viewdt"
              onClick={() => {
                Setview_shift_id(shiftsData.rows[j]._id),
                  setviewdetailsmodal(true);
              }}
            >
              view
            </div>
          </td>
          {!TrashChecked && !CancelChecked && (
            <td className="eventCell event Edit" style={{ cursor: 'pointer' }}>
              {shiftsData.rows[j].status == 1 && (
                <div
                  onClick={() => {
                    deleteShiftModalShow(shiftsData.rows[j]._id);
                  }}
                >
                  <img src="/assets/img/delete.png"></img>
                </div>
              )}
            </td>
          )}
          {!TrashChecked && !CancelChecked && (
            <td
              className="eventCell event Edit"
              style={{ cursor: 'pointer', color: '#DD3A3A' }}
            >
              {shiftsData.rows[j].status == 1 && (
                <div
                  onClick={() => {
                    cancelShiftModalShow(shiftsData.rows[j]._id);
                  }}
                >
                  Cancel
                </div>
              )}
            </td>
          )}
          {TrashChecked && (
            <td
              className="eventCell event Edit"
              style={{ cursor: 'pointer', color: 'rgb(58 116 221)' }}
            >
              {shiftsData.rows[j].status == 0 && (
                <div
                  onClick={() => {
                    restoreShiftModalShow(shiftsData.rows[j]);
                  }}
                >
                  Restore
                </div>
              )}
            </td>
          )}
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // handler for filters
  const handleChange = event => {
    const { name, value, checked } = event.target;
    setQuery({ ...query, [name]: value });
    if (name == 'shift_checked') {
      let new_selectedShiftIds = selected_shiftIds;
      if (checked) {
        new_selectedShiftIds.push(value);
      }
    }
  };
  // handler for shift cancel reason
  const shiftcancelreasonChange = event => {
    const { value } = event.target;
    setcanceltype(value);
  };
  // to view trash
  const trash_checked = value => {
    setCancelChecked(false);
    setTrashChecked(value);
    if (value) {
      setQuery({ ...query, status: 0 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // checked cancel function
  const cancelled_checked = value => {
    setCancelChecked(value);
    setTrashChecked(false);
    if (value) {
      setQuery({ ...query, status: -1 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // to print shift pdf
  const printShiftpdf = () => {
    setprintpdfLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.printShiftPdf({ Ids: selected_shiftIds })).then(
      res => {
        setprintpdfLoading(false);
        if (res && res.data && res.data.output_file_name) {
          window.open(
            apiConstants.shiftpdfUrl + res.data.output_file_name,
            '_blank',
          );
        }
      },
      () => {
        setprintpdfLoading(false);
      },
    );
  };
  // custom file for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="ShiftList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-9">
                <div className="page-title">
                  {query.from_date ? (
                    <label>
                      Shifts from{' '}
                      {moment(query.from_date, 'yyyy-MM-DD').format(
                        'DD-MMM-yyyy',
                      )}
                    </label>
                  ) : (
                    'Shiftlist'
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex justify-content-between">
                <div className="d-flex col-8 col-sm-6">
                  <div className={'form-group col-md-3'}>
                    <input
                      type="date"
                      className="form-control"
                      name="from_date"
                      placeholder=""
                      value={query.from_date}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={'form-group col-3 '}>
                    <input
                      type="date"
                      className="form-control"
                      name="to_date"
                      placeholder=""
                      value={query.to_date}
                      onChange={handleChange}
                    />
                  </div>
                  {
                    <div className="col-3">
                      <AsyncSelect
                        styles={customStyles}
                        name="employer_id"
                        onChange={setEmployer}
                        placeholder={'select employer'}
                        cacheOptions
                        defaultOptions
                        loadOptions={getEmployerlist}
                      />
                    </div>
                  }
                  {
                    <div className="col-3">
                      <AsyncSelect
                        styles={customStyles}
                        name="agency_candidate_id"
                        onChange={selectedcandidate}
                        placeholder={'select candidate'}
                        cacheOptions
                        defaultOptions
                        // loadOptions={getcandidatelist}
                        loadOptions={getagencycandidatelist}
                      />
                    </div>
                  }
                  {(user_role == 1 || user_role == 6) && (
                    <div className="col-3">
                      <AsyncSelect
                        styles={customStyles}
                        name="category"
                        onChange={category_change}
                        placeholder="select category"
                        cacheOptions
                        defaultOptions
                        // isMulti
                        loadOptions={getCategorylist}
                      />
                    </div>
                  )}
                  <div className="col-md-3">
                    <select
                      className="select col-md-12 selectDesign39"
                      name="invoice_generated"
                      value={query.invoice_generated}
                      onChange={handleChange}
                    >
                      <option value={''}> ALL</option>
                      <option value={1}> invoice generated</option>
                      <option value={0}> Not invoice generated</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select
                      className="select col-md-12 selectDesign39"
                      name="is_verified"
                      value={query.is_verified}
                      onChange={handleChange}
                    >
                      <option value={''}> ALL</option>
                      <option value={1}> timesheet generated</option>
                      <option value={0}> Not timesheet generated</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select
                      className="select col-md-12 selectDesign39"
                      name="is_attended"
                      value={query.is_attended}
                      onChange={handleChange}
                    >
                      <option value={''}> ALL</option>
                      <option value={1}>Attended</option>
                      <option value={-1}> Absent</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 d-flex  justify-content-end ">
                  {selected_shiftIds.length > 0 && (
                    <div className="ml-10">
                      <div
                        className="attendance-div card-body2 mt-1 p-2 "
                        onClick={() => {
                          settimesheetmodal(true);
                        }}
                      >
                        <p className="f-10"> Add Timesheet</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between ml-2 mr-2">
            <div className="d-flex align-items-center col-md-6">
              <div className="card-header page-header">
                {shiftidsCount} shifts selected
              </div>
            </div>
            <div className="card-header page-header">
              {selected_shiftIds.length > 0 && (
                <div className="row">
                  <>
                    <div
                      className="d-flex justify-content-end pr-1 pointer btn-action "
                      onClick={() => printShiftpdf()}
                    >
                      Download shifts{' '}
                    </div>
                    <div>
                      <img
                        style={{ height: 15, width: 15 }}
                        src="/assets/img/pdf.png"
                      />
                    </div>
                    {printpdfLoading && (
                      <div>
                        <img
                          style={{ height: 23, width: 23 }}
                          src="/assets/img/1497.gif"
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            <div className="card-header page-header d-flex align-items-center ">
              <div
                className={
                  TrashChecked ? 'trashButtonActive' : 'trashButtonInActive'
                }
                onClick={() => {
                  trash_checked(!TrashChecked);
                }}
              >
                Trash
              </div>
              <div
                className={
                  CancelChecked
                    ? 'trashButtonActive ml-2'
                    : 'trashButtonInActive ml-2'
                }
                onClick={() => {
                  cancelled_checked(!CancelChecked);
                }}
              >
                Cancelled
              </div>
            </div>
          </div>
          <div
            className="card-body  table-responsive main-card-body "
            style={{ paddingTop: 0 }}
          >
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <div
                        onClick={() => {
                          allcheckedhandle(!AllSelected);
                        }}
                      >
                        {AllSelected ? (
                          <img
                            className="check_style"
                            src="/assets/img/check1.png"
                          />
                        ) : (
                          <img
                            className="check_style"
                            src="/assets/img/un_check1.png"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead"
                    >
                      Name{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort('check_in_date_string')}
                      className="sortHead"
                    >
                      From
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort('check_out_date_string')}
                      className="sortHead"
                    >
                      To
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'check_out_date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'check_out_date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Shift</th>
                    <th>Check in</th>
                    <th>Check out</th>
                    <th>Break time</th>
                    <th>Total hours</th>
                    {(user_role == 1 || user_role == 6) && <th>Price</th>}
                    {(user_role == 1 || user_role == 6) && (
                      <th>Employer pay</th>
                    )}
                    <th>Timesheet</th>
                    <th>Invoice generated</th>
                    <th
                      onClick={() => handleSort('employer_name')}
                      className="sortHead"
                    >
                      Employer
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      onClick={() => handleSort('createdtime')}
                      className="sortHead"
                    >
                      Created
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'createdtime' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'createdtime' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th></th>
                    {!TrashChecked && !CancelChecked && <th></th>}
                    {!TrashChecked && !CancelChecked && <th></th>}
                    {TrashChecked && !CancelChecked && <th></th>}
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {shiftsRows &&
              shiftsRows &&
              shiftsRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <Shifts id={view_shift_id} />
              </div>
            </div>
          </div>
        </div>
      )}
      {shiftverificationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className=" modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshiftverificationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshiftverificationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => confirmshiftverification()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setdeleteconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="d-flex justify-content-center">
                        <img
                          className="delete-icon"
                          src="/assets/img/bin.png"
                        ></img>
                      </div>
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure you want to delete shift?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => deleteshift()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setcancelconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '30px', width: '30px' }}
                            className="delete-icon"
                            src="/assets/img/warning.png"
                          ></img>
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Cancel Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want to cancel the shift? this
                            shift wont be considered anymore and this action
                            cannot be undone
                          </p>
                        </div>
                      </div>
                      {
                        <div className="mb-30">
                          <small>* Select reason for cancellation </small>
                          <select
                            className="select col-md-10 selectDesign"
                            name="cancel_type"
                            value={query.cancel_type}
                            onChange={shiftcancelreasonChange}
                          >
                            <option value={''}>Select</option>
                            <option value={'Accidentally added shift'}>
                              Accidentally added shift{' '}
                            </option>
                            <option value={'Emergency cancellation'}>
                              Emergency cancellation
                            </option>
                            <option
                              value={'Shift cannot be attended on this date'}
                            >
                              Shift cannot be attended on this date
                            </option>
                            <option value="Other"> Other</option>
                          </select>
                        </div>
                      }
                      {!canceltype && submitted && (
                        <div className="help-block">
                          Cancel type is required
                        </div>
                      )}
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setcancelconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            onClick={() => cancelshift()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {restoreconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setrestoreconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '40px', width: '40px' }}
                            className="delete-icon"
                            src="/assets/img/restoreicon.png"
                          ></img>
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Restore Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want to restore the shift?
                          </p>
                        </div>
                      </div>
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setrestoreconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_restore width_100"
                            onClick={() => restoreshift()}
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {timesheetmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      settimesheetmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <AttendaceBloackbooking
                    hidefilter={1}
                    showTimesheetModal={showTimesheetModalfunction}
                    shifts_Ids={selected_shiftIds}
                  ></AttendaceBloackbooking>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for employer in select options
const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for agency candidate in select options
const getagencycandidatelist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencycandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for category list in select options
const getCategorylist = () =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedAgencyShifts = connect(mapStateToProps)(AgencyShifts);
export { connectedAgencyShifts as AgencyShifts };
