import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const categoryService = {
  categoryAdd,
  categoryList,
  categoryDelete,
  categoryListNoAuth,
  dashbordCategoryReport,
  rateCardList,
  jobTitleList,
};

function categoryAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addcategory', requestOptions).then(
    miscService.handleResponse,
  );
}
function categoryList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getcategoryList', requestOptions).then(
    miscService.handleResponse,
  );
}

function dashbordCategoryReport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordcategoryreport',
    requestOptions,
  ).then(miscService.handleResponse);
}
function categoryListNoAuth(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcategorylistnoauth',
    requestOptions,
  ).then(miscService.handleResponse);
}
function categoryDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletecategory', requestOptions).then(
    miscService.handleResponse,
  );
}
function jobTitleList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/jobs/shift/jobtitle',
    requestOptions,
  ).then(miscService.handleResponse);
}

function rateCardList(data) {
  // Convert the data object to URL parameters
  const queryParams = new URLSearchParams(data);

  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  // Append the query parameters to the URL
  const apiUrlWithParams =
    apiConstants.apiUrl + '/vacancy/ratecard?' + queryParams;

  return fetch(apiUrlWithParams, requestOptions)
    .then(response => response.json())
    .then(parsedData => {
      // Handle the parsed data as needed
      console.log(parsedData);
      return parsedData; // You can also return the data for further processing
    })
    .catch(error => {
      console.error(error);
      throw error; // Re-throw the error for further error handling if needed
    });
}
