import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const candidateService = {
  candidateAdd,
  candidateList,
  candidateDelete,
  candidateshifts,
  candidateListv2,
  candidateProfilePublic,
};

function candidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addcandidate', requestOptions).then(
    miscService.handleResponse,
  );
}

function candidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getcandidateList', requestOptions).then(
    miscService.handleResponse,
  );
}

function candidateProfilePublic(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateprofilepublic',
    requestOptions,
  ).then(miscService.handleResponse);
}

function candidateListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateListv2',
    requestOptions,
  ).then(miscService.handleResponse);
}

// function unverifiedcandidateList(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/getcandidateListv2',
//     requestOptions,
//   ).then(miscService.handleResponse);
// }

function candidateshifts(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateshifts',
    requestOptions,
  ).then(miscService.handleResponse);
}
function candidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletecandidate', requestOptions).then(
    miscService.handleResponse,
  );
}
