import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { miscActions } from '../_actions';

import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

const Totalpricegraph = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState({});
  const [year, setyear] = useState(0);
  let employer_id = '';
  let agency_id = '';
  // effect to call dashboard details
  useEffect(() => {
    dashbordgraph();
  }, [year]);
  // handler for previous year
  const previousyearhandlechange = () => {
    setyear(year - 1);
  };
  // handler for next year
  const upcomingyearhandlechange = () => {
    setyear(year + 1);
  };
  // call for get dashboard graph details
  const dashbordgraph = () => {
    setIsLoading(true);
    const { dispatch } = props;
    let fromDate = new Date().getFullYear() + year + '-01-01';
    let toDate = new Date().getFullYear() + year + '-12-31';
    let querynew = {
      fromDate: fromDate,
      toDate: toDate,
    };
    if (employer_id) {
      querynew.employer_id = employer_id;
    }
    if (agency_id) {
      querynew.agency_id = agency_id;
    }
    dispatch(miscActions.getgraphData(querynew)).then(res => {
      setPerformanceData(res.data);
      setIsLoading(false);
    });
  };
  // labels for chart
  const labels = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  // custom data for chart
  const data = {
    labels,
    datasets: [
      {
        label: 'price',
        data: performanceData,
        height: '10px',
        backgroundColor: '#20B9B7',
        border: 'none',
        borderWidth: '1px',
        barPercentage: 0.5,
        width: '100px',
        borderRadius: 20, // This will round the corners
        borderSkipped: false,
      },
    ],
  };
  return (
    <div className="col-md-12 pl-0 pr-0">
      <div className="  table-responsive ">
        <div className="">
          <div className="card-body">
            {!isLoading && (
              <div className="chart-bar">
                <div className="col">
                  <label>Total Price {new Date().getFullYear() + year}</label>
                </div>
                <Bar height={'100px'} options={options} data={data} />
                <div
                  className="left-right-arrow ml-2"
                  onClick={upcomingyearhandlechange}
                >
                  <i className="fas fa-arrow-right white"></i>
                </div>
                <div
                  className="left-right-arrow"
                  onClick={previousyearhandlechange}
                >
                  <i className="fas fa-arrow-left white"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  const { isMenuOpen } = state.misc;

  return {
    user,
    users,
    isMenuOpen,
  };
}
// chart options
const options = {
  responsive: true,
  legend: {
    cursor: 'pointer',
    display: true,
    position: 'right',
    labels: {
      usePointStyle: false,
      boxWidth: 10,
    },
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#898989',
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#898989',
        },
      },
    ],
  },
};
const connectedTotalpricegraph = connect(mapStateToProps)(Totalpricegraph);
export { connectedTotalpricegraph as Totalpricegraph };
