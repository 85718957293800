import React, { useEffect, useState } from 'react';

export const PaginationNew = props => {
  const [paginationItems, setPaginationItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const pageOnClick = data => {
    if (props.pageOnClick) {
      props.pageOnClick(data);
    }
  };

  const setListAndPagination = paginationData => {
    let paginationItems = [];
    if (paginationData && paginationData.pages > 1) {
      let currentPageNo = paginationData.page;
      let totalPages = paginationData.pages;

      paginationItems.push(
        <button
          className="pagination-arrows"
          onClick={() => {
            if (currentPageNo > 1) {
              pageOnClick(currentPageNo - 1);
            }
          }}
          disabled={currentPageNo === 1}
          key="prev"
        >
          &lt;
        </button>,
      );

      paginationItems.push(
        <button
          className="pagination-arrows"
          onClick={() => {
            if (totalPages > currentPageNo) {
              pageOnClick(currentPageNo + 1);
            }
          }}
          disabled={totalPages <= currentPageNo}
          key="next"
        >
          &gt;
        </button>,
      );
    }

    setPaginationItems(paginationItems);
  };

  useEffect(() => {
    if (props.paginationData) {
      setCurrentPage(props.paginationData.page);
      setListAndPagination(props.paginationData);
    }
  }, [props.paginationData]);

  useEffect(() => {
    if (props.paginationData?.rows?.length === 0 && currentPage > 1) {
      pageOnClick(currentPage - 1);
    }
  }, [props.paginationData?.rows, currentPage]);

  const { page = 1, total = 0 } = props.paginationData || {};

  const itemsPerPage = props.itemsPerPage || 20;

  const startIndex = (page - 1) * itemsPerPage + 1;

  const endIndex = Math.min(page * itemsPerPage, total);
  return (
    <div className="pagination-top">
      <span>{`${startIndex} - ${endIndex} of ${total}`}</span>
      {paginationItems}
    </div>
  );
};
