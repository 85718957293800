import React from "react";
import { connect } from "react-redux";

const SampleCards = () => {
  return (
    <>
      <div className="container flx-job-post-main-container">
        <h2 className="text-center">Post Agency Shift</h2>
        <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
          <p className="text-center flx-recs-required mb-22">Add Job Details</p>
          <div className="container mt-4">
            <table className="table flx-shift-table">
              <thead className="table flx-shift-final-header">
                <tr>
                  <th>Date</th>
                  <th>Position</th>
                  <th>Shift time</th>
                  <th>Vacancies</th>
                  <th>Price</th>
                  <th />
                </tr>
              </thead>
              <tbody className="table flx-shift-final-body text-center">
                <tr>
                  <td className="flx-shift-final-date-container">
                    <div className="flx-shift-final-date">15</div>
                    <div className="flx-shift-final-month">Dec</div>
                    <div className="flx-shift-final-year">2023</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-title">
                      Healthcare assistant
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      9.00-16.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Morning Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        1
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£18</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Repeat <tr> for more entries */}
              </tbody>
            </table>
          </div>

          {/* shift final table 2 */}
          <div className="container mt-4">
            <table className="table flx-shift-table">
              <thead className="table flx-shift-final-header">
                <tr>
                  <th>Date</th>
                  <th>Position</th>
                  <th>Shift time</th>
                  <th>Vacancies</th>
                  <th>Price</th>
                  <th />
                </tr>
              </thead>
              <tbody className="table flx-shift-final-body text-center">
                <tr>
                  <td className="flx-shift-final-date-container">
                    <div className="flx-shift-final-date">15</div>
                    <div className="flx-shift-final-month">Dec</div>
                    <div className="flx-shift-final-year">2023</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-title">
                      Healthcare assistant
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      9.00-16.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Morning Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        1
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£18</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Repeat <tr> for more entries */}
                <tr>
                  <td className="flx-shift-final-date-container"></td>
                  <td></td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      16.00-23.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Evening Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        1
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£18</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
                {/* next row */}
                <tr>
                  <td className="flx-shift-final-date-container">
                    <div className="flx-shift-final-date">16</div>
                    <div className="flx-shift-final-month">Dec</div>
                    <div className="flx-shift-final-year">2023</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-title">RGN</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      16.00-23.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Evening Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        3
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£23</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* shift final table ends */}

          {/* shift final table 3 */}
          <div className="container mt-4">
            <table className="table flx-shift-table">
              <thead className="table flx-shift-final-header">
                <tr>
                  <th>Date</th>
                  <th>Position</th>
                  <th>Shift time</th>
                  <th>Vacancies</th>
                  <th>Price</th>
                  <th />
                </tr>
              </thead>
              <tbody className="table flx-shift-final-body text-center">
                <tr>
                  <td className="flx-shift-final-date-container">
                    <div className="flx-shift-final-date">15</div>
                    <div className="flx-shift-final-month">Dec</div>
                    <div className="flx-shift-final-year">2023</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-title">
                      Healthcare assistant
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      9.00-16.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Morning Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        1
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£18</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
                {/* Repeat <tr> for more entries */}

                {/* next row */}
                <tr>
                  <td className="flx-shift-final-date-container">
                    <div className="flx-shift-final-date">16</div>
                    <div className="flx-shift-final-month">Dec</div>
                    <div className="flx-shift-final-year">2023</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-title">RGN</div>
                  </td>
                  <td>
                    <div className="flx-shift-final-job-timings">
                      16.00-23.00
                    </div>
                    <div className="flx-shift-final-job-shiftname">
                      Evening Shift
                    </div>
                  </td>
                  <td>
                    <div className="flx-shift-final-vacancy-card">
                      <div className="flx-shift-final-job-shift-vacancy-number">
                        3
                      </div>
                      <div>
                        <i className="fa fa-adjust flx-immediate-cover-icon"></i>
                        <i className="fa fa-certificate flx-paid-break-icon"></i>
                      </div>
                    </div>
                  </td>
                  <td className="flx-shift-final-price-cell text-center">
                    <div className="flx-shift-rate">£23</div>
                    <i className="fa fa-eye flx-payment-view-icon"></i>
                  </td>
                  <td>
                    <div className="flx-shift-final-actions">
                      <div className="flx-shift-final-actions-copy">
                        <i className="flx-shift-final-actions-copy-icon fa fa-book"></i>
                        Copy Shift
                      </div>
                      <div className="flx-shift-final-actions-edit">
                        <i className="flx-shift-final-actions-edit-icon fa fa-tag"></i>
                        Edit
                      </div>
                      <div className="flx-shift-final-actions-delete">
                        <i className="flx-shift-final-actions-delete-icon fa fa-trash"></i>
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* shift final table ends */}
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {requestStatus};
}

const connectedSampleCards = connect(mapStateToProps)(SampleCards);
export { connectedSampleCards as SampleCards };