import React from 'react';
import { connect } from 'react-redux';

const ReferalSuccess = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/flexi_logo_bg.jpg" className="logo" />
            {/* <span>Ship Easy </span> */}
          </div>
        </div>
        <div className=" row justify-content-center">
          <div className="col-md-8 col-lg-8  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6 align-self-center">
                <div className="row align-items-baseline">
                  <h2 className="bold">Registration Successfull</h2>
                  <img
                    className="h20  ml-2"
                    src="/assets/img/check22.png"
                  ></img>
                </div>
                <div className="row">
                  <label className="mt-3 reg_success_desc">
                    Thank you for choosing flexirecs. Your registration
                    completed successfully.
                  </label>
                </div>
              </div>
              {/* //----second div */}
              <div className="col-md-6 d-flex align-items-center">
                <div>
                  <img
                    className="image-width-regsuccess"
                    src="/assets/img/win22.jpg"
                  ></img>
                </div>
              </div>
            </div>
            {/*   <SocialLogin/>*/}
          </div>
        </div>
      </div>
    </div>
  );
  //  }
};

function mapStateToProps() {
  //   const { loggingIn } = state.authentication;
  return {
    // loggingIn,
  };
}

const connectedReferalSuccess = connect(mapStateToProps)(ReferalSuccess);
export { connectedReferalSuccess as ReferalSuccess };
