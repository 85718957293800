import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { AccountIDPrompt, RegPopup } from '../_components';
import { loginRequest, userService } from '../_services';

const MicrosoftAuth = ({ isLogin, showError, showLoading, isRegister }) => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [microsoftLoginId, setMicrosoftLoginId] = useState(null);
  const [promptModel, setpromptModel] = useState(false);
  const [promptModelRegistration, setPromptModelRegistration] = useState(false);

  const handleLogin = async () => {
    await instance.loginPopup(loginRequest);
  };

  const updateLoadingStatus = status => {
    showLoading(status);
  };

  useEffect(() => {
    return () => {
      // Cleanup function to execute when component unmounts or navigates away
      updateLoadingStatus(false);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      // updateLoadingStatus(true);
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: loginRequest.scopes,
        })
        .then(response => {
          microsoftLoginService(response);
        });
    }
  }, [inProgress, isAuthenticated, accounts, instance]);

  const microsoftLoginService = async data => {
    setMicrosoftLoginId(data);
    data.isLogin = isLogin;
    data.isRegister = isRegister;
    updateLoadingStatus(true);
    try {
      const res = await userService.microsoftlogin(data);

      if (res.error_code == 0) {
        // The user is created there new account and tries to sign-up with our platform.
        if (res.data.microsoftLogin) {
          handleLogin();
          // updateLoadingStatus(true);
          return;
        }
        // To show the RegPopup modal
        if (res.data.show_microsoftIntegrationModel) {
          updateLoadingStatus(false);
          setPromptModelRegistration(true);
          return;
        }

        // To show the AccountIDPrompt modal In
        if (res.data.toLinkWithGoogle) {
          setpromptModel(true);
          updateLoadingStatus(false);
          return;
        }

        // Store data in local storage
        localStorage.setItem('user', JSON.stringify(res.data));
        // Navigate
        if (res.data.role == 4) {
          if (res.data.is_signup_completed) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/phonenumber';
            updateLoadingStatus(false);
          }
        } else if (res.data.role == 6 || res.data.role == 7) {
          window.location.href = '/dashboardemployer';
        } else {
          updateLoadingStatus(false);
          window.location.href = '/login';
        }
      } else {
        updateLoadingStatus(false);
        showError(res.message);
      }
    } catch (error) {
      updateLoadingStatus(false);
      showError(typeof error == 'string' ? error : 'Microsoft Login Failed');
      window.localStorage.clear();
    }
  };

  // For close the modal
  const closeModal = () => {
    setMicrosoftLoginId(null);
    setpromptModel(false);
    setPromptModelRegistration(false);
  };

  // Popup for AccountIDPrompt modal in Sign-up
  const cancelPopModal = async () => {
    setMicrosoftLoginId(null);
    window.localStorage.clear();
    window.location.href = '/login';
    return;
  };

  // AccountIDPrompt modal to link with microsoft in Sign-in
  const handleMicrosoftLoginClick = () => {
    microsoftLoginService({
      ...microsoftLoginId,
      is_saved_microsoft_id: 'true',
    });
  };

  return (
    <div>
      <>
        {promptModel && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <AccountIDPrompt
              onCancel={closeModal}
              onMicrosoftLogin={handleMicrosoftLoginClick}
            />
          </div>
        )}
      </>

      <>
        {promptModelRegistration && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <RegPopup onCancel={cancelPopModal} onAnotherAction={closeModal} />
          </div>
        )}
      </>

      <button className="microsoft-auth-btn" onClick={() => handleLogin()}>
        <img src="/assets/img/microsoft-login-icon.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Microsoft</span>
      </button>
    </div>
  );
};

export default MicrosoftAuth;
