import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from './misc.service';
export const staffService = {
  staffAdd,
  staffList,
  staffDelete,
};

function staffAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addstaff', requestOptions).then(
    miscService.handleResponse,
  );
}
function staffList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getstaffList', requestOptions).then(
    miscService.handleResponse,
  );
}
function staffDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletestaff', requestOptions).then(
    miscService.handleResponse,
  );
}
