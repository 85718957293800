import { AllShift } from './AdminShiftComponents/AllShift';

export const AdminShiftMain = () => {
  const currentPage = 1;
  return (
    <>
      <div className="">{currentPage == 1 && <AllShift />}</div>
    </>
  );
};
