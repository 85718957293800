import React, { useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { LoadingModal } from '../_components';
import { messageConstant } from '../_constants';
import { sectionService } from '../_services';

export const AddShiftTimeForm = ({
  handleCloseModal,
  existingTimeList,
  employerId,
  onSuccess,
}) => {
  const [error, setError] = useState({ common: '' });
  const [breakTimeError, setBreakTimeError] = useState(false);
  const [nextDay, setNextDay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shift, setShift] = useState({
    name: '',
    employer_id: '',
    from_time: '',
    to_time: '',
    breakTime: '',
  });
  const currentTime = new Date(); // Get the current date and time

  const startTime = useMemo(
    () =>
      new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        shift.from_time.split(':')[0],
        shift.from_time.split(':')[1],
      ),
    [shift.from_time],
  );

  const endTime = useMemo(
    () =>
      new Date(
        currentTime.getFullYear(),
        currentTime.getMonth(),
        currentTime.getDate(),
        shift.to_time.split(':')[0],
        shift.to_time.split(':')[1],
      ),
    [shift.to_time],
  );

  const durationInMilliseconds = useMemo(() => {
    setNextDay(false);
    let duration = endTime - startTime;
    if (endTime < startTime) {
      const additionalDays = Math.ceil((startTime - endTime) / (24 * 3600000));
      duration += additionalDays * 24 * 3600000;
      setNextDay(true);
    }
    return duration;
  }, [startTime, endTime]);

  const hours = useMemo(
    () => Math.floor(durationInMilliseconds / 3600000),
    [durationInMilliseconds],
  );

  const minutes = useMemo(
    () => Math.floor((durationInMilliseconds % 3600000) / 60000),
    [durationInMilliseconds],
  );

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === 'from_time' && value === '') {
      setError(prevError => ({
        ...prevError,
        from_time: messageConstant.START_TIMER_REQUIRED,
        isError: true,
      }));
    }

    if (name === 'to_time' && value === '') {
      setError(prevError => ({
        ...prevError,
        to_time: messageConstant.END_TIMER_REQUIRED,
        isError: true,
      }));
    }

    if (name === 'name' && value === '') {
      setError(prevError => ({
        ...prevError,
        name: messageConstant.SHIFT_NAME_REQUIRED,
        isError: true,
      }));
    }

    if (name === 'breakTime' && value < 20) {
      if (hours >= 6) {
        setBreakTimeError(true);
      }
    }
    if (name === 'name' && value.length < 20) {
      setShift(prevShift => ({
        ...prevShift,
        [name]: value,
      }));
    } else if (name !== 'name') {
      setShift(prevShift => ({
        ...prevShift,
        [name]: value,
      }));
    }
    setError(prevError => ({
      ...prevError,
      [name]: '',
      isError: false,
    }));
  };
  const handleSubmit = async () => {
    error.isError = false;
    if (!shift.from_time) {
      error.from_time = messageConstant.START_TIMER_REQUIRED;
      error.isError = true;
    }

    if (!shift.to_time) {
      error.to_time = messageConstant.END_TIMER_REQUIRED;
      error.isError = true;
    }

    if (!shift.name) {
      error.name = messageConstant.SHIFT_NAME_REQUIRED;
      error.isError = true;
    }
    if (hours >= 6) {
      if (!shift.breakTime) {
        shift.breakTime = Math.abs(hours) >= 6 ? `${(0 + 4) * 5}` : `${0 * 5}`;
      }
    } else {
      shift.breakTime = shift.breakTime ? shift.breakTime : 0;
    }
    // Normalize value function to handle null and undefined
    const normalizeValue = value =>
      value !== null && value !== undefined ? value.toString() : '';
    const existingBreakTime = existingTimeList?.find(shiftTime => {
      const normalizedShiftTimeBreak = normalizeValue(shiftTime.breakTime);
      const normalizedShiftBreak = normalizeValue(shift.breakTime);
      return normalizedShiftTimeBreak === normalizedShiftBreak;
    });
    const existingStartTime = existingTimeList?.find(
      shiftTime => shiftTime.from_time === shift.from_time,
    );
    const existingEndTime = existingTimeList?.find(
      shiftTime => shiftTime.to_time === shift.to_time,
    );
    if (existingBreakTime && existingStartTime && existingEndTime) {
      error.common = messageConstant.SHIFT_EXISTS;
      error.isError = true;
    }
    if (error.isError) {
      setError({ ...error });
      return;
    }
    if (error.isError) {
      return;
    }
    try {
      setIsLoading(true);
      if (shift.name && shift.employer_id && shift.from_time && shift.to_time) {
        const response = await sectionService.sectionAdd(shift);
        if (
          response.error_code === 0 &&
          response.data.employer_id === shift.employer_id
        ) {
          {
            onSuccess(response.data);
            handleCloseModal();
            setIsLoading(false);
            setShift({
              name: '',
              employer_id: '',
              from_time: '',
              to_time: '',
              breakTime: '',
            });
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setError({
        ...error,
        common: error.message,
        isError: true,
      });
    }
    if (error.isError) {
      setError({ ...error });
      return;
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setShift(prevstate => ({
          ...prevstate,
          employer_id: user.employer_id,
        }));
      } else {
        setShift(prevstate => ({
          ...prevstate,
          employer_id: employerId,
        }));
      }
    }
  }, []);

  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header flx-add-shift-popup-header">
          <h5 className="" id="modalLabel">
            Create Your Shift
          </h5>
          <button
            type="button"
            className="close flx-closebutton-popup flx-addshift-close-icon"
            data-dismiss="modal"
            aria-label="Close"
            style={{ margin: 'none' }}
            onClick={handleCloseModal}
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
          <p style={{ padding: '5px 14px 5px 0px' }}>
            You can create your own shift
          </p>
        </div>
        <div className="modal-body">
          <form>
            <div className="flx-dateframe-addshift">
              <div className="mb-3">
                <label htmlFor="startTime" className="form-label">
                  Start Time
                </label>
                <input
                  className="form-control"
                  type="time"
                  id="flx-start-time-shift"
                  min="00:00"
                  max="24:00"
                  step="00:30"
                  name="from_time"
                  value={shift.from_time}
                  onChange={handleChange}
                  required
                ></input>
                <div className="help-block">{error.from_time}</div>
              </div>
              <div className="mb-3">
                <label htmlFor="endTime" className="form-label">
                  End Time
                </label>
                <input
                  className="form-control"
                  id="flx-end-time-shift"
                  type="time"
                  min="00:00"
                  max="24:00"
                  step="00:30"
                  name="to_time"
                  value={shift.to_time}
                  onChange={handleChange}
                  required
                ></input>
                <div className="help-block">{error.to_time}</div>
              </div>
            </div>
            {shift.to_time !== '' && shift.from_time !== '' && (
              <div className="d-flex flx-shift-popup-total-time-container justify-content-center align-items-center mb-3">
                <img
                  className="flx-rate-card-title-date-edit"
                  src="/assets/img/tot-shift-hours.svg"
                />
                <span className="flx-shift-popup-total-hours-text">
                  Total Shift Hours
                </span>
                <span className="flx-shift-popup-total-hours-calculated">
                  {nextDay ? (
                    <span>
                      {`${Math.abs(hours)
                        .toString()
                        .padStart(2, '0')}.${Math.abs(minutes)
                        .toString()
                        .padStart(2, '0')}h `}
                      <span
                        style={{
                          fontSize: '0.7em',
                          marginLeft: '0.5em',
                        }}
                      >
                        +1Day
                      </span>
                    </span>
                  ) : (
                    `${hours.toString().padStart(2, '0')}.${minutes
                      .toString()
                      .padStart(2, '0')}h`
                  )}
                </span>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="shiftName" className="form-label">
                Shift Name
              </label>
              <input
                type="text"
                className="form-control"
                id="shiftName"
                name="name"
                placeholder="Enter Your Shift Name"
                value={shift.name}
                onChange={handleChange}
              />
              <div className="help-block">{error.name}</div>
            </div>
            <div className="mb-3">
              <label htmlFor="breakTime" className="form-label">
                Break Time
              </label>
              <select
                className="form-control"
                id="breakTime"
                name="breakTime"
                value={shift.breakTime}
                onChange={handleChange}
              >
                {Math.abs(hours) >= 6
                  ? [...Array(21).keys()].map(value => (
                      <option key={value} value={`${(value + 4) * 5}`}>
                        {`${(value + 4) * 5}m`}
                      </option>
                    ))
                  : [...Array(13).keys()].map(value => (
                      <option key={value} value={`${value * 5}`}>
                        {`${value * 5}m`}
                      </option>
                    ))}
              </select>

              <div className="help-block">{error.breakTime}</div>
              {breakTimeError && (
                <div>
                  <div className="flx-notice-period"></div>
                  <i className="fa fa-exclamation-circle"></i>
                  <span className="flx-error-msg-breaktime">
                    {messageConstant.MINIMUM_BREAK}
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>
        {error.common && (
          <div className="help-block" style={{ padding: '0px 5px 0px 20px' }}>
            {error.common}
          </div>
        )}
        <div
          className="modal-footer flx-popup-bottom flx-add-shift-popup"
          style={{ justifyContent: 'end' }}
        >
          <button onClick={handleCloseModal} className="flx-add-shift-cancel">
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-sm flx-popup-button float-end"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
        {isLoading && <LoadingModal />}
      </div>
    </div>
  );
};
