import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { candidateService } from '../_services';
export const candidateActions = {
  candidateAdd,
  candidateList,
  candidateDelete,
  candidateshifts,
  candidateListv2,
  candidateProfilePublic,
};

function candidateAdd(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.CANDIDATE_REQUEST, candidateObj });

      candidateService.candidateAdd(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function candidateList(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CANDIDATE_REQUEST,
        candidateObj,
      });

      candidateService.candidateList(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function candidateListv2(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CANDIDATE_REQUEST,
        candidateObj,
      });

      candidateService.candidateListv2(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function candidateDelete(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CANDIDATE_REQUEST,
        candidateObj,
      });

      candidateService.candidateDelete(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function candidateProfilePublic(userObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CANDIDATE_REQUEST,
        userObj,
      });

      candidateService.candidateProfilePublic(userObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function candidateshifts(candidateObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.CANDIDATE_REQUEST,
        candidateObj,
      });

      candidateService.candidateshifts(candidateObj).then(
        data => {
          dispatch({
            type: reducerConstants.CANDIDATE_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.CANDIDATE_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
