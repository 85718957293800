import React from 'react';

const EmployerSettingsMenu = props => {
  return (
    <>
      <div className="col-md-12 mb-3 d-none d-lg-block">
        <div className="d-flex flex-column">
          <h3 className="flx-company-name-text">Settings</h3>
          <ul className="flx-company-info-settings-main-links">
            <MenuItem
              icon="/assets/img/Company.svg"
              text="Company Info"
              active={props.currentPage === 1}
              onClick={() => props.setCurrentPage(1)}
            />
            <MenuItem
              icon="/assets/img/Biiling info.svg"
              text="Billing Info"
              active={props.currentPage === 2}
              onClick={() => props.setCurrentPage(2)}
            />
            <MenuItem
              icon="/assets/img/Documents.svg"
              text="Documents"
              active={props.currentPage === 3}
              onClick={() => props.setCurrentPage(3)}
            />
            <MenuItem
              icon="/assets/img/User.svg"
              text="Users"
              active={props.currentPage === 4}
              onClick={() => props.setCurrentPage(4)}
            />
            <MenuItem
              icon="/assets/img/sett-bus-usr.svg"
              text="Business Units"
              active={props.currentPage === 5}
              onClick={() => props.setCurrentPage(5)}
            />
            <MenuItem
              icon="/assets/img/sett-agency.svg"
              text="Agency Shift Settings"
              active={props.currentPage === 6}
              onClick={() => props.setCurrentPage(6)}
            />
            <MenuItem
              icon="/assets/img/sett-perm.svg"
              text="Permanent Settings"
            />
            <MenuItem
              icon="/assets/img/sett-international.svg"
              text="International Settings"
            />
            <MenuItem
              icon="/assets/img/sett-delegate.svg"
              text="Delegate Access"
            />
            <MenuItem
              icon="/assets/img/sett-mob-app.svg"
              text="Connect Mobile App"
              active={props.currentPage === 7}
              onClick={() => props.setCurrentPage(7)}
            />
          </ul>
        </div>
      </div>
      <div className="container-fluid flx-emp-profile-mobile-menu-container d-block d-lg-none">
        <div className="row flx-emp-profile-mobile-menu-row">
          <div className="col flx-emp-profile-mobile-menu-col">
            <div className="d-flex flex-row flex-nowrap">
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 1
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(1)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Company.svg"
                />
                Company Info
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 2
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(2)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Biiling info.svg"
                />
                Billing Info
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 3
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(3)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/Documents.svg"
                />
                Documents
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 4
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(4)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/User.svg"
                />
                Users
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 5
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(5)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-bus-usr.svg"
                />
                Business Units
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 6
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(6)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-agency.svg"
                />
                Agency Shift Settings
              </div>
              <div
                className={`flx-emp-profile-mob-menu-icon-set d-flex pointer ${
                  props.currentPage === 7
                    ? 'flx-emp-profile-mob-menu-icon-set_active'
                    : ''
                }`}
                onClick={() => props.setCurrentPage(7)}
              >
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-mob-app.svg"
                />
                Connect Mobile App
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MenuItem = ({ icon, text, active, onClick }) => {
  return (
    <div
      className={`${
        [
          'Permanent Settings',
          'International Settings',
          'Delegate Access',
        ].includes(text)
          ? ' d-flex pb-4 text-permanent'
          : `flx-emp-profile-menu-icons flx-emp-settings-icons pointer d-flex ${
              active ? 'active' : ''
            }`
      }`}
      onClick={onClick}
    >
      <img className="flx-emp-profile-menu-icons" src={icon} alt={text} />
      <li
        className={`${
          [
            'Permanent Settings',
            'International Settings',
            'Delegate Access',
          ].includes(text)
            ? 'text-permanent pt-1'
            : `flx-company-info-settings-li ${
                active ? 'flx-company-info-settings-li-active' : ''
              }`
        }`}
      >
        {text}
      </li>
    </div>
  );
};

export default EmployerSettingsMenu;
