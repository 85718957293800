export function payout(
  state = { requestStatus: 0, payoutData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'PAYOUT_SUCCESS':
      return {
        ...state,
        requestStatus: 'PAYOUT_SUCCESS',
        requestPayoutStatus: 'PAYOUT_SUCCESS',
        payoutData: action.data.data,
        data: action.data,
      };
    case 'PAYOUT_FAILURE':
      return {
        ...state,
        requestStatus: 'PAYOUT_FAILURE',
        requestPayoutStatus: 'PAYOUT_FAILURE',
        payoutData: {},
        data: action.data,
      };
    case 'PAYOUT_REQUEST':
      return {
        ...state,
        requestStatus: 'PAYOUT_REQUEST',
        requestPayoutStatus: 'PAYOUT_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
