/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     SHIFTS_SUCCESS:'SHIFTS_SUCCESS',
     SHIFTS_REQUEST:'SHIFTS_REQUEST',
     SHIFTS_FAILURE:'SHIFTS_FAILURE', 
    */
export function shifts(
  state = { requestStatus: 0, shiftsData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'SHIFTS_SUCCESS':
      return {
        ...state,
        requestStatus: 'SHIFTS_SUCCESS',
        requestShiftsStatus: 'SHIFTS_SUCCESS',
        shiftsData: action.data.data,
        data: action.data,
      };
    case 'SHIFTS_FAILURE':
      return {
        ...state,
        requestStatus: 'SHIFTS_FAILURE',
        requestShiftsStatus: 'SHIFTS_FAILURE',
        shiftsData: {},
        data: action.data,
      };
    case 'SHIFTS_REQUEST':
      return {
        ...state,
        requestStatus: 'SHIFTS_REQUEST',
        requestShiftsStatus: 'SHIFTS_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
