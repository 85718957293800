/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     USER_SUCCESS:'USER_SUCCESS',
     USER_REQUEST:'USER_REQUEST',
     USER_FAILURE:'USER_FAILURE', 
    */
export function user(
  state = { requestStatus: 0, userData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'USER_SUCCESS':
      return {
        ...state,
        requestStatus: 'USER_SUCCESS',
        requestUserStatus: 'USER_SUCCESS',
        userData: action.data.data,
        data: action.data,
      };
    case 'USER_FAILURE':
      return {
        ...state,
        requestStatus: 'USER_FAILURE',
        requestUserStatus: 'USER_FAILURE',
        userData: {},
        data: action.data,
      };
    case 'USER_REQUEST':
      return {
        ...state,
        requestStatus: 'USER_REQUEST',
        requestUserStatus: 'USER_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
