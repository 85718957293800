import { postAgencyConstant } from '../_constants/postAgency.constants';

export function postAgencyReducer(
  state = { postAgencyData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case postAgencyConstant.DATA:
      return {
        ...state,
        type: 'postagency-data',
        postAgencyData: action.payload,
        data: action.payload,
      };

    default:
      return state;
  }
}
