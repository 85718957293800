import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { employerActions } from '../_actions';
import { LoadingImg } from '../_components';

const EmployeesList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [employeesData, setEmployeesData] = useState({ rows: [] });
  const [employeesRows, setEmployeesRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  // effect to load employee data having query dependency
  useEffect(() => {
    getAllEmployees(1);
  }, [query]);
  /**
   * Get all employees
   **/
  const getAllEmployees = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(employerActions.employerList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = employeesData => {
    let employeesList = [];
    for (
      let j = 0;
      employeesData && employeesData.rows && j < employeesData.rows.length;
      j = j + 1
    ) {
      employeesList.push(
        <tr key={j}>
          <td>{(employeesData.page - 1) * employeesData.limit + j + 1}</td>
          {/* --------id starts from 2000 --------- */}
          <td>{employeesData.rows[j].company_name} </td>
          <td> {2000 + employeesData.rows[j]._id} </td>
          <td className="eventCell  eventView">
            <a href={'/employer/' + employeesData.rows[j]._id}>view</a>
          </td>
          <td className="eventCell  eventView addleave">
            <a className="" href={'/employer/' + employeesData.rows[j]._id}>
              Add leave
            </a>
          </td>
          <td className="eventCell  eventView">
            <a href={'/employer/' + employeesData.rows[j]._id}>
              Add availability
            </a>
          </td>
        </tr>,
      );
    }
    setEmployeesRows(employeesList);
    setEmployeesData(employeesData);
  };
  return (
    <div className="EmployeesList">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color ">
            {!props.isHomepage && (
              <div className="card-header page-header ">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title">Employees</div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 "></div>
                  {/* FROM DATE PICKER======== */}
                  <div className="col-md-6">
                    <div className="row d-flex justify-content-end align-items-center">
                      <div className="col-md-3">
                        <a className="btn btn-primary-blue float-right">
                          <p className="button-text"> Add Employee</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                !props.isHomepage
                  ? 'card-body  table-responsive main-card-body '
                  : '  table-responsive main-card-body '
              }
            >
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-top1">No</th>
                      <th className="">Employees name</th>
                      <th>Id</th>
                      <th className="eventCell  eventView " />
                      <th className="eventCell  eventView" />
                      <th className="eventCell  eventView" />
                    </tr>
                  </thead>
                  <tbody>{employeesRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {employeesData &&
                employeesData.rows &&
                employeesData.rows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedEmployeesList = connect(mapStateToProps)(EmployeesList);
export { connectedEmployeesList as EmployeesList };
