/*  ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     CATEGORY_SUCCESS:'CATEGORY_SUCCESS',
     CATEGORY_REQUEST:'CATEGORY_REQUEST',
     CATEGORY_FAILURE:'CATEGORY_FAILURE', 
    */
export function category(
  state = { requestStatus: 0, categoryData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'CATEGORY_SUCCESS':
      return {
        ...state,
        requestStatus: 'CATEGORY_SUCCESS',
        requestCategoryStatus: 'CATEGORY_SUCCESS',
        categoryData: action.data.data,
        data: action.data,
      };
    case 'CATEGORY_FAILURE':
      return {
        ...state,
        requestStatus: 'CATEGORY_FAILURE',
        requestCategoryStatus: 'CATEGORY_FAILURE',
        categoryData: {},
        data: action.data,
      };
    case 'CATEGORY_REQUEST':
      return {
        ...state,
        requestStatus: 'CATEGORY_REQUEST',
        requestCategoryStatus: 'CATEGORY_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
