import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { vacancyshiftService } from '../_services';
export const vacancyshiftActions = {
  vacancyshiftAdd,
  vacancyshiftList,
  vacancyshiftListv2,
  vacancyshiftDelete,
  Vacancycalender,
};

function vacancyshiftAdd(vacancyshiftObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.VACANCYSHIFT_REQUEST,
        vacancyshiftObj,
      });

      vacancyshiftService.vacancyshiftAdd(vacancyshiftObj).then(
        data => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function vacancyshiftList(vacancyshiftObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.VACANCYSHIFT_REQUEST,
        vacancyshiftObj,
      });

      vacancyshiftService.vacancyshiftList(vacancyshiftObj).then(
        data => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function vacancyshiftListv2(vacancyshiftObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.VACANCYSHIFT_REQUEST,
        vacancyshiftObj,
      });

      vacancyshiftService.vacancyshiftListv2(vacancyshiftObj).then(
        data => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function Vacancycalender(vacancyshiftObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.VACANCYSHIFT_REQUEST,
        vacancyshiftObj,
      });

      vacancyshiftService.Vacancycalender(vacancyshiftObj).then(
        data => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function vacancyshiftDelete(vacancyshiftObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.VACANCYSHIFT_REQUEST,
        vacancyshiftObj,
      });

      vacancyshiftService.vacancyshiftDelete(vacancyshiftObj).then(
        data => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.VACANCYSHIFT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
