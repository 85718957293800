import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { alertActions, miscActions, qualificationActions } from '../_actions';
import { alertConstants } from '../_constants';

const QualificationAndTrainingAdd = props => {
  // eslint-disable-next-line no-unused-vars
  const [isSubmit, setisSubmit] = useState(false);
  const [disabled, setdisabled] = useState(false);
  const [qualification, setqualification] = useState({
    mode: 1,
  });
  const { dispatch } = props;
  const [qualification_id, setqualification_id] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({ common: '' });
  //   const [changeproof_document, setchangeproof_document] = useState(false);
  const inputElement = useRef(null);
  useEffect(() => {
    // if (props?.qualification) {
    //   setqualification(props.qualification);
    //   qualification.from_date = moment(qualification.from_date).format('DD-MM-YYYY');
    // }
    // inputElement.current && inputElement.current.focus();
  }, [qualification]);

  useEffect(() => {
    if (props.qualification_id) {
      setqualification_id(props.qualification_id);
      getqualification();
    }

    if (props.mode) {
      setqualification({ ...qualification, mode: props.mode });
    }

    // console.log(
    //   'aaaa hide file 123 ',
    //   changeproof_document,
    //   qualification.proof_document,
    //   props.qualification_id,
    // );
    // if (props.candidate_id) {
    // }
  }, []);

  const showchangefileupload = () => {
    inputElement.current && inputElement.current.click();

    // setchangeproof_document(true);
    // console.log(
    //   'aaaa hide file 123 ',
    //   changeproof_document,
    //   qualification.proof_document,
    // );
  };
  const handleQualification = event => {
    const { name, value } = event.target;
    if (name == 'qualification') {
      if (event.target.checked) {
        setqualification({
          ...qualification,
          mode: 1,
        });
      } else {
        setqualification({
          ...qualification,
          mode: 2,
        });
      }
    } else if (name == 'training') {
      if (event.target.checked) {
        setqualification({
          ...qualification,
          mode: 2,
        });
      } else {
        setqualification({
          ...qualification,
          mode: 1,
        });
      }
    } else {
      setqualification({
        ...qualification,
        [name]: value,
      });
    }
    setdisabled(false);
  };

  const getqualification = () => {
    let qualification = {};

    setisLoading(true);
    const { dispatch } = props;

    if (props.qualification_id) {
      qualification._id = props.qualification_id;
    }
    dispatch(qualificationActions.qualificationList(qualification)).then(
      res => {
        setisLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setqualification(res?.data?.rows?.[0]);
        }
      },
      () => {
        setisLoading(false);
      },
    );
  };

  ////------------file upload new ----------
  const onFileChangeDirectS3 = event => {
    setisLoading(true);
    const { dispatch } = props;
    let ext = '';
    if (!event.target.files || !event.target.files[0]) {
      return;
    }

    let file_new = event.target.files[0];
    ext = event.target.files[0].name.split('.').pop();
    //-------get signed url for put data
    dispatch(
      miscActions.s3frontEndUploadUrl({
        type: 'document',
        mime_type: file_new.type,
        ext,
      }),
    ).then(async res => {
      let signedurl = res.data.signedUrl;
      let file_name = res.data.filename;

      fetch(signedurl, {
        method: 'PUT',
        headers: {
          'Content-Type': file_new.type,
          'Content-Disposition': 'inline',
        },
        body: file_new,
      })
        .then(response => response.text())
        .catch(() => {
          setisLoading(false);
          dispatch(alertActions.error('SOMETHING WENT WRONG'));
        })
        .then(res => {
          if (res != undefined) {
            if (file_name) {
              setqualification(prevState => ({
                ...prevState,
                proof_document: file_name,
              }));
            }

            setisLoading(false);
          }
        });
    });
  };
  const handleSubmit = () => {
    setisSubmit(true);
    setdisabled(true);
    setisLoading(true);
    if (
      qualification.completed_date &&
      qualification.mode &&
      qualification.level
    ) {
      if (qualification.completed_date) {
        let completed_date_string = moment(qualification.completed_date).format(
          'YYYY-MM-DD',
        );
        qualification.completed_date_string = completed_date_string;
      }

      if (qualification_id) {
        qualification._id = qualification_id;
      }
      if (props.candidate_id) {
        qualification.candidate_id = props.candidate_id;
      }
      dispatch(qualificationActions.qualificationAdd(qualification)).then(
        // eslint-disable-next-line no-unused-vars
        res => {
          // window.location.href = '/profile/qualificationlist/' + candidate_id;
          setisSubmit(false);
          props?.hideaddqualificationmodal();
          // props?.getQalificationAddResponse();
          // props?.hideeditqualificationmodal();
        },
        err => {
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          // hideError();
          // setisSubmit(false);
          setdisabled(false);
          setisLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });

      // setisSubmit(false);
      setdisabled(false);
      setisLoading(false);
    }
  };

  return (
    <div className="card  mt-2">
      <div className="card-body">
        <form name="form">
          <h2 style={{ fontWeight: '700' }}>
            {qualification._id ? 'Edit ' : 'Add '} Training and Certifications
          </h2>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group currentLocationCardText datefield">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !qualification.completed_date
                      ? ' has-error'
                      : '')
                  }
                >
                  <label htmlFor="usr" className="currentLocationCardText">
                    Date Completed:*
                  </label>
                  <input
                    type={'date'}
                    name="completed_date"
                    onChange={handleQualification}
                    value={
                      qualification.completed_date
                        ? moment(qualification.completed_date).format(
                            'YYYY-MM-DD',
                          )
                        : ''
                    }
                    max={moment().format('YYYY-MM-DD')}
                    className="form-control"
                  />
                  {isSubmit && !qualification.completed_date && (
                    <div className="help-block">Completed date is required</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className={'form-group'}>
                <label>This is a:*</label>

                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="qualification"
                        checked={qualification.mode == 1 ? true : false}
                        onChange={handleQualification}
                      />
                      <label className="ml-2 m-0">Certification</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center ">
                      <input
                        type="checkbox"
                        name="training"
                        checked={qualification.mode == 2 ? true : false}
                        onChange={handleQualification}
                      />
                      <label className="ml-2 m-0">Training</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* {isSubmit && !qualification.mode && (
                    <div className="help-block"> is required</div>
                  )} */}
            </div>
          </div>
          {qualification.mode == 2 && (
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !qualification.level ? ' has-error' : '')
                  }
                >
                  {/* <label className="currentLocationCardText datefield">
                  Care Related Qualifications:*
                </label> */}
                  <p>
                    {' '}
                    Care Related Training:*
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: '11px',
                      color: 'rgb(6 62 139)',
                      fontWeight: ' bold',
                    }}
                  >
                    {' '}
                    <img className="info-icon" src="/assets/img/info.png" /> You
                    may be asked to provide original certificate
                  </p>

                  <select
                    className="select col-md-12 selectDesign mt-10"
                    name="level"
                    value={qualification.level}
                    onChange={handleQualification}
                  >
                    <option value="" hidden={true}>
                      {' '}
                      select level of training
                    </option>
                    <option value={'Moving and Handling'}>
                      Moving and Handling
                    </option>
                    <option value={'Medication Administartion'}>
                      Medication Administartion
                    </option>
                    <option value={'Epilepsy with buccal Midazolam'}>
                      Epilepsy with buccal Midazolam
                    </option>
                    <option value={'Basic Life Support'}>
                      Basic Life Support
                    </option>
                    <option value={'Tracheostomy for Adults'}>
                      Tracheostomy for Adults
                    </option>
                    <option value={'PEG Feed'}>PEG Feed</option>
                    <option value={'Learning Disabilities'}>
                      Learning Disabilities
                    </option>
                    <option value={'Dementia Awareness'}>
                      Dementia Awareness
                    </option>
                    <option value={'Others'}>Others</option>
                  </select>
                  {isSubmit && !qualification.level && (
                    <div className="help-block">
                      level of training is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {qualification.mode == 1 && (
            <div className="row">
              <div className="col-md-12">
                <div
                  className={
                    'form-group ' +
                    (isSubmit && !qualification.level ? ' has-error' : '')
                  }
                >
                  {/* <label className="currentLocationCardText datefield">
                  Care Related Qualifications:*
                </label> */}
                  <p>
                    {' '}
                    Care Related Certification::*
                    <br />
                  </p>
                  <p
                    style={{
                      fontSize: '11px',
                      color: 'rgb(6 62 139)',
                      fontWeight: ' bold',
                    }}
                  >
                    <img className="info-icon" src="/assets/img/info.png" /> You
                    may be asked to provide original certificate
                  </p>
                  <select
                    className="select col-md-12 selectDesign mt-10"
                    name="level"
                    value={qualification.level}
                    onChange={handleQualification}
                  >
                    <option value="" hidden={true}>
                      {' '}
                      select level of qualification
                    </option>
                    <option value={'Entry Level'}>Entry Level</option>
                    <option value={'NVQ/SVQ Level 1'}>NVQ/SVQ Level 1</option>
                    <option value={'NVQ/SVQ Level 2'}>NVQ/SVQ Level 2</option>
                    <option value={'NVQ/SVQ Level 3'}>NVQ/SVQ Level 3</option>
                    <option value={'NVQ/SVQ Level 4'}>NVQ/SVQ Level 4</option>
                    <option value={'NVQ/SVQ Level 5'}>NVQ/SVQ Level 5</option>
                    <option value={'NVQ/SVQ Level 6'}>NVQ/SVQ Level 6</option>
                    <option value={'NVQ/SVQ Level 7'}>NVQ/SVQ Level 7</option>
                  </select>
                  {isSubmit && !qualification.level && (
                    <div className="help-block">
                      level of qualification is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-12 ">
              <div className={'form-group mb-4'}>
                <label htmlFor="type">Upload Evidence of Completion</label>
                {qualification.proof_document && (
                  <div
                    className="col-md-8 d-flex justify-content-between align-items-center filenames"
                    style={{ maxWidth: 'none' }}
                  >
                    <div>
                      {' '}
                      {qualification && qualification.proof_document
                        ? qualification.proof_document
                        : 'no image uploaded'}{' '}
                    </div>
                    <div className="float-right">
                      {' '}
                      <div
                        className="changebutton m-2 p-3"
                        onClick={showchangefileupload}
                      >
                        {' '}
                        Change{' '}
                      </div>
                    </div>
                  </div>
                )}

                {/* {!qualification.proof_document && ( */}
                <div className="d-flex">
                  <div className="float-right">
                    {' '}
                    <input
                      type="file"
                      ref={inputElement}
                      className={
                        !qualification.proof_document
                          ? 'form-control '
                          : 'form-control dispfile'
                      }
                      name="proof_document"
                      placeholder=""
                      onChange={onFileChangeDirectS3}
                    />
                  </div>
                </div>
                {/* hello tetsing samplessss)} */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className={'form-group'}>
                <label className="currentLocationCardText datefield">
                  Description
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  id=""
                  onChange={handleQualification}
                  cols="30"
                  rows="5"
                >
                  {qualification.description ? qualification.description : ''}
                </textarea>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="error">{error.common && error.common}</div>
          </div>
          {!disabled ? (
            <div
              data-cy="addqualification"
              className=" btn regbutton float-right"
              onClick={handleSubmit}
            >
              Save
            </div>
          ) : (
            <div className=" btn regbutton-disabled  float-right">
              Saving..
              {isLoading && (
                <div className="lds-ring ml-2">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}
const connectedQualificationAndTrainingAdd = connect(mapStateToProps)(
  QualificationAndTrainingAdd,
);

export { connectedQualificationAndTrainingAdd as QualificationAndTrainingAdd };
