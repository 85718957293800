/* eslint-disable no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import { AccountIDPrompt, RegPopup } from '../_components';
import { userService } from '../_services';

const GoogleLogin = ({ isLogin, showError, isRegister, showLoading }) => {
  const [googleLoginId, setGoogleLoginId] = useState(null);
  const [promptModel, setPromptModel] = useState(false);
  const [promptModelRegistration, setPromptModelRegistration] = useState(false);

  const updateLoadingStatus = status => {
    showLoading(status);
  };

  useEffect(() => {
    return () => {
      updateLoadingStatus(false);
    };
  }, []);

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      GoogleLoginService(codeResponse);
    },
    onError: () => {
      showError('Google Login Failed');
    },
  });

  const GoogleLoginService = async user => {
    setGoogleLoginId(user);
    user.isLogin = isLogin;
    user.isRegister = isRegister;
    updateLoadingStatus(true);
    try {
      const res = await userService.GoogleLoginRegistration(user);

      if (res.error_code == 0) {
        // To show the RegPopup modal
        if (res.data.GoogleRegistration) {
          setPromptModelRegistration(true);
          updateLoadingStatus(false);
          return;
        }
        // To show the AccountIDPrompt modal In
        if (res.data.toLinkWithGoogle) {
          setPromptModel(true);
          updateLoadingStatus(false);
          return;
        }
        // Store data in local storage
        localStorage.setItem('user', JSON.stringify(res.data));

        // Navigate
        if (res.data.role == 4) {
          if (res.data.is_signup_completed) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/phonenumber';
            updateLoadingStatus(false);
          }
        } else if (res.data.role == 6 || res.data.role == 7) {
          window.location.href = '/dashboardemployer';
        } else {
          //If user is not employer
          updateLoadingStatus(false);
          window.location.href = '/login';
        }
      } else {
        showError(res.message);
        updateLoadingStatus(false);
      }
    } catch (error) {
      updateLoadingStatus(false);
      console.error('Error in GoogleLoginService:', error);
      showError(typeof error === 'string' ? error : 'Google Login Failed');
    }
    updateLoadingStatus(false);
  };

  // For close the modal
  const closeModal = () => {
    setGoogleLoginId(null);
    setPromptModel(false);
    setPromptModelRegistration(false);
  };

  // Popup for AccountIDPrompt modal in Sign-up
  const cancelPopModal = () => {
    setGoogleLoginId(null);
    window.location.href = '/login';
  };
  // AccountIDPrompt modal to link with Google in Sign-in
  const handleGoogleLoginClick = () => {
    GoogleLoginService({
      ...googleLoginId,
      is_save_google_id: 'true',
    });
  };

  return (
    <>
      <>
        {promptModel && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <AccountIDPrompt
              onCancel={closeModal}
              onGoogleLogin={handleGoogleLoginClick}
            />
          </div>
        )}
      </>

      <>
        {promptModelRegistration && (
          <div id="exampleModal" tabIndex={-1} role="dialog">
            <RegPopup onCancel={cancelPopModal} onAnotherAction={closeModal} />
          </div>
        )}
      </>

      <button
        className="google-auth-btn"
        onClick={() => {
          login();
        }}
      >
        <img src="/assets/img/google.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Google</span>
      </button>
    </>
  );
};

export default GoogleLogin;
