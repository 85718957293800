import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DashbordAgencyReport } from '../Agency/DshbordAgencyReport';
import { DashbordBunitReport } from '../BusinessUnit/DashbordBunitReport';
import { DashbordCategoryReport } from '../Category/DashbordCategoryReport';
import { Averagepricegraph } from './Averagepricegraph';
import { DashbordOrganizationReport } from './DashbordOrganizationReport';
import { SectionReportGraph } from './SectionReportGraph';
import { Totalpricegraph } from './Totalpricegraph';
// import { DashbordCategoryReport } from '../Category/DashbordCategoryReport';

const Dashbordnew = () => {
  // eslint-disable-next-line no-unused-vars
  let id = '';
  const [isbunit, setbunit] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      // if (user.role == 1) {
      //     setisAdmin(true);
      // }
      if (user.role == 4 || user.role == 7) {
        // setisEmployer(true);

        id = user.employer_id;
      }
      if (user.role == 7) {
        setbunit(true);
      }
      // if (user.role == 5) {
      //     setisAgency(true);
      // }
      // if (user.role == 6) {
      //     setStaff(true);
      // }
    }
    // dashboarddata({ id: id })
    // dashbordgraph()
  }, []);
  //--------get dashboard counts data-------------
  // const dashboarddata = data => {
  //   new Promise((resolve, reject) => {
  //     fetch(apiConstants.apiUrl + '/dashboardadmin', {
  //       method: 'post',
  //       headers: authHeader(),
  //       body: JSON.stringify(data),
  //     })
  //       .then(response => response.json())
  //       .then(({ data }) => {
  //         resolve();
  //       })
  //       .catch(reject);
  //   });
  // };
  return (
    <div className="col-md-12 pl-0 pr-0">
      <div className="container-fluid container-medium">
        <div className={' menuopen'}> </div>
        <div className="">
          <div className="row">
            <div className="col-md-6 ">
              <div className="card_item mt-3">
                <Averagepricegraph></Averagepricegraph>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card_item mt-3">
                <Totalpricegraph></Totalpricegraph>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex flex-wrap mt-4">
          <div className="row col-md-8">
            {!isbunit && (
              <div className=" p-0 col-md-12">
                <DashbordOrganizationReport
                  ishomepage={true}
                ></DashbordOrganizationReport>
              </div>
            )}
            {!isbunit && (
              <div className=" p-0 col-md-12">
                <DashbordBunitReport ishomepage={true}></DashbordBunitReport>
              </div>
            )}
            <div className=" p-0 col-md-12">
              <DashbordAgencyReport ishomepage={true}></DashbordAgencyReport>
            </div>
          </div>
          <div className="col-md-4 p-0">
            <div className="h-right-grid p-0 m-2">
              <div className=" " style={{ marginTop: '4px' }}>
                <div className="custom-card">
                  <DashbordCategoryReport
                    ishomepage={true}
                  ></DashbordCategoryReport>
                </div>
              </div>
              <div className=" " style={{ marginTop: '4px' }}>
                {' '}
                <SectionReportGraph></SectionReportGraph>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-4 p-0">
          <div className="h-right-grid p-0 m-2">
            <div className=" " style={{ marginTop: '4px' }}>
              <DashbordOrganizationReport
                ishomepage={true}
              ></DashbordOrganizationReport>
            </div> */}

        {/* <div className=" " style={{ marginTop: '4px' }}>
                {' '}
                <SectionReportGraph></SectionReportGraph>
              </div> */}
        {/* </div>
        </div> */}
        <div className="row"></div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  const { isMenuOpen } = state.misc;
  return {
    user,
    users,
    isMenuOpen,
  };
}
// eslint-disable-next-line no-unused-vars
const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 10,
        },
      },
    ],
  },
};
const connectedDashbordnew = connect(mapStateToProps)(Dashbordnew);
export { connectedDashbordnew as Dashbordnew };
