import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { businessunitActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Businessunit = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [businessunit, setBusinessunit] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  // effect to load get all business unit
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllBusinessunit(1, query);
    }
  }, []);
  /**
   * Get all businessunits
   * */
  const getAllBusinessunit = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(businessunitActions.businessunitList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setBusinessunit(res?.data?.rows?.[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Businessunit">
      {(props.requestStatus === reducerConstants.BUSINESSUNIT_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Businessunit view</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Name</label>
              </div>
              <div className="col-md-6">
                <b>{businessunit.name}</b>
              </div>
            </div>{' '}
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Employer</label>
              </div>
              <div className="col-md-6">
                <b>{businessunit['Employer.company_name']}</b>
              </div>
            </div>{' '}
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Email</label>
              </div>
              <div className="col-md-6">
                <b>{businessunit.email}</b>
              </div>
            </div>{' '}
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Address</label>
              </div>
              <div className="col-md-6">
                <b>{businessunit.address}</b>
              </div>
            </div>{' '}
          </div>
          <div className="card-footer">
            <Link className="link" to={'/businessunitadd/' + businessunit._id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, businessunitData } = state.businessunit;
  return {
    requestStatus,
    businessunitData,
  };
}

const connectedBusinessunit = connect(mapStateToProps)(Businessunit);
export { connectedBusinessunit as Businessunit };
