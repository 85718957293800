import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';

const ShiftExistList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [candidateRows, setCandidateRows] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
    searchKey: '',
    isverified: '',
  });
  useEffect(() => {
    getAllCandiateWithShiftExist(1);
  }, [query.searchKey, query.isverified]);
  //--------all candidate with shift existing data---------
  const getAllCandiateWithShiftExist = async (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    const { dispatch } = props;
    setIsLoading(true);
    await dispatch(shiftsActions.getAllCandiateWithShiftExist(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
        setCandidateData(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //-----------list and pagination data for candidate data --------------
  const setListAndPagination = candidateData => {
    let ShiftExistList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      ShiftExistList.push(
        <tr key={j}>
          <td>
            {' '}
            {candidateData &&
            candidateData.rows &&
            candidateData.rows[j].profileimage ? (
              <img
                className=" round-emp-image"
                src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
              />
            ) : (
              <img
                className=" round-emp-image"
                src="/assets/img/recsy-fav-icon.png"
              />
            )}
            {candidateData.rows[j].candidate[0]}{' '}
          </td>
          <td className="topay_text">{candidateData.rows[j].no_of_shifts}</td>
          <td className="topay_text">
            {candidateData.rows[j].no_of_shifts_verified}
          </td>
        </tr>,
      );
    }
    setCandidateRows(ShiftExistList);
  };
  return (
    <div className="ShiftExistList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-body  table-responsive main-card-body ">
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-top1">candidate</th>
                      <th>to be invoice</th>
                      <th>to be payout</th>
                      <th className="eventCell  eventView border-top2" />
                    </tr>
                  </thead>
                  <tbody>{candidateRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {candidateRows && candidateRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllCandiateWithShiftExist(data);
                }}
                paginationData={candidateData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedShiftExistList = connect(mapStateToProps)(ShiftExistList);
export { connectedShiftExistList as ShiftExistList };
