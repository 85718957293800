import { miscConstants } from '../_constants';

let dt = new Date();
//   let date= dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2)

export function misc(
  state = {
    requestStatus: 0,
    data: {},
    isSidebarOpen: false,
    isOtheroptionOpen: true,
    isMenuOpen: false,
    isrouteKey: new Date().toString(),
    vacancyshiftFilterDate:
      dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2),
    vacancyshiftFilterbusinessunit: {},
  },
  action,
) {
  switch (action.type) {
    case miscConstants.REQUEST:
      return {
        ...state,
        requestStatus: miscConstants.REQUEST,
        data: action.data,
      };
    case miscConstants.SUCCESS:
      return {
        ...state,
        requestStatus: miscConstants.SUCCESS,
        data: action.data,
      };
    case miscConstants.FAILURE:
      return {
        ...state,
        requestStatus: miscConstants.FAILURE,
        data: action.error,
      };
    case miscConstants.CLEAR:
      return { ...state, requestStatus: miscConstants.CLEAR };
    case 'isMenuOpen':
      return { ...state, isMenuOpen: action.data };
    case 'isOtheroptionOpen':
      return { ...state, isOtheroptionOpen: action.data };
    case 'isSidebarOpen':
      return { ...state, isSidebarOpen: action.data };
    case 'vacancyshiftFilterDate':
      return { ...state, vacancyshiftFilterDate: action.data };
    case 'vacancyshiftFilterbusinessunit':
      return { ...state, vacancyshiftFilterbusinessunit: action.data };
    case 'isrouteKey':
      return { ...state, isrouteKey: action.data };
    default:
      return state;
  }
}
