import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { payoutService } from '../_services';
export const payoutActions = {
  payoutAdd,
  payoutList,
  payoutDelete,
  payoutBulkGenerate,
  generatenewpayout,
  generatesinglepayout,
  getcandidatedetails,
};

function payoutAdd(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PAYOUT_REQUEST, payoutObj });

      payoutService.payoutAdd(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function getcandidatedetails(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PAYOUT_REQUEST, payoutObj });

      payoutService.getcandidatedetails(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            // dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            // dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function generatesinglepayout(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PAYOUT_REQUEST, payoutObj });

      payoutService.generatesinglepayout(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            // dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            // dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function generatenewpayout(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PAYOUT_REQUEST, payoutObj });

      payoutService.generatenewpayout(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function payoutBulkGenerate(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.PAYOUT_REQUEST, payoutObj });

      payoutService.payoutBulkGenerate(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function payoutList(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.PAYOUT_REQUEST,
        payoutObj,
      });

      payoutService.payoutList(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function payoutDelete(payoutObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.PAYOUT_REQUEST,
        payoutObj,
      });

      payoutService.payoutDelete(payoutObj).then(
        data => {
          dispatch({
            type: reducerConstants.PAYOUT_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.PAYOUT_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
