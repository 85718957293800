export function businessunit(
  state = { requestStatus: 0, businessunitData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'BUSINESSUNIT_SUCCESS':
      return {
        ...state,
        requestStatus: 'BUSINESSUNIT_SUCCESS',
        requestBusinessunitStatus: 'BUSINESSUNIT_SUCCESS',
        businessunitData: action.data.data,
        data: action.data,
      };
    case 'BUSINESSUNIT_FAILURE':
      return {
        ...state,
        requestStatus: 'BUSINESSUNIT_FAILURE',
        requestBusinessunitStatus: 'BUSINESSUNIT_FAILURE',
        businessunitData: {},
        data: action.data,
      };
    case 'BUSINESSUNIT_REQUEST':
      return {
        ...state,
        requestStatus: 'BUSINESSUNIT_REQUEST',
        requestBusinessunitStatus: 'BUSINESSUNIT_REQUEST',
        data: action.data,
      };
    default:
      return state;
  }
}
