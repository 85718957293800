export const SettingsCompanyInfo = () => {
  return (
    <div className="flx-company-info container mt-3">
      <div className="flx-company-info-row row d-block d-lg-flex">
        {/* Sidebar for user profile options */}
        <div className="col-md-3 mb-3 d-none d-lg-block">
          <div className="d-flex flex-column">
            <h3 className="flx-company-name-text">Settings</h3>
            <ul className="flx-company-info-settings-main-links">
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/company-info-icon.svg"
                />

                <li className="flx-company-info-settings-li flx-company-info-settings-li-active">
                  Company Info
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-usr.svg"
                />

                <li className="flx-company-info-settings-li ">Users</li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-bus-usr.svg"
                />
                <li className="flx-company-info-settings-li flx-emp-settings-li ">
                  Business Units
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-agency.svg"
                />
                <li className="flx-company-info-settings-li">
                  Agency Shift Settings
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-perm.svg"
                />
                <li className="flx-company-info-settings-li">
                  Permanent Settings
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-international.svg"
                />
                <li className="flx-company-info-settings-li">
                  International Settings
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-delegate.svg"
                />
                <li className="flx-company-info-settings-li">
                  Delegate Access
                </li>
              </div>
              <div className="flx-emp-profile-menu-icons flx-emp-settings-icons d-flex">
                <img
                  className="flx-emp-profile-menu-icons"
                  src="/assets/img/sett-mob-app.svg"
                />
                <li className="flx-company-info-settings-li">
                  Connect mobile app
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className="flx-emp-profile-vertical-hr d-none d-lg-block"></div>
        {/* Sidebar turned as a scrollable menu on smaller screens */}
        <div className="container-fluid flx-emp-profile-mobile-menu-container d-block d-lg-none">
          <div className="row flx-emp-profile-mobile-menu-row">
            <div className="col flx-emp-profile-mobile-menu-col">
              <div className="d-flex flex-row flex-nowrap">
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-profile-mob-menu-icon-set_active  flx-emp-sett-mob-icon-users-menu d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/company-info-icon.svg"
                  />
                  <span className="flx-emp-profile-mob-menu-text">
                    Company Info
                  </span>
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set  flx-emp-sett-mob-icon-users-menu d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-usr.svg"
                  />
                  <span className="flx-emp-profile-mob-menu-text">Users</span>
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set  flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-bus-usr.svg"
                  />
                  Business Units
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set  flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-agency.svg"
                  />
                  Agency Shift Settings
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-perm.svg"
                  />
                  Permanent Settings
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-international.svg"
                  />
                  International Settings
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-delegate.svg"
                  />
                  Delegate Access
                </div>
                <div className="flx-emp-profile-mob-menu-icon-set flx-emp-sett-mob-icon d-flex">
                  <img
                    className="flx-emp-profile-menu-icons"
                    src="/assets/img/sett-mob-app.svg"
                  />
                  Connect Mobile App
                </div>
                {/* Add more menu items here */}
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar turned as a scrollable menu on smaller screens ends */}
        {/* Main content for editing profile information */}

        <div className="col-md-6 flx-emp-profile-main-form">
          <h2 className="flx-company-info-form-heading">Company info</h2>
          <form>
            <div className="mb-3">
              <label
                htmlFor="companyName"
                className="form-label company-info-labels"
              >
                Company name
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="companyName"
                placeholder="My Company"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="postcode"
                className="form-label company-info-labels"
              >
                Postcode
              </label>
              <i className="fa fa-info-circle company-info-icon-tooltip"></i>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="postcode"
                placeholder="Enter postcode"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="postcode"
                className="form-label company-info-labels"
              >
                Select Address
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id=""
                placeholder="Type your address"
              />
            </div>
            <div className="mb-3 ml-3">
              <input
                className="form-check-input flx-comp-settings-form-control"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />

              <label
                className="form-check-label company-info-labels"
                htmlFor="flexCheckDefault"
              >
                This is my billing address
              </label>
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Contact Person
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="Enter full name"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Email
              </label>{' '}
              <i className="fa fa-info-circle company-info-icon-tooltip"></i>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="Enter email"
              />
            </div>
            <label htmlFor="website" className="form-label company-info-labels">
              Phone
            </label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="flx-basic-addon1">
                  +44
                </span>
              </div>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                placeholder="07888 435 355"
                aria-label="Phone number"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="website"
                className="form-label company-info-labels"
              >
                Website
              </label>
              <input
                type="text"
                className="form-control flx-comp-settings-form-control"
                id="website"
                placeholder="www.company.com"
              />
            </div>
            <button
              type="submit"
              className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
