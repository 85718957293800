import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { agencyService } from '../_services';
export const agencyActions = {
  agencyAdd,
  agencyList,
  agencyListv2,
  agencyDelete,
  dashbordagencyreport,
  subscribedagencylist,
};

function agencyAdd(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.AGENCY_REQUEST, agencyObj });

      agencyService.agencyAdd(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function dashbordagencyreport(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCY_REQUEST,
        agencyObj,
      });

      agencyService.dashbordagencyreport(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function agencyList(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCY_REQUEST,
        agencyObj,
      });

      agencyService.agencyList(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function agencyListv2(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCY_REQUEST,
        agencyObj,
      });

      agencyService.agencyListv2(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function subscribedagencylist(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCY_REQUEST,
        agencyObj,
      });
      agencyService.subscribedagencylist(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function agencyDelete(agencyObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.AGENCY_REQUEST,
        agencyObj,
      });

      agencyService.agencyDelete(agencyObj).then(
        data => {
          dispatch({
            type: reducerConstants.AGENCY_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.AGENCY_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
