import React, { useEffect, useState } from 'react';
import { AddShiftTimeForm } from '../../Job/AddShiftTimePopup';
import { LoadingImg } from '../../_components';
import { sectionService } from '../../_services';
// import ShiftAddModal from './ShiftAddModal';

function AgencyShiftTime() {
  const [showModal, setShowModal] = useState(false);
  const [myShiftResponse, setMyShiftResponse] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 4,
    },
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    getAllSectionList();
  }, []);

  const getAllSectionList = async (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setError(null);
    setQuery(query);
    setIsLoading(true);
    await sectionService.sectionList(queryNew).then(
      res => {
        if (res?.data?.rows?.length > 0) {
          setMyShiftResponse(res?.data?.rows);
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);

        setError('Error fetching user list. Please try again.');
      },
    );
  };

  function ShiftCard({ title, time, breakTime, id, status }) {
    const [isChecked, setIsChecked] = useState(status === 1);
    const [activateQuery, setActivateQuery] = useState({});

    useEffect(() => {
      setIsChecked(status === 1);
    }, [status]);

    const toggleChecked = (item, status) => {
      const newStatus = status === 1 ? -1 : 1;
      setIsChecked(newStatus);
      activateQuery.status = status;
      activateQuery.id = item.id;
      setActivateQuery(activateQuery);
      setIsLoading(true);
      sectionService.changeStatusShiftTime(activateQuery).then(
        () => {
          getAllSectionList();
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
          setError('Error updating the shift time status. Please try again.');
        },
      );
    };

    return (
      <div
        className={`flx-cards-default-shift-container pointer ${
          isChecked ? 'flx-cards-default-shift-container-checked pointer' : ''
        }`}
        onClick={() => toggleChecked({ id: id }, status === -1 ? 1 : -1)}
      >
        <input
          type="checkbox"
          className="flx-checkbox-shift-timings pointer"
          checked={isChecked}
          onChange={() => {}}
        />
        <h6 className="flx-shift-card-title">{title}</h6>
        <p className="flx-shift-card-text">{time}</p>
        <p className="flx-shift-card-text-shift-brk-time">
          <img
            className="flx-emp-profile-menu-icons"
            src="/assets/img/flx-ag-shift-paid-break.svg"
            alt="Break icon"
          />
          Break time <span style={{ fontWeight: 600 }}>{breakTime} min</span>
        </p>
      </div>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
    getAllSectionList();
  };

  const handleSuccess = () => {
    setSuccessMessage(true);
    setShowModal(false);
    setTimeout(() => {
      setSuccessMessage(false);
      getAllSectionList();
    }, 5000);
  };
  return (
    <div>
      <div
        className="d-md-flex justify-content-between align-items-center"
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <button
          className="btn btn-create-shift-ag-settings"
          onClick={() => {
            setShowModal(true);
          }}
        >
          <i className="flx-plus-icon-create-user fa fa-plus"></i>
          Create Your Shift
        </button>
      </div>
      <div>
        <h2 className="flx-company-info-form-heading2">Shifts</h2>

        <div className="flx-emp-profile-settings-container">
          <p>Select from below shift times or create new one</p>
        </div>
      </div>

      <div className="container my-5"></div>

      {myShiftResponse.length !== 0 && (
        <div className="container my-5 px-0">
          <div className="row flx-cards-ag-shift-main-container no-gutters custom-margin">
            {myShiftResponse.map((shift, index) => (
              <ShiftCard
                key={index}
                title={shift.name}
                time={`${shift.from_time} to ${shift.to_time}`}
                breakTime={shift.breakTime}
                id={shift._id}
                status={shift.status}
              />
            ))}
          </div>
        </div>
      )}

      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <LoadingImg />
          </div>
        </div>
      )}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* {showModal && (
        <div className="request-modal-overlay">
          <div className="popup">
            <ShiftAddModal
              setShowModal={setShowModal}
              getAllSectionList={getAllSectionList}
            />
          </div>
        </div>
      )} */}
      {showModal && (
        <div className="request-modal-overlay">
          <div className="popup">
            <AddShiftTimeForm
              setShowModal={setShowModal}
              getAllSectionList={getAllSectionList}
              handleCloseModal={handleCloseModal}
              existingTimeList={myShiftResponse}
              onSuccess={handleSuccess}
            />
          </div>
        </div>
      )}
      {successMessage && (
        <div className="alert alertboxCustomSuccess">
          <span className="alert-close">X</span>
          <p>Successfully Created</p>
        </div>
      )}
    </div>
  );
}

export default AgencyShiftTime;
