export const IncludePendingSignOffPopup = () => {
  return (
    <div className="modal-dialog flx-modal-popup" role="document">
      <div className="modal-content">
        <div className="modal-header flx-invoice-close-icon">
          <button
            type="button"
            className="close flx-closebutton-popup"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" className="flx-closeicon">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <p className="flx-popup-body flx-invoice-decline-text text-center">
            1 more shift sign-offs are pending for the selected business unit
          </p>

          <div className="mt-2 text-center">
            <label htmlFor="reason" className="form-label">
              Would you like to include?
            </label>
          </div>
        </div>
        <div className="flx-popup-bottom">
          <div className="flx-popup-buttons-invoice-confirm">
            <div className="modal-footer flx-popup-bottom flx-add-shift-popup mb-10">
              <span className="flx-not-include-button-sign-off">
                Don&apos;t Include
              </span>
              <button
                type="button"
                className="btn btn-sm flx-popup-button flx-send-reminder-button"
              >
                Include
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
