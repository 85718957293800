import React, { useEffect, useState } from 'react';

import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { sectionActions } from '../_actions';

const SectionReportGraph = props => {
  const [isLoading, setIsLoading] = useState(false);
  // const [showDoughnut, setshowDoughnut] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  let employer_id = '';

  useEffect(() => {
    if (localStorage.getItem('user')) {
      JSON.parse(localStorage.getItem('user'));
    }
    dashbordgraph();
  }, []);

  const data2 = {
    labels: ['Night', 'Morning', 'Custome'],
    datasets: [
      {
        label: '# of Votes',
        data: performanceData,
        // data: [1, 0, 1],
        backgroundColor: ['#047A79', '#14A0C0', '#01CC9B'],
        borderColor: ['#047A79', '#14A0C0', '#01CC9B'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      cursor: 'pointer',
      display: true,
      // position: 'right',
      labels: {
        usePointStyle: false,
        boxWidth: 10,
      },
    },
  };

  // eslint-disable-next-line no-unused-vars
  const dashbordgraph = () => {
    setIsLoading(true);
    const { dispatch } = props;
    let fromDate = new Date().getFullYear() + '-01-01';
    let toDate = new Date().getFullYear() + '-12-31';

    let querynew = {
      fromDate: fromDate,
      toDate: toDate,
    };
    if (employer_id) {
      querynew.employer_id = employer_id;
    }

    dispatch(sectionActions.getgraphData(querynew)).then(res => {
      if (res) {
        if (
          res?.data?.[0]?.night_percentage == 0 &&
          res?.data?.[0]?.morning_percentage == 0 &&
          res?.data?.[0]?.custome_percentage == 0
        ) {
          // setshowDoughnut(false);
        } else {
          setPerformanceData([
            res?.data?.[0]?.night_percentage,
            res?.data?.[0]?.morning_percentage,
            res?.data?.[0]?.custome_percentage,
          ]);
          // setshowDoughnut(true);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <div className="col-md-12 p-0">
      <div className=" regular-table-with-color  ">
        <div className=" table-responsive main-card-body ">
          <div className="d-flex justify-content-center align-items-center mt-4">
            {!isLoading && (
              <div className="donutgraph">
                <Doughnut data={data2} className="" options={options} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  const { isMenuOpen } = state.misc;

  return {
    user,
    users,
    isMenuOpen,
  };
}

const connectedSectionReportGraph =
  connect(mapStateToProps)(SectionReportGraph);
export { connectedSectionReportGraph as SectionReportGraph };
