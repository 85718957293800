import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

const Shiftdetails = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });
  // effect to call get shifts
  useEffect(() => {
    getAllShifts(1);
  }, []);
  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  let shiftsList = [];
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j}>
          <td style={{ color: '#3D4A54' }}>
            {shiftsData.rows[j].view_check_in_date}
          </td>
          <td style={{ color: '#3D4A54' }}>
            {shiftsData.rows[j].view_check_in_time}
          </td>
          <td style={{ color: '#3D4A54' }}>
            {shiftsData.rows[j].view_check_out_time}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].shift_status}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j]['Candidate.name']}
          </td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };

  return (
    <div className="ShiftsList">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color ml-0 mr-0">
            <div className="card-body  table-responsive main-card-body pl-0 pr-0">
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>check_in_date</th>
                      <th>check_in_time</th>
                      <th>check_out_time</th>
                      <th>shift status</th>
                      <th>candidate name</th>
                    </tr>
                  </thead>
                  <tbody>{shiftsRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {shiftsData &&
                shiftsData.rows &&
                shiftsData.rows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                shiftsData={shiftsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}
const connectedShiftdetails = connect(mapStateToProps)(Shiftdetails);
export { connectedShiftdetails as Shiftdetails };
